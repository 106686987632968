import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Select from "../FormikComponents/Select";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import logo from "../../assets/logo192.png";

interface AssignFPOToClusterModalProps {
    id: string;
    action: string;
    role: string;
}

interface FormValues {
    fpo_id: string;
}

const AssignFPOToClusterModal = ({ id, action, role }: AssignFPOToClusterModalProps) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [fpoOptions, setFpoOptions] = useState<{ value: string; label: string }[]>([]);

    const fetchFPOs = async () => {
        const { data } = await axiosInstance.get("/fpo/all");
        return data;
    };

    const { data, isLoading, isError } = useQuery({
        queryKey: ["getAllFPOs"],
        queryFn: fetchFPOs,
    });

    useEffect(() => {
        if (data && data.data) {
            const options = data.data.map((fpo: { fpo_name: string; fpo_id: string }) => ({
                value: fpo.fpo_id,
                label: `${fpo.fpo_name} (FPO Id: ${fpo.fpo_id})`,
            }));
            setFpoOptions(options);
        }
    }, [data]);

    const initialValues: FormValues = {
        fpo_id: "",
    };

    const validationSchema = Yup.object({
        fpo_id: Yup.string().trim().required("FPO is required"),
    });

    const handleSubmit = async (values: FormValues) => {
        setIsSubmitting(true);
        try {
            const { data } = await axiosInstance.put(
                `/fpo/assign-project-cluster/${values.fpo_id}/${id}`
            );
            if (data) {
                queryClient.invalidateQueries("getAllFPOs");
                dispatch(
                    addToast({
                        kind: SUCCESS,
                        msg: "FPO Assigned Successfully",
                    })
                );
                dispatch(hideModal());
            }
        } catch (error: any) {
            dispatch(
                addToast({
                    kind: ERROR,
                    msg: "Assignment failed",
                })
            );
        }
        setIsSubmitting(false);
    };

    if (isError) {
        dispatch(
            addToast({
                kind: ERROR,
                msg: "Failed to load FPOs. Please try again.",
            })
        );
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            <Form className="px-6 py-4 mt-2" style={{ minWidth: "40vw" }}>
                <div className="flex justify-center items-center bg-white px-6 py-5">
                    <img src={logo} alt="logo" height={100} width={100} />
                </div>

                <div className="flex item-center gap-x-6">
                    {isLoading ? (
                        <div className="w-full">
                            <label className="block text-sm font-medium text-gray-700">FPO</label>
                            <div className="mt-1 py-2">Loading FPOs...</div>
                        </div>
                    ) : fpoOptions.length > 0 ? (
                        <Select
                            options={fpoOptions}
                            label="Select FPO"
                            id="fpo_id"
                            name="fpo_id"
                            placeholder="Select an FPO"
                            classes="w-full"
                        />
                    ) : (
                        <div className="w-full">
                            <label className="block text-sm font-medium text-gray-700">FPO</label>
                            <div className="mt-1 py-2">No FPOs available</div>
                        </div>
                    )}
                </div>

                <div className="buttons flex items-center w-full justify-center my-4 mt-10">
                    <SubmitBtn
                        text="Assign FPO"
                        isSubmitting={isSubmitting}
                        containerClasses="h-full"
                        isSubmittingClasses="lg:mt-6"
                        classes="text-sm lg:p-4 lg:rounded-lg"
                    />
                </div>
            </Form>
        </Formik>
    );
};

export default AssignFPOToClusterModal;
