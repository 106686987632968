import { useParams } from 'react-router-dom';
import Iot from '../iot/iot';

const PyroIot = () => {
    const { id } = useParams();
    return (
        <Iot otherUrl={`/pyrolysis_technology/iot/all?pt_id=${id}`} />
    )
}

export default PyroIot