import { useState, useEffect } from "react";
import { HiOutlineSearch, HiOutlineX } from "react-icons/hi";

interface Props {
  searchValue: string;
  setSearchValue: (value: string) => void;
  handleSearch: () => void;
  placeholder?: string;
}

const SearchBox = ({ searchValue, setSearchValue, handleSearch, placeholder }: Props) => {
  const [inputValue, setInputValue] = useState(searchValue);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setSearchValue(inputValue.trim());
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [inputValue, setSearchValue]);

  useEffect(() => {
    setInputValue(searchValue);
  }, [searchValue]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSearchValue(inputValue.trim());
    handleSearch();
  };

  const clearInput = () => {
    setInputValue("");
    setSearchValue("");
  };

  return (
    <form
      className="flex items-center p-4 mr-4 text-base bg-white border rounded-xl"
      onSubmit={handleSubmit}
    >
      <input
        type="text"
        placeholder={placeholder || "Search"}
        className="bg-transparent outline-none flex-1"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />

      <div className="w-8 flex justify-center items-center">
        {inputValue && (
          <button
            type="button"
            onClick={clearInput}>
            <HiOutlineX className="icon-md" />
          </button>
        )}
      </div>

      <button type="submit">
        <HiOutlineSearch className="icon-md" />
      </button>

    </form>
  );
};

export default SearchBox;
