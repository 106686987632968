import { createSlice } from "@reduxjs/toolkit";

interface SidebarState {
  show: boolean;
}

const getSavedState = (): boolean => {
  const savedState = localStorage.getItem("sidebarState");
  return savedState === null ? true : savedState === "true";
};

const initialState: SidebarState = {
  show: getSavedState(),
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    showSidebar: (state) => {
      state.show = true;
    },

    hideSidebar: (state) => {
      state.show = false;
    },
  },
});

export const { showSidebar, hideSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
