import { useState } from "react";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import { useQuery, useQueryClient } from "react-query";
import {
    ADD_COMPOST_MATERIAL
} from "../../types/constants";
import { MdAdd, MdUnarchive } from "react-icons/md";
import { toast } from "react-toastify";

interface ArchiveMaterialObj {
    material_id: number;
    material_name: string;
}
interface ArchiveMaterialProps {
    otherUrl?: string;
}

const ArchiveCompostMaterial = ({ otherUrl }: ArchiveMaterialProps) => {
    const queryClient = useQueryClient();

    const handleUnarchive = async (id: string) => {
        try {
            const response = await axiosInstance.patch(
                `/biomass_batch/sequestration/compost_material/archive/${id}`,
                { is_archived: false }
            );
            console.log(response);
            toast.success("Compost Material Unarchived Successfully");
            queryClient.invalidateQueries(["getArchiveCompostMaterial"]);
        } catch (error: any) {
            console.error(error);
            toast.error(error.response?.data?.error || "Failed to archive Compost Material");
        }
    };

    const ALL_USERS_COLUMNS = [
        {
            Header: "Sr. No.",
            accessor: 'sr_no',
            Cell: ({ value, row }: any) => {
                return (
                    <>
                        {row.index + 1}
                    </>)
            }
        },
        {
            Header: "Material  Id",
            accessor: "material_id",
            // Cell: ({ row }: any) => {
            //     const material_id = row?.original?.material_id;
            //     return !material_id || material_id === "Not Available" ? (
            //         <p className="">Not Available</p>
            //     ) : (
            //         <NavLink
            //             to={material_id ? `${material_id}` : "#"}
            //             className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
            //             title="View Compost Material Details"
            //         >
            //             {material_id}
            //         </NavLink>
            //     );
            // },
        },
        {
            Header: "Material Name",
            accessor: "material_name",
            // Cell: ({ row }: any) => {
            //     const material_name = row?.original?.material_name || "Not Available";
            //     const material_id = row?.original?.material_id;
            //     return !material_id || material_name === "Not Available" ? (
            //         <p className="">Not Available</p>
            //     ) : (
            //         <NavLink
            //             to={material_id ? `${material_id}` : "#"}
            //             className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
            //             title="View Compost Material Details"
            //         >
            //             {material_name}
            //         </NavLink>
            //     );
        },
        {
            Header: "Unarchive",
            accessor: "unarchive",
            Cell: ({ row }: any) => {
                return (
                    <button
                        onClick={() => handleUnarchive(
                            row?.original.material_id
                        )
                        }
                        className="text-green-600"
                    >
                        <MdUnarchive size={36} />
                    </button>
                );
            },
        },
    ];

    const dispatch = useDispatch();

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState(null);
    const [desc, setDesc] = useState(true);
    const [q, setQ] = useState("");

    const [selectedRows, setSelectedRows] = useState([]);

    let component = null;

    const getArchiveCompostMaterial = async (queryKey: string) => {
        const { data } = await axiosInstance.get(otherUrl || `/biomass_batch/sequestration/compost_material/all?is_archived=true`);
        return data.data;
    };

    const { data, error, isLoading } = useQuery<
        ArchiveMaterialObj[] | undefined,
        any,
        ArchiveMaterialObj[],
        any[]
    >(["getArchiveCompostMaterial", pageIndex, cPageSize, cSortBy, desc, q], () => getArchiveCompostMaterial(q));

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {error.message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance excludeColumnSearch={[]}
                tableData={data || []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1} // do calculation here
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        );
    }

    return (
        <div className="all-users">
            <header className="flex items-center justify-between mb-8">
                <div className="left">
                    <SearchBox
                        searchValue={q}
                        setSearchValue={setQ}
                        handleSearch={() => { }}
                    />

                    {selectedRows.length > 0 && (
                        <span className="btn-primary flex items-center mt-5 w-48">
                            Selected Row : {selectedRows.length}
                        </span>
                    )}

                </div>

                {/* <div className="right">
                    <button
                        className="btn-primary flex items-center"
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_COMPOST_MATERIAL,
                                    modalTitle: "Add Compost Material",
                                })
                            );
                        }}
                    >
                        <span className="mr-1">Add</span> <MdAdd size={17} />
                    </button>
                </div> */}
            </header>

            {component !== null && component}
        </div>
    );
};

export default ArchiveCompostMaterial;
