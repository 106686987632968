import { ReactNode, useState } from "react";
import { useQuery } from "react-query";
import { FaEye } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { useParams } from "react-router-dom";
import axiosInstance from "../../axiosInstance";

interface Field {
    field(updatedAt: string | null): unknown;
    id: string;
    fieldName: string;
    type: string;
    area: number;
    cropVariety: string | null;
    cropCycle: string | null;
    seedType: string | null;
    farmId: string;
    createdAt: string | null;
    updatedAt: string | null;
    soilTexture: string | null;
    cropId: string | null;
    age: number | null;
    treeCount: number | null;
    geofence_coordinates: {
        type: string;
        crs: {
            type: string;
            properties: {
                name: string;
            };
        };
        coordinates: number[][][];
    };
}

interface Farm {
    id: string;
    farmName: string;
    farmerName: string;
    coordinates: string;
    userId: string;
    createdAt: string;
    updatedAt: string;
}

interface FarmData {
    farm: Farm;
    fields: Field[];
}

interface Address {
    [x: string]: string;
    id: string;
    label: string;
    address: string;
    city: string;
    state: string;
    pincode: string;
    addressProofUrl: string;
    coordinates: string;
}

interface Artisan {
    value: any;
    label: ReactNode;
    name: string;
    address: string;
    company_email: string;
    company_mobile: string;
    artisan_kyc_doc_url: string;
    site_image_url: string;
    user_id: string;
    createdAt: string;
    updatedAt: string;
    status: string;
    artisan_email: string;
    company_registration_id: string | null;
    artisan_kyc_doc_type: string;
    internal_fpo_user: boolean;
    fpo_id: string;
    artisan_id: string;
    seller_id: string | null;
}

interface UserBuyerDetailsObj {
    id: string;
    name: string;
    phonenumber: string;
    email: string;
    role: string;
    fpo_id: string | null;
    internal_fpo_user: boolean;
    addresses: Address[];
    artisans: Artisan[];
    farms: FarmData[];
    plantation_trees: any[];
    notes: any[];
    fpo_transactions: any[];
    createdAt?: string;
    updatedAt?: string;
}

const UserBuyerDetails = () => {
    const { id } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [isSite, setIsSite] = useState(false);
    const [isAddressProof, setIsAddressProof] = useState(false);


    const formatDate = (dateString: string | undefined) => {
        if (!dateString) return { date: "Not Available", time: "Not Available" };
        const date = new Date(dateString);
        return {
            date: date.toLocaleDateString("en-GB"),
            time: date.toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
            }).toUpperCase(),
        };
    };


    const getUserDetails = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/user/details/${id}`);
            return data?.data || null;
        } catch (error) {
            console.error("Error fetching user details:", error);
            throw error;
        }
    };

    const { data: user, error, isLoading } = useQuery<UserBuyerDetailsObj | null>(
        ["getUserDetails", id],
        getUserDetails
    );

    return (
        <div className="p-6">
            {error && <p className="text-red-600">Error: {(error as Error).message}</p>}
            {isLoading ? (
                <p className="text-center">Loading...</p>
            ) : user ? (
                <div className="space-y-10">
                    <div className="grid grid-cols-2 gap-4 mb-10">
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Name</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={
                                    (user.name || "Not Available")
                                        .toLowerCase()
                                        .replace(/\b\w/g, (char) => char.toUpperCase())
                                }
                                readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Email</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={user.email || "Not Available"}
                                readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Phone Number</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={user.phonenumber || "Not Available"}
                                readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Role</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={
                                    (user.role || "Not Available")
                                        .toLowerCase()
                                        .replace(/\b\w/g, (char) => char.toUpperCase())
                                }
                                readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Internal FPO User</label>
                            <input
                                type="text"
                                className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 outline-none border-none ${user.internal_fpo_user ? "text-green-600 bg-green-100" : "text-red-700 bg-red-100"
                                    }`}
                                value={user.internal_fpo_user ? "Yes" : "No"}
                                readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">FPO ID</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={user.fpo_id || "Not Available"}
                                readOnly
                            />
                        </div>
                    </div>

                    {user.plantation_trees?.length > 0 && (
                        <div>
                            <h3 className="text-lg font-bold mb-4 text-green-600 flex items-center w-full py-5">
                                <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                                <span className="px-4 text-green-600">Plantation Tress Details</span>
                                <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                            </h3>
                            <div className="grid grid-cols-2 gap-4">
                                {user.plantation_trees.map((tree, index) => (
                                    <div key={index} className="pr-10">
                                        <label className="block text-md font-semibold text-gray-700">{tree.label}</label>
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={
                                                tree.value
                                                    ? tree.value.replace(/\b\w/g, (char: string) => char.toUpperCase())
                                                    : "Not Available"
                                            }
                                            readOnly
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {user.notes?.length > 0 && (
                        <div>
                            <h3 className="text-lg font-bold mb-4 text-green-600 flex items-center w-full py-5">
                                <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                                <span className="px-4 text-green-600">Notes Details</span>
                                <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                            </h3>
                            <div className="grid grid-cols-2 gap-4">
                                {user.notes.map((note, index) => (
                                    <div key={index} className="pr-10">
                                        <label className="block text-md font-semibold text-gray-700">{note.label}</label>
                                        <input
                                            type="text"
                                            className="mt-1 w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={note.value ? note.value.replace(/\b\w/g, (char: string) => char.toUpperCase()) : "Not Available"}
                                            readOnly
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {user.fpo_transactions?.length > 0 && (
                        <div>
                            <h3 className="text-lg font-bold mb-4 text-green-600 flex items-center w-full py-5">
                                <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                                <span className="px-4 text-green-600">FPO Transaction Details</span>
                                <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                            </h3>
                            <div className="grid grid-cols-2 gap-4">
                                {user.fpo_transactions.map((transaction, index) => (
                                    <div key={index} className="pr-10">
                                        <label className="block text-md font-semibold text-gray-700">{transaction.label}</label>
                                        <input
                                            type="text"
                                            className="mt-1 w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={
                                                typeof transaction.value === "number"
                                                    ? transaction.value
                                                    : transaction.value
                                                        ? transaction.value.replace(/\b\w/g, (char: string) => char.toUpperCase())
                                                        : "Not Available"
                                            }
                                            readOnly
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {user.artisans && user.artisans.length > 0 && (
                        <div>
                            <h3 className="text-lg font-bold mb-4 text-green-600 flex items-center w-full py-5">
                                <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                                <span className="px-4 text-green-600">Artisans Details</span>
                                <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                            </h3>
                            {user.artisans.map((artisan, index) => (
                                <div key={index} className="mb-10">
                                    <div className="grid grid-cols-2 gap-4">
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Artisan Name</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={
                                                    (artisan.name || "Not Available").toUpperCase() === artisan.name
                                                        ? artisan.name
                                                        : artisan.name
                                                            .split(' ')
                                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                            .join(' ')
                                                }
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Artisan Email</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={artisan.artisan_email || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Address</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={
                                                    (artisan.address || "Not Available").toUpperCase() === artisan.address
                                                        ? artisan.address
                                                        : artisan.address
                                                            .split(' ')
                                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                            .join(' ')
                                                }
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Company Email</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={artisan.company_email || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Company Mobile</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={artisan.company_mobile || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Company Registration Id</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={artisan.company_registration_id || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Artisan Kyc Doc Type</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={
                                                    artisan.artisan_kyc_doc_type
                                                        ? artisan.artisan_kyc_doc_type
                                                            .split('_')
                                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                            .join(' ')
                                                        : "Not Available"
                                                }
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Status</label>
                                            <input
                                                type="text"
                                                className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 outline-none border-none 
                                ${artisan.status?.toUpperCase() === "APPROVED"
                                                        ? "text-green-700 bg-green-100"
                                                        : "text-red-700 bg-red-100"}`}
                                                value={
                                                    (artisan.status || "Not Available").toUpperCase() === artisan.status
                                                        ? artisan.status
                                                        : artisan.status
                                                            .split(' ')
                                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                            .join(' ')
                                                }
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Internal Fpo User</label>
                                            <input
                                                type="text"
                                                className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 outline-none border-none 
                                ${artisan.internal_fpo_user
                                                        ? "text-green-700 bg-green-100"
                                                        : "text-red-700 bg-red-100"}`}
                                                value={artisan.internal_fpo_user ? "True" : "False"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Fpo Id</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={artisan.fpo_id || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Artisan Id</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={artisan.artisan_id || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Seller Id</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={artisan.seller_id || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Created At</label>
                                            <div className="flex space-x-4">
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={formatDate(artisan.createdAt).date}
                                                    readOnly
                                                />
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={formatDate(artisan.createdAt).time}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Updated At</label>
                                            <div className="flex space-x-4">
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={formatDate(artisan.updatedAt).date}
                                                    readOnly
                                                />
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={formatDate(artisan.updatedAt).time}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">KYC Document</label>
                                            <div className="relative mt-1">
                                                <div className="absolute inset-y-0 left-3 flex items-center">
                                                    <button
                                                        className="text-green-600 hover:text-green-800"
                                                        onClick={() => artisan.artisan_kyc_doc_url && setIsOpen(true)}
                                                    >
                                                        <FaEye size={20} />
                                                    </button>
                                                </div>
                                                <div
                                                    className="block w-full rounded-2xl shadow-sm bg-green-100 text-green-600 px-12 py-3 outline-none border-none text-center cursor-pointer"
                                                    onClick={() => artisan.artisan_kyc_doc_url && setIsOpen(true)}
                                                >
                                                    <span className="font-bold text-lg hover:text-green-800">
                                                        {artisan.artisan_kyc_doc_url ? "Open Image" : "Not Available"}
                                                    </span>
                                                    <span className="text-sm text-green-600 font-bold">
                                                        {artisan.createdAt
                                                            ? ` (${new Date(artisan.createdAt).toLocaleDateString("en-GB")}, ${new Date(artisan.createdAt).toLocaleTimeString("en-GB", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                                second: "2-digit",
                                                                hour12: true,
                                                            }).toUpperCase()})`
                                                            : " (Not Available, Not Available)"}
                                                    </span>
                                                </div>
                                            </div>
                                            {isOpen && (
                                                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
                                                    <div className="relative bg-white rounded-xl shadow-lg max-w-xl w-full">
                                                        <button
                                                            className="absolute top-2 right-2 bg-green-600 text-white p-2 rounded-full hover:bg-green-800"
                                                            onClick={() => setIsOpen(false)}
                                                        >
                                                            <FiX size={20} />
                                                        </button>
                                                        <img
                                                            src={artisan.artisan_kyc_doc_url || "default-image.jpg"}
                                                            alt="Product"
                                                            className="w-full h-auto rounded-lg"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Site Image URL</label>
                                            <div className="relative mt-1">
                                                <div className="absolute inset-y-0 left-3 flex items-center">
                                                    <button
                                                        className="text-green-600 hover:text-green-800"
                                                        onClick={() => artisan.site_image_url && setIsSite(true)}
                                                    >
                                                        <FaEye size={20} />
                                                    </button>
                                                </div>
                                                <div
                                                    className="block w-full rounded-2xl shadow-sm bg-green-100 text-green-600 px-12 py-3 outline-none border-none text-center cursor-pointer"
                                                    onClick={() => artisan.site_image_url && setIsSite(true)}
                                                >
                                                    <span className="font-bold text-lg hover:text-green-800">
                                                        {artisan.site_image_url ? "Open Image" : "Not Available"}
                                                    </span>
                                                    <span className="text-sm text-green-600 font-bold">
                                                        {artisan.createdAt
                                                            ? ` (${new Date(artisan.createdAt).toLocaleDateString("en-GB")}, ${new Date(artisan.createdAt).toLocaleTimeString("en-GB", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                                second: "2-digit",
                                                                hour12: true,
                                                            }).toUpperCase()})`
                                                            : " Not Available, Not Available)"}
                                                    </span>
                                                </div>
                                            </div>
                                            {isSite && (
                                                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
                                                    <div className="relative bg-white rounded-xl shadow-lg max-w-xl w-full">
                                                        <button
                                                            className="absolute top-2 right-2 bg-green-600 text-white p-2 rounded-full hover:bg-green-800"
                                                            onClick={() => setIsSite(false)}
                                                        >
                                                            <FiX size={20} />
                                                        </button>
                                                        <img
                                                            src={artisan.site_image_url || "default-image.jpg"}
                                                            alt="Product"
                                                            className="w-full h-auto rounded-lg"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {user.farms && user.farms.length > 0 && (
                        <div>
                            <h3 className="text-lg font-bold mb-4 text-green-600 flex items-center w-full py-5">
                                <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                                <span className="px-4 text-green-600">Farms Details</span>
                                <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                            </h3>
                            {user.farms.map((farmData) => (
                                <div key={farmData.farm.id} className="mb-10">
                                    <h3 className="text-lg font-bold mb-4 text-green-600 flex items-center w-full py-5">
                                        <span className="px-4 text-green-600">Farm Details</span>
                                    </h3>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Farm Name</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={farmData.farm.farmName || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Farmer Name</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={farmData.farm.farmerName || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Coordinates</label>
                                            {farmData.farm.coordinates ? (
                                                <a
                                                    href={`https://www.google.com/maps?q=${farmData.farm.coordinates.replace(/[()]/g, '')}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-blue-100 px-5 py-3 text-blue-600 font-semibold text-center"
                                                >
                                                    Open Location
                                                </a>
                                            ) : (
                                                <div className="mt-1 block w-full rounded-2xl shadow-sm bg-red-100 px-5 py-3 text-red-600 font-semibold text-center">
                                                    No Coordinates Available
                                                </div>
                                            )}
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Created At</label>
                                            <div className="flex space-x-4">
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={formatDate(farmData.farm.createdAt).date}
                                                    readOnly
                                                />
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={formatDate(farmData.farm.createdAt).time}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Updated At</label>
                                            <div className="flex space-x-4">
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={formatDate(farmData.farm.updatedAt).date}
                                                    readOnly
                                                />
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={formatDate(farmData.farm.updatedAt).time}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <h3 className="text-lg font-bold mb-4 text-green-600 flex items-center w-full py-5">
                                        <span className="px-4 text-green-600 mt-5">Fields Details</span>
                                    </h3>
                                    {farmData.fields.map((field) => (
                                        <div key={field.id} className="mb-5">
                                            <div className="grid grid-cols-2 gap-4">
                                                <div className="pr-10">
                                                    <label className="block text-md font-semibold text-gray-700">Field Name</label>
                                                    <input
                                                        type="text"
                                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                        value={field?.fieldName ? field.fieldName.replace(/\b\w/g, (char) => char.toUpperCase()) : "Not Available"}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="pr-10">
                                                    <label className="block text-md font-semibold text-gray-700">Type</label>
                                                    <input
                                                        type="text"
                                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                        value={field?.type ? field.type.replace(/\b\w/g, (char) => char.toUpperCase()) : "Not Available"}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="pr-10">
                                                    <label className="block text-md font-semibold text-gray-700">Area (Hectares)</label>
                                                    <input
                                                        type="text"
                                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                        value={field.area?.toLocaleString() ?? "Not Available"}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="pr-10">
                                                    <label className="block text-md font-semibold text-gray-700">Age</label>
                                                    <input
                                                        type="text"
                                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                        value={field.age || "Not Available"}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="pr-10">
                                                    <label className="block text-md font-semibold text-gray-700">Soil Texture</label>
                                                    <input
                                                        type="text"
                                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                        value={field?.soilTexture ? field.soilTexture.replace(/\b\w/g, (char) => char.toUpperCase()) : "Not Available"}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="pr-10">
                                                    <label className="block text-md font-semibold text-gray-700">Crop Variety</label>
                                                    <input
                                                        type="text"
                                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                        value={field?.cropVariety ? field.cropVariety.replace(/\b\w/g, (char) => char.toUpperCase()) : "Not Available"}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="pr-10">
                                                    <label className="block text-md font-semibold text-gray-700">Crop Cycle</label>
                                                    <input
                                                        type="text"
                                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                        value={field?.cropCycle ? field.cropCycle.replace(/\b\w/g, (char) => char.toUpperCase()) : "Not Available"}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="pr-10">
                                                    <label className="block text-md font-semibold text-gray-700">Seed Type</label>
                                                    <input
                                                        type="text"
                                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                        value={field?.seedType ? field.seedType.replace(/\b\w/g, (char) => char.toUpperCase()) : "Not Available"}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="pr-10">
                                                    <label className="block text-md font-semibold text-gray-700">Tree Count</label>
                                                    <input
                                                        type="text"
                                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                        value={field.treeCount || "Not Available"}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="pr-10">
                                                    <label className="block text-md font-semibold text-gray-700">Created At</label>
                                                    <div className="flex space-x-4">
                                                        <input
                                                            type="text"
                                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                            value={formatDate(field.createdAt ?? undefined).time}
                                                            readOnly
                                                        />
                                                        <input
                                                            type="text"
                                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                            value={formatDate(field.createdAt ?? undefined).time}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                                <div className="pr-10">
                                                    <label className="block text-md font-semibold text-gray-700">Updated At</label>
                                                    <div className="flex space-x-4">
                                                        <input
                                                            type="text"
                                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                            value={formatDate(field.updatedAt ?? undefined).time}
                                                            readOnly
                                                        />
                                                        <input
                                                            type="text"
                                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                            value={formatDate(field.updatedAt ?? undefined).time}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                                {field.geofence_coordinates && (
                                                    <>
                                                        <div className="pr-10 col-span-2">
                                                            <label className="block text-md font-semibold text-gray-700">Geofence Coordinates</label>
                                                            <textarea
                                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none font-mono text-sm"
                                                                value={
                                                                    field.geofence_coordinates
                                                                        ? JSON.stringify(field.geofence_coordinates, null, 2)
                                                                        : "Not Available"
                                                                }
                                                                readOnly
                                                                rows={6}
                                                            />
                                                        </div>
                                                    </>)
                                                }
                                            </div>
                                            <h3 className="text-lg font-bold mb-4 mt-8 text-green-600 flex items-center w-full py-5">
                                                <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                                                <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                                            </h3>

                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    )}


                    {user.addresses && user.addresses.length > 0 && (
                        <div>
                            <h3 className="text-lg font-bold mb-4 text-green-600 flex items-center w-full py-5">
                                <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                                <span className="px-4 text-green-600">Addresses Details</span>
                                <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                            </h3>
                            {user.addresses.map((address, index) => (
                                <div key={index} className="mb-10">
                                    <div className="grid grid-cols-2 gap-4">
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Label</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={address.label ? address.label.replace(/\b\w/g, (char) => char.toUpperCase()) : "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Address</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={address.address ? address.address.replace(/\b\w/g, (char) => char.toUpperCase()) : "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">City</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={address.city ? address.city.replace(/\b\w/g, (char) => char.toUpperCase()) : "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">State</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={address.state ? address.state.replace(/\b\w/g, (char) => char.toUpperCase()) : "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Pincode</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={address.pincode || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Addressable Id</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={address.addressable_id || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Addressable Type</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={address.addressable_type ? address.addressable_type.replace(/\b\w/g, (char) => char.toUpperCase()) : "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Address Proof</label>
                                            <div className="relative mt-1">
                                                {address.addressProofUrl && (
                                                    <div className="absolute inset-y-0 left-3 flex items-center">
                                                        <button
                                                            className="text-green-600 hover:text-green-800"
                                                            onClick={() => setIsAddressProof(true)}
                                                        >
                                                            <FaEye size={20} />
                                                        </button>
                                                    </div>
                                                )}
                                                <div
                                                    className={`block w-full rounded-2xl shadow-sm px-12 py-3 outline-none border-none text-center cursor-pointer ${address.addressProofUrl ? "bg-green-100 text-green-600" : "bg-red-100 text-red-600"
                                                        }`}
                                                    onClick={() => address.addressProofUrl && setIsAddressProof(true)}
                                                >
                                                    <span
                                                        className={`font-bold text-lg ${address.addressProofUrl ? "hover:text-green-800" : "hover:text-red-800"
                                                            }`}
                                                    >
                                                        {address.addressProofUrl ? "Open Image" : "Not Available"}
                                                    </span>
                                                    {/* <span className="text-sm text-green-600 font-bold">
                                                        {supplier.createdAt
                                                            ? ` (${new Date(supplier.createdAt).toLocaleDateString("en-GB")}, ${new Date(supplier.createdAt).toLocaleTimeString("en-GB", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                                second: "2-digit",
                                                                hour12: true,
                                                            }).toUpperCase()})`
                                                            : " (Not Available, Not Available)"}
                                                    </span> */}
                                                </div>
                                                {/* <div className="absolute inset-y-0 right-3 flex items-center">
                                                    <a
                                                        href={address.addressProofUrl || "#"}
                                                        download="product_image.jpg"
                                                        className="text-green-600 hover:text-green-800"
                                                        onClick={(e) => {
                                                            if (!address.addressProofUrl) {
                                                                e.preventDefault();
                                                                alert("No image available to download.");
                                                            }
                                                        }}
                                                    >
                                                        <FiDownload size={20} />
                                                    </a>
                                                </div> */}
                                            </div>
                                            {isAddressProof && (
                                                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
                                                    <div className="relative bg-white rounded-xl shadow-lg max-w-xl w-full">
                                                        <button
                                                            className="absolute top-2 right-2 bg-green-600 text-white p-2 rounded-full hover:bg-green-800"
                                                            onClick={() => setIsAddressProof(false)}
                                                        >
                                                            <FiX size={20} />
                                                        </button>
                                                        <img
                                                            src={address.addressProofUrl || "default-image.jpg"}
                                                            alt="Address Proof"
                                                            className="w-full h-auto rounded-lg"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Coordinates</label>
                                            {address?.coordinates ? (
                                                <a
                                                    href={`https://www.google.com/maps?q=${address.coordinates.split(',')[0]},${address.coordinates.split(',')[1]}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-blue-100 px-5 py-3 text-blue-600 font-semibold text-center"
                                                >
                                                    Open Location
                                                </a>
                                            ) : (
                                                <div className="mt-1 block w-full rounded-2xl shadow-sm bg-red-100 px-5 py-3 text-red-600 font-semibold text-center">
                                                    No Coordinates Available
                                                </div>
                                            )}
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Geofence Coordinates</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={address.geofence_coordinates || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ) : (
                <p className="text-center">No user data found</p>
            )
            }
        </div >
    );
};

export default UserBuyerDetails;