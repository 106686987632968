import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { addToast } from "../../redux/features/toastSlice";
import { SUCCESS } from "../../types/constants";
import Loader from "../Loader/Loader";

interface ViewArtisanOrBatchesModalProps {
  id: string;
}

const ViewArtisanBatches = (props: ViewArtisanOrBatchesModalProps) => {
  const { id } = props;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [batchList, setBatchList] = useState<any | null>(null);

  useEffect(() => {
    const getAllBatchDetails = async () => {
      setIsLoading(true);
      try {
        const result = await axiosInstance.get(
          "/biomass_batch/admin/all?artisan_id=" + id
        );
        setBatchList(result.data.data);
        if (result.data.data.length === 0) setBatchList(null);
      } catch (error: any) {
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Error Fetching Artisans List",
          })
        );
      }
      setIsLoading(false);
    };
    getAllBatchDetails();
  }, [id]);

  if (isLoading) return <Loader />;

  return (
    <div
      className="px-6 py-4 mt-2"
      // style={{
      //   minWidth: "360px",
      //   maxWidth: "760px",
      // }}
      style={{ minWidth: "40vw" }}
    >
      <div className="flex flex-row item-center gap-x-6">
        <div className="text-xl flex flex-col gap-4 max-h-[900px] overflow-x-scroll">
          {batchList && batchList.length > 0 ? (
            batchList.map((batch: any) => {
              return (
                <div
                  key={batch.id}
                  className="flex flex-col gap-2 border border-gray-200 p-4 rounded-md"
                >
                  <div className="flex flex-row gap-2">
                    <div className="font-semibold">Batch Number:</div>
                    <div>{batch?.biomass_batch_id}</div>
                  </div>

                  <div className="flex flex-row gap-2">
                    <div className="font-semibold">Preparation Id:</div>
                    <div>{batch?.preparation_id ?? "null"}</div>
                  </div>

                  <div className="flex flex-row gap-2">
                    <div className="font-semibold">Pyrolysis Id:</div>
                    <div>{batch?.pyrolysis_id ?? "null"}</div>
                  </div>

                  <div className="flex flex-row gap-2">
                    <div className="font-semibold">Biochar Id:</div>
                    <div>{batch?.biochar_id ?? "null"}</div>
                  </div>

                  <div className="flex flex-row gap-2">
                    <div className="font-semibold">Sequestration Id:</div>
                    <div>{batch?.sequestration_id ?? "null"}</div>
                  </div>

                  <div className="flex flex-row gap-2">
                    <div className="font-semibold">Status:</div>
                    <div className="capitalize">{batch?.status}</div>
                  </div>
                  
                </div>
              );
            })
          ) : (
            <div>{!batchList && <p>No Batches Created</p>}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewArtisanBatches;
