import { useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import { useParams } from "react-router-dom";
import { FaEye } from "react-icons/fa";

interface SemiIndustrialObj {
    pyro_eq_si_id: number;
    pyro_eq_name: string;
    pyro_eq_model_name: string;
    pyro_eq_manufacturer: string;
    pyro_eq_methane_emission: string;
    length: string;
    breadth: string;
    height: string;
    pyro_eq_image: string;
    created_at: string;
    updated_at: string;
    is_archived: boolean;
}

const SemiIndustrialDetails = () => {
    const { id } = useParams<{ id: string }>();

    const getSemiIndustrialDetails = async () => {
        try {
            const response = await axiosInstance.get(`/pyrolysis_technology/semi_Industrial/${id}`);
            return response.data;
        } catch (error) {
            console.error("Error fetching semi-industrial details:", error);
            throw error;
        }
    };

    const { data, error, isLoading } = useQuery<{ data: SemiIndustrialObj }>(
        ["getSemiIndustrialDetails", id],
        getSemiIndustrialDetails,
        {
            refetchOnWindowFocus: false,
            retry: 1,
        }
    );

    if (isLoading) return <p className="mt-6 text-center">Loading...</p>;
    if (error) return <p className="mt-6 text-center text-red-600">Error: {(error as Error).message}</p>;

    const equipmentData = data?.data;

    return (
        <div className="p-6">
            {equipmentData ? (
                <div className="space-y-10">
                    <div className="grid grid-cols-2 gap-6 mb-10">
                        {/* Equipment Details */}
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Equipment Name</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={equipmentData.pyro_eq_name || "N/A"}
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Model Name</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={equipmentData.pyro_eq_model_name || "N/A"}
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Manufacturer</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={equipmentData.pyro_eq_manufacturer || "N/A"}
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Methane Emission</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={equipmentData.pyro_eq_methane_emission || "N/A"}
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Length (cm)</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={equipmentData.length || "N/A"}
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Breadth (cm)</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={equipmentData.breadth || "N/A"}
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Height (cm)</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={equipmentData.height || "N/A"}
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Equipment Image</label>
                            <div className="mt-1">
                                {equipmentData.pyro_eq_image ? (
                                    <a
                                        href={equipmentData.pyro_eq_image}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center w-full rounded-2xl shadow-sm bg-green-100 px-5 py-3 hover:bg-green-200 transition-colors font-bold"
                                    >
                                        <FaEye className="w-5 h-5 mr-2 text-green-600" />
                                        <span className="flex-1 text-center text-green-600">View Image</span>
                                    </a>
                                ) : (
                                    <div className="w-full rounded-2xl shadow-sm bg-red-100 px-5 py-3 text-center">
                                        Not Available
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Timestamps */}
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Created At</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={equipmentData.created_at ? new Date(equipmentData.created_at).toLocaleDateString("en-GB") : "N/A"}
                                    readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        equipmentData.created_at
                                            ? new Date(equipmentData.created_at).toLocaleTimeString("en-GB", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: true
                                            }).toUpperCase()
                                            : "N/A"
                                    }
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Updated At</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={equipmentData.updated_at ? new Date(equipmentData.updated_at).toLocaleDateString("en-GB") : "N/A"}
                                    readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        equipmentData.updated_at
                                            ? new Date(equipmentData.updated_at).toLocaleTimeString("en-GB", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: true
                                            }).toUpperCase()
                                            : "N/A"
                                    }
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <p className="mt-6 text-center">No equipment found with ID: {id}</p>
            )}
        </div>
    );
};

export default SemiIndustrialDetails;