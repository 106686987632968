import { Form, Formik } from "formik";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { uploadImage } from "../../http/uploadImage";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import {
  AZURE_STORAGE_DIRECTORY,
  AZURE_STORAGE_SUBDIRECTORY,
  ERROR,
  SUCCESS,
} from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import Select from "../FormikComponents/Select";
import { VOLUME_UNIT } from "../../types/constants";
import logo from "../../assets/logo192.png";

interface FileWithPreview extends File {
  preview?: string;
}

interface FormValues {
  vessel_name: string;
  manufacturer_name: string;
  vessel_type: string;
  volume_value: number;
  volume_unit: string;
  pt_id: string;
}

interface PyrolysisTechnology {
  pt_id: number;
  name: string;
}

interface PyrolysisTechnologyResponse {
  msg: string;
  data: {
    pyro_techs: PyrolysisTechnology[];
    count: number;
  };
}

const AddMeasuringVesselModal = ({ otherUrl }: { otherUrl?: string }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  // Changed from single file to array of files
  const [files, setFiles] = useState<FileWithPreview[]>([]);

  const { data: pyrolysisTechnologies, isLoading } = useQuery<PyrolysisTechnologyResponse>(
    ["getAllPyrolysisTechnologies"],
    async () => {
      const response = await axiosInstance.get("/pyrolysis_technology/all");
      return response.data;
    }
  );

  const initialValues: FormValues = {
    vessel_name: "",
    manufacturer_name: "",
    vessel_type: "",
    volume_value: 0,
    volume_unit: VOLUME_UNIT[0].value,
    pt_id: otherUrl?.split("=")[1] || "",
  };

  const validationSchema = Yup.object({
    vessel_name: Yup.string().trim().required("Vessel Name is Required"),
    manufacturer_name: Yup.string().trim().required("Manufacturer Name is Required"),
    vessel_type: Yup.string().trim().required("Vessel Type is Required"),
    volume_value: Yup.number().required("Volume is Required"),
    volume_unit: Yup.string().trim().required("Volume Unit is Required"),
    pt_id: Yup.string().trim().required("PT ID is Required"),
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      const totalFiles = files.length + selectedFiles.length;

      if (totalFiles > 11) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "You can upload a maximum of 11 images",
          })
        );
        return;
      }

      const newFiles = selectedFiles.map((file) => {
        if (file.type.startsWith("image/")) {
          const fileWithPreview = file as FileWithPreview;
          fileWithPreview.preview = URL.createObjectURL(file);
          return fileWithPreview;
        }
        return file;
      });

      setFiles((prev) => [...prev, ...newFiles]);
    }
  };

  const removeFile = (index: number) => {
    const updatedFiles = [...files];
    if (updatedFiles[index].preview) {
      URL.revokeObjectURL(updatedFiles[index].preview!);
    }
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleSubmit = async (values: FormValues) => {
    if (files.length === 0) {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "At least one file is required",
        })
      );
      return;
    }

    setIsSubmitting(true);
    try {
      const uploadPromises = files.map((file) =>
        uploadImage(file, AZURE_STORAGE_DIRECTORY.MEASURING_VESSEL, AZURE_STORAGE_SUBDIRECTORY.PROFILE)
      );
      const uploadResults = await Promise.all(uploadPromises);
      const imageUrls = uploadResults.map((result) => result.data.publicUrl).filter(Boolean);

      const vessel_images: { [key: string]: string } = {};
      imageUrls.forEach((url, index) => {
        vessel_images[`image${index + 1}`] = url;
      });

      const combinedVolume = `${values.volume_value} ${values.volume_unit}`;
      const obj = {
        vessel_name: values.vessel_name,
        manufacturer_name: values.manufacturer_name,
        vessel_type: values.vessel_type,
        volume: combinedVolume,
        vessel_images,
        pt_id: Number(values.pt_id),
      };

      const result = await axiosInstance.post("/pyrolysis_technology/measuring_vessel/create", obj);
      if (result) {
        setIsSubmitting(false);
        queryClient.invalidateQueries(["getAllMeasuringVessel"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Measuring Vessel Added Successfully!",
          })
        );
        dispatch(hideModal());
      }
    } catch (error: any) {
      setIsSubmitting(false);

      if (error.response) {
        const response = error.response;
        const { msg } = response.data;

        switch (response.status) {
          case 400:
          case 500:
            dispatch(
              addToast({
                kind: ERROR,
                msg: msg,
              })
            );
            break;
          default:
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Oops, something went wrong",
              })
            );
            break;
        }
      } else if (error.request) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Oops, something went wrong",
          })
        );
      } else {
        dispatch(
          addToast({
            kind: ERROR,
            msg: `Error: ${error.message}`,
          })
        );
      }
    }
    setIsSubmitting(false);
  };

  const ptOptions = (pyrolysisTechnologies?.data?.pyro_techs || []).map((tech) => ({
    value: tech.pt_id.toString(),
    label: `${tech.name} (Pyro Tech Id: ${tech.pt_id})`,
  }));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form className="px-6 py-4 mt-2" style={{ minWidth: "40vw" }}>
        <div className="flex justify-center items-center bg-white px-6 py-5">
          <img src={logo} alt="logo" height={100} width={100} />
        </div>

        <div className="flex items-center gap-x-6">
          <Input
            label="Vessel Name"
            id="vessel_name"
            name="vessel_name"
            type="text"
            placeholder="Enter Vessel Name"
          />
        </div>

        <div className="flex items-center gap-x-6">
          <Input
            label="Manufacturer Name"
            id="manufacturer_name"
            name="manufacturer_name"
            type="text"
            placeholder="Enter Manufacturer Name"
          />
        </div>

        <div className="flex items-center gap-x-6">
          <Input
            label="Vessel Type"
            id="vessel_type"
            name="vessel_type"
            type="text"
            placeholder="Enter Vessel Type"
          />
        </div>

        <div className="flex items-center gap-x-6">
          <Input
            label="Volume"
            id="volume_value"
            name="volume_value"
            type="number"
          />
          <div className="mb-5">
            <Select
              name="volume_unit"
              id="volume_unit"
              label="Unit"
              options={VOLUME_UNIT}
            />
          </div>
        </div>

        <div className="flex items-center gap-x-6 mb-5">
          <Select
            label="Pyrolysis Technology Id"
            id="pt_id"
            name="pt_id"
            options={ptOptions}
            isLoading={isLoading}
            disabled={otherUrl ? true : false}
            placeholder="Select Pyrolysis Technology Id"
            classes="w-full"
          />
        </div>

        <div className="flex items-center gap-x-6">
          <div className="w-full">
            <label htmlFor="vessel_file" className="block mb-1 text-gray-700 font-bold text-sm">
              Upload Measuring Vessel Files (Image or PDF)
            </label>
            <input
              id="vessel_file"
              name="vessel_file"
              type="file"
              accept=".jpg,.png,.jpeg,.webp,.pdf"
              multiple
              onChange={handleFileChange}
              className="w-full p-2 border border-gray-300 rounded-lg"
            />
            <p className="text-xs text-gray-500 mt-1">*You can upload multiple files</p>
          </div>
        </div>

        {/* Preview section for uploaded files */}
        {files.length > 0 && (
          <div className="mt-4 mb-6">
            <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
              {files.map((file, index) => (
                <div key={index} className="relative border rounded-lg p-2">
                  {file.preview ? (
                    <img
                      src={file.preview}
                      alt={`Preview ${index}`}
                      className="h-20 w-full object-cover rounded-lg mb-1"
                    />
                  ) : (
                    <div className="h-20 w-full flex items-center justify-center bg-gray-100 rounded-lg mb-1">
                      <span className="text-xs text-gray-500">File</span>
                    </div>
                  )}
                  <div className="flex justify-between items-center">
                    <span className="text-xs truncate max-w-[80%]">{`file${index + 1}: ${file.name}`}</span>
                    <button
                      type="button"
                      onClick={() => removeFile(index)}
                      className="text-red-500 hover:text-red-700 text-xs"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Add Measuring Vessel"
            isSubmitting={isSubmitting}
            containerClasses="h-full"
            isSubmittingClasses="lg:mt-6"
            classes="text-sm lg:p-4 lg:rounded-lg"
          />
        </div>
      </Form>
    </Formik>
  );
};

export default AddMeasuringVesselModal;