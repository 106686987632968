import { useState } from "react";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { FaEye } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import { showModal } from "../../redux/features/modalSlice";
import { ADD_ARTISAN_GROUND_STAFF_MODAL, ASSIGN_SITE_TO_ARTISAN } from "../../types/constants";

interface ArtisanGStaffObj {
    artisan_gs_id: string;
    name: string;
    mobile: string;
    kyc_doc_url: string;
    profile_picture: string | null;
    status: string;
    email: string;
    fpo_id: string;
    fpo_name: string;
}

interface ArtisanGroundStaffProps {
    otherUrl?: string;
}

const ArtisanGroundStaff = ({ otherUrl }: ArtisanGroundStaffProps) => {
    const dispatch = useDispatch();

    const STAFF_COLUMNS = [
        {
            Header: "Sr. No",
            accessor: "serialNumber",
            Cell: ({ row }: any) => row?.index + 1 || "N/A",
        },
        {
            Header: "Staff ID",
            accessor: "artisan_gs_id",
            Cell: ({ value }: any) => value || "Not Available",
        },
        {
            Header: "Name",
            accessor: "name",
            Cell: ({ value }: any) => (
                <span>
                    {value
                        ? value.replace(/\b\w/g, (char: string) => char.toUpperCase())
                        : "Not Available"}
                </span>
            ),
        },
        {
            Header: "Date of Birth",
            accessor: "dob",
            Cell: ({ value }: any) =>
                value ? new Date(value).toLocaleString() : "Not Available",
        },
        {
            Header: "Mobile",
            accessor: "mobile",
            Cell: ({ value }: any) => value || "Not Available",
        },
        {
            Header: "Email",
            accessor: "email",
            Cell: ({ value }: any) => value || "Not Available",
        },
        {
            Header: "FPO Id",
            accessor: "fpo_id",
            Cell: ({ value }: any) => value || "Not Available",
        },
        {
            Header: "FPO Name",
            accessor: "fpo_name",
            Cell: ({ value }: any) => (
                <span>
                    {value
                        ? value.replace(/\b\w/g, (char: string) => char.toUpperCase())
                        : "Not Available"}
                </span>
            ),
        },
        {
            Header: "Site ID",
            accessor: "site_id",
            Cell: ({ value, row }: any) => {
                return value !== null ? (
                    value
                ) : (
                    <button
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ASSIGN_SITE_TO_ARTISAN,
                                    modalTitle: "Assign Site",
                                    modalProps: {
                                        artisan_gs_id: row.original.artisan_gs_id,
                                    },
                                })
                            );
                        }}
                        className="bg-green-600 text-white px-2 py-3"
                    >
                        Assign Site
                    </button>
                );
            },
        },
        {
            Header: "KYC Doc",
            accessor: "kyc_doc_url",
            Cell: ({ value }: any) =>
                value ? (
                    <a href={value} className="text-green-400" target="_blank" rel="noreferrer">
                        <button className="bg-green-600 text-white px-2 py-3">
                            <FaEye />
                        </button>
                    </a>
                ) : (
                    <span className="text-gray-400">Not Available</span>
                ),
        },
        {
            Header: "Profile Picture",
            accessor: "profile_picture",
            Cell: ({ value }: any) =>
                value ? (
                    <a
                        href={value}
                        className="text-green-400"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <button className="bg-green-600 text-white px-2 py-3">
                            <FaEye />
                        </button>
                    </a>
                ) : (
                    <span className="text-gray-400">Not Available</span>
                ),
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: ({ value }: any) => (
                <span>
                    {value
                        ? value.replace(/\b\w/g, (char: string) => char.toUpperCase())
                        : "Not Available"}
                </span>
            ),
        },
    ];

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState(null);
    const [desc, setDesc] = useState(true);
    const [q, setQ] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);

    let component = null;

    const getAllArtisanGStaff = async ({ queryKey }: any) => {
        const [, pageIdx, pageSize, sortBy, isDesc, searchQuery] = queryKey;
        try {
            const params = new URLSearchParams({
                limit: pageSize?.toString() || "10",
                offset: (pageIdx * pageSize)?.toString() || "0",
                ...(sortBy && { sort_by: sortBy }),
                ...(sortBy && { desc: isDesc?.toString() || "true" }),
                ...(searchQuery && { search: searchQuery }),
            });

            const { data } = await axiosInstance.get(
                otherUrl || `/artisan_gstaff/all?${params.toString()}`
            );

            if (!data?.data?.artisan_g_staffs) {
                throw new Error("Invalid data structure received");
            }

            return data.data.artisan_g_staffs.map((staff: ArtisanGStaffObj) => ({
                ...staff,
                artisan_gs_id: staff.artisan_gs_id || "N/A",
                name: staff.name || "Unknown",
                mobile: staff.mobile || "N/A",
                email: staff.email || "N/A",
                fpo_id: staff.fpo_id || "N/A",
                fpo_name: staff.fpo_name || "N/A",
                status: staff.status || "Unknown",
                kyc_doc_url: staff.kyc_doc_url || null,
                profile_picture: staff.profile_picture || null,
            }));
        } catch (error: any) {
            toast.error(error.message || "Failed to fetch artisan ground staff");
            throw error;
        }
    };

    const { data, error, isLoading } = useQuery<
        ArtisanGStaffObj[] | undefined,
        any,
        ArtisanGStaffObj[],
        any[]
    >(
        ["getAllArtisanGStaff", pageIndex, cPageSize, cSortBy, desc, q],
        getAllArtisanGStaff,
        {
            retry: 1,
            refetchOnWindowFocus: false,
            onError: (err: any) => {
                toast.error(err.message || "Failed to load data");
            },
        }
    );

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {error.message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        try {
            component = (
                <TableInstance
                    excludeColumnSearch={[]}
                    tableData={data || []}
                    columnName={STAFF_COLUMNS}
                    cPageSize={cPageSize || 10}
                    cSetPageSize={cSetPageSize}
                    pageIndex={pageIndex || 0}
                    setPageIndex={setPageIndex}
                    pageCount={-1}
                    cSortBy={cSortBy}
                    cSetSortBy={cSetSortBy}
                    desc={desc ?? true}
                    setDesc={setDesc}
                    q={q || ""}
                    setQ={setQ}
                    setSelectedRows={setSelectedRows}
                    selectRow={true}
                />
            );
        } catch (tableError: any) {
            component = (
                <p className="mt-6 ml-4 text-center">
                    Error rendering table: {tableError.message}
                </p>
            );
        }
    }

    return (
        <div className="all-users">
            <header className="flex items-center justify-between mb-8">
                <div className="left">
                    <SearchBox
                        searchValue={q}
                        setSearchValue={setQ}
                        handleSearch={() => { }}
                    />
                    {selectedRows.length > 0 && (
                        <span className="btn-primary flex items-center mt-5 w-48">
                            Selected Row : {selectedRows.length}
                        </span>
                    )}
                </div>

                <div className="right">
                    <button
                        className="btn-primary flex items-center"
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_ARTISAN_GROUND_STAFF_MODAL,
                                    modalTitle: "Add Artisan Ground Staff",
                                })
                            );
                        }}
                    >
                        <span className="mr-1">Add</span> <MdAdd size={17} />
                    </button>
                </div>
            </header>
            {component !== null && component}
        </div>
    );
};

export default ArtisanGroundStaff;