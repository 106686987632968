import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { Option } from "../../types";
import { ERROR } from "../../types/constants";
import logo from "../../assets/logo192.png";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface AssignSiteToArtisanProps {
    artisan_id?: string;
    artisan_gs_id?: string;
}

const AssignSiteToArtisan = (props: AssignSiteToArtisanProps) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [sites, setSites] = useState<Option[] | null>(null);

    const getAllSites = async () => {
        try {
            const { data } = await axiosInstance.get("/site/all?limit=10&offset=0");
            return data;
        } catch (error) {
            console.error("Error fetching sites:", error);
        }
    };

    const { data, status } = useQuery({
        queryKey: ["getAllSites"],
        queryFn: getAllSites,
    });

    useEffect(() => {
        if (status === "success" && data) {
            const options = data?.data?.sites?.map((item: any) => ({
                label: `${item.site_name} (Site Id: ${item.site_id})`,
                value: item.site_id,
            }));
            setSites(options);
        } else if (status === "error") {
            dispatch(
                addToast({
                    kind: ERROR,
                    msg: "An error occurred while fetching sites",
                })
            );
        }
    }, [status, data, dispatch]);

    const initialValues = { site: "" };

    const validationSchema = Yup.object({
        site: Yup.string().required("Please select a site"),
    });

    const handleSubmit = async (
        values: { site: string },
        { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
    ) => {
        if (!values.site) {
            dispatch(
                addToast({
                    kind: ERROR,
                    msg: "Please select a site",
                })
            );
            setSubmitting(false);
            return;
        }

        if (!props.artisan_id && !props.artisan_gs_id) {
            dispatch(
                addToast({
                    kind: ERROR,
                    msg: "Artisan identifier not provided",
                })
            );
            setSubmitting(false);
            return;
        }

        try {
            const payload: any = {};
            if (props.artisan_id) {
                payload.artisan_id = props.artisan_id;
            }
            if (props.artisan_gs_id) {
                payload.artisan_gs_id = props.artisan_gs_id;
            }

            console.log("Payload being sent:", payload);

            const { data } = await axiosInstance.patch(
                `/artisan/assign-site/${values.site}`,
                payload
            );
            if (data) {
                toast.success("Site Assigned Successfully");
                queryClient.invalidateQueries("getArtisans");
                dispatch(hideModal());
            }
        } catch (error: any) {
            console.error("Error assigning site:", error);
            if (error.response) {
                const { msg } = error.response.data;
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: msg || "An error occurred",
                    })
                );
            } else {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: `Error: ${error.message}`,
                    })
                );
            }
        }
        setSubmitting(false);
    };

    return (
        <div className="px-6 py-4 mt-2" style={{ minWidth: "40vw" }}>
            <div className="flex justify-center items-center bg-white px-6 py-5">
                <img src={logo} alt="logo" height={100} width={100} />
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, values, isSubmitting }) => (
                    <Form className="flex flex-col">
                        <div className="flex flex-col gap-4 mb-10">
                            <p className="font-bold">Select Site:</p>
                            {sites ? (
                                <Select
                                    options={sites}
                                    onChange={(option) =>
                                        setFieldValue("site", option ? option.value : "")
                                    }
                                    value={sites.find((opt) => opt.value === values.site) || null}
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor: "green",
                                            outlineColor: "green",
                                            outline: "none",
                                        }),
                                    }}
                                    placeholder="Select a site"
                                />
                            ) : (
                                <span>No site to show</span>
                            )}
                        </div>

                        <div className="buttons flex items-center w-full justify-center my-4">
                            <SubmitBtn
                                text="Assign Site"
                                isSubmitting={isSubmitting}
                                containerClasses="h-full"
                                isSubmittingClasses="lg:mt-6"
                                classes={`text-sm lg:p-4 lg:rounded-lg ${!values.site
                                    ? "bg-red-500 opacity-50"
                                    : "bg-green-500 hover:bg-green-600"
                                    }`}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AssignSiteToArtisan;
