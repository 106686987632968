import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
    ADD_BUYER_MODAL,
    ASSIGN_FPO_MODAL,
    EDIT_BUYER_MODAL,
} from "../../types/constants";
import {
    useParams,
    NavLink,
    useLocation
} from "react-router-dom";
import useAssignFPO from "../../hooks/useAssignFPO";
import useIsAdmin from "../../hooks/useIsAdmin";
import { FaPen } from "react-icons/fa";

interface UserObj {
    id: string;
    role: string;
    name: string;
    email: string;
    createdAt: string;
    fpo_id?: string;
    fpo_name?: string;
    relationshipManager?: string;
}

interface TableColumn {
    Header: string;
    accessor: string;
    Cell?: ({ row }: any) => JSX.Element;
}

interface BuyerProps {
    otherUrl?: string;
    otherFields?: TableColumn[];
}

const UserBuyers = (props: BuyerProps) => {
    const params = useParams();
    const fpo_id = params?.id || null;
    const { otherUrl, otherFields } = props;
    const { handleFPOAssign } = useAssignFPO();
    const { isAdmin } = useIsAdmin();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const ALL_USERS_COLUMNS: TableColumn[] = [
        {
            Header: "Sr. No",
            accessor: "serialNumber",
            Cell: ({ row }: any) => row.index + 1,
        },
        // {
        //     Header: "User ID",
        //     accessor: "id",
        //     Cell: ({ row }: any) => {
        //         const id = row?.original.id;
        //         return (
        //             <NavLink
        //                 to={`${row?.original.id}`}
        //                 className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
        //                 title={`View General User Details`}
        //             >
        //                 {id}
        //             </NavLink>
        //         );
        //     },
        // },
        {
            Header: "Name",
            accessor: "name",
            Cell: ({ row }: any) => {
                const name = row?.original.name || "Not Available";
                const id = row?.original.id || "#";
                return (
                    <NavLink
                        to={`details/${id}`}
                        className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
                        title="View General User Details"
                    >
                        {name}
                    </NavLink>
                );
            },
        },
        {
            Header: "Email",
            accessor: "email",
            Cell: ({ value }: any) => {
                const email = value && value.trim().toLowerCase() !== "n/a" ? value : "Not Available";
                return <span>{email}</span>;
            },
        },
        {
            Header: "Role",
            accessor: "role",
            Cell: ({ value }: { value?: string }) => (
                <span>{value ? value.charAt(0).toUpperCase() + value.slice(1) : "Not Available"}</span>
            ),
        },
        {
            Header: "Relationship Manager",
            accessor: "relationshipManager",
            Cell: ({ row }: any) => {
                const relationshipManager = row?.original.relationshipManager;
                return relationshipManager === null ? (
                    <p >Not Available</p>
                ) : (
                    <NavLink
                        to={`${row?.original.id}`}
                        className="text-green px-2 py-3"
                    >
                        {relationshipManager}
                    </NavLink>
                );
            },
        },
        {
            Header: "Created At",
            accessor: "createdAt",
            Cell: ({ value }: any) => {
                return value ? new Date(value).toLocaleString() : "Invalid Date";
            },
        },
        {
            Header: "Edit Access",
            accessor: "editAccess",
            Cell: ({ value }: any) =>
                (value ? "Yes" : "No"),
        },
    ];
    if (isAdmin) {
        ALL_USERS_COLUMNS.push({
            Header: "Assigned FPO",
            accessor: "fpo_id",
            Cell: ({ row }: any) => {
                const fpo_id = row.original.fpo_id || null;
                const fpo_name = row.original.fpo_name || null;
                return (
                    <>
                        {fpo_id && (
                            <p className="text-green-400">
                                {fpo_name} :
                                <br />({fpo_id})
                            </p>
                        )}
                        {!fpo_id && (
                            <p className="text-red-400">No FPO assigned</p>
                        )}
                    </>
                );
            },
        });
        ALL_USERS_COLUMNS.push({
            Header: "Assign/Unassign FPO",
            accessor: "assignFPO",
            Cell: ({ row }: any) => {
                const fpo_id = row.original.fpo_id || null;
                const is_fpo_details_page = useLocation()
                    .pathname.includes("/fpo/details");
                return (
                    <button
                        onClick={() => {
                            if (fpo_id) {
                                handleFPOAssign({
                                    id: row.original.id,
                                    action: "unassign",
                                    user_role: "user",
                                    fpo_id: row.original.fpo_id,
                                })
                                    .then(() => {
                                        queryClient.invalidateQueries(["getAllUsers"]);
                                    })
                                    .catch((err) => {
                                        console.error("Failed to unassign FPO:", err);
                                    });
                                return;
                            }
                            dispatch(
                                showModal({
                                    modalType: ASSIGN_FPO_MODAL,
                                    modalTitle: "Assign FPO Modal",
                                    modalProps: {
                                        id: row.original.id,
                                        action: fpo_id
                                            ? "unassign"
                                            : "assign",
                                        role: "user",
                                        fpo_id: fpo_id,
                                        onSuccess: () => {
                                            queryClient.invalidateQueries(["getAllUsers"]);
                                        },
                                    },
                                })
                            );
                        }}
                        className={`${fpo_id ? "bg-red-500" : "bg-green-600"
                            } text-white px-2 py-3`}
                    >
                        {is_fpo_details_page
                            ? "Unlink General User"
                            : fpo_id
                                ? "Unassign FPO"
                                : "Assign FPO"}
                    </button>
                );
            },
        });
    }
    ALL_USERS_COLUMNS.push({
        Header: "Edit",
        accessor: "edit",
        Cell: ({ row }: any) => (
            <button
                onClick={() => {
                    dispatch(
                        showModal({
                            modalType: EDIT_BUYER_MODAL,
                            modalTitle: "Edit General User",
                            modalProps: {
                                user_id: row.original.id,
                                name: row.original.name,
                                role: row.original.role,
                                email: row.original.email,
                                relationshipManager: row.original.relationshipManager,
                            },
                        })
                    );
                }}
                className="bg-green-600 text-white px-2 py-3"
            >
                <FaPen />
            </button>
        ),
    });

    if (otherFields) {
        const validFields = otherFields.map((field) => ({
            ...field,
            Cell: field.Cell || (() => <></>),
        }));
        ALL_USERS_COLUMNS.push(...validFields);
    }

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState("createdAt");
    const [desc, setDesc] = useState(true);
    const [q, setQ] = useState("");

    const [selectedRows, setSelectedRows] = useState([]);

    let component = null;

    const getAllUsers = async ({ queryKey }: any) => {
        try {
            const { data } = await axiosInstance.get(otherUrl || `/admin/users`, {
                params: {
                    fpo_id: fpo_id,
                },
            });
            const users = Array.isArray(data) ? data : data.data || [];
            const filteredData = users.filter(
                (e: any) => e.role !== "seller" && e.role !== "admin"
            );
            return filteredData;
        } catch (err) {
            if (err instanceof Error) {
                throw new Error("Failed to fetch users: " + (err.message || "Unknown error"));
            } else {
                throw new Error("Failed to fetch users: Unknown error");
            }
        }
    };

    const getAllCertificates = async () => {
        const { data } = await axiosInstance.get(
            `/admin/seller-certification-requests`
        );
    };

    useEffect(() => {
        getAllCertificates();
    }, []);

    const { data, error, isLoading } = useQuery<
        UserObj[] | undefined,
        any,
        UserObj[],
        any[]
    >(["getAllUsers", pageIndex, cPageSize, cSortBy, desc, q], getAllUsers);

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center text-red-500">
                An error has occurred: {error.message || "Unable to load user data"}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = data && data.length > 0 ? (
            <TableInstance
                excludeColumnSearch={[]}
                tableData={data}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1} // do calculation here
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        ) : (
            <p className="mt-6 ml-4 text-center">No data found</p>
        );
    }

    return (
        <div className="all-users">
            <header className="flex items-center justify-between mb-8">
                <div className="left">
                    <SearchBox
                        searchValue={q}
                        setSearchValue={setQ}
                        handleSearch={() => { }}
                    />
                    {selectedRows.length > 0 && (
                        <span className="btn-primary flex items-center mt-5 w-48">
                            Selected Row : {selectedRows.length}
                        </span>
                    )}

                </div>

                <div className="right">
                    <button
                        className="btn-primary flex items-center"
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_BUYER_MODAL,
                                    modalTitle: "Add a General User",
                                })
                            );
                        }}
                    >
                        <span className="mr-1">Add</span> <MdAdd size={17} />
                    </button>
                </div>
            </header>

            {component !== null && component}
        </div>
    );
};

export default UserBuyers;
