import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR } from "../../types/constants";
import logo from "../../assets/logo192.png";

interface AssignFPOToSiteModalProps {
    id: string;
    action: string;
    role: string;
}

const AssignFPOToSiteModal = ({ id, action, role }: AssignFPOToSiteModalProps) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [fpoOptions, setFpoOptions] = useState([]);
    const [selectedFPO, setSelectedFPO] = useState<{ label: string; value: string } | null>(null);

    const fetchFPOs = async () => {
        try {
            const { data } = await axiosInstance.get("/fpo/all");
            return data;
        } catch (error) {
            console.log(error);
        }
    };

    const { data, status } = useQuery({
        queryKey: ["getAllFPOs"],
        queryFn: fetchFPOs,
    });

    useEffect(() => {
        if (status === "success" && data) {
            const options = data?.data?.map((fpo: { fpo_name: any; fpo_id: any; }) => ({
                label: `${fpo.fpo_name} (FPO Id: ${fpo.fpo_id})`,
                value: fpo.fpo_id,
            }));
            setFpoOptions(options);
        } else if (status === "error") {
            dispatch(addToast({ kind: ERROR, msg: "Error fetching FPOs" }));
        }
    }, [status, data, dispatch]);

    const handleSubmit = async () => {
        if (!selectedFPO) {
            dispatch(addToast({ kind: ERROR, msg: "Please select an FPO" }));
            return;
        }

        setIsSubmitting(true);
        try {
            const { data } = await axiosInstance.patch(
                `/admin/site/assign-fpo/${id}/${selectedFPO.value}`,
                { action, user_role: role }
            );
            if (data) {
                toast.success("FPO Assigned Successfully");
                queryClient.invalidateQueries("getAllFPOs");
                dispatch(hideModal());
            }
        } catch (error) {
            setIsSubmitting(false);
            dispatch(addToast({ kind: ERROR, msg: "Assignment failed" }));
        }
        setIsSubmitting(false);
    };

    return (
        <div className="px-6 py-4 mt-2"
            style={{ minWidth: "40vw" }}
        >

            <div className="flex justify-center items-center bg-white px-6 py-5">
                <img src={logo} alt="logo" height={100} width={100} />
            </div>

            <div className="flex flex-col gap-4 mb-10">
                <p className="font-bold">Select FPO:</p>
                {fpoOptions.length > 0 ? (
                    <Select
                        options={fpoOptions}
                        onChange={(e) => setSelectedFPO(e)}
                        styles={{
                            control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: "darkgreen",
                                outline: "none",
                            }),
                        }}
                    />
                ) : (
                    <span>No FPOs available</span>
                )}
            </div>
            <div className="buttons flex items-center w-full justify-center my-4">
                <button
                    onClick={handleSubmit}
                    className="btn-primary w-full disabled:opacity-100 flex items-center justify-center cursor-pointer"
                    disabled={isSubmitting}
                >
                    Assign FPO
                </button>
            </div>
        </div>
    );
};

export default AssignFPOToSiteModal;
