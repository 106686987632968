import React, { useState, useCallback, useMemo } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FaMapMarkerAlt, FaClock, FaChevronDown } from "react-icons/fa";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import Select from "../../components/FormikComponents/Select";
import Input from "../../components/FormikComponents/Input";
import SubmitBtn from "../../components/FormikComponents/SubmitBtn";

interface TimelineDetails {
  [key: string]: string;
}

interface TimelineEvent {
  title: string;
  time: string;
  details: TimelineDetails;
  coordinates: { lat: number; lng: number };
}

interface ValuesProp {
  trackThrough: string;
  trackingId: string;
}

const convertDateFormat = (utcTime: string) => {
  const utcDate = new Date(utcTime);
  return utcDate.toLocaleDateString("en-US", {
    hour12: true,
    hour: "numeric",
    minute: "numeric",
    day: "numeric",
    month: "short",
    year: "numeric",
    timeZone: "UTC",
  });
};

const TrackTraceTimeline: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [productData, setProductData] = useState<any[]>([]);
  const [commonError, setCommonError] = useState("");

  const validationSchema = Yup.object({
    trackThrough: Yup.string().required("Product Type is Required"),
    trackingId: Yup.string()
      .required("Tracking ID Required")
      .min(8, "Invalid Tracking ID"),
  });

  const initialValues: ValuesProp = { trackThrough: "", trackingId: "" };

  const handleSubmit = useCallback(async (values: ValuesProp) => {
    try {
      const response = await axiosInstance.post(`/admin/track`, values);
      if (response.status === 200) {
        setProductData(response.data?.data);
      }
    } catch (error) {
      setCommonError("Error fetching tracking data");
    }
  }, []);

  const timelineEvents = useMemo(() => {
    if (!productData.length) return [];
    const events: TimelineEvent[] = [];
    const product = productData[0];

    product.biomass_suppliers?.forEach((supplier: any) => {
      const coords = supplier.addresses?.[0]?.coordinates
        ? { lat: supplier.addresses[0].coordinates.x, lng: supplier.addresses[0].coordinates.y }
        : { lat: 28.7041, lng: 77.1025 };

      events.push({
        title: "Biomass Supplier",
        time: convertDateFormat(supplier.createdAt),
        details: {
          "Biomass Seller ID": supplier.seller_id,
          "Owner Name": supplier.owner_name,
          "Company Email": supplier.company_email,
          "Biomass Type": supplier.biomass_type,
        },
        coordinates: coords,
      });
    });

    product.fpos?.forEach((fpos: any) => {
      const coords = fpos.addresses?.[0]?.coordinates
        ? { lat: fpos.addresses[0].coordinates.x, lng: fpos.addresses[0].coordinates.y }
        : { lat: 28.7041, lng: 77.1025 };

      events.push({
        title: "FPO Details",
        time: convertDateFormat(fpos.created_at),
        details: {
          "FPO ID": fpos.fpo_id,
          "FPO Name": fpos.fpo_name,
          "Office Email": fpos.fpo_email,
          "CIN": fpos.cin,
          "GST Number": fpos.gst_number,
        },
        coordinates: coords,
      });
    });

    product.sequesters?.forEach((sequester: any) => {
      const coords = sequester.addresses?.[0]?.coordinates
        ? { lat: sequester.addresses[0].coordinates.x, lng: sequester.addresses[0].coordinates.y }
        : { lat: 19.076, lng: 72.8777 };

      events.push({
        title: "Sequester",
        time: convertDateFormat(sequester.incorporation_time),
        details: {
          "Biomass Batch ID": sequester.biomass_batch_id,
          "Sequestration Type": sequester.sequestration_type.main_type,
          "SP ID": sequester.sp_id,
          "Total Weight": sequester.biochar_weight,
        },
        coordinates: coords,
      });
    });

    product.artisans?.forEach((artisan: any) => {
      const coords = artisan.addresses?.[0]?.coordinates
        ? { lat: artisan.addresses[0].coordinates.x, lng: artisan.addresses[0].coordinates.y }
        : { lat: 13.0827, lng: 80.2707 };

      events.push({
        title: "Artisan",
        time: convertDateFormat(artisan.createdAt),
        details: {
          "Artisan ID": artisan.artisan_id,
          Name: artisan.name,
          Status: artisan.status,
          PinCode: artisan.pincode,
        },
        coordinates: coords,
      });
    });

    product.sequesters?.forEach((biochar: any) => {
      const coords = biochar.addresses?.[0]?.coordinates
        ? { lat: biochar.addresses[0].coordinates.x, lng: biochar.addresses[0].coordinates.y }
        : { lat: 19.076, lng: 72.8777 };

      events.push({
        title: "Biochar Details",
        time: convertDateFormat(biochar.created_at),
        details: {
          "BioChar Batch ID": biochar.biochar_id,
          "Avg Biomass Moisture": biochar.total_compost_qty,
          "Pyro Equipment Type": biochar.total_compost_qty,
          "Pyro Equipment ID": biochar.total_compost_qty,
          "Biochar Volume": biochar.total_compost_qty,
        },
        coordinates: coords,
      });
    });

    product.buyers?.forEach((buyer: any) => {
      const coords = buyer.addresses?.[0]?.coordinates
        ? { lat: buyer.addresses[0].coordinates.x, lng: buyer.addresses[0].coordinates.y }
        : { lat: 13.0827, lng: 80.2707 };

      events.push({
        title: "Application Details",
        time: convertDateFormat(buyer.transaction_dates[0]),
        details: {
          "Buyer ID": buyer.user_id,
          "Buyer Name": buyer.name,
          "Buyer email": buyer.email,
          "Product Quantity": buyer.addressable_type,
          "Geofenced Area": buyer.geofence_coordinates,
        },
        coordinates: coords,
      });
    });

    return events;
  }, [productData]);

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "YOUR_API_KEY_HERE"}>
      <div className="p-8 bg-gray-50 min-h-screen flex flex-col items-center">


        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="w-full mb-8 text-lg">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div>
                  <Select
                    label="Product Type"
                    name="trackThrough"
                    options={[
                      { value: "suncarbon_product", label: "Suncarbon Product" },
                      { value: "biomass_batch", label: "Biomass Batch" },
                      { value: "biochar", label: "Biochar" },
                      { value: "sequestration", label: "Sequestration" },
                    ]}
                    id={""}
                  />
                </div>

                <div>
                  <Input
                    name="trackingId"
                    label="Tracking ID"
                    placeholder="Enter Tracking ID"
                    type={""}
                    id={""}
                  />
                </div>

                <div className="flex items-end">
                  <SubmitBtn
                    text="Track & Trace"
                    containerClasses="h-full"
                    isSubmittingClasses="lg:mt-6"
                    classes="text-sm lg:mt-6 lg:p-4 lg:rounded-lg" isSubmitting={false} />
                </div>
              </div>
              <div className="flex justify-center items-center h-full">
                <h2 className="text-3xl font-bold text-green-700 mt-6 text-center">
                  Track & Trace Timeline
                </h2>
              </div>

            </Form>
          )}
        </Formik>

        {commonError && (
          <div className="text-red-500 mb-4">{commonError}</div>
        )}

        {/*@ts-ignore*/}
        <VerticalTimeline lineColor="#14532d" className="w-full">
          {timelineEvents.map((event, index) => (
            //@ts-ignore
            <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#eafaf1", color: "#2c5f2d" }}
              contentArrowStyle={{ borderRight: "7px solid #2c5f2d" }}
              date={event.time}
              iconStyle={{ background: "#2c5f2d", color: "#fff" }}
              icon={<FaClock />}
            >
              <h3 className="font-bold text-lg">{event.title}</h3>

              <div className="mt-3 border-t pt-2">
                <button
                  type="button"
                  className="flex items-center justify-between w-full px-4 py-2 text-left text-green-600 bg-green-100 rounded-lg focus:outline-none"
                  onClick={() => setOpenIndex(openIndex === index ? null : index)}
                >
                  View Details
                  <FaChevronDown
                    className={`w-4 h-4 transition-transform ${openIndex === index ? "rotate-180" : ""}`}
                  />
                </button>

                {openIndex === index && (
                  <div className="p-3 bg-white border rounded mt-2">
                    {Object.entries(event.details).map(([key, value]) => (
                      <p key={key} className="text-sm text-gray-800 mb-2">
                        <FaMapMarkerAlt className="inline w-4 h-4 text-green-600 mr-2" />
                        <span className="font-medium">{key}:</span> {value}
                      </p>
                    ))}

                    <div className="h-40 w-full mt-3 rounded overflow-hidden">
                      <Map center={event.coordinates} zoom={12} style={{ height: "100%" }}>
                        <Marker
                          position={event.coordinates}
                          icon={{
                            path: window.google.maps.SymbolPath.CIRCLE,
                            scale: 8,
                            fillColor: "#2c5f2d",
                            fillOpacity: 1,
                          }}
                        />
                      </Map>
                    </div>
                  </div>
                )}
              </div>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    </APIProvider>
  );
};

export default TrackTraceTimeline;
