import { useParams } from 'react-router-dom';
import RetentionBucket from '../RetentionBucket/RetetionBucket';

const PyroRetentionBucket = () => {
    const { id } = useParams();
    return (
        <RetentionBucket otherUrl={`/pyrolysis_technology/retention_bucket/all?pt_id=${id}`} />
    )
}

export default PyroRetentionBucket