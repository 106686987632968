import { useState } from "react";
import { MdAdd, MdArchive } from "react-icons/md";
import { useDispatch } from "react-redux";
import { FaPen, FaEye } from "react-icons/fa";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
  ADD_PYROLYSIS_KILN_MODAL,
  EDIT_PYROLYSIS_KILN_MODAL,
} from "../../types/constants";
import { useQuery, useQueryClient } from "react-query";
import useIsAdmin from "../../hooks/useIsAdmin";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";

interface PyrolysisKilnObj {
  id: string;
  name: string;
  createdAt: string;
}

interface PyrolysisKilnProps {
  otherUrl?: string;
}

const PyrolysisKiln = ({ otherUrl }: PyrolysisKilnProps) => {
  const queryClient = useQueryClient();

  const handleArchival = async (
    id: string,
  ) => {
    try {
      const response = await axiosInstance.patch(
        `/pyrolysis_technology/pyrolysis_kiln/archive/${id}`,
        { is_archived: true }
      );
      console.log(response);
      toast.success("Pyrolysis Kiln Archived Successfully");
      queryClient.invalidateQueries(["getAllPyrolysisKiln"]);
    } catch (error: any) {
      console.error(error);
      toast.error(error.response?.data?.error || "Failed to archive Pyrolysis Kiln");
    }
  };

  const ALL_USERS_COLUMNS = [
    {
      Header: "Sr. No",
      accessor: "serialNumber",
      Cell: ({ row }: any) => {
        return row.index + 1;
      },
    },
    // {
    //   Header: "Pyrolysis Kiln Id",
    //   accessor: "pyrolysis_kiln_id",
    //   Cell: ({ row }: any) =>
    //     row?.original?.pyrolysis_kiln_id || "Not Available",
    // },
    {
      Header: "Kiln Name",
      accessor: "kiln_name",
      Cell: ({ row }: any) => {
        const kiln_name = row?.original.kiln_name;
        return (
          <NavLink
            to={`${row?.original.pyrolysis_kiln_id}`}
            className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
            title={`View Kontiki Details`}
          >
            {kiln_name}
          </NavLink>
        );
      },
    },
    {
      Header: "Kiln Type",
      accessor: "kiln_type",
      Cell: ({ row }: any) =>
        row?.original?.kiln_type || "Not Available",
    },
    {
      Header: "Depth (cm)",
      accessor: "depth",
      Cell: ({ row }: any) =>
        row?.original?.depth || " 0",
    },
    {
      Header: "Upper Diameter (cm)",
      accessor: "upper_diameter",
      Cell: ({ row }: any) =>
        row?.original?.upper_diameter || " 0",
    },
    {
      Header: "Lower Diameter (cm)",
      accessor: "lower_diameter",
      Cell: ({ row }: any) =>
        row?.original?.lower_diameter || " 0",
    },
    {
      Header: "Address",
      accessor: "address",
      Cell: ({ row }: any) =>
        row?.original?.address || "Not Available",
    },
    // {
    //   Header: "Created By FPO ID",
    //   accessor: "created_by_fpo_id",
    //   Cell: ({ row }: any) => row?.original?.created_by_fpo_id || "Not Available",
    // },
    // {
    //   Header: "Address ID",
    //   accessor: "address_id",
    // },
    {
      Header: "Created At",
      accessor: "created_at",
      Cell: ({ value }: any) => {
        return value ? new Date(value).toLocaleString() : "Invalid Date";
      },
    },
    // {
    //   Header: "Updated At",
    //   accessor: "updated_at",
    //   Cell: ({ value }: any) => {
    //     return new Date(value).toLocaleString();
    //   },
    // },
    {
      Header: "Kiln Photo",
      accessor: "kiln_photo",
      Cell: ({ value }: any) => {
        return (
          // <a href={value} className="text-green-400" target="_blank">
          <a href={value} className="text-green-400" target="_blank" rel="noreferrer">
            <button className="bg-green-600 text-white px-2 py-3">
              <FaEye />
            </button>
          </a>
        );
      },
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: ({ value, row }: any) => {
        console.table(row.original);
        return (
          <button
            onClick={() => {
              dispatch(
                showModal({
                  modalType: EDIT_PYROLYSIS_KILN_MODAL,
                  modalTitle: "Edit Pyrolysis Klin",
                  modalProps: {
                    pyrolysisKilnId: row?.original.pyrolysis_kiln_id,
                    kiln_name: row?.original.kiln_name,
                    kiln_type: row?.original.kiln_type,
                    depth: row?.original.depth,
                    upper_diameter: row?.original.upper_diameter,
                    lower_diameter: row?.original.lower_diameter,
                    address: row?.original.address,
                    kiln_photo: row?.original.kiln_photo,
                    addressId: row?.original.addressId,
                  },
                })
              );
            }}
            className="bg-green-600 text-white px-2 py-3"
          >
            <FaPen />
          </button>
        );
      },
    },
    {
      Header: "Archive",
      accessor: "archive",
      Cell: ({ value, row }: any) => {
        return (
          <button
            onClick={() =>
              handleArchival(
                row?.original.pyrolysis_kiln_id,
              )
            }
            className="text-green-600"
          >
            <MdArchive size={36} />
          </button>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const { isAdmin } = useIsAdmin();
  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getAllPyrolysisKiln = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(
      otherUrl || `/pyrolysis_technology/pyrolysis_kiln/all?is_archived=false`
    );

    // filter data where created_by_fpo_id is null
    const filteredData = isAdmin
      ? data?.data.filter((e: any) => e.created_by_fpo_id === null)
      : data?.data;
    return filteredData;
  };

  const { data, error, isLoading } = useQuery<
    PyrolysisKilnObj[] | undefined,
    any,
    PyrolysisKilnObj[],
    any[]
  >(
    ["getAllPyrolysisKiln", pageIndex, cPageSize, cSortBy, desc, q],
    getAllPyrolysisKiln
  );

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        excludeColumnSearch={[]}
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => { }}
          />

          {selectedRows.length > 0 && (
            <span className="btn-primary flex items-center mt-5 w-48">
              Selected Row : {selectedRows.length}
            </span>
          )}
        </div>

        <div className="right">
          <button
            className="btn-primary flex items-center"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_PYROLYSIS_KILN_MODAL,
                  modalTitle: "Add Pyrolysis kiln",
                })
              );
            }}
          >
            <span className="mr-1">Add</span> <MdAdd size={17} />
          </button>
        </div>
      </header>

      {component !== null && component}
    </div>
  );
};

export default PyrolysisKiln;
