import { Form, Formik } from "formik";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, STATES, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import { GoogleMap, LoadScript, Marker, Polygon } from "@react-google-maps/api";
import { GOOLGLE_MAPS_API_KEY } from "../../config";
import logo from "../../assets/logo192.png";
import Select from "../FormikComponents/Select";

interface GeoCoordinate {
  latitude: number;
  longitude: number;
}

interface GeoCoordinate {
  latitude: number;
  longitude: number;
}

interface FormValues {
  project_name: string;
  project_location: string;
  person_phone?: string;
  person_name?: string;
  start_date: string;
  address_line_1?: string;
  address_city?: string;
  address_state?: string;
  address_pincode?: string;
  geofence_coordinates?: GeoCoordinate[];
  biochar_capacity_per_anum?: string;
  project_area?: string;
}

interface Props {
  isUpdate?: boolean;
  data?: any;
}

const AddNewProjectModal = (props: Props) => {
  const { isUpdate, data } = props;
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [coordinates, setCoordinates] = useState<GeoCoordinate[]>(
    data?.address?.geofence_coordinates || []
  );
  const [projectAreaUnit, setProjectAreaUnit] = useState("Acres");
  const [biocharCapacityUnit, setBiocharCapacityUnit] = useState("kg");

  const initialValues: FormValues = {
    project_name: data?.project_name || "",
    project_location: data?.project_location || "",
    person_phone: data?.project_contact_details?.person_phone || "",
    person_name: data?.project_contact_details?.person_name || "",
    start_date: data?.start_date || "",
    address_line_1: data?.address?.line_1 || "",
    address_city: data?.address?.city || "",
    address_state: data?.address?.state || "",
    address_pincode: data?.address?.pincode || "",
    geofence_coordinates: data?.address?.geofence_coordinates || [],
    biochar_capacity_per_anum: data?.biochar_capacity_per_anum || "",
    project_area: data?.project_area || "",
  };

  const validationSchema = Yup.object({
    project_name: Yup.string().trim().required("Project Name is required"),
    project_location: Yup.string().trim().required("Project Location is required"),
    person_phone: Yup.string()
      .required("Phone Number is required")
      .min(10, "Invalid Phone Number")
      .max(10, "Invalid Phone Number"),
    person_name: Yup.string().trim().required("Person Name is required"),

    start_date: Yup.date().required("Start Date is required"),
    address_line_1: Yup.string().trim().required("Address Line 1 is required"),
    address_city: Yup.string().trim().required("City is required"),
    address_state: Yup.string().trim().required("State is required"),
    address_pincode: Yup.string()
      .trim()
      .required("Pincode is required")
      .matches(/\d{6}/, "Pincode must be exactly 6 digits"),
    biochar_capacity_per_anum: Yup.string().trim().required("Biochar Capacity per Anum is required"),
    project_area: Yup.string().trim().required("Project Area is required"),
  });

  const handleSubmit = async (project: FormValues) => {
    if (coordinates.length < 3) {
      dispatch(addToast({ kind: ERROR, msg: "At least 3 geofence points are required!" }));
      return;
    }

    setIsSubmitting(true);
    try {
      const obj = {
        ...project,
        project_contact_details: {
          person_phone: project.person_phone,
          person_name: project.person_name,
        },
        address: {
          line_1: project.address_line_1,
          city: project.address_city,
          state: project.address_state,
          pincode: project.address_pincode,
          geofence_coordinates: [...coordinates, coordinates[0]],
        },

      };
      delete obj.person_name;
      delete obj.person_phone;
      delete obj.address_line_1;
      delete obj.address_pincode;
      delete obj.address_state;
      delete obj.geofence_coordinates;
      delete obj.address_city;

      const result = isUpdate
        ? await axiosInstance.patch(`/project/update/${data.project_id}`, obj)
        : await axiosInstance.post("/project/create", obj);

      if (result) {
        queryClient.invalidateQueries(["getAllProjectsDetails"]);
        dispatch(addToast({ kind: SUCCESS, msg: isUpdate ? "Project updated successfully!" : "Project added successfully!" }));
        dispatch(hideModal());
      }
    } catch (error: any) {
      dispatch(addToast({ kind: ERROR, msg: error.response?.data?.msg || "Oops, something went wrong" }));
    }
    setIsSubmitting(false);
  };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const newCoordinate = { latitude: event.latLng.lat(), longitude: event.latLng.lng() };
      setCoordinates((prev) => {
        if (!prev.some(coord => coord.latitude === newCoordinate.latitude && coord.longitude === newCoordinate.longitude)) {
          return [...prev, newCoordinate];
        }
        return prev;
      });
    }
  };

  const resetGeofence = () => {
    setCoordinates([]);
  };

  const projectAreaFactors: { [unit: string]: number } = {
    Acres: 1,
    Hectares: 2.47105,
    Bigha: 0.3076,
  };

  const capacityFactors: { [unit: string]: number } = {
    kg: 1,
    tonnes: 1000,
    quintal: 100,
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(formik) => (
        <Form className="px-6 py-4 mt-2" style={{ minWidth: "40vw" }}>

          <div className="flex justify-center items-center bg-white px-6 py-5">
            <img src={logo} alt="logo" height={100} width={100} />
          </div>

          <Input
            label="Project Name"
            id="project_name"
            name="project_name"
            type="text"
            placeholder="Enter Project Name"
          />

          <Input
            label="Project Location"
            id="project_location"
            name="project_location"
            type="text"
            placeholder="Enter Project Location"
          />

          <Input
            label="Phone Number"
            id="person_phone"
            name="person_phone"
            type="text"
            placeholder="Enter Phone Number"
          />

          <Input
            label="Person Name"
            id="person_name"
            name="person_name"
            type="text"
            placeholder="Enter Person Name"
          />

          <Input
            label="Start Date"
            id="start_date"
            name="start_date"
            type="date"
          />

          <Input
            label="Address Line 1"
            id="address_line_1"
            name="address_line_1"
            type="text"
            placeholder="Enter Address"
          />

          <div className="flex gap-4">
            <div className="flex-1">
              <Input
                label="City"
                id="address_city"
                name="address_city"
                type="text"
                placeholder="Enter City"
              />
            </div>
            <div className="flex-1">
              <Select
                options={STATES}
                label="State"
                id="address_state"
                name="address_state"
                placeholder="Select state"
              />
            </div>
            <div className="flex-1">
              <Input
                label="Pincode"
                id="address_pincode"
                name="address_pincode"
                type="number"
                placeholder="Enter Pincode"
              />
            </div>
          </div>


          <div className="flex flex-col md:flex-row gap-x-6">
            <div className="flex items-center gap-x-3 w-full">
              <div className="flex-1">
                <Input
                  label={`Project Area (in ${projectAreaUnit})`}
                  id="project_area"
                  name="project_area"
                  type="text"
                />
              </div>
              <div className="mb-5">
                <label className="block text-sm font-bold mb-1">Unit</label>
                <select
                  value={projectAreaUnit}
                  onChange={(e) => {
                    const newUnit = e.target.value;
                    const currentValue = parseFloat(formik.values.project_area || "0");
                    if (!isNaN(currentValue)) {
                      const valueInAcres = currentValue * projectAreaFactors[projectAreaUnit];
                      const newValue = valueInAcres / projectAreaFactors[newUnit];
                      formik.setFieldValue("project_area", newValue.toFixed(2));
                    }
                    setProjectAreaUnit(newUnit);
                  }}
                  className="p-3 border rounded-lg"
                >
                  <option value="Acres">Acres</option>
                  <option value="Hectares">Hectares</option>
                  <option value="Bigha">Bigha</option>
                </select>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-x-">
            <div className="flex items-center gap-x-3 w-full">
              <div className="flex-1">
                <Input
                  label={`Biochar Capacity per Anum (in ${biocharCapacityUnit})`}
                  id="biochar_capacity_per_anum"
                  name="biochar_capacity_per_anum"
                  type="text"
                />
              </div>
              <div className="mb-5">
                <label className="block text-sm font-bold mb-1">Unit</label>
                <select
                  value={biocharCapacityUnit}
                  onChange={(e) => {
                    const newUnit = e.target.value;
                    const currentValue = parseFloat(formik.values.biochar_capacity_per_anum || "0");
                    if (!isNaN(currentValue)) {
                      const valueInKg = currentValue * capacityFactors[biocharCapacityUnit];
                      const newValue = valueInKg / capacityFactors[newUnit];
                      formik.setFieldValue("biochar_capacity_per_anum", newValue.toFixed(2));
                    }
                    setBiocharCapacityUnit(newUnit);
                  }}
                  className="p-3 border rounded-lg"
                >
                  <option value="kg">Kg</option>
                  <option value="tonnes">Tonnes</option>
                  <option value="quintal">Quintal</option>
                </select>
              </div>
            </div>
          </div>

          <h3 className="font-semibold text-lg mb-5">Geofence Area</h3>
          {/*
            // @ts-ignore */}
          <LoadScript googleMapsApiKey={GOOLGLE_MAPS_API_KEY as string}>
            {/*
            // @ts-ignore */}
            <GoogleMap
              center={{ lat: 20, lng: 78 }}
              zoom={5}
              mapContainerStyle={{ height: "300px", width: "100%" }}
              onClick={handleMapClick}
              options={{
                zoomControl: true,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: true,
                gestureHandling: "greedy",
              }}
            >
              {coordinates.map((c, index) => (
                //@ts-ignore
                <Marker key={index} position={{ lat: c.latitude, lng: c.longitude }} />
              ))}

              {coordinates.length >= 3 &&
                //@ts-ignore
                <Polygon paths={coordinates} options={{ fillColor: "#00FF00", fillOpacity: 0.2, strokeColor: "#00FF00", strokeWeight: 2 }} />}
            </GoogleMap>
          </LoadScript>

          <button
            type="button"
            onClick={resetGeofence}
            className="mt-5 bg-red-500 text-white py-2 px-4 rounded mb-5">
            Reset Geofence
          </button>

          <SubmitBtn
            text="Save"
            isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default AddNewProjectModal;