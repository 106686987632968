import { Form, Formik } from "formik";
import { useState } from "react";
import { useQueryClient, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { uploadImage } from "../../http/uploadImage";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import {
    AZURE_STORAGE_DIRECTORY,
    AZURE_STORAGE_SUBDIRECTORY,
    ERROR,
    SUCCESS,
} from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import Select from "../FormikComponents/Select";
import logo from "../../assets/logo192.png";

interface FileWithPreview extends File {
    preview?: string;
}

interface FormValues {
    manufacturer_name: string;
    manufacturer_model: string;
    product_unique_number: string;
    volume: number;
    pt_id: string;
}

interface PyrolysisTechnology {
    pt_id: number;
    name: string;
}

interface PyrolysisTechnologyResponse {
    msg: string;
    data: {
        pyro_techs: PyrolysisTechnology[];
        count: number;
    };
}

const AddSamplingBeakerModal = ({ otherUrl }: { otherUrl?: string }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const [isSubmitting, setIsSubmitting] = useState(false);
    // **Changed**: From single file to array of files
    const [files, setFiles] = useState<FileWithPreview[]>([]);
    const [volumeUnit, setVolumeUnit] = useState("litre");

    const { data: pyrolysisTechnologies, isLoading } = useQuery<PyrolysisTechnologyResponse>(
        ["getAllPyrolysisTechnologies"],
        async () => {
            const response = await axiosInstance.get("/pyrolysis_technology/all");
            return response.data;
        }
    );

    const initialValues: FormValues = {
        manufacturer_name: "",
        manufacturer_model: "",
        product_unique_number: "",
        volume: 0,
        pt_id: otherUrl?.split("=")[1] || "",
    };

    const validationSchema = Yup.object({
        manufacturer_name: Yup.string().trim().required("Manufacturer Name is Required"),
        manufacturer_model: Yup.string().trim().required("Manufacturer Model is Required"),
        product_unique_number: Yup.string().trim().required("Product Unique Number is Required"),
        volume: Yup.number().required("Volume is required"),
        pt_id: Yup.string().trim().required("PT ID is Required"),
    });

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const selectedFiles = Array.from(e.target.files);
            const totalFiles = files.length + selectedFiles.length;

            if (totalFiles > 11) {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: "You can upload a maximum of 11 files",
                    })
                );
                return;
            }

            const newFiles = selectedFiles.map((file) => {
                if (file.type.startsWith("image/")) {
                    const fileWithPreview = file as FileWithPreview;
                    fileWithPreview.preview = URL.createObjectURL(file);
                    return fileWithPreview;
                }
                return file;
            });

            setFiles((prev) => [...prev, ...newFiles]);
        }
    };

    // **Added**: Remove a selected file
    const removeFile = (index: number) => {
        const updatedFiles = [...files];
        if (updatedFiles[index].preview) {
            URL.revokeObjectURL(updatedFiles[index].preview!);
        }
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
    };

    const handleSubmit = async (values: FormValues) => {
        if (files.length === 0) {
            dispatch(
                addToast({
                    kind: ERROR,
                    msg: "At least one file is required",
                })
            );
            return;
        }

        setIsSubmitting(true);
        try {
            const uploadPromises = files.map((file) =>
                uploadImage(file, AZURE_STORAGE_DIRECTORY.PUBLIC, AZURE_STORAGE_SUBDIRECTORY.PROFILE)
            );
            const uploadResults = await Promise.all(uploadPromises);
            const imageUrls = uploadResults.map((result) => result.data.publicUrl).filter(Boolean);

            const beaker_images: { [key: string]: string } = {};
            imageUrls.forEach((url, index) => {
                beaker_images[`image${index + 1}`] = url;
            });

            const obj = {
                manufacturer_name: values.manufacturer_name,
                manufacturer_model: values.manufacturer_model,
                product_unique_number: values.product_unique_number,
                volume: Number(values.volume),
                beaker_images,
                pt_id: Number(values.pt_id),
            };

            const result = await axiosInstance.post("/pyrolysis_technology/sampling_beaker/create", obj);
            if (result) {
                setIsSubmitting(false);
                queryClient.invalidateQueries(["getAllSamplingBeakers"]);
                dispatch(
                    addToast({
                        kind: SUCCESS,
                        msg: "Sampling Beaker Added Successfully!",
                    })
                );
                dispatch(hideModal());
            }
        } catch (error: any) {
            setIsSubmitting(false);
            if (error.response) {
                const response = error.response;
                if (
                    response.data.error &&
                    (response.data.error.includes("duplicate key value violates unique constraint") ||
                        response.data.error.includes("sampling_beaker_product_unique_number_unique"))
                ) {
                    dispatch(
                        addToast({
                            kind: ERROR,
                            msg: "Product Unique Number already exists.",
                        })
                    );
                } else {
                    const { msg } = response.data;
                    switch (response.status) {
                        case 400:
                        case 500:
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: msg,
                                })
                            );
                            break;
                        default:
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: "Oops, something went wrong",
                                })
                            );
                            break;
                    }
                }
            } else if (error.request) {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: "Oops, something went wrong",
                    })
                );
            } else {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: `Error: ${error.message}`,
                    })
                );
            }
        }
        setIsSubmitting(false);
    };

    const ptOptions = (pyrolysisTechnologies?.data?.pyro_techs || []).map((tech) => ({
        value: tech.pt_id.toString(),
        label: `${tech.name} (Pyro Tech Id: ${tech.pt_id})`,
    }));

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
        >
            <Form className="px-6 py-4 mt-2" style={{ minWidth: "40vw" }}>
                <div className="flex justify-center items-center bg-white px-6 py-5">
                    <img src={logo} alt="logo" height={100} width={100} />
                </div>

                <div className="flex items-center gap-x-6">
                    <Input
                        label="Manufacturer Name"
                        id="manufacturer_name"
                        name="manufacturer_name"
                        type="text"
                        placeholder="Enter Manufacturer Name"
                    />
                </div>

                <div className="flex items-center gap-x-6">
                    <Input
                        label="Manufacturer Model"
                        id="manufacturer_model"
                        name="manufacturer_model"
                        type="text"
                        placeholder="Enter Manufacturer Model"
                    />
                </div>

                <div className="flex items-center gap-x-6">
                    <Input
                        label="Product Unique Number"
                        id="product_unique_number"
                        name="product_unique_number"
                        type="text"
                        placeholder="Enter Product Unique Number"
                    />
                </div>

                <div className="flex items-center gap-x-6">
                    <div className="flex-1">
                        <Input
                            label={`Volume (${volumeUnit})`}
                            id="volume"
                            name="volume"
                            type="number"
                        />
                    </div>
                    <div>
                        <select
                            value={volumeUnit}
                            className="w-22 p-3.5 border border-gray-300 rounded-lg"
                            onChange={(e) => setVolumeUnit(e.target.value)}
                        >
                            <option value="litre">Litre</option>
                        </select>
                    </div>
                </div>

                <div className="flex items-center gap-x-6 mb-5">
                    <Select
                        label="Pyrolysis Technology Id"
                        id="pt_id"
                        name="pt_id"
                        options={ptOptions}
                        isLoading={isLoading}
                        disabled={otherUrl ? true : false}
                        placeholder="Select Pyrolysis Technology Id"
                        classes="w-full"
                    />
                </div>

                <div className="flex items-center gap-x-6">
                    <div className="w-full">
                        <Input
                            label="Upload Sampling Beaker Files (Image or PDF)"
                            id="sampling_beaker_file"
                            name="sampling_beaker_file"
                            type="file"
                            accept=".jpg,.png,.jpeg,.webp,.pdf"
                            multiple
                            onChange={handleFileChange}
                        />
                        <p className="text-xs text-gray-500">*You can upload multiple files</p>
                    </div>
                </div>

                {files.length > 0 && (
                    <div className="mt-4 mb-6">
                        <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
                            {files.map((file, index) => (
                                <div key={index} className="relative border rounded-lg p-2">
                                    {file.preview ? (
                                        <img
                                            src={file.preview}
                                            alt={`Preview ${index}`}
                                            className="h-20 w-full object-cover rounded-lg mb-1"
                                        />
                                    ) : (
                                        <div className="h-20 w-full flex items-center justify-center bg-gray-100 rounded-lg mb-1">
                                            <span className="text-xs text-gray-500">File</span>
                                        </div>
                                    )}
                                    <div className="flex justify-between items-center">
                                        <span className="text-xs truncate max-w-[80%]">{`file${index + 1}: ${file.name}`}</span>
                                        <button
                                            type="button"
                                            onClick={() => removeFile(index)}
                                            className="text-red-500 hover:text-red-700 text-xs"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div className="buttons flex items-center w-full justify-center my-4">
                    <SubmitBtn
                        text="Add Sampling Beaker"
                        isSubmitting={isSubmitting}
                        containerClasses="h-full"
                        isSubmittingClasses="lg:mt-6"
                        classes="text-sm lg:p-4 lg:rounded-lg"
                    />
                </div>
            </Form>
        </Formik>
    );
};

export default AddSamplingBeakerModal;