import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import { AxiosError } from "axios";
import logo from "../../assets/logo192.png";
import axiosInstance from "../../axiosInstance";
import ErrorBox from "../FormikComponents/ErrorBox";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import { loginUser } from "../../redux/features/authSlice";
import { data } from "../../data/dummyGraphData";
import Select from "../FormikComponents/Select";
import { addToast } from "../../redux/features/toastSlice";
import { LAND_HOLDING_UNIT, SUCCESS } from "../../types/constants";
import { useQueryClient } from "react-query";
import { hideModal } from "../../redux/features/modalSlice";
import { STATES } from "../../types/constants";

interface FPOObj {
  fpo_name: string;
  fpo_number: string;
  fpo_email: string;
  password: string;
  address: string;
  street?: string;
  city: string;
  state: string;
  pincode: string;
  cin: string;
  gst_number: string;
  land_holding?: number | string;
  land_holding_unit?: string;
  enrolled_members?: number | string;
}

const FPOOnboardedForm: React.FC = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [commonError, setCommonError] = useState("");
  const [showForm, setShowForm] = useState(true);
  const queryClient = useQueryClient();
  const initialValues: FPOObj = {
    fpo_name: "",
    fpo_number: "",
    fpo_email: "",
    password: "",
    address: "",
    street: "",
    city: "",
    state: "",
    pincode: "",
    cin: "",
    gst_number: "",
    land_holding: 0,
    land_holding_unit: "hectare",
    enrolled_members: 0,
  };

  const validationSchema = Yup.object({
    fpo_name: Yup.string().trim().required("FPO Name is required"),
    fpo_number: Yup.string()
      .matches(/^[0-9]+$/, "Invalid. Phone Number must be only digits")
      .length(10, "Phone Number must be exactly 10 digits")
      .required("Phone Number is required"),
    fpo_email: Yup.string().trim()
      .email("Invalid fpo_email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
    address: Yup.string().trim().required("House Number is required"),
    street: Yup.string().trim().required("Street/Area is required"),
    city: Yup.string().trim().required("City is required"),
    state: Yup.string().trim().required("State is required"),
    pincode: Yup.string()
      .matches(/^[0-9]+$/, "Invalid. Pin Code must be only digits")
      .length(6, "Pin Code must be exactly 6 digits")
      .required("Pin Code is required"),
    cin: Yup.string().trim().required("CIN is required"),
    gst_number: Yup.string()
      .required("GST Number is required")
      .min(12, "GST Number must be 12 characters long")
      .max(15, "GST Number must be 15 characters long"),
    enrolled_members: Yup.number().min(0, "Enrolled Members must be greater than 0").optional(),
    land_holding: Yup.number().optional().min(0, "Land Holding must be greater than 0"),
    land_holding_unit: Yup.string().trim().optional()
  });

  const handleSubmit = useCallback(
    (
      values: FPOObj,
      { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
    ) => {
      setIsSubmitting(true);
      setCommonError("");

      const obj: FPOObj = {
        fpo_name: values.fpo_name,
        fpo_number: values.fpo_number,
        fpo_email: values.fpo_email,
        password: values.password,
        cin: values.cin,
        gst_number: values.gst_number,
        address: values.address,
        city: values.city,
        state: values.state,
        pincode: values.pincode,
      };
      if (values.land_holding !== 0) {
        if (values.land_holding_unit === "") {
          setCommonError("Please select a unit for land holding");
          setIsSubmitting(false);
          setSubmitting(false);
          return;
        }
        let landHoldingInHectares = values.land_holding as number;
        switch (values.land_holding_unit) {
          case "acre":
            landHoldingInHectares *= 0.404686;
            break;
          case "sqft":
            landHoldingInHectares *= 0.0000092903;
            break;
          case "sqm":
            landHoldingInHectares *= 0.0001;
            break;
          default:
            break;
        }
        obj.land_holding = landHoldingInHectares;
      }

      if (values.enrolled_members !== 0) {
        obj.enrolled_members = values.enrolled_members;
      }




      axiosInstance
        .post(`/fpo/create`, obj, {
          headers: {
            ContentType: "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setIsSubmitting(false);
            setSubmitting(false);
            dispatch(
              addToast({
                kind: SUCCESS,
                msg: "FPO Added Successfully!",
              })
            );
            queryClient.invalidateQueries(["getAllFpos"]);
            setShowForm(false);
            dispatch(hideModal());
          }
        })
        .catch((error: AxiosError) => {
          setIsSubmitting(false);
          setSubmitting(false);

          if (error.response) {
            const { msg } = error.response.data as { msg: string };
            switch (error.response.status) {
              case 400:
              case 403:
              case 500:
                setCommonError(msg);
                break;
              case 404:
                setCommonError("No data found for the given FPO.");
                break;
              default:
                setCommonError("Oops, something went wrong");
                break;
            }
          } else if (error.request) {
            setCommonError("Oops, something went wrong");
          } else {
            setCommonError(`Error: ${error.message}`);
          }
        });
    },
    [dispatch, queryClient]
  );

  return (
    <>
      {showForm && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form
            className="max-w-sm flex flex-col justify-center items-center bg-white px-6 py-5 shadow"
            style={{ minWidth: "40vw" }}
          >
            <img src={logo} alt="logo" height={100} width={100} />

            <Input
              label="FPO Name"
              id="fpo_name"
              name="fpo_name"
              type="text"
              placeholder="Enter FPO Name"
              className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
            />
            <Input
              label="Phone Number"
              id="fpo_number"
              name="fpo_number"
              type="number"
              className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
            />
            <Input
              label="Email"
              id="fpo_email"
              name="fpo_email"
              type="email"
              placeholder="Enter FPO Email"
              className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
            />
            <Input
              label="Password"
              id="password"
              name="password"
              type="password"
              placeholder="Enter Password Assigned By FPO"
              className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
            />

            <div className="mb-0 w-full">
              <label className="block text-sm font-semibold text-gray-800">
                Address
              </label>
              <Input
                label="House Number"
                id="address"
                name="address"
                type="text"
                placeholder="Enter Full Address"
                className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              />
              <Input
                label="Street/Area"
                id="street"
                name="street"
                type="text"
                placeholder="Enter Street/Area Name"
                className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              />
              <div className="flex w-full space-x-4">
                <Input
                  label="City"
                  id="city"
                  name="city"
                  type="text"
                  placeholder="Enter City Name"
                  className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                />
                <div className="w-full">
                  <Select
                    options={STATES}
                    label="State"
                    id="state"
                    name="state"
                  />
                </div>
                <Input
                  label="Pin Code"
                  id="pincode"
                  name="pincode"
                  type="number"
                  placeholder="Pin Code"
                  className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                />
              </div>
            </div>

            <Input
              label="CIN"
              id="cin"
              name="cin"
              type="text"
              placeholder="Enter CIN Number"
              className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
            />

            <Input
              label="GST Number"
              id="gst_number"
              name="gst_number"
              type="text"
              placeholder="Enter GST Number"
              className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
            />

            <Input
              label="Number of Enrolled Member"
              id="enrolled_members"
              name="enrolled_members"
              type="number"
              placeholder="Number of Enrolled Member"
              className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
            />

            <div className="grid grid-cols-3 gap-x-4 w-full">
              <Input
                label="Land Holding"
                id="land_holding"
                name="land_holding"
                type="number"
                step="any"
                placeholder="Land Holding"
                className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                classes="col-span-2"
              />
              <Select
                name="land_holding_unit"
                id="land_holding_unit"
                label="Unit"
                options={LAND_HOLDING_UNIT}
              />
            </div>

            {commonError && (
              <div className="common-error mb-2 text-center">
                <ErrorBox msg={commonError} />
              </div>
            )}

            <div className="buttons flex flex-col items-center w-full justify-center my-4 mb-6">
              <SubmitBtn
                text="Add FPO"
                isSubmitting={isSubmitting}
                // classes="text-sm"
                containerClasses="h-full"
                isSubmittingClasses="lg:mt-6"
                classes="text-sm lg:p-4 lg:rounded-lg"
              />
            </div>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default FPOOnboardedForm;
