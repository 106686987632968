import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import Error404 from "../pages/Error404";

import Header from "../components/Header/Header";
import Loader from "../components/Loader/Loader";
import Sidebar from "../components/Sidebar/Sidebar";
import AllApprovedArtisan from "../pages/artisan/AllApprovedArtisan";
import AllArtisan from "../pages/artisan/AllArtisan";
import AllBatch from "../pages/biomass-batch/AllBatch";
import BiomassComposition from "../pages/biomass-composition";
import AllCategories from "../pages/categories/AllCategories";
import AllCrops from "../pages/crops/AllCrops";
import AllFertilisers from "../pages/fertilisers/AllFertilisers";
import AllFertilisersCombination from "../pages/fertilisersCombination/AllFertilisers";
import MeasuringVessel from "../pages/measuring-vessel";
import AllProductFormulation from "../pages/product-formulation/AllProductFormulation";
import AllProductTransfer from "../pages/product-formulation/AllProductTransfer";
import AllProducts from "../pages/products/AllProducts";
import DeletedProducts from "../pages/products/DeletedProducts";
import Projects from "../pages/project";
import PyrolysisKlin from "../pages/pyrolysis-kiln";
import SoilPit from "../pages/soil-pit";
import TrackAndTrace from "../pages/track-and-trace/TrackAndTrace";
import UserBuyers from "../pages/users/UserBuyers";
import { RootState } from "../redux/app";
import ArtisanLayout from "./ArtisanLayout";
import BiomassBatchLaoyout from "./BiomassBatchLayout";
import BioMassCompositionLayout from "./BioMassCompositionLayout";
import CategoryLayout from "./CategoryLayout";
import CompostMaterialLayout from "./CompostMaterialLayout";
import CropLayout from "./CropLayout";
import FertiliserLayoutCombination from "./FertiliserCombLayout";
import FertiliserLayout from "./FertiliserLayout";
import FPOInventoryLayout from "./FPOInventoryLayout";
import FPOLayout from "./FPOLayout";
import MeasuringVesselLayout from "./MeasuringVesselLayout";
import ProductFormulationLayout from "./ProductFormulationLayout";
import ProductsLayout from "./ProductLayout";
import ProjectLayout from "./ProjectsLayout";
import PyrolysisKilnLayout from "./PyrolysisKilnLayout";
import SoilPitLayout from "./SoilPitLayout";
import UsersLayout from "./UsersLayout";
// import ProjectDetails from "../components/ModalComponents/ProjectDetails";
// import MeasuringVesselDetails from "../components/ModalComponents/MeasuringVesselDetails";
// import SoilPitDetails from "../components/ModalComponents/SoilPitDetails";
// import PyrolysisKilnDetails from "../components/ModalComponents/PyrolysisKilnDetails";
// import BiomassCompositionDetails from "../components/ModalComponents/BiomassCompositionDetails";
// import AllFertilisersDetails from "../components/ModalComponents/AllFertilisersDetails";
// import AllProductFormulationDetails from "../components/ModalComponents/AllProductFormulationDetails";
// import AllCategoriesDetails from "../components/ModalComponents/AllCategoriesDetails";
import AllBatchDetails from "../components/ModalComponents/AllBatchDetails";
// import AllCropsDetails from "../components/ModalComponents/AllCropsDetails";
// import AllFertilisersCombosDetails from "../components/ModalComponents/AllFertiliserCombosDetails";
// import AllProductTransferDetails from "../components/ModalComponents/AllProductTransferDetails";
import AllProductDetails from "../components/ModalComponents/AllProductsDetails";
// import UserFpoDetails from "../components/ModalComponents/UserFpoDetails";
import AllArtisanDetails from "../components/ModalComponents/AllArtisanDetails";
import AllApprovedArtisanDetails from "../components/ModalComponents/AllApprovedArtisanDetails";
import UserBuyerDetails from "../components/ModalComponents/UserBuyerDetails";
import AllUsersDetails from "../components/ModalComponents/AllUsersDetails";
// import CompostMaterialDetails from "../components/ModalComponents/CompostMaterialDetails";
import SequestrationLayout from "./SequestrationLayout";
import Sequestration from "../pages/sequestration/sequestration";
import SequestrationDetails from "../components/ModalComponents/SequestrationDetails";
import FpoBioChar from "../pages/fpo-user-details/FpoBioChar";
import FpoSequestration from "../pages/fpo-user-details/FpoSequestration";
import ViewPreparationDetails from "../components/ModalComponents/ViewPreparationDetails";
import SellerLayout from "./SellerLayout";
import BuyerLayout from "./BuyerLayout";
import ArchivedCategories from "../pages/categories/ArchivedCategories";
import ArchiveBiomassComposition from "../pages/biomass-composition/ArchiveBiomassComposition";
import ArchivePyrolysisKiln from "../pages/pyrolysis-kiln/ArchivePyrolysisKiln";
import ArchiveSoilPit from "../pages/soil-pit/ArchiveSoilPit";
import ArchiveMeasuringVessel from "../pages/measuring-vessel/ArchiveMeasuringVessel";
import ArchiveCompostMaterial from "../pages/compost/ArchiveCompostMaterial";
import SiteLayout from "./SiteLayout";
import Site from "../pages/Site/Site";
import Cluster from "../pages/Cluster/Cluster";
import ClusterLayout from "./ClusterLayout";
// import ProjectDetails from "../components/ModalComponents/ProjectDetails";
import ProjectDetailsModal from "../components/ModalComponents/ProjectDetailsModal";
import ArtisanGroundStaff from "../pages/ArtisanGroundStaff/ArtisanGrounfStaff";
// import ThermometerLayout from "./ThermometerLayout";
import Thermometer from "../pages/Thermometer";
// import MoistureMeterLayout from "./MoistureMeterLayout";
import MoistureMeter from "../pages/MoistureMeter/MoistureMeter";
import RetentionBucket from "../pages/RetentionBucket/RetetionBucket";
// import RetentionBucketLayout from "./RetentionBucketLayout";
// import SamplingBeakerLayout from "./SamplingBeakerLayout";
import SamplingBeaker from "../pages/samplingBeaker/samplingBeaker";
// import IotLayout from "./IotLayout";
import Iot from "../pages/iot/iot";
import PyrolysisEquipmentLayout from "./PyrolysisEquipmentLayout";
import PyroTechBundleLayout from "./PyroTechBundleLayout";
import PyroTechBundle from "../pages/PyroTechBundle/PyroTechBundle";
import SemiIndustrialLayout from "./SemiIndustrialLayout";
import SemiIndustrial from "../pages/SemiIndustrial/SemiIndustrial";
// import PyrolysisKiln from "../pages/pyrolysis-kiln";
import PyroTechBundleDetails from "../pages/PyroTechBundle/PyroTechBundleDetails";
// import PyroTechBundleDetailsLayout from "./PyroTechBundleDetailsLayout";
import PyroThermometer from "../pages/PyroTechBundleDetails/PyroThermometer";
import PyroMoistureMeter from "../pages/PyroTechBundleDetails/PyroMoistureMeter";
import PyroRetentionBucket from "../pages/PyroTechBundleDetails/PyroRetentionBucket";
import PyroSamplingBeaker from "../pages/PyroTechBundleDetails/PyroSamplingBeaker";
import PyroIot from "../pages/PyroTechBundleDetails/PyroIot";
import PyroMeasuringVessel from "../pages/PyroTechBundleDetails/PyroMeasuringVessel";
import SemiIndustrialDetailsLayout from "./SemiIndustriallDetailsLayout";
import SemiIndustrialDetails from "../pages/SemiIndustrial/SemiIndustrailDetails";
import SoilPitDetailsLayout from "./SoilPitDetailsLayout";
import SoilPitDetails from "../pages/soil-pit/SoilPitDetails";
import PyrolysisKilnDetailsLayout from "./PyrolysisKilnDetailsLayout";
import PyrolysisKilnDetails from "../pages/pyrolysis-kiln/PyrolysisKilnDetails";
import ClusterDetailsLayout from "./ClusterDetailsLayout";
import ClusterDetails from "../pages/Cluster/ClusterDetails";
import SiteDetailsLayout from "./SiteDetailsLayout";
import SiteDetails from "../pages/Site/SiteDetails";
import MeasuringVesselDetailsLayout from "./MeasuringVesselDetails";
import MeasuringVesselDetails from "../pages/measuring-vessel/MeasuringVesselDetails";
import BuyerDetailsLayout from "./BuyerDetailsLayout";
import SellerDetailsLayout from "./SellerDetailsLayout";
import TrackTraceTimeline from "../pages/track-and-trace/TrackTraceTimeline";

const Dashboard = lazy(() => import("../pages/Dashboard"));
const AllUsers = lazy(() => import("../pages/users/AllUsers"));
const UserSales = lazy(() => import("../pages/users/UserSales"));
const UserFpo = lazy(() => import("../pages/users/UserFpo"));
const FpoSeller = lazy(() => import("../pages/fpo-user-details/FpoSeller"));
// const FpoBuyer = lazy(() => import("../pages/fpo-user-details/FpoBuyer"));
const FpoArtisan = lazy(() => import("../pages/fpo-user-details/FpoArtisan"));
const FpoPyrolysisKiln = lazy(() => import("../pages/fpo-user-details/FpoPyrolysisKiln"));
const FpoSoilPit = lazy(() => import("../pages/fpo-user-details/FpoSoilPit"));
const FpoMeasuringVessel = lazy(() => import("../pages/fpo-user-details/FpoMeasuringVessel"));
const FpoCompositeMaterial = lazy(() => import("../pages/fpo-user-details/FpoCompostMaterials"));
const AllFPOProducts = lazy(() => import("../pages/fpo-inventory/AllFPOProducts"));
const AllFPOTransactions = lazy(() => import("../pages/fpo-inventory/AllFPOTransactions"));
const CompostMaterials = lazy(() => import("../pages/compost/CompostMaterials"));

const AdminLayout = () => {
  const { show } = useSelector((state: RootState) => state.sidebar);

  return (
    <div className="layout relative">
      <div className="layout__content flex">
        <Sidebar />

        <main
          className={`main flex-1 flex flex-col ${show ? "ml-60" : "ml-20"
            } transition-all`}
          style={{
            minHeight: "100vh",
          }}
        >
          <Header />

          <div className="page-wrapper flex-1 mt-4 px-12 pl-8 pb-24 box-border">
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/" element={<Dashboard />} />

                <Route path="/users" element={<UsersLayout />}>
                  <Route index element={<UserBuyers />} />
                  <Route path="sales" element={<UserSales />} />
                  <Route path="seller" element={<AllUsers />} />
                  <Route path="fpo" element={<UserFpo />} />
                  <Route path="artisan-ground-staff" element={<ArtisanGroundStaff />} />
                  <Route path="artisans" element={<ArtisanLayout />}>
                    <Route index element={<AllArtisan />} />
                    <Route path="approved" element={<AllApprovedArtisan />} />
                  </Route>
                </Route>

                <Route path="/seller" element={<SellerLayout />}>
                  <Route index element={<AllUsers />} />
                </Route>

                <Route path="users/seller/details/:id" element={<SellerDetailsLayout />}>
                  <Route index element={<AllUsersDetails />} />
                </Route>

                <Route path="/site" element={<SiteLayout />}>
                  <Route index element={<Site />} />
                </Route>

                <Route path="/site/:id" element={<SiteDetailsLayout />}>
                  <Route index element={<SiteDetails />} />
                </Route>

                <Route path="/cluster" element={<ClusterLayout />}>
                  <Route index element={<Cluster />} />
                </Route>

                <Route path="/cluster/:id" element={<ClusterDetailsLayout />}>
                  <Route index element={<ClusterDetails />} />
                </Route>

                {/* <Route path="/thermometer" element={<ThermometerLayout />}>
                  <Route index element={<Thermometer />} />
                </Route> */}

                {/* <Route path="/moisture-meter" element={<MoistureMeterLayout />}>
                  <Route index element={<MoistureMeter />} />
                </Route> */}

                {/* <Route path="/retention-bucket" element={<RetentionBucketLayout />}>
                  <Route index element={<RetentionBucket />} />
                </Route> */}

                {/* <Route path="/sampling-beaker" element={<SamplingBeakerLayout />}>
                  <Route index element={<SamplingBeaker />} />
                </Route> */}

                {/* <Route path="/Iot" element={<IotLayout />}>
                  <Route index element={<Iot />} />
                </Route> */}

                <Route path="/pyrolysis-equipment" element={<PyrolysisEquipmentLayout />}>

                  <Route element={<PyrolysisKilnLayout />}>
                    <Route index element={<PyrolysisKlin />} />
                    <Route path="archive" element={<ArchivePyrolysisKiln />} />
                  </Route>
                  <Route path="moisture-meter" element={<MoistureMeter />} />
                  <Route path="retention-bucket" element={<RetentionBucket />} />
                  <Route path="thermometer" element={<Thermometer />} />
                  <Route path="sampling-beaker" element={<SamplingBeaker />} />
                  <Route path="measuring-vessel" element={<MeasuringVesselLayout />}>
                    <Route index element={<MeasuringVessel />} />
                    <Route path="archive" element={<ArchiveMeasuringVessel />} />
                  </Route>
                  <Route path="iot" element={<Iot />} />

                  <Route path="soil-pit" element={<SoilPitLayout />}>
                    <Route index element={<SoilPit />} />
                    <Route path="archive" element={<ArchiveSoilPit />} />
                  </Route>
                  <Route path="semi-industrial" element={<SemiIndustrial />} />
                </Route>

                <Route path="/pyrolysis-equipment/semi-industrial/:id" element={<SemiIndustrialDetailsLayout />}>
                  <Route index element={<SemiIndustrialDetails />} />
                </Route>

                <Route path="/pyrolysis-equipment/measuring-vessel/:id" element={<MeasuringVesselDetailsLayout />}>
                  <Route index element={<MeasuringVesselDetails />} />
                </Route>

                <Route path="/pyrolysis-equipment/soil-pit/:id" element={<SoilPitDetailsLayout />}>
                  <Route index element={<SoilPitDetails />} />
                </Route>

                <Route path="/pyrolysis-equipment/:id" element={<PyrolysisKilnDetailsLayout />}>
                  <Route index element={<PyrolysisKilnDetails />} />
                </Route>

                <Route path="/pyro-tech-bundle" element={<PyroTechBundleLayout />}>
                  <Route index element={<PyroTechBundle />} />
                </Route>

                <Route path="/pyro-tech-bundle/:id" element={<PyroTechBundleDetails />}>
                  <Route index element={<PyroThermometer />} />
                  <Route path="moisture-meter" element={<PyroMoistureMeter />} />
                  <Route path="retention-bucket" element={<PyroRetentionBucket />} />
                  <Route path="sampling-beaker" element={<PyroSamplingBeaker />} />
                  <Route path="measuring-vessel" element={<PyroMeasuringVessel />} />
                  <Route path="iot" element={<PyroIot />} />
                  <Route path="pyrolysis-kiln" element={<PyroThermometer />} />
                  <Route path="soil-pit" element={<PyroThermometer />} />
                  <Route path="semi-industrial" element={<PyroThermometer />} />
                  <Route path="" />
                </Route>

                <Route path="/semi-industrial" element={<SemiIndustrialLayout />}>
                  <Route index element={<SemiIndustrial />} />
                </Route>

                <Route path="/users" element={<BuyerLayout />}>
                  <Route index element={<UserBuyers />} />
                </Route>

                <Route path="/users/details/:id" element={<BuyerDetailsLayout />}>
                  <Route index element={<UserBuyerDetails />} />
                </Route>

                <Route path="/artisan" element={<ArtisanLayout />}>
                  <Route index element={<AllArtisan />} />
                  <Route path="approved" element={<AllApprovedArtisan />} />
                </Route>

                <Route path="/artisan/:id" element={<AllArtisanDetails />}>
                </Route>

                <Route path="/artisan/approved/:id" element={<AllApprovedArtisanDetails />}>
                </Route>

                <Route path="/users/artisans" element={<AllArtisanDetails />}>
                  <Route path=":id" />
                </Route>

                <Route path="/users/buyers/details" element={<UserBuyerDetails />}>
                  <Route path=":id" />
                </Route>

                <Route path="/users/artisans/approved" element={<AllApprovedArtisanDetails />}>
                  <Route path=":id" />
                </Route>

                <Route path="/users/details" element={<AllUsersDetails />}>
                  <Route path=":id" />
                </Route>

                <Route path="/users/fpo/details/:id" element={<FPOLayout />} >
                  <Route index element={<FpoSeller />} />
                  <Route path="sales" element={<FpoSeller />} />
                  <Route path="buyers" element={<UserBuyers />} />
                  <Route path="artisans" element={<FpoArtisan />} />
                  <Route path="pyrolysisKiln" element={<FpoPyrolysisKiln />} />
                  <Route path="soilPit" element={<FpoSoilPit />} />
                  <Route path="measuringVessel" element={<FpoMeasuringVessel />} />
                  <Route path="compostMaterial" element={<FpoCompositeMaterial />} />
                  <Route path="artisans/approved" element={<AllApprovedArtisan />} />
                  <Route path="product" element={<AllFPOProducts />} />
                  <Route path="transactions" element={<AllFPOTransactions />} />
                  <Route path="bioChar" element={<FpoBioChar />} />
                  <Route path="sequestration" element={<FpoSequestration />} />
                </Route>

                <Route path="/products" element={<ProductsLayout />}>
                  <Route index element={<AllProducts />} />
                  <Route path="deleted" element={<DeletedProducts />} />
                </Route>

                <Route path="/products/details" element={<AllProductDetails />}>
                  <Route path=":id" />
                </Route>

                <Route path="/categories" element={<CategoryLayout />}>
                  <Route index element={<AllCategories />} />
                  <Route path="archive" element={<ArchivedCategories />} />
                </Route>

                {/* <Route path="/categories" element={<AllCategoriesDetails />}>
                  <Route path=":id" />
                </Route> */}

                <Route path="/fpo-inventory" element={<FPOInventoryLayout />}>
                  <Route index element={<AllFPOProducts />} />
                  <Route path="transactions" element={<AllFPOTransactions />} />
                </Route>

                <Route path="/compost-material" element={<CompostMaterialLayout />}>
                  <Route index element={<CompostMaterials />} />
                  <Route path="archive" element={<ArchiveCompostMaterial />} />
                </Route>

                {/* <Route path="/compost-material" element={<CompostMaterialDetails />}>
                  <Route path=":id" />
                </Route> */}

                <Route path="/track-and-trace" element={<TrackTraceTimeline />} />

                <Route path="/crops" element={<CropLayout />}>
                  <Route index element={<AllCrops />} />
                </Route>

                {/* <Route path="/crops" element={<AllCropsDetails />}>
                  <Route path=":id" />
                </Route> */}

                <Route path="/fertilisers" element={<FertiliserLayout />}>
                  <Route index element={<AllFertilisers />} />
                </Route>

                {/* <Route path="/fertilisers" element={<AllFertilisersDetails />}>
                  <Route path=":id" />
                </Route> */}

                <Route path="/fertiliser/comb" element={<FertiliserLayoutCombination />}>
                  <Route index element={<AllFertilisersCombination />} />
                </Route>

                {/* <Route path="/fertiliser/comb" element={<AllFertilisersCombosDetails />}>
                  <Route path=":id" />
                </Route> */}

                <Route path="/biomass-batch" element={<BiomassBatchLaoyout />}>
                  <Route index element={<AllBatch />} />
                </Route>

                <Route path="/biomass-batch/:id" element={<AllBatchDetails />}>
                  {/* <Route path=":id" element={<AllBatchDetails />} /> */}
                  <Route path="viewPreparation/:preparation_id" element={<ViewPreparationDetails />} />
                  <Route path="viewPyrolysisDetail/:pyrolysis_id" element={<AllBatchDetails />} />
                  <Route path="viewBioCharDetails/:biochar_id" element={<AllBatchDetails />} />
                  {/* <Route path="viewSequestrationDetails/:id" element={<AllBatchDetails />} /> */}
                </Route>

                <Route path="/sequestration" element={<SequestrationLayout />}>
                  <Route index element={<Sequestration />} />
                  <Route path=":biomass_batch_id/:sequestration_id" element={<SequestrationDetails />} />
                </Route>

                <Route path="/product-formulation" element={<ProductFormulationLayout />}>
                  <Route index element={<AllProductFormulation />} />
                  <Route path="transfer" element={<AllProductTransfer />} />
                </Route>

                {/* <Route path="/product-formulation" element={<AllProductFormulationDetails />}>
                  <Route path=":id" />
                </Route> */}

                {/* <Route path="/product-formulation/transfer" element={<AllProductTransferDetails />}>
                  <Route path=":id" />
                </Route> */}

                <Route path="/biomass-composition" element={<BioMassCompositionLayout />}>
                  <Route index element={<BiomassComposition />} />
                  <Route path="archive" element={<ArchiveBiomassComposition />} />
                </Route>

                {/* <Route path="/biomass-composition" element={<BiomassCompositionDetails />}>
                  <Route path=":id" />
                </Route> */}

                <Route path="/pyrolysis-kiln" element={<PyrolysisKilnLayout />}>
                  <Route index element={<PyrolysisKlin />} />
                  <Route path="archive" element={<ArchivePyrolysisKiln />} />
                </Route>

                {/* <Route path="/pyrolysis-kiln" element={<PyrolysisKilnDetails />}>
                  <Route path=":id" />
                </Route> */}

                <Route path="/soil-pit" element={<SoilPitLayout />}>
                  <Route index element={<SoilPit />} />
                  <Route path="archive" element={<ArchiveSoilPit />} />
                </Route>

                {/* <Route path="/soil-pit" element={<SoilPitDetails />}>
                  <Route path=":id" />
                </Route> */}

                <Route path="/measuring-vessel" element={<MeasuringVesselLayout />}>
                  <Route index element={<MeasuringVessel />} />
                  <Route path="archive" element={<ArchiveMeasuringVessel />} />
                </Route>

                {/* <Route path="/measuring-vessel" element={<MeasuringVesselDetails />}>
                  <Route path=":id" />
                </Route> */}

                <Route path="/project" element={<ProjectLayout />}>
                  <Route index element={<Projects />} />
                </Route>

                <Route path="/project" element={<ProjectDetailsModal />}>
                  <Route path=":id" />
                </Route>

                <Route path="/404" element={<Error404 />} />

                <Route path="*" element={<Navigate to="/404" replace={true} />} />
              </Routes>

            </Suspense>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
