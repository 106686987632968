import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { uploadImage } from "../../http/uploadImage";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { AZURE_STORAGE_DIRECTORY, AZURE_STORAGE_SUBDIRECTORY, ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import ImagePreview from "../ModalComponents/ImagePreview";
import logo from "../../assets/logo192.png";

interface SiteObj {
    site_name: string;
    site_area: string;
    image1: string;
    image2: string;
    image3: string;
    image4: string;
}

type EditSiteModalProps = {
    site_id: number;
    site_name: string;
    site_area: number;
    site_pictures: {
        image1: string;
        image2: string;
        image3: string;
        image4: string;
    };
};

const EditSiteModal: React.FC<EditSiteModalProps> = ({
    site_id,
    site_name,
    site_area,
    site_pictures,
}) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [newImg1, setNewImg1] = useState<File | null>(null);
    const [newImg2, setNewImg2] = useState<File | null>(null);
    const [newImg3, setNewImg3] = useState<File | null>(null);
    const [newImg4, setNewImg4] = useState<File | null>(null);
    const [areaUnit, setAreaUnit] = useState<string>("acres");

    const initialValues: SiteObj = {
        site_name,
        site_area: site_area.toString(),
        image1: site_pictures.image1,
        image2: site_pictures.image2,
        image3: site_pictures.image3,
        image4: site_pictures.image4,
    };

    const validationSchema = Yup.object({
        site_name: Yup.string().trim().required("Site Name is required"),
        site_area: Yup.number()
            .typeError("Site Area must be a number")
            .required("Site Area is required"),
    });

    const handleSubmit = useCallback(
        async (values: SiteObj) => {
            setIsSubmitting(true);
            try {
                let finalImage1 = values.image1;
                let finalImage2 = values.image2;
                let finalImage3 = values.image3;
                let finalImage4 = values.image4;

                let finalSiteArea = parseFloat(values.site_area);
                if (areaUnit === "hectares") {
                    finalSiteArea = finalSiteArea * 2.47105;
                }

                if (newImg1) {
                    const imgRes1 = await uploadImage(
                        newImg1,
                        AZURE_STORAGE_DIRECTORY.PUBLIC,
                        AZURE_STORAGE_SUBDIRECTORY.PROFILE
                    );
                    if (imgRes1.data.publicUrl) {
                        finalImage1 = imgRes1.data.publicUrl;
                    } else {
                        throw new Error("Image1 upload failed");
                    }
                }

                if (newImg2) {
                    const imgRes2 = await uploadImage(
                        newImg2,
                        AZURE_STORAGE_DIRECTORY.PUBLIC,
                        AZURE_STORAGE_SUBDIRECTORY.PROFILE
                    );
                    if (imgRes2.data.publicUrl) {
                        finalImage2 = imgRes2.data.publicUrl;
                    } else {
                        throw new Error("Image2 upload failed");
                    }
                }

                if (newImg3) {
                    const imgRes3 = await uploadImage(
                        newImg3,
                        AZURE_STORAGE_DIRECTORY.PUBLIC,
                        AZURE_STORAGE_SUBDIRECTORY.PROFILE
                    );
                    if (imgRes3.data.publicUrl) {
                        finalImage3 = imgRes3.data.publicUrl;
                    } else {
                        throw new Error("Image3 upload failed");
                    }
                }

                if (newImg4) {
                    const imgRes4 = await uploadImage(
                        newImg4,
                        AZURE_STORAGE_DIRECTORY.PUBLIC,
                        AZURE_STORAGE_SUBDIRECTORY.PROFILE
                    );
                    if (imgRes4.data.publicUrl) {
                        finalImage4 = imgRes4.data.publicUrl;
                    } else {
                        throw new Error("Image4 upload failed");
                    }
                }

                const payload = {
                    site_name: values.site_name,
                    site_area: finalSiteArea,
                    site_pictures: {
                        image1: finalImage1,
                        image2: finalImage2,
                        image3: finalImage3,
                        image4: finalImage4,
                    },
                };

                await axiosInstance.patch(
                    `/admin/site/update/${site_id}`,
                    payload,
                    { headers: { "Content-Type": "application/json" } }
                );

                queryClient.invalidateQueries(["getAllSites"]);
                dispatch(
                    addToast({
                        kind: SUCCESS,
                        msg: "Site Updated Successfully!",
                    })
                );

                dispatch(hideModal());
            } catch (error: any) {
                if (error.response) {
                    const response = error.response;
                    const { msg } = response.data;

                    switch (response.status) {
                        case 400:
                        case 500:
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: msg,
                                })
                            );
                            break;
                        default:
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: "Oops, something went wrong",
                                })
                            );
                            break;
                    }
                } else if (error.request) {
                    dispatch(
                        addToast({
                            kind: ERROR,
                            msg: "Oops, something went wrong",
                        })
                    );
                } else {
                    dispatch(
                        addToast({
                            kind: ERROR,
                            msg: `Error: ${error.message}`,
                        })
                    );
                }
            }
            setIsSubmitting(false);
        },
        [dispatch, site_id, queryClient, newImg1, newImg2, newImg3, newImg4, areaUnit]
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
            enableReinitialize
        >
            {({ values, setFieldValue }) => (
                <Form className="px-6 py-4 mt-2" style={{ minWidth: "40vw" }}>

                    <div className="flex justify-center items-center bg-white px-6 py-5">
                        <img src={logo} alt="logo" height={100} width={100} />
                    </div>

                    <div className="flex flex-col">
                        <Input
                            label="Site Name"
                            id="site_name"
                            name="site_name"
                            type="text"
                        />
                        {/*  */}
                        <div className="flex items-center gap-x-6">
                            <div className="flex-1">
                                <Input
                                    label={`Site Area (in ${areaUnit})`}
                                    id="site_area"
                                    name="site_area"
                                    type="text"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-bold text-gray-700 mb-1">Unit</label>
                                <select
                                    value={areaUnit}
                                    onChange={(e) => {
                                        const newUnit = e.target.value;
                                        const currentValue = parseFloat(values.site_area);
                                        if (!isNaN(currentValue)) {
                                            if (areaUnit === "acres" && newUnit === "hectares") {
                                                const converted = currentValue * 0.404686;
                                                setFieldValue("site_area", converted.toFixed(6));
                                            } else if (areaUnit === "hectares" && newUnit === "acres") {
                                                const converted = currentValue * 2.47105;
                                                setFieldValue("site_area", converted.toFixed(6));
                                            }
                                        }
                                        setAreaUnit(newUnit);
                                    }}
                                    className="p-3 border rounded-lg focus:outline-none border-gray-300 focus:border-green-500 mb-5"
                                >
                                    <option value="acres">Acres</option>
                                    <option value="hectares">Hectares</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    {/* <div className="flex flex-col gap-y-4 mt-4">
                        <label className="block text-sm font-extrabold text-black">
                            Preview Image 1
                        </label>
                        <ImagePreview
                            imageUrl={values.image1 || ""}
                            altText="Image 1 Preview"
                        />
                        <Input
                            label="Upload New Image 1"
                            id="new_image1"
                            name="new_image1"
                            type="file"
                            accept=".jpg,.png,.jpeg,.webp"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.files) {
                                    setNewImg1(e.target.files[0]);
                                }
                            }}
                        />
                    </div> */}

                    <div className="item-center gap-x-6">
                        <Input
                            label="Upload New Image 1 File (Image or PDF)"
                            id="new_image1"
                            name="new_image1"
                            type="file"
                            accept=".jpg,.png,.jpeg,.webp,.pdf"
                            onChange={(e) => {
                                if (e.target.files) {
                                    const selectedFile = e.target.files[0];
                                    const allowedTypes = ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'];
                                    if (allowedTypes.includes(selectedFile.type)) {
                                        setNewImg1(selectedFile);
                                    } else {
                                        dispatch(
                                            addToast({
                                                kind: ERROR,
                                                msg: "Only images (jpg, png, webp) and PDFs are allowed",
                                            })
                                        );
                                        e.target.value = '';
                                    }
                                }
                            }}
                        />
                    </div>

                    {/* <div className="flex flex-col gap-y-4 mt-4">
                        <label className="block text-sm font-extrabold text-black">
                            Preview Image 2
                        </label>
                        <ImagePreview
                            imageUrl={values.image2 || ""}
                            altText="Image 2 Preview"
                        />
                        <Input
                            label="Upload New Image 2"
                            id="new_image2"
                            name="new_image2"
                            type="file"
                            accept=".jpg,.png,.jpeg,.webp"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.files) {
                                    setNewImg2(e.target.files[0]);
                                }
                            }}
                        />
                    </div> */}

                    <div className="item-center gap-x-6 mt-5">
                        <Input
                            label="Upload New Image 2 File (Image or PDF)"
                            id="new_image2"
                            name="new_image2"
                            type="file"
                            accept=".jpg,.png,.jpeg,.webp,.pdf"
                            onChange={(e) => {
                                if (e.target.files) {
                                    const selectedFile = e.target.files[0];
                                    const allowedTypes = ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'];
                                    if (allowedTypes.includes(selectedFile.type)) {
                                        setNewImg2(selectedFile);
                                    } else {
                                        dispatch(
                                            addToast({
                                                kind: ERROR,
                                                msg: "Only images (jpg, png, webp) and PDFs are allowed",
                                            })
                                        );
                                        e.target.value = '';
                                    }
                                }
                            }}
                        />
                    </div>

                    {/* <div className="flex flex-col gap-y-4 mt-4">
                        <label className="block text-sm font-extrabold text-black">
                            Preview Image 3
                        </label>
                        <ImagePreview
                            imageUrl={values.image3 || ""}
                            altText="Image 3 Preview"
                        />
                        <Input
                            label="Upload New Image 3"
                            id="new_image3"
                            name="new_image3"
                            type="file"
                            accept=".jpg,.png,.jpeg,.webp"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.files) {
                                    setNewImg3(e.target.files[0]);
                                }
                            }}
                        />
                    </div> */}
                    <div className="item-center gap-x-6 mt-5">
                        <Input
                            label="Upload New Image 3 File (Image or PDF)"
                            id="new_image3"
                            name="new_image3"
                            type="file"
                            accept=".jpg,.png,.jpeg,.webp,.pdf"
                            onChange={(e) => {
                                if (e.target.files) {
                                    const selectedFile = e.target.files[0];
                                    const allowedTypes = ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'];
                                    if (allowedTypes.includes(selectedFile.type)) {
                                        setNewImg3(selectedFile);
                                    } else {
                                        dispatch(
                                            addToast({
                                                kind: ERROR,
                                                msg: "Only images (jpg, png, webp) and PDFs are allowed",
                                            })
                                        );
                                        e.target.value = '';
                                    }
                                }
                            }}
                        />
                    </div>

                    {/* <div className="flex flex-col gap-y-4 mt-4">
                        <label className="block text-sm font-extrabold text-black">
                            Preview Image 4
                        </label>
                        <ImagePreview
                            imageUrl={values.image4 || ""}
                            altText="Image 4 Preview"
                        />
                        <Input
                            label="Upload New Image 4"
                            id="new_image4"
                            name="new_image4"
                            type="file"
                            accept=".jpg,.png,.jpeg,.webp"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.files) {
                                    setNewImg4(e.target.files[0]);
                                }
                            }}
                        />
                    </div> */}

                    <div className="item-center gap-x-6 mt-5">
                        <Input
                            label="Upload New Image 4 File (Image or PDF)"
                            id="new_image4"
                            name="new_image4"
                            type="file"
                            accept=".jpg,.png,.jpeg,.webp,.pdf"
                            onChange={(e) => {
                                if (e.target.files) {
                                    const selectedFile = e.target.files[0];
                                    const allowedTypes = ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'];
                                    if (allowedTypes.includes(selectedFile.type)) {
                                        setNewImg4(selectedFile);
                                    } else {
                                        dispatch(
                                            addToast({
                                                kind: ERROR,
                                                msg: "Only images (jpg, png, webp) and PDFs are allowed",
                                            })
                                        );
                                        e.target.value = '';
                                    }
                                }
                            }}
                        />
                    </div>

                    <div className="buttons flex items-center w-full justify-center my-4 mt-10">
                        <SubmitBtn
                            text="Save"
                            isSubmitting={isSubmitting}
                            containerClasses="h-full"
                            isSubmittingClasses="lg:mt-6"
                            classes="text-sm lg:p-4 lg:rounded-lg"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default EditSiteModal;
