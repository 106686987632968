import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import logo from "../../assets/logo192.png";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import ErrorBox from "../FormikComponents/ErrorBox";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface CompostMaterialObj {
    material_name: string;
}


const AddCompostMaterialModal: React.FC = (props) => {

    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [commonError, setCommonError] = useState("");
    const queryClient = useQueryClient();
    const initialValues: CompostMaterialObj = {
        material_name: "",
    };
    const validationSchema = Yup.object().shape({
        material_name: Yup.string().trim().required("FPO Id is required").min(3, "Minimum 3 characters required").max(50, "Maximum 50 characters allowed"),
    });

    const handleSubmit = async (values: CompostMaterialObj) => {
        try {
            setIsSubmitting(true);
            const response = await axiosInstance.post(
                "/biomass_batch/sequestration/compost_material/set",
                values,
            ).then((res) => {
                setIsSubmitting(false);
                dispatch(
                    addToast({
                        kind: SUCCESS,
                        msg: "Compost Material Added Successfully",
                    })
                );
                queryClient.invalidateQueries("getCompostMaterials");
                dispatch(hideModal())
            }).catch((error: AxiosError) => {
                setIsSubmitting(false);
                setCommonError("Oops! Something went wrong.");
                dispatch(addToast({
                    kind: ERROR,
                    msg: `ERROR: ${error.response?.data}`
                }))

            })

        } catch (error) {
            setCommonError("Oops! Something went wrong.");
            dispatch(addToast({
                kind: ERROR,
                msg: `Oops! Something went wrong.`
            }))

        }
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({
                    setFieldValue,
                    setFieldTouched,
                    values,
                    errors,
                    touched,
                }) => (
                    <Form
                        className="max-w-sm flex flex-col justify-center items-center bg-white px-6 py-5 shadow"
                        style={{ minWidth: "40vw" }}
                    // encType="multipart/form-data"
                    >
                        <img src={logo} alt="logo" height={100} width={100} />
                        <div className="flex flex-col w-full">
                            <Input name="material_name" id="material_name" label="Material Name" placeholder="Enter Material Name" type="text" />

                        </div>
                        {commonError && (
                            <div className="common-error mb-2 text-center">
                                <ErrorBox msg={commonError} />
                            </div>
                        )}
                        <div className="buttons flex flex-col items-center w-full justify-center my-4 mb-6">
                            <SubmitBtn
                                text="Add Compost Material"
                                isSubmitting={isSubmitting}
                                // classes="text-sm"
                                containerClasses="h-full"
                                isSubmittingClasses="lg:mt-6"
                                classes="text-sm lg:p-4 lg:rounded-lg"
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddCompostMaterialModal;
