import { Form, Formik } from "formik";
import { useState } from "react";
import { MdCancel } from "react-icons/md";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { uploadImage } from "../../http/uploadImage";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import {
  AZURE_STORAGE_DIRECTORY,
  AZURE_STORAGE_SUBDIRECTORY,
  ERROR, SUCCESS,
  LENGTH_UNIT
} from "../../types/constants";
import AddNewFields from "../AddNewFields";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import logo from "../../assets/logo192.png";

interface FormValues {
  biomass_name: string;
  cotton_stock: number;
  rice_straw: number;
  average_biomass_diameter: number;
  average_biomass_length: number;
  biomass_type: string;
  biomass_sourcing_type: string;
  expected_processing: number;
  harvest_details: string;
  pre_pyrolysis_processing: string;
}

interface FileWithPreview extends File {
  preview?: string;
}

const AddBiomassComposeModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [new_fields, setNewFields] = useState<
    { title: string; value: number }[]
  >([]);

  const [isNewField, setIsNewField] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [diameterUnit, setDiameterUnit] = useState("cm");
  const [lengthUnit, setLengthUnit] = useState("cm");

  const initialValues: FormValues = {
    biomass_name: "",
    cotton_stock: 0,
    rice_straw: 0,
    average_biomass_diameter: 0,
    average_biomass_length: 0,
    biomass_type: "",
    biomass_sourcing_type: "",
    expected_processing: 0,
    harvest_details: "",
    pre_pyrolysis_processing: "",
  };

  const validationSchema = Yup.object({
    biomass_name: Yup.string()
      .trim()
      .required("Biomass Name is Required"),
    cotton_stock: Yup.number()
      .required("Cotton Stock is Required"),
    rice_straw: Yup.number()
      .required("Rice Straw is Required"),
    average_biomass_diameter: Yup.number()
      .min(0.002, "Average Biomass Diameter must be at least 0.002")
      .required("Average Biomass Diameter is Required"),
    average_biomass_length: Yup.number()
      .min(0.002, "Average Biomass Length must be at least 0.002")
      .required("Average Biomass Length is Required"),
    biomass_type: Yup.string()
      .trim()
      .required("Biomass Type is Required"),
    biomass_sourcing_type: Yup.string()
      .trim()
      .required("Biomass Sourcing Type is Required"),
    expected_processing: Yup.number()
      .min(0.001, "Expected Processing must be greater than 0")
      .required("Expected Processing is Required"),
    harvest_details: Yup.string()
      .trim()
      .required("Harvest Details are Required"),
    pre_pyrolysis_processing: Yup.string()
      .trim()
      .required("Pre Pyrolysis Processing is Required"),
  });

  const getConversionFactor = (from: string, to: string) => {
    const factorToCm: { [key: string]: number } = { mm: 0.1, cm: 1, m: 100 };
    const factorFromCm: { [key: string]: number } = { mm: 10, cm: 1, m: 0.01 };
    return factorToCm[from] * factorFromCm[to];
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      const totalFiles = files.length + selectedFiles.length;

      if (totalFiles > 11) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "You can upload a maximum of 11 images",
          })
        );
        return;
      }

      const newFiles = selectedFiles.map(file => {
        if (file.type.startsWith('image/')) {
          const fileWithPreview = file as FileWithPreview;
          fileWithPreview.preview = URL.createObjectURL(file);
          return fileWithPreview;
        }
        return file;
      });

      setFiles(prev => [...prev, ...newFiles]);
    }
  };

  const removeFile = (index: number) => {
    const updatedFiles = [...files];
    if (updatedFiles[index].preview) {
      URL.revokeObjectURL(updatedFiles[index].preview!);
    }
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleSubmit = async (biomass_compose: FormValues) => {
    if (files.length === 0) {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "At least one image is required",
        })
      );
      return;
    }

    setIsSubmitting(true);
    try {
      const uploadPromises = files.map((file) =>
        uploadImage(file, AZURE_STORAGE_DIRECTORY.BIOMASS_COMPOSITION, AZURE_STORAGE_SUBDIRECTORY.PROFILE)
      );

      const uploadResults = await Promise.all(uploadPromises);
      const imageUrls = uploadResults.map((result) => result.data.publicUrl).filter(Boolean);

      if (imageUrls.length > 0) {
        const biomass_photos: { [key: string]: string } = {};
        imageUrls.forEach((url, index) => {
          biomass_photos[`image${index + 1}`] = url;
        });

        const compostObj = new_fields.reduce((acc: any, item) => {
          acc[item.title] = item.value;
          return acc;
        }, {});

        const obj = {
          biomass_name: biomass_compose.biomass_name,
          biomass_blend: {
            cotton_stock: biomass_compose.cotton_stock,
            rice_straw: biomass_compose.rice_straw,
            ...compostObj,
          },
          average_biomass_diameter: biomass_compose.average_biomass_diameter,
          average_biomass_length: biomass_compose.average_biomass_length,
          biomass_photos: biomass_photos,
          biomass_type: biomass_compose.biomass_type,
          biomass_sourcing_type: biomass_compose.biomass_sourcing_type,
          expected_processing: biomass_compose.expected_processing,
          harvest_details: biomass_compose.harvest_details,
          pre_pyrolysis_processing: biomass_compose.pre_pyrolysis_processing,
        };

        const result = await axiosInstance.post("/biomass_composition/create", obj);
        if (result) {
          queryClient.invalidateQueries(["getAllBiomassComposition"]);
          dispatch(
            addToast({
              kind: SUCCESS,
              msg: "Biomass Config Added Successfully!",
            })
          );
          dispatch(hideModal());
        }
      }
    } catch (error: any) {
      if (error.response) {
        const response = error.response;
        const { msg } = response.data;

        switch (response.status) {
          case 400:
          case 500:
            dispatch(
              addToast({
                kind: ERROR,
                msg: msg,
              })
            );
            break;
          default:
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Oops, something went wrong",
              })
            );
            break;
        }
      } else if (error.request) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Oops, something went wrong",
          })
        );
      } else {
        dispatch(
          addToast({
            kind: ERROR,
            msg: `Error: ${error.message}`,
          })
        );
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, setFieldValue }) => (
        <Form
          className="px-6 py-4 mt-2"
          // style={{
          //   minWidth: "360px",
          //   maxWidth: "760px",
          // }}
          style={{ minWidth: "40vw" }}
        >
          <div className="flex justify-center items-center bg-white px-6 py-5">
            <img src={logo} alt="logo" height={100} width={100} />
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Biomass Name"
              id="biomass_name"
              name="biomass_name"
              type="text"
              placeholder="Enter Biomass Name"
            />
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Cotton Stock"
              id="cotton_stock"
              name="cotton_stock"
              type="number"
              step="any"
            />
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Rice Straw"
              id="rice_straw"
              name="rice_straw"
              type="number"
              step="any"
            />
          </div>

          {new_fields.map((field, index) => (
            <div key={index} className="flex item-center gap-x-6 relative">
              <MdCancel
                size={20}
                className="absolute right-0 text-primary cursor-pointer"
                onClick={() => {
                  setNewFields(
                    new_fields.filter((item) => item.title !== field.title)
                  );
                }}
              />

              <Input
                label={field.title}
                id={field.title}
                name={field.title}
                type="text"
                value={field.value}
                readOnly
              />
            </div>
          ))}

          <div className="flex justify-center items-center mb-4">
            {!isNewField ? (
              <button
                onClick={() => {
                  setIsNewField(true);
                }}
                className="my-2 border-2 text-white bg-primary p-1 w-full"
              >
                Add New Field
              </button>
            ) : (
              <AddNewFields
                handleAdd={(title, value) => {
                  const fieldExists = new_fields.find(
                    (field) => field.title.toLowerCase() === title.toLowerCase()
                  );
                  if (fieldExists) {
                    dispatch(
                      addToast({
                        kind: ERROR,
                        msg: "Field already exists",
                      })
                    );
                    return;
                  }

                  setNewFields([...new_fields, { title, value: +value }]);
                  setIsNewField(false);
                }}
                handleCancel={() => {
                  setIsNewField(false);
                }}
              />
            )}
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input
                label={`Average Biomass Diameter (${diameterUnit})`}
                id="average_biomass_diameter"
                name="average_biomass_diameter"
                type="number"
                step="any"
              />
            </div>

            <div>
              <select
                value={diameterUnit}
                className="w-22 p-3.5 border border-gray-300 rounded-lg"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(diameterUnit, newUnit);
                  setFieldValue("average_biomass_diameter", values.average_biomass_diameter * conversionFactor);
                  setDiameterUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input
                label={`Average Biomass Length (${lengthUnit})`}
                id="average_biomass_length"
                name="average_biomass_length"
                type="number"
                step="any"
              />
            </div>

            <div>
              <select
                value={lengthUnit}
                className="w-22 p-3.5 border border-gray-300 rounded-lg"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(lengthUnit, newUnit);
                  setFieldValue("average_biomass_length", values.average_biomass_length * conversionFactor);
                  setLengthUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Biomass Type"
              id="biomass_type"
              name="biomass_type"
              type="text"
              placeholder="Enter Biomass Type"
            />
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Biomass Sourcing Type"
              id="biomass_sourcing_type"
              name="biomass_sourcing_type"
              type="text"
              placeholder="Enter Biomass Sourcing Type"
            />
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Expected Processing"
              id="expected_processing"
              name="expected_processing"
              type="number"
              step="any"
            />
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Harvest Details"
              id="harvest_details"
              name="harvest_details"
              type="text"
              placeholder="Enter Harvesting Details"
            />
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Pre Pyrolysis Processing"
              id="pre_pyrolysis_processing"
              name="pre_pyrolysis_processing"
              type="text"
              placeholder="Enter Pre Pyrolysis Processing"
            />
          </div>

          <div className="flex item-center gap-x-6 mb-5">
            <div className="w-full">
              <label htmlFor="biomass_photo" className="block mb-1 text-gray-700 font-bold text-sm">
                Upload Biomass Photos (Image or PDF)
              </label>
              <input
                id="biomass_photo"
                name="biomass_photo"
                type="file"
                accept=".jpg,.png,.jpeg,.webp, .pdf"
                multiple
                onChange={handleFileChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
              <p className="text-xs text-gray-500 mt-1">
                *You can also upload multiple images
              </p>
            </div>
          </div>

          {files.length > 0 && (
            <div className="mt-4 mb-6">
              <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
                {files.map((file, index) => (
                  <div key={index} className="relative border rounded-lg p-2">
                    {file.preview ? (
                      <img
                        src={file.preview}
                        alt={`Preview ${index}`}
                        className="h-20 w-full object-cover rounded-lg mb-1"
                      />
                    ) : (
                      <div className="h-20 w-full flex items-center justify-center bg-gray-100 rounded-lg mb-1">
                        <span className="text-xs text-gray-500">
                          File
                        </span>
                      </div>
                    )}
                    <div className="flex justify-between items-center">
                      <span className="text-xs truncate max-w-[80%]">{`image${index + 1}: ${file.name}`}</span>
                      <button
                        type="button"
                        onClick={() => removeFile(index)}
                        className="text-red-500 hover:text-red-700 text-xs"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text="Save"
              isSubmitting={isSubmitting}
              // classes="text-sm"
              containerClasses="h-full"
              isSubmittingClasses="lg:mt-6"
              classes="text-sm lg:p-4 lg:rounded-lg"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddBiomassComposeModal;
