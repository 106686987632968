import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { uploadImage } from "../../http/uploadImage";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { AZURE_STORAGE_DIRECTORY, AZURE_STORAGE_SUBDIRECTORY, ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import ImagePreview from "../ModalComponents/ImagePreview";
import logo from "../../assets/logo192.png";

interface ArtisanObj {
  name: string;
  company_email: string;
  artisan_kyc_doc_url: string;
  company_mobile: number;
  site_image_url: string;
}

type EditProductModalProps = {
  artisan_id: string;
  name: string;
  company_email: string;
  artisan_kyc_doc_url: string;
  company_mobile: number;
  site_image_url: string;
};

const EditProductModal: React.FC<EditProductModalProps> = ({
  artisan_id,
  name,
  company_email,
  artisan_kyc_doc_url,
  company_mobile,
  site_image_url,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newKycImg, setNewKycImg] = useState<File | null>(null);
  const [newSiteImg, setNewSiteImg] = useState<File | null>(null);

  const initialValues: ArtisanObj = {
    name,
    company_email,
    artisan_kyc_doc_url,
    company_mobile,
    site_image_url,
  };

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Name is required"),
    company_email: Yup.string().trim().email("Invalid email format").required("Email is required"),
    company_mobile: Yup.number()
      .typeError("Mobile must be a number")
      .required("Mobile is required"),
  });

  const handleSubmit = useCallback(
    async (artisan: ArtisanObj) => {
      setIsSubmitting(true);
      try {
        let finalKycImgUrl = artisan_kyc_doc_url;
        let finalSiteImgUrl = site_image_url;

        if (newKycImg) {
          const kycImgRes = await uploadImage(newKycImg, AZURE_STORAGE_DIRECTORY.ARTISAN, AZURE_STORAGE_SUBDIRECTORY.KYC);
          if (kycImgRes.data.publicUrl) {
            finalKycImgUrl = kycImgRes.data.publicUrl;
          } else {
            throw new Error("KYC image upload failed");
          }
        }

        if (newSiteImg) {
          const siteImgRes = await uploadImage(newSiteImg, AZURE_STORAGE_DIRECTORY.ARTISAN, AZURE_STORAGE_SUBDIRECTORY.SITE);
          if (siteImgRes.data.publicUrl) {
            finalSiteImgUrl = siteImgRes.data.publicUrl;
          } else {
            throw new Error("Site image upload failed");
          }
        }

        const payload: Partial<ArtisanObj> = {
          ...artisan,
          artisan_kyc_doc_url: finalKycImgUrl,
          site_image_url: finalSiteImgUrl,
        };

        await axiosInstance.patch(`/artisan/update/${artisan_id}`, payload, {
          headers: { ContentType: "application/json" },
        });

        queryClient.invalidateQueries(["getProducts"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Artisan Updated Successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setIsSubmitting(false);
    },
    [dispatch, artisan_id, queryClient, newKycImg, newSiteImg, artisan_kyc_doc_url, site_image_url]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
      enableReinitialize
    >
      {() => (
        <Form
          className="px-6 py-4 mt-2"
          // style={{
          //   minWidth: "360px",
          //   maxWidth: "760px",
          // }}
          style={{ minWidth: "40vw" }}
        >

          <div className="flex justify-center items-center bg-white px-6 py-5">
            <img src={logo} alt="logo" height={100} width={100} />
          </div>

          <div className="flex item-center gap-x-6">
            <Input label="Name"
              id="name"
              name="name"
              type="text" />
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Company Email"
              id="company_email"
              name="company_email"
              type="text"
            />
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Company Mobile"
              id="company_mobile"
              name="company_mobile"
              type="text"
            />
          </div>

          {/* <div className="item-center gap-x-6">
            <label
              htmlFor="artisan_kyc_doc_url"
              className="block text-sm font-extrabold text-black"
            >
              Preview KYC Image
            </label>
            <ImagePreview
              imageUrl={artisan_kyc_doc_url}
              altText="KYC Image Preview"
            />
          </div> */}

          <div className="item-center gap-x-6">
            <Input
              label="Upload New KYC Image (Image or PDF)"
              id="new_kyc_image"
              name="new_kyc_image"
              type="file"
              accept=".jpg,.png,.jpeg,.webp,.pdf"
              onChange={(e) => {
                if (e.target.files) {
                  setNewKycImg(e.target.files[0]);
                }
              }}
            />
          </div>

          {/* 
          <div className="item-center gap-x-6">
            <label
              htmlFor="site_image_url"
              className="block text-sm font-extrabold text-black"
            >
              Preview Site Image
            </label>
            <ImagePreview
              imageUrl={site_image_url}
              altText="Site Image Preview"
            />
          </div> */}

          <div className="item-center gap-x-6">
            <Input
              label="Upload New Site Image (Image or PDF)"
              id="new_site_image"
              name="new_site_image"
              type="file"
              accept=".jpg,.png,.jpeg,.webp, .pdf"
              onChange={(e) => {
                if (e.target.files) {
                  setNewSiteImg(e.target.files[0]);
                }
              }}
            />
          </div>

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn text="Save"
              isSubmitting={isSubmitting}
              // classes="text-sm" 
              containerClasses="h-full"
              isSubmittingClasses="lg:mt-6"
              classes="text-sm lg:p-4 lg:rounded-lg"
            />

          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditProductModal;
