import { useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import { useParams } from "react-router-dom";
import { FaEye } from "react-icons/fa";

interface PyrolysisKilnDetailsObj {
    pyrolysis_kiln_id: number;
    kiln_name: string;
    kiln_type: string;
    depth: string;
    upper_diameter: string;
    lower_diameter: string;
    kiln_photo: string;
    created_at: string;
    updated_at: string;
    created_by_fpo_id: string;
    is_archived: boolean;
    address_id: string;
    label: string;
    address: string;
    city: string;
    state: string;
    pincode: string;
    addressProofUrl: string;
    coordinates: {
        x: number;
        y: number;
    };
}

const PyrolysisKilnDetails = () => {
    const { id } = useParams<{ id: string }>();

    const getAllPyrolysisKilns = async () => {
        try {
            const response = await axiosInstance.get(`/pyrolysis_technology/pyrolysis_kiln/all?is_archived=false`);
            return response.data;
        } catch (error) {
            console.error("Error fetching pyrolysis kilns:", error);
            throw error;
        }
    };

    const { data, error, isLoading } = useQuery<{ data: PyrolysisKilnDetailsObj[] }>(
        ["getAllPyrolysisKilns"],
        getAllPyrolysisKilns,
        {
            refetchOnWindowFocus: false,
            retry: 1,
        }
    );

    if (isLoading) return <p className="mt-6 text-center">Loading...</p>;
    if (error) return <p className="mt-6 text-center text-red-600">Error: {(error as Error).message}</p>;

    const filteredData = data?.data.filter(
        (item: PyrolysisKilnDetailsObj) => item.pyrolysis_kiln_id === Number(id)
    );

    const kilnData = filteredData?.[0];

    return (
        <div className="p-6">
            {kilnData ? (
                <div className="space-y-10">
                    <div className="grid grid-cols-2 gap-6 mb-10">
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Kiln Name</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={kilnData.kiln_name ? kilnData.kiln_name.replace(/\b\w/g, (char) => char.toUpperCase()) : "N/A"}
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Kiln Type</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={kilnData.kiln_type ? kilnData.kiln_type.replace(/\b\w/g, (char) => char.toUpperCase()) : "N/A"}
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Depth</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={kilnData.depth || "N/A"}
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Upper Diameter</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={kilnData.upper_diameter || "N/A"}
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Lower Diameter</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={kilnData.lower_diameter || "N/A"}
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Created By Fpo Id</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={kilnData.created_by_fpo_id !== null ? kilnData.created_by_fpo_id : "Not Available"}
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Kiln Photo</label>
                            <div className="mt-1">
                                {kilnData.kiln_photo ? (
                                    <a
                                        href={kilnData.kiln_photo}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center w-full rounded-2xl shadow-sm bg-green-100 px-5 py-3 hover:bg-green-200 transition-colors font-bold"
                                    >
                                        <FaEye className="w-5 h-5 mr-2 text-green-600" />
                                        <span className="flex-1 text-center text-green-600">View Photo</span>
                                    </a>
                                ) : (
                                    <div className="w-full rounded-2xl shadow-sm bg-red-100 px-5 py-3 text-center">
                                        Not Available
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Created At</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={kilnData.created_at ? new Date(kilnData.created_at).toLocaleDateString("en-GB") : "N/A"}
                                    readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        kilnData.created_at
                                            ? new Date(kilnData.created_at).toLocaleTimeString("en-GB", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: true
                                            }).toUpperCase()
                                            : "N/A"
                                    }
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Updated At</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={kilnData.updated_at ? new Date(kilnData.updated_at).toLocaleDateString("en-GB") : "N/A"}
                                    readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        kilnData.updated_at
                                            ? new Date(kilnData.updated_at).toLocaleTimeString("en-GB", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: true
                                            }).toUpperCase()
                                            : "N/A"
                                    }
                                    readOnly
                                />
                            </div>
                        </div>


                    </div>

                    <div>
                        <h2 className="text-xl font-bold mb-4 text-green-600 flex items-center w-full py-5">
                            <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                            <span className="px-4 text-green-600">Address Details</span>
                            <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                        </h2>

                        <div className="p-4 bg-gray-100 rounded-2xl shadow-sm space-y-4">
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-md font-semibold text-gray-700">Label</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            kilnData.label !== "N/A"
                                                ? kilnData.label.replace(/\b\w/g, (char) => char.toUpperCase())
                                                : "Not Available"
                                        }
                                        readOnly
                                    />
                                </div>

                                <div>
                                    <label className="block text-md font-semibold text-gray-700">Address</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            kilnData.address !== "N/A"
                                                ? kilnData.address.replace(/\b\w/g, (char) => char.toUpperCase())
                                                : "Not Available"
                                        }
                                        readOnly
                                    />
                                </div>

                                <div>
                                    <label className="block text-md font-semibold text-gray-700">City</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            kilnData.city !== "N/A"
                                                ? kilnData.city.replace(/\b\w/g, (char) => char.toUpperCase())
                                                : "Not Available"
                                        }
                                        readOnly
                                    />
                                </div>

                                <div>
                                    <label className="block text-md font-semibold text-gray-700">State</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            kilnData.state !== "N/A"
                                                ? kilnData.state.replace(/\b\w/g, (char) => char.toUpperCase())
                                                : "Not Available"
                                        }

                                        readOnly
                                    />
                                </div>

                                <div>
                                    <label className="block text-md font-semibold text-gray-700">Pincode</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            kilnData.pincode !== "N/A"
                                                ? kilnData.pincode
                                                : "Not Available"
                                        }
                                        readOnly
                                    />
                                </div>

                                <div>
                                    <label className="block text-md font-semibold text-gray-700">Coordinates</label>
                                    {kilnData.coordinates ? (
                                        <a
                                            href={`https://www.google.com/maps?q=${kilnData.coordinates.x},${kilnData.coordinates.y}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-blue-100 px-5 py-3 text-blue-600 font-semibold text-center"
                                        >
                                            Open Location
                                        </a>
                                    ) : (
                                        <div className="mt-1 block w-full rounded-2xl shadow-sm bg-red-100 px-5 py-3 text-red-600 font-semibold text-center">
                                            No Coordinates Available
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <label className="block text-md font-semibold text-gray-700">Address Proof</label>
                                    <div className="mt-1">
                                        {kilnData.addressProofUrl && kilnData.addressProofUrl !== "N/A" ? (
                                            <a
                                                href={kilnData.addressProofUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center w-full rounded-2xl shadow-sm bg-green-100 px-5 py-3 hover:bg-green-200 transition-colors font-bold"
                                            >
                                                <FaEye className="w-5 h-5 mr-2 text-green-600" />
                                                <span className="flex-1 text-center text-green-600">View Proof</span>
                                            </a>
                                        ) : (
                                            <div className="w-full rounded-2xl shadow-sm bg-red-100 px-5 py-3 text-center text-red-600 font-bold">
                                                Not Available
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <p className="mt-6 text-center">No kiln found with ID: {id}</p>
            )}
        </div>
    );
};

export default PyrolysisKilnDetails;