import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import {
  AZURE_STORAGE_DIRECTORY,
  AZURE_STORAGE_SUBDIRECTORY,
  ERROR,
  SUCCESS
} from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
// import ImagePreview from "../ModalComponents/ImagePreview";
import { uploadImage } from "../../http/uploadImage";
import logo from "../../assets/logo192.png";

interface SoilPitObj {
  pitName: string;
  pitLength: number;
  pitWidth: number;
  pitHeight: number;
  pitPhoto: string;
}

type EditSoilPitModalProps = {
  soilPitId: string;
  pitName: string;
  pitLength: number;
  pitWidth: number;
  pitHeight: number;
  pitPhoto: string;
};

export const LENGTH_UNIT: { value: string; label: string }[] = [
  { value: "mm", label: "mm" },
  { value: "cm", label: "cm" },
  { value: "m", label: "m" },
];

const EditSoilPitModal: React.FC<EditSoilPitModalProps> = ({
  soilPitId,
  pitName,
  pitLength,
  pitWidth,
  pitHeight,
  pitPhoto,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [newImg, setNewImg] = useState<File | null>(null);
  const [lengthUnit, setLengthUnit] = useState("cm");
  const [widthUnit, setWidthUnit] = useState("cm");
  const [heightUnit, setHeightUnit] = useState("cm");
  const [newFile, setNewFile] = useState<File | null>(null);

  const initialValues: SoilPitObj = {
    pitName,
    pitLength,
    pitWidth,
    pitHeight,
    pitPhoto,
  };

  const validationSchema = Yup.object({
    pitName: Yup.string().trim().required("Pit Name is Required"),
    pitLength: Yup.number()
      .min(0.002, "Pit Length must be at least 0.002")
      .required("Pit Length is Required"),
    pitWidth: Yup.number()
      .min(0.002, "Pit Width must be at least 0.002")
      .required("Pit Width is Required"),
    pitHeight: Yup.number()
      .min(0.002, "Pit Height must be at least 0.002")
      .required("Pit Height is Required"),
    pitPhoto: Yup.string().required("Pit Photo is Required"),
  });

  const getConversionFactor = (from: string, to: string) => {
    const factorToCm: { [key: string]: number } = { mm: 0.1, cm: 1, m: 100 };
    const factorFromCm: { [key: string]: number } = { mm: 10, cm: 1, m: 0.01 };
    return factorToCm[from] * factorFromCm[to];
  };

  const handleSubmit = useCallback(
    async (soilPit: SoilPitObj) => {
      setIsSubmitting(true);
      try {
        let finalPhotoUrl = pitPhoto;
        if (newFile) {
          const imgRes = await uploadImage(
            newFile,
            AZURE_STORAGE_DIRECTORY.SOIL_PIT,
            AZURE_STORAGE_SUBDIRECTORY.PROFILE
          );
          if (imgRes.data.publicUrl) {
            finalPhotoUrl = imgRes.data.publicUrl;
          } else {
            throw new Error("Image upload failed");
          }
        }

        await axiosInstance.patch(
          `/soil_pit/update/${soilPitId}`,
          {
            pit_name: soilPit.pitName,
            pit_length: soilPit.pitLength,
            pit_width: soilPit.pitWidth,
            pit_height: soilPit.pitHeight,
            pit_photo: finalPhotoUrl,
          },
          { headers: { ContentType: "application/json" } }
        );

        queryClient.invalidateQueries(["getAllSoilPitDetails"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Soil Pit Updated Successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        if (error.response) {
          const { msg } = error.response.data;
          const errorMsg = [400, 500].includes(error.response.status)
            ? msg
            : "Oops, something went wrong";
          dispatch(
            addToast({
              kind: ERROR,
              msg: errorMsg,
            })
          );
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setIsSubmitting(false);
    },
    [dispatch, queryClient, soilPitId, newFile, pitPhoto]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, setFieldValue }) => (
        <Form className="px-6 py-4 mt-2" style={{ minWidth: "40vw" }}>

          <div className="flex justify-center items-center bg-white px-6 py-5">
            <img src={logo} alt="logo" height={100} width={100} />
          </div>

          <div className="flex items-center gap-x-6">
            <Input label="Pit Name" id="pitName" name="pitName" type="text" />
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input
                label={`Pit Length (${lengthUnit})`}
                id="pitLength"
                name="pitLength"
                type="number"
                step="any" />
            </div>
            <div>
              <select
                value={lengthUnit}
                className="w-22 p-3.5 border border-gray-300 rounded-lg"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(lengthUnit, newUnit);
                  setFieldValue("pitLength", values.pitLength * conversionFactor);
                  setLengthUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input
                label={`Pit Width (${widthUnit})`}
                id="pitWidth"
                name="pitWidth"
                type="number"
                step="any" />
            </div>
            <div>
              <select
                value={widthUnit}
                className="w-22 p-3.5 border border-gray-300 rounded-lg"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(widthUnit, newUnit);
                  setFieldValue("pitWidth", values.pitWidth * conversionFactor);
                  setWidthUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input
                label={`Pit Height (${heightUnit})`}
                id="pitHeight"
                name="pitHeight"
                type="number"
                step="any" />
            </div>
            <div>
              <select
                value={heightUnit}
                className="w-22 p-3.5 border border-gray-300 rounded-lg"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(heightUnit, newUnit);
                  setFieldValue("pitHeight", values.pitHeight * conversionFactor);
                  setHeightUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* <div className="mt-4">
            <label htmlFor="pitPhoto" className="block text-sm font-extrabold text-black">
              Preview Soil Pit Photo
            </label>
            <ImagePreview imageUrl={pitPhoto} altText="Soil Pit Preview" />
          </div> */}

          {/* <div className="mt-4">
            <Input
              label="Upload New Soil Pit Photo"
              id="new_pit_photo"
              name="new_pit_photo"
              type="file"
              accept=".jpg,.png,.jpeg,.webp"
              onChange={(e) => {
                if (e.target.files) {
                  setNewImg(e.target.files[0]);
                }
              }}
            />
          </div> */}

          <div className="item-center gap-x-6 mt-5">
            <Input
              label="Upload New Soil Pit Photo (Image or PDF)"
              id="new_pit_photo"
              name="new_pit_photo"
              type="file"
              accept=".jpg,.png,.jpeg,.webp,.pdf"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files && e.target.files.length > 0) {
                  const selectedFile = e.target.files[0];
                  const allowedTypes = ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'];
                  if (allowedTypes.includes(selectedFile.type)) {
                    setNewFile(selectedFile);
                  } else {
                    dispatch(
                      addToast({
                        kind: ERROR,
                        msg: "Only images (jpg, png, webp) and PDFs are allowed",
                      })
                    );
                    e.target.value = '';
                  }
                }
              }}
            />
          </div>

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text="Save"
              isSubmitting={isSubmitting}
              containerClasses="h-full"
              isSubmittingClasses="lg:mt-6"
              classes="text-sm lg:p-4 lg:rounded-lg"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditSoilPitModal;
