import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
    ADD_PYRO_TECH_BUNDLE_MODAL,
    EDIT_CLUSTER_MODAL,
    ASSIGN_SITE_MODAL,
} from "../../types/constants";
import { FaPen } from "react-icons/fa";
import { NavLink } from "react-router-dom";

interface PyroTechBundleObj {
    pt_id: number;
    name: string;
    soil_pit_id: number | null;
    pyrolysis_kiln_id: number | null;
    site_id: number | null;
    created_at: string;
    updated_at: string;
    pyro_eq_si_id: number | null;
}

const PyroTechBundle = () => {
    const dispatch = useDispatch();

    const ALL_USERS_COLUMNS = [
        {
            Header: "Sr. No",
            accessor: "serialNumber",
            Cell: ({ row }: any) => row.index + 1,
        },
        // {
        //     Header: "PyroTech ID",
        //     accessor: "pt_id",
        //     Cell: ({ row }: any) => {
        //         const pt_id = row?.original.pt_id;
        //         return (
        //             <NavLink
        //                 to={`${row?.original.pt_id}`}
        //                 className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
        //                 title={`View Pyrolysis Technology Bundles Details`}
        //             >
        //                 {pt_id}
        //             </NavLink>
        //         );
        //     },
        // },
        {
            Header: "PyroTech Name",
            accessor: "name",
            Cell: ({ row }: any) => {
                const name = row?.original.name;
                return (
                    <NavLink
                        to={`${row?.original.pt_id}`}
                        className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
                        title={`View Pyrolysis Technology Bundles Details`}
                    >
                        {name}
                    </NavLink>
                );
            },
        },
        // {
        //     Header: "Soil Pit ID",
        //     accessor: "soil_pit_id",
        //     Cell: ({ value }: any) => (value !== null ? value : "Not Available"),
        // },
        // {
        //     Header: "Pyrolysis Kiln ID",
        //     accessor: "pyrolysis_kiln_id",
        //     Cell: ({ value }: any) => (value !== null ? value : "Not Available"),
        // },
        {
            Header: "Site ID",
            accessor: "site_id",
            Cell: ({ value, row }: any) => {
                return value !== null ? (
                    value
                ) : (
                    <button
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ASSIGN_SITE_MODAL,
                                    modalTitle: "Assign Site",
                                    modalProps: {
                                        data: row.original,
                                    },
                                })
                            );
                        }}
                        className="bg-green-600 text-white px-2 py-3"
                    >
                        Assign Site
                    </button>
                );
            },
        },
        {
            Header: "Created At",
            accessor: "created_at",
            Cell: ({ value }: any) =>
                value ? new Date(value).toLocaleString() : "Invalid Date",
        },
        // {
        //     Header: "Edit",
        //     accessor: "edit",
        //     Cell: ({ row }: any) => {
        //         return (
        //             <button
        //                 onClick={() => {
        //                     dispatch(
        //                         showModal({
        //                             modalType: EDIT_CLUSTER_MODAL,
        //                             modalTitle: "Edit PyroTech", // updated modal title
        //                             modalProps: {
        //                                 pt_id: row?.original.pt_id, // updated field
        //                                 name: row?.original.name,
        //                                 soil_pit_id: row?.original.soil_pit_id,
        //                                 pyrolysis_kiln_id: row?.original.pyrolysis_kiln_id,
        //                                 site_id: row?.original.site_id,
        //                                 pyro_eq_si_id: row?.original.pyro_eq_si_id,
        //                             },
        //                         })
        //                     );
        //                 }}
        //                 className="bg-green-600 text-white px-2 py-3"
        //             >
        //                 <FaPen />
        //             </button>
        //         );
        //     },
        // },
    ];

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [q, setQ] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);

    const getAllClusterDetails = async () => {
        const { data } = await axiosInstance.get(`/pyrolysis_technology/all`);
        return data?.data?.pyro_techs || [];
    };

    const { data, error, isLoading } = useQuery<PyroTechBundleObj[]>(
        ["getAllClusterDetails", pageIndex, cPageSize, q],
        getAllClusterDetails
    );

    let component = null;

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {(error as Error).message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance
                excludeColumnSearch={[]}
                tableData={data || []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
                cSortBy={undefined}
                cSetSortBy={undefined}
                desc={undefined}
                setDesc={undefined}
            />
        );
    }

    return (
        <div className="all-users">
            <header className="flex items-center justify-between mb-8">
                <div className="left">
                    <SearchBox
                        searchValue={q}
                        setSearchValue={setQ}
                        handleSearch={() => { }}
                    />
                    {selectedRows.length > 0 && (
                        <span className="btn-primary flex items-center mt-5 w-48">
                            Selected Row : {selectedRows.length}
                        </span>
                    )}
                </div>
                <div className="right">
                    <button
                        className="btn-primary flex items-center"
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_PYRO_TECH_BUNDLE_MODAL,
                                    modalTitle: "Add Pyrolysis Technology Bundle ",
                                })
                            );
                        }}
                    >
                        <span className="mr-1">Add</span> <MdAdd size={17} />
                    </button>
                </div>
            </header>
            {component !== null && component}
        </div>
    );
};

export default PyroTechBundle;
