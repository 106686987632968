import { Form, Formik } from "formik";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { uploadImage } from "../../http/uploadImage";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import {
  AZURE_STORAGE_DIRECTORY,
  AZURE_STORAGE_SUBDIRECTORY,
  ERROR,
  SUCCESS
} from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import { LENGTH_UNIT } from "../../types/constants";
import Select from "../FormikComponents/Select";
import logo from "../../assets/logo192.png";
import CenterMarker from "../MapsComponents/CenterMarker";

interface FormValues {
  kiln_name: string;
  kiln_type: string;
  depth: number;
  upper_diameter: number;
  lower_diameter: number;
  address: string;
  pt_id?: string;
  lat: string;
  lng: string;
}

interface PyrolysisTechnology {
  pt_id: number;
  name: string;
}

interface PyrolysisTechnologyResponse {
  msg: string;
  data: {
    pyro_techs: PyrolysisTechnology[];
    count: number;
  };
}

interface FileWithPreview extends File {
  preview?: string;
}

const AddPyrolysisKilnModal = ({ pt_id }: { pt_id?: string }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [depthUnit, setDepthUnit] = useState("cm");
  const [upperDiameterUnit, setUpperDiameterUnit] = useState("cm");
  const [lowerDiameterUnit, setLowerDiameterUnit] = useState("cm");

  const { data: pyrolysisTechnologies, isLoading } = useQuery<PyrolysisTechnologyResponse>(
    ["getAllPyrolysisTechnologies"],
    async () => {
      const response = await axiosInstance.get("/pyrolysis_technology/all");
      return response.data;
    }
  );

  const initialValues: FormValues = {
    kiln_name: "",
    kiln_type: "",
    depth: 0,
    upper_diameter: 0,
    lower_diameter: 0,
    address: "",
    pt_id: pt_id || "",
    lat: "",
    lng: "",
  };

  const validationSchema = Yup.object({
    kiln_name: Yup.string()
      .trim().required("Kontiki Name is required"),
    kiln_type: Yup.string()
      .trim().required("Kontiki Type is required"),
    depth: Yup.number()
      .min(0.002, "Depth must be at least 0.002")
      .required("Depth is required"),
    upper_diameter: Yup.number()
      .min(0.002, "Upper Diameter must be at least 0.002")
      .required("Upper Diameter is required"),
    lower_diameter: Yup.number()
      .min(0.002, "Lower Diameter must be at least 0.002")
      .required("Lower Diameter is required"),
    address: Yup.string().trim().required("Address is required"),
    pt_id: Yup.string().trim().required("PT ID is Required"),
    lat: Yup.number()
      .typeError("Latitude must be a number")
      .required("Latitude is Required"),
    lng: Yup.number()
      .typeError("Longitude must be a number")
      .required("Longitude is Required"),
  });

  const getConversionFactor = (from: string, to: string) => {
    const factorToCm: { [key: string]: number } = { mm: 0.1, cm: 1, m: 100 };
    const factorFromCm: { [key: string]: number } = { mm: 10, cm: 1, m: 0.01 };
    return factorToCm[from] * factorFromCm[to];
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      const totalFiles = files.length + selectedFiles.length;

      if (totalFiles > 11) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "You can upload a maximum of 11 images",
          })
        );
        return;
      }

      const newFiles = selectedFiles.map(file => {
        if (file.type.startsWith('image/')) {
          const fileWithPreview = file as FileWithPreview;
          fileWithPreview.preview = URL.createObjectURL(file);
          return fileWithPreview;
        }
        return file;
      });

      setFiles(prev => [...prev, ...newFiles]);
    }
  };

  const removeFile = (index: number) => {
    const updatedFiles = [...files];
    if (updatedFiles[index].preview) {
      URL.revokeObjectURL(updatedFiles[index].preview!);
    }
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleSubmit = async (kiln: FormValues) => {

    if (files.length === 0) {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "At least one image is required",
        })
      );
      return;
    }

    setIsSubmitting(true);
    try {
      const uploadPromises = files.map(file =>
        uploadImage(file, AZURE_STORAGE_DIRECTORY.PYROLYSIS_KILN, AZURE_STORAGE_SUBDIRECTORY.PROFILE)
      );

      const uploadResults = await Promise.all(uploadPromises);
      const imageUrls = uploadResults.map(result => result.data.publicUrl).filter(Boolean);

      if (imageUrls.length > 0) {
        const kiln_photos: { [key: string]: string } = {};
        imageUrls.forEach((url, index) => {
          kiln_photos[`image${index + 1}`] = url;
        });

        const obj = {
          ...kiln,
          kiln_photos,
          pt_id: Number(kiln.pt_id),
          lat: Number(kiln.lat),
          lng: Number(kiln.lng),
        };

        const result = await axiosInstance.post("/pyrolysis_technology/pyrolysis_kiln/create", obj);
        if (result) {
          setIsSubmitting(false);
          queryClient.invalidateQueries(["getAllPyrolysisKiln"]);
          queryClient.invalidateQueries(["getPyrolysisTechnologyById"]);
          dispatch(
            addToast({
              kind: SUCCESS,
              msg: "Kontiki added successfully!",
            })
          );
          dispatch(hideModal());
        }
      }
    } catch (error: any) {
      setIsSubmitting(false);

      if (error.response) {
        const response = error.response;
        const { msg } = response.data;
        switch (response.status) {
          case 400:
          case 500:
            dispatch(
              addToast({
                kind: ERROR,
                msg: msg,
              })
            );
            break;
          default:
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Oops, something went wrong",
              })
            );
            break;
        }
      } else if (error.request) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Oops, something went wrong",
          })
        );
      } else {
        dispatch(
          addToast({
            kind: ERROR,
            msg: `Error: ${error.message}`,
          })
        );
      }
    }
    setIsSubmitting(false);
  };

  const ptOptions = (pyrolysisTechnologies?.data?.pyro_techs || []).map((tech) => ({
    value: tech.pt_id.toString(),
    label: `${tech.name} (Pyro Tech Id: ${tech.pt_id})`,
  }));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, setFieldValue }) => (
        <Form className="px-6 py-4 mt-2" style={{ minWidth: "40vw" }}>
          <div className="flex justify-center items-center bg-white px-6 py-5">
            <img src={logo} alt="logo" height={100} width={100} />
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Kontiki Name"
              id="kiln_name"
              name="kiln_name"
              type="text"
              placeholder="Enter Kontiki Name"
            />
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Kontiki Type"
              id="kiln_type"
              name="kiln_type"
              type="text"
              placeholder="Enter Kontiki Type"
            />
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input
                label={`Depth (${depthUnit})`}
                id="depth"
                name="depth"
                type="number"
                step="any"
              />
            </div>
            <div>
              <select
                value={depthUnit}
                className="w-22 p-3.5 border border-gray-300 rounded-lg"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(depthUnit, newUnit);
                  setFieldValue("depth", values.depth * conversionFactor);
                  setDepthUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input
                label={`Upper Diameter (${upperDiameterUnit})`}
                id="upper_diameter"
                name="upper_diameter"
                type="number"
                step="any"
              />
            </div>
            <div>
              <select
                value={upperDiameterUnit}
                className="w-22 p-3.5 border border-gray-300 rounded-lg"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(upperDiameterUnit, newUnit);
                  setFieldValue("upper_diameter", values.upper_diameter * conversionFactor);
                  setUpperDiameterUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input
                label={`Lower Diameter (${lowerDiameterUnit})`}
                id="lower_diameter"
                name="lower_diameter"
                type="number"
                step="any"
              />
            </div>
            <div>
              <select
                value={lowerDiameterUnit}
                className="w-22 p-3.5 border border-gray-300 rounded-lg"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(lowerDiameterUnit, newUnit);
                  setFieldValue("lower_diameter", values.lower_diameter * conversionFactor);
                  setLowerDiameterUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Address"
              id="address"
              name="address"
              type="text"
              placeholder="ABC Chowk , Delhi"
            />
          </div>

          <div className="w-full">
            <div className="flex gap-x-4">
              <Input
                label="Latitude"
                id="lat"
                name="lat"
                type="text"
                readOnly
                className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              />
              <Input
                label="Longitude"
                id="lng"
                name="lng"
                type="text"
                readOnly
                className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              />
            </div>
          </div>

          <div className="w-full h-96 rounded-xl mb-5">
            <CenterMarker latName="lat" lngName="lng" />
          </div>

          <div className="flex items-center gap-x-6 mb-5">
            <Select
              label="Pyrolysis Technology Id"
              id="pt_id"
              name="pt_id"
              options={ptOptions}
              isLoading={isLoading}
              disabled={pt_id ? true : false}
              placeholder="Select Pyrolysis Technology Id"
              classes="w-full"
            />
          </div>

          <div className="flex item-center gap-x-6 mb-5">
            <div className="w-full">
              <label htmlFor="kiln_photos" className="block mb-1 text-gray-700 font-bold text-sm">
                Upload Kontiki Photos (Image or PDF)
              </label>
              <input
                id="kiln_photos"
                name="kiln_photos"
                type="file"
                accept=".jpg,.png,.jpeg,.webp, .pdf"
                multiple
                onChange={handleFileChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
              <p className="text-xs text-gray-500 mt-1">*You can also upload multiple images</p>
            </div>
          </div>

          {files.length > 0 && (
            <div className="mt-4 mb-6">
              <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
                {files.map((file, index) => (
                  <div key={index} className="relative border rounded-lg p-2">
                    {file.preview ? (
                      <img
                        src={file.preview}
                        alt={`Preview ${index}`}
                        className="h-20 w-full object-cover rounded-lg mb-1"
                      />
                    ) : (
                      <div className="h-20 w-full flex items-center justify-center bg-gray-100 rounded-lg mb-1">
                        <span className="text-xs text-gray-500">File</span>
                      </div>
                    )}
                    <div className="flex justify-between items-center">
                      <span className="text-xs truncate max-w-[80%]">{`image${index + 1}: ${file.name}`}</span>
                      <button
                        type="button"
                        onClick={() => removeFile(index)}
                        className="text-red-500 hover:text-red-700 text-xs"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text="Add Kontiki"
              isSubmitting={isSubmitting}
              containerClasses="h-full"
              isSubmittingClasses="lg:mt-6"
              classes="text-sm lg:p-4 lg:rounded-lg"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddPyrolysisKilnModal;