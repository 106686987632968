import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import {
  ERROR
  , SUCCESS
} from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import logo from "../../assets/logo192.png";

interface UserObj {
  name: string;
  is_biomass: boolean;
}

const AddCategoryModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [active, setActive] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // const editAccessOptions = [
  //   {
  //     value: "Yes",
  //     label: "Yes",
  //   },
  //   {
  //     value: "No",
  //     label: "No",
  //   },
  // ];
  // const userRoleOptions = Object.values(USER_ROLES_CREATION).map(
  //   (r: string) => ({
  //     label: r,
  //     value: r,
  //   })
  // );

  const initialValues: UserObj = {
    name: "",
    is_biomass: false,
  };

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Category Title is Required"),
  });

  const handleSubmit = useCallback((user: UserObj) => {
    setIsSubmitting(true);

    axiosInstance
      .post(
        `/admin/productcategories`,
        {
          category: user.name,
          is_biomass: active,
        },
        {
          headers: {
            ContentType: "application/json",
          },
        }
      )
      .then((response) => {
        const { msg } = response.data;
        setIsSubmitting(false);
        queryClient.invalidateQueries(["getCategories"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Product Category Added Successfully!",
          })
        );
        dispatch(hideModal());
      })
      .catch((error: AxiosError) => {
        setIsSubmitting(false);
        if (error.response) {
          const response = error.response;
          const { msg } = response.data;
          switch (response.status) {
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      });
  }, [active, dispatch, queryClient]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ setFieldValue }) => (
        <Form className="px-6 py-4 mt-2" style={{ minWidth: "40vw" }}>

          <div className="flex justify-center items-center bg-white px-6 py-5">
            <img src={logo} alt="logo" height={100} width={100} />
          </div>

          <div className="flex flex-col items-center gap-x-6 ">
            <div className="w-full">
              <Input
                label="Category Title"
                id="name"
                name="name"
                type="text"
                placeholder="Enter Category Title"
              />
            </div>

            <div className="flex flex-col w-full mb-5">
              <span className="text-sm font-bold">BioMass Category</span>
              <div className="flex gap-x-4 mt-1">
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="is_biomass_option"
                    value="yes"
                    checked={active === true}
                    disabled={active === true}
                    onChange={() => setActive(true)}
                    className="form-radio h-5 w-5 text-green-600"
                  />
                  <span className="text-sm">Yes</span>
                </label>

                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="is_biomass_option"
                    value="no"
                    checked={active === false}
                    disabled={active === false}
                    onChange={() => setActive(false)}
                    className="form-radio h-5 w-5 text-green-600"
                  />
                  <span className="text-sm">No</span>
                </label>
              </div>
            </div>
          </div>

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text="Save"
              isSubmitting={isSubmitting}
              containerClasses="h-full"
              isSubmittingClasses="lg:mt-6"
              classes="text-sm lg:p-4 lg:rounded-lg"
            />
          </div>

        </Form>
      )}
    </Formik>
  );
};

export default AddCategoryModal;
