import { Form, Formik } from "formik";
import React, { useState, useCallback } from "react";
// import { useQueryClient } from "react-query";
// import { useDispatch } from "react-redux";
import * as Yup from "yup";
import logo from "../../assets/logo192.png";
import axiosInstance from "../../axiosInstance";
import useAssignProductToFPO from "../../hooks/useAssignProductToFPO";
import ErrorBox from "../FormikComponents/ErrorBox";
import Input from "../FormikComponents/Input";
import SelectDropDownAsync from "../FormikComponents/SelectDropDownAsync";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import debounce from "lodash/debounce";

interface AssignFPOObj {
    fpo_id: string;
    quantity: number;
}

interface AssignProductToFPOProps {
    sp_id: string;
    qty: number;
}

const AssignProductToFPO: React.FC<AssignProductToFPOProps> = (props) => {
    const { sp_id, qty } = props;

    // const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [commonError, setCommonError] = useState("");
    // const queryClient = useQueryClient();
    const initialValues: AssignFPOObj = {
        fpo_id: "",
        quantity: 0,
    };
    const { handleFPOAssign } = useAssignProductToFPO();

    const validationSchema = Yup.object().shape({
        fpo_id: Yup.string()
            .min(12, "FPO ID must be minimum of length 12"),
        quantity: Yup.number().min(1, "Minimum quantity must be greater than 0")
            .required("Quantity is required")
            .max(qty, `Quantity must be less than or equal to ${qty}`),

    });

    const handleSubmit = async (values: AssignFPOObj) => {
        if (!values.fpo_id) {
            setCommonError("FPO Id is required");
            return;
        }
        await handleFPOAssign({
            sp_id: sp_id,
            fpo_id: values.fpo_id,
            quantity: values.quantity,
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedLoadOptions = useCallback(
        debounce(async (
            inputValue: string,
            callback: (options: { label: string; value: string }[]) => void
        ) => {
            try {
                const response = await axiosInstance.get("/fpo/all");
                let requiredData: { label: string; value: string }[] = [];

                if (response?.data?.data?.length > 0) {
                    for (let i = 0; i < response.data.data.length; i++) {
                        if (
                            response.data.data[i].fpo_name
                                .toLowerCase()
                                .replace(" ", "")
                                .includes(inputValue.toLowerCase())
                        ) {
                            requiredData.push({
                                label: response.data.data[i].fpo_name,
                                value: response.data.data[i].fpo_id,
                            });
                        }
                    }
                }
                callback(requiredData);
            } catch (error) {
                callback([
                    {
                        label: "Error. Something went wrong!!",
                        value: "error",
                    },
                ]);
            }
        }, 500),
        []
    );

    const loadOptions = (inferString: string) => {
        return new Promise<{ label: string; value: string }[]>((resolve) => {
            debouncedLoadOptions(inferString, resolve);
        });
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({
                    setFieldValue,
                    setFieldTouched,
                    values,
                    errors,
                    touched,
                }) => (
                    <Form
                        className="max-w-sm flex flex-col justify-center items-center bg-white px-6 py-5 shadow"
                        style={{ minWidth: "40vw" }}
                    >
                        <img src={logo} alt="logo" height={100} width={100} />
                        <div className="flex flex-col w-full">
                            <SelectDropDownAsync
                                label="FPO List"
                                id="fpo_id"
                                name="fpo_id"
                                classes=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                loadOptions={loadOptions}
                            />

                            <Input
                                label="Quantity"
                                id="quantity"
                                name="quantity"
                                type="number"
                                placeholder="Enter Quantity"
                                min={1}
                                required
                            />

                            {values?.fpo_id && (
                                <div className="flex gap-2">
                                    <p>Select FPO Id : </p>
                                    <p className="font-bold">
                                        {values?.fpo_id}
                                    </p>
                                </div>
                            )}
                        </div>
                        {commonError && (
                            <div className="common-error mb-2 text-center">
                                <ErrorBox msg={commonError} />
                            </div>
                        )}
                        <div className="buttons flex flex-col items-center w-full justify-center my-4 mb-6">
                            <SubmitBtn
                                text="Add User"
                                isSubmitting={isSubmitting}
                                // classes="text-sm"
                                containerClasses="h-full"
                                isSubmittingClasses="lg:mt-6"
                                classes="text-sm lg:p-4 lg:rounded-lg"
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AssignProductToFPO;
