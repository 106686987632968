import { useState } from "react";
import { NavLink } from "react-router-dom";
import { MdAdd } from "react-icons/md";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
  ADD_FERTILISER_COMBINATION_MODAL,
} from "../../types/constants";

interface UserObj {
  id: string;
  category: string;
}

const AllFertilisersCombination = () => {
  const ALL_USERS_COLUMNS = [
    {
      Header: "Sr. No",
      accessor: "serialNumber",
      Cell: ({ row }: any) => {
        return row.index + 1;
      },
    },
    // {
    //   Header: "Fertiliser Id",
    //   accessor: "id",
    //   Cell: ({ row }: any) => {
    //     const id = row?.original.id;
    //     return (
    //       <NavLink
    //         to={`${row?.original.id}`}
    //         className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
    //         title={`View All Fertilisers Combinations Details`}
    //       >
    //         {id}
    //       </NavLink>
    //     );
    //   },
    // },
    {
      Header: "Fertiliser Name",
      accessor: "name",
      Cell: ({ value }: any) => {
        console.log(value)
        return value ? value : "Not Available";
      },
    },
    // {
    //   Header: "Fertiliser Id 1",
    //   accessor: "fertilizerId1",
    // },
    // {
    //   Header: "Fertiliser Id 2",
    //   accessor: "fertilizerId2",
    // },
    // {
    //   Header: "Fertiliser Id 3",
    //   accessor: "fertilizerId3",
    //   Cell: ({ row }: any) => {
    //     const fertilizerId3 = row?.original.fertilizerId3;
    //     return fertilizerId3 === null ? (
    //       <p className="text-red-400">Not Available</p>
    //     ) : (
    //       <NavLink
    //         to={`${row?.original.fertilizerId3}`}
    //         className="text-green px-2 py-3"
    //       >
    //         {fertilizerId3}
    //       </NavLink>
    //     );
    //   },
    // },
    // {
    //   Header: "Fertiliser Id 4",
    //   accessor: "fertilizerId4",
    //   Cell: ({ row }: any) => {
    //     const fertilizerId4 = row?.original.fertilizerId4;
    //     return fertilizerId4 === null ? (
    //       <p className="text-red-400">Not Available</p>
    //     ) : (
    //       <NavLink
    //         to={`${row?.original.fertilizerId4}`}
    //         className="text-green px-2 py-3"
    //       >
    //         {fertilizerId4}
    //       </NavLink>
    //     );
    //   },
    // },
    // {
    //   Header: "Edit",
    //   accessor: "edit",
    //   Cell: ({ value, row }: any) => {
    //     console.table(row.original);
    //     return (
    //       <button
    //         onClick={() => {
    //           dispatch(
    //             showModal({
    //               modalType: EDIT_ALL_FERTILISERS_COMB_MODAL,
    //               modalTitle: "Edit Fertiliser Combo",
    //               modalProps: {
    //                 fertiliserCombId: row?.original.id,
    //                 name: row?.original.name,
    //                 addressId: row?.original.addressId,
    //               },
    //             })
    //           );
    //         }}
    //         className="bg-green-600 text-white px-2 py-3"
    //       >
    //         Edit Fertiliser Combo
    //       </button>
    //     );
    //   },
    // },
  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getFertilisers = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(
      `/fertilizer/inorganic-fertilizer-combo/all`
    );

    return data?.data;
  };

  const { data, error, isLoading } = useQuery<
    UserObj[] | undefined,
    any,
    UserObj[],
    any[]
  >(
    ["getFertilisersComb", pageIndex, cPageSize, cSortBy, desc, q],
    getFertilisers
  );

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance excludeColumnSearch={[]}
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1}
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => { }}
          />

          {selectedRows.length > 0 && (
            <span className="btn-primary flex items-center mt-5 w-48">
              Selected Row : {selectedRows.length}
            </span>
          )}

        </div>

        <div className="right">
          <button
            className="btn-primary flex items-center"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_FERTILISER_COMBINATION_MODAL,
                  modalTitle: "Add a Fertiliser Combination",
                })
              );
            }}
          >
            <span className="mr-1">Add</span> <MdAdd size={17} />
          </button>
        </div>
      </header>

      {component !== null && component}
    </div>
  );
};

export default AllFertilisersCombination;
