import { Form, Formik } from "formik";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import Select from "../FormikComponents/Select";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import CenterMarker from "../MapsComponents/CenterMarker";
import logo from "../../assets/logo192.png";
import { STATES } from "../../../src/types/constants";
import { uploadImage } from "../../http/uploadImage";
import { AZURE_STORAGE_DIRECTORY, AZURE_STORAGE_SUBDIRECTORY } from "../../types/constants";

interface AddArtisanGroundStaffObj {
    name: string;
    mobile: string;
    email: string;
    user_address: {
        line_1: string;
        city: string;
        state: string;
        pincode: string;
        lat: string;
        lng: string;
    };
    address: {
        line_1: string;
        city: string;
        state: string;
        pincode: string;
        lat: string;
        lng: string;
    };
    relationship_manager: string;
    kyc_doc_url: string;
    kyc_doc_type: string;
    job_desc: string;
    fpo_id: string;
    profile_picture: string;
}

const AddArtisanGroundStaff = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [profileFile, setProfileFile] = useState<File | null>(null);

    const initialValues: AddArtisanGroundStaffObj = {
        name: "",
        mobile: "",
        email: "",
        user_address: {
            line_1: "",
            city: "",
            state: "",
            pincode: "",
            lat: "",
            lng: "",
        },
        address: {
            line_1: "",
            city: "",
            state: "",
            pincode: "",
            lat: "",
            lng: "",
        },
        relationship_manager: "",
        kyc_doc_url: "",
        kyc_doc_type: "",
        job_desc: "",
        fpo_id: "",
        profile_picture: "",
    };

    const validationSchema = Yup.object({
        name: Yup.string().trim().required("Name is Required"),
        mobile: Yup.number()
            .typeError("Mobile must be a number")
            .required("Mobile is Required"),
        email: Yup.string().email("Invalid email").required("Email is Required"),
        user_address: Yup.object({
            line_1: Yup.string().trim().required("User Address Line 1 is Required"),
            city: Yup.string().trim().required("User City is Required"),
            state: Yup.string().trim().required("User State is Required"),
            pincode: Yup.number()
                .typeError("Pincode must be a number")
                .required("User Pincode is Required"),
            lat: Yup.number()
                .typeError("Latitude must be a number")
                .required("User Latitude is Required"),
            lng: Yup.number()
                .typeError("Longitude must be a number")
                .required("User Longitude is Required"),
        }),
        address: Yup.object({
            line_1: Yup.string().trim().required("Address Line 1 is Required"),
            city: Yup.string().trim().required("City is Required"),
            state: Yup.string().trim().required("State is Required"),
            pincode: Yup.number()
                .typeError("Pincode must be a number")
                .required("Pincode is Required"),
            lat: Yup.number()
                .typeError("Latitude must be a number")
                .required("Latitude is Required"),
            lng: Yup.number()
                .typeError("Longitude must be a number")
                .required("Longitude is Required"),
        }),
        relationship_manager: Yup.string().trim().required("Relationship Manager is Required"),
        kyc_doc_type: Yup.string().trim().required("KYC Document Type is Required"),
        job_desc: Yup.string().trim().required("Job Description is Required"),
        fpo_id: Yup.string().trim().required("FPO ID is Required"),
        profile_picture: Yup.string().notRequired()
    });

    const handleSubmit = async (values: AddArtisanGroundStaffObj) => {
        if (!file) {
            dispatch(
                addToast({
                    kind: ERROR,
                    msg: "KYC Document is required",
                })
            );
            return;
        }

        setIsSubmitting(true);
        try {
            const kycFileRes = await uploadImage(
                file,
                AZURE_STORAGE_DIRECTORY.ARTISAN,
                AZURE_STORAGE_SUBDIRECTORY.KYC
            );

            if (!kycFileRes.data.publicUrl) {
                throw new Error("KYC Document upload failed");
            }

            let profilePictureUrl = "";
            if (profileFile) {
                const profileFileRes = await uploadImage(
                    profileFile,
                    AZURE_STORAGE_DIRECTORY.ARTISAN,
                    AZURE_STORAGE_SUBDIRECTORY.PROFILE
                );
                if (!profileFileRes.data.publicUrl) {
                    throw new Error("Profile Picture upload failed");
                }
                profilePictureUrl = profileFileRes.data.publicUrl;
            }

            const obj = {
                name: values.name,
                mobile: values.mobile,
                email: values.email,
                user_address: {
                    line_1: values.user_address.line_1,
                    city: values.user_address.city,
                    state: values.user_address.state,
                    pincode: values.user_address.pincode,
                    lat: values.user_address.lat,
                    lng: values.user_address.lng,
                },
                address: {
                    line_1: values.address.line_1,
                    city: values.address.city,
                    state: values.address.state,
                    pincode: values.address.pincode,
                    lat: values.address.lat,
                    lng: values.address.lng,
                },
                relationship_manager: values.relationship_manager,
                kyc_doc_url: kycFileRes.data.publicUrl,
                kyc_doc_type: values.kyc_doc_type,
                job_desc: values.job_desc,
                fpo_id: values.fpo_id,
                ...(profilePictureUrl ? { profile_picture: profilePictureUrl } : {})
            };

            const result = await axiosInstance.post("/artisan_gstaff/add", obj);

            if (result) {
                setIsSubmitting(false);
                queryClient.invalidateQueries(["getAllArtisanGroundStaff"]);
                dispatch(
                    addToast({
                        kind: SUCCESS,
                        msg: "Artisan Ground Staff Added Successfully!",
                    })
                );
                dispatch(hideModal());
            }
        } catch (error: any) {
            setIsSubmitting(false);
            if (error.response) {
                const response = error.response;
                const { msg } = response.data;
                switch (response.status) {
                    case 400:
                    case 500:
                        dispatch(
                            addToast({
                                kind: ERROR,
                                msg: msg,
                            })
                        );
                        break;
                    default:
                        dispatch(
                            addToast({
                                kind: ERROR,
                                msg: "Oops, something went wrong",
                            })
                        );
                        break;
                }
            } else if (error.request) {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: "Oops, something went wrong",
                    })
                );
            } else {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: `Error: ${error.message}`,
                    })
                );
            }
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
        >
            <Form className="max-w-sm flex flex-col justify-center items-center bg-white px-6 py-5 shadow" style={{ minWidth: "40vw" }}>
                <img src={logo} alt="logo" height={100} width={100} />

                <div className="flex flex-col w-full">
                    <Input
                        label="Name"
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Enter Full Name"
                        className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                    />
                    <Input
                        label="Mobile"
                        id="mobile"
                        name="mobile"
                        type="number"
                        placeholder="Enter Mobile Number"
                        className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                    />
                    <Input
                        label="Email"
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter Email ID"
                        className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                    />
                    <Input
                        label="Relationship Manager"
                        id="relationship_manager"
                        name="relationship_manager"
                        type="text"
                        placeholder="Enter Relationship Manager Name"
                        className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                    />
                </div>

                <div className="w-full">
                    <h3 className="text-lg font-semibold mb-2">User Address</h3>
                    <div className="flex flex-col">
                        <Input
                            label="Address Line 1"
                            id="user_address.line_1"
                            name="user_address.line_1"
                            type="text"
                            placeholder="Enter User Address"
                            className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                        />
                        <div className="flex w-full space-x-4">
                            <Input
                                label="City"
                                id="user_address.city"
                                name="user_address.city"
                                type="text"
                                placeholder="Enter City Name"
                                className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                            <div className="w-full">
                                <Select
                                    options={STATES}
                                    label="State"
                                    id="user_address.state"
                                    name="user_address.state"
                                    placeholder="Select state"
                                />
                            </div>
                            <Input
                                label="Pincode"
                                id="user_address.pincode"
                                name="user_address.pincode"
                                type="number"
                                className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                        </div>
                        <div className="flex gap-x-4">
                            <Input
                                label="Latitude"
                                id="user_address.lat"
                                name="user_address.lat"
                                type="text"
                                readOnly
                                className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                            <Input
                                label="Longitude"
                                id="user_address.lng"
                                name="user_address.lng"
                                type="text"
                                readOnly
                                className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                        </div>
                    </div>
                    <div className="w-full h-96 rounded-xl">
                        <CenterMarker latName="user_address.lat" lngName="user_address.lng" />
                    </div>
                </div>

                <div className="mt-5 w-full">
                    <h3 className="text-lg font-semibold mb-2">Residential Address</h3>
                    <div className="flex flex-col">
                        <Input
                            label="Address Line 1"
                            id="address.line_1"
                            name="address.line_1"
                            type="text"
                            placeholder="Enter Residential Address"
                            className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                        />
                        <div className="flex w-full space-x-4">
                            <Input
                                label="City"
                                id="address.city"
                                name="address.city"
                                type="text"
                                placeholder="Enter City Name"
                                className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                            <div className="w-full">
                                <Select
                                    options={STATES}
                                    label="State"
                                    id="address.state"
                                    name="address.state"
                                    placeholder="Select state"
                                />
                            </div>
                            <Input
                                label="Pincode"
                                id="address.pincode"
                                name="address.pincode"
                                type="number"
                                placeholder="Enter pincode"
                                className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                        </div>
                        <div className="flex gap-x-4">
                            <Input
                                label="Latitude"
                                id="address.lat"
                                name="address.lat"
                                type="text"
                                // placeholder="Auto populated"
                                readOnly
                                className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                            <Input
                                label="Longitude"
                                id="address.lng"
                                name="address.lng"
                                type="text"
                                // placeholder="Auto populated"
                                readOnly
                                className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                        </div>
                    </div>
                    <div className="w-full h-96 rounded-xl">
                        <CenterMarker latName="address.lat" lngName="address.lng" />
                    </div>
                </div>

                <div className="mt-5 flex flex-col w-full">
                    <div className="flex items-center">
                        <Input
                            label="Upload KYC Document (Image or PDF)"
                            id="kyc_doc_url"
                            name="kyc_doc_url"
                            type="file"
                            accept=".jpg,.png,.jpeg,.webp,.pdf"
                            onChange={(e) => {
                                if (e.target.files) {
                                    setFile(e.target.files[0]);
                                }
                            }}
                        />
                    </div>
                    <Input
                        label="KYC Document Type"
                        id="kyc_doc_type"
                        name="kyc_doc_type"
                        type="text"
                        placeholder="Enter Document Type"
                        className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                    />
                </div>

                <div className="flex flex-col w-full">
                    <Input
                        label="Job Description"
                        id="job_desc"
                        name="job_desc"
                        type="text"
                        placeholder="Enter Job Description"
                        className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                    />
                    <Input
                        label="FPO ID"
                        id="fpo_id"
                        name="fpo_id"
                        type="text"
                        placeholder="Enter FPO ID"
                        className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                    />
                </div>

                <div className="flex flex-col w-full">
                    <Input
                        label="Upload Profile Picture (Image)"
                        id="profile_picture"
                        name="profile_picture"
                        type="file"
                        accept=".jpg,.png,.jpeg,.webp"
                        onChange={(e) => {
                            if (e.target.files) {
                                setProfileFile(e.target.files[0]);
                            }
                        }}
                    />
                </div>

                <div className="buttons flex flex-col items-center w-full justify-center my-4 mb-6">
                    <SubmitBtn
                        text="Add Artisan Ground Staff"
                        isSubmitting={isSubmitting}
                        containerClasses="h-full"
                        isSubmittingClasses="lg:mt-6"
                        classes="text-sm lg:p-4 lg:rounded-lg"
                    />
                </div>
            </Form>
        </Formik>
    );
};

export default AddArtisanGroundStaff;
