import { Option } from ".";

// toast types
export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";
export const INFO = "INFO";
export const WARNING = "WARNING";
export const DEFAULT = "DEFAULT";

// modal types
export const ADD_CATEGORY_MODAL = "ADD_CATEGORY_MODAL";
export const EDIT_PRODUCT_MODAL = "EDIT_PRODUCT_MODAL";
export const EDIT_USER_MODAL = "EDIT_USER_MODAL";
export const ADD_PYRO_TECH_BUNDLE_MODAL = "ADD_PYRO_TECH_BUNDLE_MODAL";
export const ASSIGN_FPO_TO_SITE_MODAL = "ASSIGN_FPO_TO_SITE_MODAL";
export const ASSIGN_SITE_TO_ARTISAN = "ASSIGN_SITE_TO_ARTISAN";
export const ASSIGN_FPO_TO_CLUSTER_MODAL = "ASSIGN_FPO_TO_CLUSTER_MODAL";
export const EDIT_BUYER_MODAL = "EDIT_BUYER_MODAL";
export const EDIT_ALL_CROPS_MODAL = "EDIT_ALL_CROPS_MODAL";
export const EDIT_ALL_ARTISAN_MODAL = "EDIT_ALL_ARTISAN_MODAL";
export const EDIT_ALL_CATEGORIES_MODAL = "EDIT_ALL_CATEGORIES_MODAL";
export const EDIT_MEASURING_VESSEL_MODAL = "EDIT_MEASURING_VESSEL_MODAL";
export const EDIT_CLUSTER_MODAL = "EDIT_CLUSTER_MODAL";
export const EDIT_SITE_MODAL = "EDIT_SITE_MODAL";
export const EDIT_SOIL_PIT_MODAL = "EDIT_SOIL_PIT_MODAL";
export const EDIT_ALL_FERTILISERS_MODAL = "EDIT_ALL_FERTILISERS_MODAL";
export const EDIT_ALL_FERTILISERS_COMB_MODAL =
  "EDIT_ALL_FERTILISERS_COMB_MODAL";
export const EDIT_BIOMASS_CONFIG_MODAL = "EDIT_BIOMASS_CONFIG_MODAL";
export const EDIT_PYROLYSIS_KILN_MODAL = "EDIT_PYROLYSIS_KILN_MODAL";
export const EDIT_FPO_MODAL = "EDIT_FPO_MODAL";
export const VIEW_FPO_MODAL = "VIEW_FPO_MODAL";
export const ADD_FPO_MODAL = "ADD_FPO_MODAL";
export const FPO_ONBOARDING_FORM = "FPO_ONBOARDING_FORM";
export const FORMULATION_FORM_MODAL = "FORMULATION_FORM_MODAL";
export const VIEW_PRODUCT_FORMULATION_MODAL = "VIEW_PRODUCT_FORMULATION_MODAL";
export const ASSIGN_PRODUCT_TO_FPO_MODAL = "ASSIGN_PRODUCT_TO_FPO_MODAL";
export const ADD_CROP_MODAL = "ADD_CROP_MODAL";
export const ADD_SEED_TYPE_MODAL = "ADD_SEED_TYPE_MODAL";
export const ADD_SEED_VARIETY_MODAL = "ADD_SEED_VARIETY_MODAL";
export const ADD_CROP_VARIETY_MODAL = "ADD_CROP_VARIETY_MODAL";
export const ADD_FERTILISER_MODAL = "ADD_FERTILISER_MODAL";
export const ADD_FERTILISER_COMBINATION_MODAL =
  "ADD_FERTILISER_COMBINATION_MODAL";
export const ADD_ICAR_MODAL = "ADD_ICAR_MODAL";
export const EDIT_ICAR_MODAL = "EDIT_ICAR_MODAL";
export const VIEW_PREPARATION_MODAL = "VIEW_PREPARATION_MODAL";
export const VIEW_PYROLYSIS_MODAL = "VIEW_PYROLYSIS_MODAL";
export const VIEW_BIOCHAR_MODAL = "VIEW_BIOCHAR_MODAL";
export const VIEW_SEQUESTRATION_MODAL = "VIEW_SEQUESTRATION_MODAL";
export const ADD_BIOMASS_COMPOSE_MODAL = "ADD_BIOMASS_COMPOSE_MODAL";
export const ADD_PYROLYSIS_KILN_MODAL = "ADD_PYROLYSIS_KILN_MODAL";
export const ADD_SOIL_PIT_MODAL = "ADD_SOIL_PIT_MODAL";
export const ASSIGN_SITE_MODAL = "ASSIGN_SITE_MODAL";
export const ADD_MEASURING_VESSEL_MODAL = "ADD_MEASURING_VESSEL_MODAL";
export const ADD_SEMI_INDUSTRIAL_MODAL = "ADD_SEMI_INDUSTRIAL_MODAL";
export const ADD_CLUSTER_MODAL = "ADD_CLUSTER_MODAL";
export const ADD_ARTISAN_GROUND_STAFF_MODAL = "ADD_ARTISAN_GROUND_STAFF_MODAL";
export const ADD_NEW_SITE_MODAL = "ADD_NEW_SITE_MODAL";
export const ASSIGN_BIOMASS_MODAL = "ASSIGN_BIOMASS_MODAL";
export const ADD_NEW_PROJECT_MODAL = "ADD_NEW_PROJECT_MODAL";
export const VIEW_ARTISAN_OR_PROJECT_MODAL = "VIEW_ARTISAN_OR_PROJECT_MODAL";
export const VIEW_ASSIGNED_ARTISAN = "VIEW_ASSIGNED_ARTISAN";
export const APPROVE_ARTISAN_MODAL = "APPROVE_ARTISAN_MODAL";
export const VIEW_ARTISAN_BATCHES_DETAIL = "VIEW_ARTISAN_BATCHES_DETAIL";
export const ASSIGN_FPO_MODAL = "ASSIGN_FPO_MODAL";
export const ASSIGN_PROJECT_MODAL = "ASSIGN_PROJECT_MODAL";
export const ADD_THERMOMETER_MODAL = "ADD_THERMOMETER_MODAL";
export const ADD_MOISTURE_METER_MODAL = "ADD_MOISTURE_METER_MODAL";
export const ADD_RETENTION_BUCKET_MODAL = "ADD_RETENTION_BUCKET_MODAL";
export const ADD_SAMPLING_BEAKER_MODAL = "ADD_SAMPLING_BEAKER_MODAL";
export const ADD_IOT_MODAL = "ADD_IOT_MODAL";

export const ADD_BUYER_MODAL = "ADD_BUYER_MODAL";
export const ADD_SELLER_MODAL = "ADD_SELLER_MODAL";
export const ADD_ARTISAN_MODAL = "ADD_ARTISAN_MODAL";

export const ADD_NOTE_MODAL = "ADD_NOTE_MODAL";

export const SELL_TO_BUYER = "SELL_TO_BUYER";
export const ADD_COMPOST_MATERIAL = "ADD_COMPOST_MATERIAL";

// user roles
export const USER_ROLES = {
  ADMIN: "ADMIN",
  ACCOUNT: "ACCOUNT",
  SALES: "SALES",
  FINANCE: "FINANCE",
  MARKETING: "MARKETING",
};

// user profile
export const USER_ROLES_CREATION = {
  ACCOUNTS: "ACCOUNTS",
  SALES: "SALES",
  PURCHASE: "PURCHASE",
  MARKETING: "MARKETING",
};

// Default Page sizes
export const DEFAULT_PAGE_SIZES = [10, 20, 50, 100];

// states
export const STATES: Option[] = [
  {
    label: "Andaman and Nicobar Islands",
    value: "Andaman and Nicobar Islands",
  },
  {
    label: "Andhra Pradesh",
    value: "Andhra Pradesh",
  },
  {
    label: "Arunachal Pradesh",
    value: "Arunachal Pradesh",
  },
  {
    value: "Assam",
    label: "Assam",
  },
  {
    value: "Bihar",
    label: "Bihar",
  },
  {
    value: "Chandigarh",
    label: "Chandigarh",
  },
  {
    value: "Chhattisgarh",
    label: "Chhattisgarh",
  },
  {
    value: "Dadra and Nagar Haveli",
    label: "Dadra and Nagar Haveli",
  },
  {
    value: "Daman and Diu",
    label: "Daman and Diu",
  },
  {
    value: "Delhi",
    label: "Delhi",
  },
  {
    value: "Goa",
    label: "Goa",
  },
  {
    value: "Gujarat",
    label: "Gujarat",
  },
  {
    value: "Haryana",
    label: "Haryana",
  },
  {
    value: "Himachal Pradesh",
    label: "Himachal Pradesh",
  },
  {
    value: "Jammu and Kashmir",
    label: "Jammu and Kashmir",
  },
  {
    value: "Jharkhand",
    label: "Jharkhand",
  },
  {
    value: "Karnataka",
    label: "Karnataka",
  },
  {
    value: "Kerala",
    label: "Kerala",
  },
  {
    value: "Ladakh",
    label: "Ladakh",
  },
  {
    value: "Lakshadweep",
    label: "Lakshadweep",
  },
  {
    value: "Madhya Pradesh",
    label: "Madhya Pradesh",
  },
  {
    value: "Maharashtra",
    label: "Maharashtra",
  },
  {
    value: "Manipur",
    label: "Manipur",
  },
  {
    value: "Meghalaya",
    label: "Meghalaya",
  },
  {
    value: "Mizoram",
    label: "Mizoram",
  },
  {
    value: "Nagaland",
    label: "Nagaland",
  },
  {
    value: "Odisha",
    label: "Odisha",
  },
  {
    value: "Puducherry",
    label: "Puducherry",
  },
  {
    value: "Punjab",
    label: "Punjab",
  },
  {
    value: "Rajasthan",
    label: "Rajasthan",
  },
  {
    value: "Sikkim",
    label: "Sikkim",
  },
  {
    value: "Tamil Nadu",
    label: "Tamil Nadu",
  },
  {
    value: "Telangana",
    label: "Telangana",
  },
  {
    value: "Tripura",
    label: "Tripura",
  },
  {
    value: "Uttar Pradesh",
    label: "Uttar Pradesh",
  },
  {
    value: "Uttarakhand",
    label: "Uttarakhand",
  },
  {
    value: "West Bengal",
    label: "West Bengal",
  },
];

export const LAND_HOLDING_UNIT: Option[] = [
  { value: "hectare", label: "Hectare" },
  { value: "acre", label: "Acre" },
  { value: "sqft", label: "Sqft" },
  { value: "sqm", label: "Sqm" },
];

export const VOLUME_UNIT: Option[] = [
  { value: "m3", label: "m³" },
  { value: "ml", label: "mL" },
  { value: "l", label: "L" },
];

export const WEIGHT_UNIT: Option[] = [
  { value: "g", label: "g" },
  { value: "kg", label: "kg" },
  { value: "tonne", label: "t" },
];

export const LENGTH_UNIT: Option[] = [
  { value: "mm", label: "mm" },
  { value: "cm", label: "cm" },
  { value: "m", label: "m" },
];

export const KYCType: Option[] = [
  { label: "Aadhar Card", value: "aadhar_card" },
  { label: "PAN Card", value: "pan_card" },
  { label: "Voter ID", value: "voter_id" },
  { label: "Driving License", value: "driving_license" },
  { label: "Passport", value: "passport" },
];

export const checkStrings = (st1: string, st2: string): boolean => {
  return st1
    .toLowerCase()
    .replace(" ", "")
    .includes(st2.toLowerCase().replace(" ", ""));
};

export const AZURE_STORAGE_DIRECTORY = {
  BIOMASS_BATCH: "batch",
  USER: "user",
  PRODUCT: "product",
  FPO: "fpo",
  ARTISAN: "artisan",
  ADDRESS: "address",
  BIOMASS_COMPOSITION: "biomass_composition",
  BIOMASS_SUPPLIER: "biomass_supplier",
  PUBLIC: "public",
  MEASURING_VESSEL: "measuring_vessel",
  PLANTATION_TREE: "plantation_tree",
  PYROLYSIS_KILN: "pyrolysis_kiln",
  SOIL_PIT: "soil_pit",
  SELLER: "seller",
};

export const AZURE_STORAGE_SUBDIRECTORY = {
  KYC: "kyc",
  SITE: "site",
  PROFILE: "profile",
  GENERAL: "general",
};

export const EXTREME_DATES = {
  start_date: "2023-01-01",
  end_date: "2100-12-31",
};

export const BIOMASS_BATCH_STATUS = {
  DRAFT: "draft",
  SUBMITTED: "submitted",
  DELETED: "deleted",
  COMPLETED: "completed",
  SEQUESTERED: "sequestered",
  CANCELLED: "cancelled",
  ACCEPTED: "accepted",
};

export const SEQUESTRATION_STATUS = {
  SUBMITTED: "submitted",
  ACCEPTED: "accepted",
  CANCELLED: "cancelled",
};
