import { Form, Formik } from "formik";
import React, { useState, useCallback } from "react";
// import { useQueryClient } from "react-query";
// import { useDispatch } from "react-redux";
import * as Yup from "yup";
import debounce from "lodash/debounce";
import logo from "../../assets/logo192.png";
import axiosInstance from "../../axiosInstance";
import useAssignFPO from "../../hooks/useAssignFPO";
import ErrorBox from "../FormikComponents/ErrorBox";
import SelectDropDownAsync from "../FormikComponents/SelectDropDownAsync";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface AssignFPOObj {
    fpo_id: string;
}

interface AssignFPOModalProps {
    id: string;
    action: string;
    role: string;
}

const AssignFPOModal: React.FC<AssignFPOModalProps> = (props) => {
    const { id, action, role } = props;

    // const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [commonError, setCommonError] = useState("");
    // const queryClient = useQueryClient();
    const initialValues: AssignFPOObj = {
        fpo_id: "",
    };
    const { handleFPOAssign } = useAssignFPO();

    const validationSchema = Yup.object().shape({
        fpo_id: Yup.string().min(12, "FPO ID must be minimum of length 12"),
    });

    const handleSubmit = async (values: AssignFPOObj) => {
        if (!values.fpo_id) {
            setCommonError("FPO Id is required");
            return;
        }
        await handleFPOAssign({
            action: action,
            fpo_id: values.fpo_id,
            user_role: role,
            id: id,
        });
    };

    // Debounced function to load options from the API
    const debouncedLoadOptions = useCallback(
        debounce(
            async (
                inputValue: string,
                callback: (options: { label: string; value: string }[]) => void
            ) => {
                try {
                    const response = await axiosInstance.get("/fpo/all");
                    let requiredData: { label: string; value: string }[] = [];
                    if (response?.data?.data?.length > 0) {
                        for (let i = 0; i < response.data.data.length; i++) {
                            const fpo = response.data.data[i];
                            // Filter based on input value matching the FPO name
                            if (
                                fpo.fpo_name
                                    .toLowerCase()
                                    .replace(" ", "")
                                    .includes(inputValue.toLowerCase())
                            ) {
                                requiredData.push({
                                    label: `${fpo.fpo_name} (${fpo.fpo_id})`,
                                    value: fpo.fpo_id,
                                });
                            }
                        }
                    }
                    callback(requiredData);
                } catch (error) {
                    callback([
                        {
                            label: "Error. Something went wrong!!",
                            value: "error",
                        },
                    ]);
                }
            },
            500
        ),
        []
    );

    const loadOptions = (inputValue: string) => {
        return new Promise<{ label: string; value: string }[]>((resolve) => {
            debouncedLoadOptions(inputValue, resolve);
        });
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values }) => (
                    <Form
                        className="max-w-sm flex flex-col justify-center items-center bg-white px-6 py-5 shadow"
                        style={{ minWidth: "40vw" }}
                    >
                        <img src={logo} alt="logo" height={100} width={100} />
                        <div className="flex flex-col w-full">
                            <SelectDropDownAsync
                                label="FPO List"
                                id="fpo_id"
                                name="fpo_id"
                                classes="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                loadOptions={loadOptions}
                            />
                            {values?.fpo_id && (
                                <div className="flex gap-2">
                                    <p>Select FPO Id: </p>
                                    <p className="font-bold">{values.fpo_id}</p>
                                </div>
                            )}
                        </div>
                        {commonError && (
                            <div className="common-error mb-2 text-center">
                                <ErrorBox msg={commonError} />
                            </div>
                        )}
                        <div className="buttons flex flex-col items-center w-full justify-center my-4 mb-6">
                            <SubmitBtn
                                text="Add User"
                                isSubmitting={isSubmitting}
                                // classes="text-sm"
                                containerClasses="h-full"
                                isSubmittingClasses="lg:mt-6"
                                classes="text-sm lg:p-4 lg:rounded-lg"
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AssignFPOModal;
