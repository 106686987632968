import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { NewICAR } from "../../types";
import { ERROR, SUCCESS, USER_ROLES_CREATION } from "../../types/constants";
import Input from "../FormikComponents/Input";
import Select from "../FormikComponents/Select";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import logo from "../../assets/logo192.png";

const AddICARModal = ({
  cropId,
  cropType,
}: {
  cropId: string;
  cropType: string;
}) => {

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const editAccessOptions = [
    {
      value: "Yes",
      label: "Yes",
    },
    {
      value: "No",
      label: "No",
    },
  ];
  const userRoleOptions = Object.values(USER_ROLES_CREATION).map(
    (r: string) => {
      return {
        label: r,
        value: r,
      };
    }
  );

  const initialValues: NewICAR = {
    cropId: cropId,
    nutrient: "",
    min: 0,
    max: 0,
    avg: 0,
    cropType: cropType,
    unit: "",
    age: 0,
    treeCount: 0,
  };

  const validationSchema = Yup.object({
    cropId: Yup.string().trim().required("Required"),
    nutrient: Yup.string().trim().required("Nutrient Name is Required"),
    min: Yup.number().required("Required"),
    max: Yup.number().required("Required"),
    avg: Yup.number().required("Required"),
    unit: Yup.string().trim().required("Unit is Required"),
    age: Yup.number().when("cropType", {
      is: "Fruit",
      then: Yup.number().required("Age is required for Fruit"),
      otherwise: Yup.number().optional(),
    }),
    treeCount: Yup.number().when("cropType", {
      is: "Fruit",
      then: Yup.number().required("Tree count is required for Fruit"),
      otherwise: Yup.number().optional(),
    }),
  });

  const handleSubmit = useCallback((icar: NewICAR) => {
    setIsSubmitting(true);

    if (cropType.toLocaleLowerCase() !== "fruit") {
      delete icar.age;
      delete icar.treeCount;
    }
    icar.cropType = icar.cropType.toLowerCase();

    axiosInstance
      .post(`/fertilizer/icar-recommendation/add`, icar, {
        headers: {
          ContentType: "application/json",
        },
      })
      .then((response) => {
        const { msg } = response.data;

        setIsSubmitting(false);

        queryClient.invalidateQueries(["getCrops"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "ICAR Added Successfully!",
          })
        );

        dispatch(hideModal());
      })
      .catch((error: AxiosError) => {
        setIsSubmitting(false);

        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            // bad request or invalid format or unauthorized
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form
        className="px-6 py-4 mt-2"
        // style={{
        //   minWidth: "360px",
        //   maxWidth: "760px",
        // }}
        style={{ minWidth: "40vw" }}
      >

        <div className="flex justify-center items-center bg-white px-6 py-5">
          <img src={logo} alt="logo" height={100} width={100} />
        </div>

        <div className="flex flex-col item-center gap-x-6">
          {/* <div>
            <Input
              readonly
              label="Crop Id"
              id="cropId"
              name="cropId"
              type="text"
            />
          </div> */}

          <div>
            <Input
              label="Nutrient Name"
              id="nutrient"
              name="nutrient"
              type="text"
              placeholder="Enter Nutrient Name"
            />
          </div>

          <div>
            <Input
              label="Minimum"
              id="min"
              name="min"
              type="number"
              step="any"
            />
          </div>

          <div>
            <Input
              label="Maximum"
              id="max"
              name="max"
              type="number"
              step="any"
            />
          </div>

          <div>
            <Input
              label="Average"
              id="avg"
              name="avg"
              type="number"
              step="any"
            />
          </div>

          {cropType === "Fruit" && (
            <>
              <div>
                <Input
                  label="Age"
                  id="age"
                  name="age"
                  type="number"
                  step="any"
                />
              </div>

              <div>
                <Input
                  label="No of Trees"
                  id="treeCount"
                  name="treeCount"
                  type="number"
                  step="any"
                />
              </div>

            </>
          )}

          <div>
            <Select
              options={[{
                value: "kg/hectare",
                label: "kg/hectare"
              }]}
              label="Unit"
              id="unit"
              name="unit"
            />
          </div>
        </div>

        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Save"
            isSubmitting={isSubmitting}
            // classes="text-sm"
            containerClasses="h-full"
            isSubmittingClasses="lg:mt-6"
            classes="text-sm lg:p-4 lg:rounded-lg"
          />
        </div>

      </Form>
    </Formik>
  );
};

export default AddICARModal;
