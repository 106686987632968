import { useParams } from 'react-router-dom';
import MeasuringVessel from '../measuring-vessel';

const PyroMeasuringVessel = () => {
    const { id } = useParams();
    return (
        <MeasuringVessel otherUrl={`/pyrolysis_technology/measuring_vessel/all?pt_id=${id}`} />
    )
}

export default PyroMeasuringVessel