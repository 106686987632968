import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import logo from "../../assets/logo192.png";

interface CategoriesObj {
  category: string;
  is_biomass: boolean;
}

type EditCategoriesModalProps = {
  categoryId: string;
  category: string;
  is_biomass: boolean;
};

const EditAllCategoriesModal: React.FC<EditCategoriesModalProps> = ({
  categoryId,
  category,
  is_biomass,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: CategoriesObj = {
    category: category,
    is_biomass: is_biomass,
  };

  const validationSchema = Yup.object({
    category: Yup.string().trim().required("Category Title is Required"),
    is_biomass: Yup.boolean().optional(),
  });

  const handleSubmit = useCallback(
    async (categories: CategoriesObj) => {
      setIsSubmitting(true);
      try {
        const result = await axiosInstance.patch(
          `/admin/productcategories/${categoryId}`,
          {
            category: categories.category,
            is_biomass: categories.is_biomass,
          },
          { headers: { ContentType: "application/json" } }
        );

        queryClient.invalidateQueries(["getCategories"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Categories Updated Successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setIsSubmitting(false);
    },
    [categoryId, dispatch, queryClient]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, setFieldValue }) => (
        <Form
          className="px-6 py-4 mt-2"
          style={{ minWidth: "40vw" }}
        >

          <div className="flex justify-center items-center bg-white px-6 py-5">
            <img src={logo} alt="logo" height={100} width={100} />
          </div>

          <div className="flex item-center gap-x-6">
            <Input label="Category Title" id="category" name="category" type="text" />
          </div>

          <div className="flex flex-col item-center gap-y-2 mb-5">
            <span className="text-sm font-bold">BioMass Category</span>
            <div className="flex gap-x-4 mt-1">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="is_biomass_option"
                  value="yes"
                  checked={values.is_biomass === true}
                  disabled={values.is_biomass === true}
                  onChange={() => setFieldValue("is_biomass", true)}
                />
                <span className="text-sm">Yes</span>
              </label>

              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="is_biomass_option"
                  value="no"
                  checked={values.is_biomass === false}
                  disabled={values.is_biomass === false}
                  onChange={() => setFieldValue("is_biomass", false)}
                />
                <span className="text-sm">No</span>
              </label>
            </div>
          </div>

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text="Save"
              isSubmitting={isSubmitting}
              containerClasses="h-full"
              isSubmittingClasses="lg:mt-6"
              classes="text-sm lg:p-4 lg:rounded-lg"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditAllCategoriesModal;
