import { NavLink, Outlet } from "react-router-dom";

const PyroTechBundleLayout = () => {
    return (
        <div className="users h-full w-full">
            <nav className="users-nav mb-12 noselect">
                <ul className="flex gap-x-6 items-center">
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            end
                            to=""
                        >
                            Pyrolysis Technology Bundle
                        </NavLink>
                    </li>
                </ul>
            </nav>

            <div className="content">
                <Outlet />
            </div>

        </div>
    );
};

export default PyroTechBundleLayout;
