import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { SEQUESTRATION_STATUS, ERROR, SUCCESS } from "../../types/constants";
import { addToast } from "../../redux/features/toastSlice";
import { AxiosError } from "axios";

import useIsAdmin from "../../hooks/useIsAdmin";

interface SequestrationObj {
  biomass_batch_id: string;
  batch_date: string;
  compost_details: {
    "Select Compost Type": string;
    total_compost_qty: number;
  };
  incorporation_time: string;
  mixing_image: string;
  final_image: string;
  created_at: string;
  updated_at: string;
  sequestration_type: {
    main_type: string;
  };
  biochar_weight: number;
  sp_id: string | null;
  sequestration_id: string;
}

interface SequestrationProps {
  otherUrl?: string;
}

const Sequestration = ({ otherUrl }: SequestrationProps) => {
  const { isAdmin } = useIsAdmin();

  const ALL_USERS_COLUMNS = [
    {
      Header: "Sr. No",
      accessor: "serialNumber",
      Cell: ({ row }: any) => row.index + 1,
    },
    {
      Header: "Sequestration ID",
      accessor: "sequestration_id",
      Cell: ({ row }: any) => {
        const { biomass_batch_id, sequestration_id } = row?.original || {};
        return (
          <NavLink
            to={biomass_batch_id && sequestration_id ? `/sequestration/${biomass_batch_id}/${sequestration_id}` : "#"}
            className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
            title="View Sequestration Details"
          >
            {sequestration_id || "Not Available"}
          </NavLink>
        );
      },
    },
    {
      Header: "Project ID",
      accessor: "project_id",
      Cell: ({ row }: any) => {
        const project_id = row?.original.project_id;
        return project_id === null || "" || !project_id ? (
          <p>Not Available</p>
        ) : (
          <span className="text-green px-2 py-3">
            {project_id}
          </span>
        );
      },
    },
    {
      Header: "Artisan ID",
      accessor: "artisan_id",
      Cell: ({ row }: any) => {
        const artisan_id = row?.original.artisan_id;
        return artisan_id === null || "" || !artisan_id ? (
          <p>Not Available</p>
        ) : (
          <span className="text-green px-2 py-3">
            {artisan_id}
          </span>
        );
      },
    },
    {
      Header: "BioChar ID",
      accessor: "biomass_batch_id",
      Cell: ({ row }: any) => {
        const biomass_batch_id = row?.original.biomass_batch_id;
        return biomass_batch_id === null || "" || !biomass_batch_id ? (
          <p>Not Available</p>
        ) : (
          <span className="text-green px-2 py-3">
            {biomass_batch_id}
          </span>
        );
      },
    },
    // {
    //     Header: "Batch Date",
    //     accessor: "batch_date",
    //     Cell: ({ value }: any) => value ? new Date(value).toLocaleString() : 'Not Available',
    // },
    // {
    //     Header: "Compost Qty",
    //     accessor: "compost_details",
    //     Cell: ({ value }: { value: any }) => {
    //         if (!value) return 'Not Available';
    //         const formatKey = (key: string): string =>
    //             key.replace(/_/g, ' ')
    //                 .replace(/\b\w/g, (char: string) => char.toUpperCase());
    //         const displayText = Object.entries(value)
    //             .filter(([key, val]) => val)
    //             .map(([key, val]) =>
    //                 `<div style="display: flex; justify-content: space-between; margin: 4px 0; padding: 2px 4px;">
    //                     <span style="justify-self: start;">${formatKey(key)}:</span>
    //                     <span style="justify-self: end;">${val},</span>
    //                 </div>`
    //             )
    //             .join("");
    //         return <div style={{ padding: '8px' }} dangerouslySetInnerHTML={{ __html: displayText }} />;
    //     }
    // },
    {
      Header: "Date of Submission",
      accessor: "incorporation_time",
      Cell: ({ value }: any) => value ? new Date(value).toLocaleString() : 'Not Available',
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }: any) => {
        const statusColors: Record<string, string> = {
          submitted: "bg-blue-500 text-white",
          draft: "bg-yellow-500 text-white ",
          sequestered: "bg-yellow-700 text-white",
          accepted: "bg-green-500 text-white",
          cancelled: "bg-red-500 text-white",
          deleted: "bg-gray-500 text-white",
          completed: "bg-green-500 text-white",
        };
        const displayValue = value ? value.toUpperCase() : "Not Available";
        return (
          <span
            className={`px-2 py-1 text-sm rounded-full ${statusColors[value] || "bg-gray-300 text-black"}`}
          >
            {displayValue}
          </span>
        );
      },
    },
    // {
    //     Header: "Biochar Weight",
    //     accessor: "biochar_weight",
    //     Cell: ({ value }: any) => value ? `${value} Kg` : 'Not Available',
    // },
    // {
    //     Header: "SP ID",
    //     accessor: "sp_id",
    //     Cell: ({ value }: any) => value ? value : 'Not Available',
    // },
    // {
    //     Header: "Sequestration ID",
    //     accessor: "sequestration_id",
    //     Cell: ({ value }: any) => value ? value : 'Not Available',
    // },
    // {
    //     Header: "Sequestration Type",
    //     accessor: "sequestration_type.main_type",
    //     Cell: ({ value }: any) => {
    //         if (!value) return 'Not Available';
    //         const formattedValue: string = value
    //             .replace(/_/g, ' ')
    //             .replace(/\b\w/g, (char: string) => char.toUpperCase());
    //         return formattedValue;
    //     },
    // },
    // {
    //     Header: "Mixing Image",
    //     accessor: "mixing_image",
    //     Cell: ({ value }: any) => {
    //         return value ? (
    //             <a href={value} className="text-green-400" target="_blank" rel="noreferrer">
    //                 <button className="bg-green-600 text-white px-2 py-3">
    //                     <FaEye />
    //                 </button>
    //             </a>
    //         ) : (
    //             <span>Not Available</span>
    //         );
    //     },
    // },
    // {
    //     Header: "Final Image",
    //     accessor: "final_image",
    //     Cell: ({ value }: any) => {
    //         return value ? (
    //             <a href={value} className="text-green-400" target="_blank" rel="noreferrer">
    //                 <button className="bg-green-600 text-white px-2 py-3">
    //                     <FaEye />
    //                 </button>
    //             </a>
    //         ) : (
    //             <span>Not Available</span>
    //         );
    //     },
    // },
    // {
    //     Header: "Created At",
    //     accessor: "created_at",
    //     Cell: ({ value }: any) => value ? new Date(value).toLocaleString() : 'Not Available',
    // },
    // {
    //     Header: "Updated At",
    //     accessor: "updated_at",
    //     Cell: ({ value }: any) => value ? new Date(value).toLocaleString() : 'Not Available',
    // },
  ];

  if (isAdmin) {
    ALL_USERS_COLUMNS.push(
      {
        Header: "Verify",
        accessor: "verify",
        Cell: ({ row }: any) => {
          const currentStatus = row?.original?.status;
          const { biomass_batch_id, sequestration_id } = row?.original || {};

          const updateStatus = (newStatus: string) => {
            if (!biomass_batch_id) {
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Error: Biochar ID is missing.",
                })
              );
              return;
            }
            axiosInstance
              .patch(`/biomass_batch/sequestration/status/${sequestration_id}`, { status: newStatus })
              .then((res) => {
                dispatch(
                  addToast({
                    kind: SUCCESS,
                    msg: "Sequestration Batch status updated successfully",
                  })
                );
                queryClient.invalidateQueries("getAllSequestration");
              })
              .catch((error: AxiosError) => {
                console.log(error.response);
                dispatch(
                  addToast({
                    kind: ERROR,
                    msg: `Error: ${error.response?.data?.msg || "Failed to update status"}`,
                  })
                );
              });
          };

          return (
            <div>
              {currentStatus !== SEQUESTRATION_STATUS.CANCELLED &&
                currentStatus !== SEQUESTRATION_STATUS.ACCEPTED && (
                  <>
                    <button
                      onClick={() => updateStatus(SEQUESTRATION_STATUS.ACCEPTED)}
                      className="block w-full mb-2 px-4 py-2 text-white bg-green-500 rounded"
                    >
                      Accept
                    </button>
                    <button
                      onClick={() => updateStatus(SEQUESTRATION_STATUS.CANCELLED)}
                      className="block w-full px-4  py-2 text-white bg-red-500 rounded"
                    >
                      Reject
                    </button>
                  </>
                )}
              {currentStatus === SEQUESTRATION_STATUS.ACCEPTED && (
                <button
                  onClick={() => updateStatus(SEQUESTRATION_STATUS.CANCELLED)}
                  className="block w-full px-4 py-2 text-white bg-red-500 rounded"
                >
                  Reject
                </button>
              )}

              {currentStatus === SEQUESTRATION_STATUS.CANCELLED && (
                <button
                  onClick={() => updateStatus(SEQUESTRATION_STATUS.ACCEPTED)}
                  className="block w-full px-4 py-2 text-white bg-green-500 rounded"
                >
                  Accept
                </button>
              )}

            </div>
          );
        }
      }
    );
  }

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState<string | null>(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const getAllSequestration = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(otherUrl || "biomass_batch/sequestration/all?sp_id=null");
    return data?.data;
  };

  const { data, error, isLoading } = useQuery<
    SequestrationObj[] | undefined,
    any,
    SequestrationObj[],
    any[]
  >(
    ["getAllSequestration", pageIndex, cPageSize, cSortBy, desc, q],
    getAllSequestration
  );

  let component = null;

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        Error loading sequestration data: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        excludeColumnSearch={[]}
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1}
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => { }}
          />
          {selectedRows.length > 0 && (
            <span className="btn-primary flex items-center mt-5 w-48">
              Selected Row : {selectedRows.length}
            </span>
          )}
        </div>
      </header>
      {component !== null && component}
    </div>
  );
};

export default Sequestration;