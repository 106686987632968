import { useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showModal } from "../../redux/features/modalSlice";
import { ASSIGN_FPO_TO_SITE_MODAL } from "../../types/constants";
import { FaEye } from "react-icons/fa";


interface Address {
    id: string;
    city: string;
    label: string;
    state: string;
    address: string;
    pincode: string;
    coordinates: any;
    addressable_id: string | null;
    addressProofUrl: string;
    addressable_type: string;
    geofence_coordinates: {
        crs: {
            type: string;
            properties: {
                name: string;
            };
        };
        type: string;
        coordinates: number[][][];
    };
}

interface SiteDetailsObj {
    site_id: string;
    site_name: string;
    site_area: number;
    site_pictures: {
        image1: string;
        image2: string;
        image3: string;
        image4: string;
    };
    is_archived: boolean;
    fpo_id: number | null;
    created_at: string;
    updated_at: string;
    id: string;
    addresses: Address[];
}

interface ApiResponse {
    data: SiteDetailsObj;
    status: string;
    message: string;
}

const SiteDetails = () => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const getAllSitesDetails = async () => {
        try {
            const response = await axiosInstance.get(`/site/${id}`);
            return response.data;
        } catch (error) {
            console.error("Error fetching site details:", error);
            throw error;
        }
    };

    const { data, error, isLoading } = useQuery<ApiResponse>(
        ["getAllSitesDetails", id],
        getAllSitesDetails,
        {
            refetchOnWindowFocus: false,
            retry: 1,
            onError: (err) => {
                console.error("Query error:", err);
            }
        }
    );

    if (isLoading) return <p className="mt-6 text-center">Loading...</p>;
    if (error) return <p className="mt-6 text-center text-red-600">Error: {(error as Error).message}</p>;

    const siteData = data?.data;

    return (
        <div className="p-6">
            {siteData ? (
                <div className="space-y-10">
                    <div className="grid grid-cols-2 gap-6 mb-10">
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Site ID</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={siteData.site_id || "N/A"}
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Site Name</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={siteData.site_name ? siteData.site_name.replace(/\b\w/g, (char) => char.toUpperCase()) : "N/A"}
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Site Area (Acres)</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={siteData.site_area?.toString() || "N/A"}
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Assigned FPO ID</label>
                            <div className="flex gap-2">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={siteData.fpo_id?.toString() || "N/A"}
                                    readOnly
                                />
                                {!siteData.fpo_id && (
                                    <button
                                        onClick={() => {
                                            dispatch(
                                                showModal({
                                                    modalType: ASSIGN_FPO_TO_SITE_MODAL,
                                                    modalTitle: "Assign FPO To Site",
                                                    modalProps: {
                                                        id: siteData.site_id
                                                    },
                                                })
                                            );
                                        }}
                                        className="mt-1 px-4 py-2 bg-green-600 text-white rounded-2xl hover:bg-green-700 transition-colors"
                                    >
                                        Assign FPO
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="col-span-2 grid grid-cols-2 gap-4">
                            {['image1', 'image2', 'image3', 'image4'].map((imageKey, index) => (
                                <div key={imageKey}>
                                    <label className="block text-md font-semibold text-gray-700">Image {index + 1}</label>
                                    <div className="mt-1">
                                        {siteData.site_pictures[imageKey as keyof typeof siteData.site_pictures] ? (
                                            <a
                                                href={siteData.site_pictures[imageKey as keyof typeof siteData.site_pictures]}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center w-full rounded-2xl shadow-sm bg-green-100 px-5 py-3 hover:bg-green-200 transition-colors font-bold"
                                            >
                                                <FaEye className="w-5 h-5 mr-2 text-green-600" />
                                                <span className="flex-1 text-center text-green-600">Open Image</span>
                                            </a>
                                        ) : (
                                            <div className="w-full rounded-2xl shadow-sm bg-green-100 px-5 py-3 text-center">
                                                Not Available
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Created At</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={siteData.created_at ? new Date(siteData.created_at).toLocaleDateString("en-GB") : "N/A"}
                                    readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        siteData.created_at
                                            ? new Date(siteData.created_at).toLocaleTimeString("en-GB", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: true
                                            }).toUpperCase()
                                            : "N/A"
                                    }
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Updated At</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={siteData.updated_at ? new Date(siteData.updated_at).toLocaleDateString("en-GB") : "N/A"}
                                    readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        siteData.updated_at
                                            ? new Date(siteData.updated_at).toLocaleTimeString("en-GB", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: true
                                            }).toUpperCase()
                                            : "N/A"
                                    }
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <h2 className="text-xl font-bold mb-4 text-green-600 flex items-center w-full py-5">
                            <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                            <span className="px-4 text-green-600">Site Address</span>
                            <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                        </h2>
                        {siteData.addresses && siteData.addresses.length > 0 ? (
                            <div className="space-y-4">
                                {siteData.addresses.map((address, index) => (
                                    <div key={address.id} className="p-4 bg-gray-100 rounded-2xl shadow-sm">
                                        <div className="grid grid-cols-2 gap-4">
                                            <div>
                                                <label className="block text-md font-semibold text-gray-700">Label</label>
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={address.label ? address.label.replace(/\b\w/g, (char) => char.toUpperCase()) : "N/A"}
                                                    readOnly
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-md font-semibold text-gray-700">Address</label>
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={address.address ? address.address.replace(/\b\w/g, (char) => char.toUpperCase()) : "N/A"}
                                                    readOnly
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-md font-semibold text-gray-700">Pincode</label>
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={address.pincode || "N/A"}
                                                    readOnly
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-md font-semibold text-gray-700">City</label>
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={address.city ? address.city.replace(/\b\w/g, (char) => char.toUpperCase()) : "N/A"}
                                                    readOnly
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-md font-semibold text-gray-700">State</label>
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={address.state ? address.state.replace(/\b\w/g, (char) => char.toUpperCase()) : "N/A"}
                                                    readOnly
                                                />
                                            </div>

                                            {/* <div>
                                                <label className="block text-md font-semibold text-gray-700">Coordinates</label>
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={address.coordinates || "N/A"}
                                                    readOnly
                                                />
                                            </div> */}

                                            <div className="pr-10">
                                                <label className="block text-md font-semibold text-gray-700">Coordinates</label>
                                                {address.coordinates ? (
                                                    <a
                                                        href={`https://www.google.com/maps?q=${address.coordinates.replace(/[()]/g, '')}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-blue-100 px-5 py-3 text-blue-600 font-semibold text-center"
                                                    >
                                                        Open Location
                                                    </a>
                                                ) : (
                                                    <div className="mt-1 block w-full rounded-2xl shadow-sm bg-red-100 px-5 py-3 text-red-600 font-semibold text-center">
                                                        No Coordinates Available
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <label className="block text-md font-semibold text-gray-700">Geofence Coordinates</label>
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={address.geofence_coordinates ? JSON.stringify(address.geofence_coordinates) : "N/A"}
                                                    readOnly
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-md font-semibold text-gray-700">Addressable Id</label>
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={address.addressable_id || "N/A"}
                                                    readOnly
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-md font-semibold text-gray-700">Addressable Type</label>
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={address.addressable_type ? address.addressable_type.replace(/\b\w/g, (char) => char.toUpperCase()) : "N/A"}
                                                    readOnly
                                                />
                                            </div>
                                            {address.addressProofUrl !== "N/A" && (
                                                <div className="col-span-2">
                                                    <a
                                                        href={address.addressProofUrl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-green-600 hover:underline"
                                                    >
                                                        View Address Proof
                                                    </a>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-gray-600">No addresses found</p>
                        )}
                    </div>
                </div>
            ) : (
                <p className="mt-6 text-center">No site data found</p>
            )}
        </div>
    );
};

export default SiteDetails;