import { useState } from "react";
import { useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import { FaEye } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { FiDownload, FiX } from "react-icons/fi";
import { FaDownload } from "react-icons/fa6";
import JSZip from "jszip";
import { saveAs } from "file-saver";

interface SequestrationDetailsObj {
    biomass_batch_id: string;
    batch_date: string;
    compost_details: {
        biochar_qty: number;
        chicken_qty: number;
        pressmud_qty: number;
        phosphate_qty: number;
        total_compost_qty: number;
    };
    incorporation_time: string;
    mixing_image: string;
    final_image: string;
    created_at: string;
    updated_at: string;
    sequestration_type: {
        main_type: string;
        subtype: string;
    };
    biochar_weight: number;
    sp_id: string | null;
    sequestration_id: string;
    status: string;
    address: Array<{
        address_id: string;
        label: string;
        address: string;
        city: string;
        state: string;
        pincode: string;
        addressProofUrl: string;
        coordinates: {
            x: number;
            y: number;
        };
    }>;
}

interface SequestrationDetailsProps {
    otherUrl?: string;
}

const SequestrationDetails = ({ otherUrl }: SequestrationDetailsProps) => {
    const { biomass_batch_id, sequestration_id } = useParams<{ biomass_batch_id: string; sequestration_id: string }>();
    const [q, setQ] = useState("");
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const getSingleSequestration = async () => {
        try {
            const { data } = await axiosInstance.get(`/biomass_batch/sequestration/${biomass_batch_id}/${sequestration_id}`);
            return data?.data;
        } catch (error) {
            console.error("Error fetching sequestration details:", error);
            throw error;
        }
    };

    const { data, error, isLoading } = useQuery<SequestrationDetailsObj | undefined, Error>(
        ["getSingleSequestration", biomass_batch_id, sequestration_id],
        getSingleSequestration,
        {
            enabled: !!biomass_batch_id && !!sequestration_id,
            retry: 1,
        }
    );

    const formatDate = (dateString: string | undefined) => {
        if (!dateString) return { date: "Not Available", time: "Not Available" };
        const date = new Date(dateString);
        return {
            date: date.toLocaleDateString("en-GB"),
            time: date.toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
            }).toUpperCase(),
        };
    };

    const formatSequestrationType = (type: string) => {
        return type
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const handleDownload = async (url: string, label: string) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const extension = blob.type.split('/')[1] || 'jpg';
            const filename = `${label.toLowerCase().replace(/ /g, '_')}.${extension}`;
            const urlObject = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = urlObject;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(urlObject);
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };

    const handleDownloadAll = async () => {
        if (!data) return;

        const zip = new JSZip();
        const imageFields = [
            { label: "Mixing Image", url: data.mixing_image },
            { label: "Final Image", url: data.final_image },
            { label: "Address Proof", url: data.address?.[0]?.addressProofUrl },
        ].filter(field => field.url && field.url !== "Not Available");

        if (imageFields.length === 0) {
            alert("No images available to download.");
            return;
        }

        await Promise.all(
            imageFields.map(async (image) => {
                try {
                    const response = await fetch(image.url);
                    if (!response.ok) throw new Error(`Failed to fetch ${image.url}`);
                    const blob = await response.blob();
                    const contentType = response.headers.get("content-type");
                    let extension = "jpg";
                    if (contentType) {
                        if (contentType.includes("jpeg")) extension = "jpg";
                        else if (contentType.includes("png")) extension = "png";
                        else if (contentType.includes("gif")) extension = "gif";
                    }
                    const filename = `${image.label.replace(/ /g, "_")}.${extension}`;
                    zip.file(filename, blob);
                } catch (error) {
                    console.error(`Error downloading ${image.label}:`, error);
                }
            })
        );

        zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, "sequestration_images.zip");
        });
    };

    const ImageField = ({ label, imageUrl, createdAt }: { label: string; imageUrl: string | undefined; createdAt: string | undefined }) => (
        <div className="pr-10">
            <label className="block text-md font-semibold text-gray-700">{label}</label>
            <div className="relative mt-1">
                {imageUrl && imageUrl !== "Not Available" && (
                    <div className="absolute inset-y-0 left-3 flex items-center">
                        <button
                            className="text-green-600 hover:text-green-800"
                            onClick={() => setSelectedImage(imageUrl)}
                        >
                            <FaEye size={20} />
                        </button>
                    </div>
                )}
                <div
                    className={`block w-full rounded-2xl shadow-sm px-12 py-3 outline-none border-none text-center cursor-pointer ${imageUrl && imageUrl !== "Not Available" ? "bg-green-100 text-green-600" : "bg-red-100 text-red-600"}`}
                    onClick={() => imageUrl && imageUrl !== "Not Available" && setSelectedImage(imageUrl)}
                >
                    <span className="font-bold text-lg hover:text-green-800">
                        {imageUrl && imageUrl !== "Not Available" ? "Open Image" : "Not Available"}
                    </span>
                    {imageUrl && imageUrl !== "Not Available" && (
                        <span className="text-sm text-green-600 font-bold">
                            {createdAt ? ` (${formatDate(createdAt).date}, ${formatDate(createdAt).time})` : " (N/A, N/A)"}
                        </span>
                    )}
                </div>
                {imageUrl && (
                    <div className="absolute inset-y-0 right-3 flex items-center">
                        <button
                            onClick={() => handleDownload(imageUrl, label)}
                            className="text-green-600 hover:text-green-800"
                        >
                            <FiDownload size={20} />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );

    const ImageViewer = ({ imageUrl, onClose }: { imageUrl: string; onClose: () => void }) => (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <div className="relative bg-white rounded-xl shadow-lg max-w-xl w-full">
                <button
                    className="absolute top-2 right-2 bg-green-600 text-white p-2 rounded-full hover:bg-green-800"
                    onClick={onClose}
                >
                    <FiX size={20} />
                </button>
                <img src={imageUrl} alt="View" className="w-full h-auto rounded-lg" />
            </div>
        </div>
    );

    let content = null;

    if (error) {
        content = (
            <p className="mt-6 text-center text-red-600">
                An error has occurred: {error.message || "Unable to fetch sequestration details"}
            </p>
        );
    } else if (isLoading) {
        content = <p className="mt-6 text-center text-gray-600">Loading...</p>;
    } else if (data) {
        const address = data.address?.[0];
        const statusClass = {
            submitted: "bg-blue-500 text-white",
            draft: "bg-yellow-500 text-white",
            sequestered: "bg-yellow-700 text-white",
            accepted: "bg-green-500 text-white",
            cancelled: "bg-red-500 text-white",
            deleted: "bg-gray-500 text-white",
            completed: "bg-green-500 text-white",
        }[data.status] || "bg-gray-200 text-gray-700";

        content = (
            <>
                <div className="grid grid-cols-2 gap-4 mb-10">
                    <div className="pr-10">
                        <label className="block text-md font-semibold text-gray-700">Biomass Batch ID</label>
                        <input
                            type="text"
                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                            value={data.biomass_batch_id || "Not Available"}
                            readOnly
                        />
                    </div>
                    <div className="pr-10">
                        <label className="block text-md font-semibold text-gray-700">Sequestration ID</label>
                        <input
                            type="text"
                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                            value={data.sequestration_id || "Not Available"}
                            readOnly
                        />
                    </div>
                    <div className="pr-10">
                        <label className="block text-md font-semibold text-gray-700">SP ID</label>
                        <input
                            type="text"
                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                            value={data.sp_id || "Not Available"}
                            readOnly
                        />
                    </div>
                    <div className="pr-10">
                        <label className="block text-md font-semibold text-gray-700">Status</label>
                        <input
                            type="text"
                            className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 outline-none border-none ${statusClass}`}
                            value={data.status ? formatSequestrationType(data.status) : "Not Available"}
                            readOnly
                        />
                    </div>
                    <div className="pr-10">
                        <label className="block text-md font-semibold text-gray-700">Sequestration Main Type</label>
                        <input
                            type="text"
                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                            value={data.sequestration_type?.main_type ? formatSequestrationType(data.sequestration_type.main_type) : "Not Available"}
                            readOnly
                        />
                    </div>
                    <div className="pr-10">
                        <label className="block text-md font-semibold text-gray-700">Sequestration Subtype</label>
                        <input
                            type="text"
                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                            value={data.sequestration_type?.subtype || "Not Available"}
                            readOnly
                        />
                    </div>
                    <div className="pr-10">
                        <label className="block text-md font-semibold text-gray-700">Batch Date</label>
                        <div className="flex space-x-4">
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={formatDate(data.batch_date).date}
                                readOnly
                            />
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={formatDate(data.batch_date).time}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="pr-10">
                        <label className="block text-md font-semibold text-gray-700">Incorporation Time</label>
                        <div className="flex space-x-4">
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={formatDate(data.incorporation_time).date}
                                readOnly
                            />
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={formatDate(data.incorporation_time).time}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="pr-10">
                        <label className="block text-md font-semibold text-gray-700">Biochar Weight</label>
                        <input
                            type="text"
                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                            value={data.biochar_weight ? `${data.biochar_weight} Kg` : "Not Available"}
                            readOnly
                        />
                    </div>
                    <ImageField label="Mixing Image" imageUrl={data.mixing_image} createdAt={data.created_at} />
                    <ImageField label="Final Image" imageUrl={data.final_image} createdAt={data.created_at} />
                    <div className="pr-10">
                        <label className="block text-md font-semibold text-gray-700">Created At</label>
                        <div className="flex space-x-4">
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={formatDate(data.created_at).date}
                                readOnly
                            />
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={formatDate(data.created_at).time}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="pr-10">
                        <label className="block text-md font-semibold text-gray-700">Updated At</label>
                        <div className="flex space-x-4">
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={formatDate(data.updated_at).date}
                                readOnly
                            />
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={formatDate(data.updated_at).time}
                                readOnly
                            />
                        </div>
                    </div>
                </div>

                {data.compost_details && (
                    <h2 className="text-xl font-bold mb-4 text-green-600 flex items-center w-1/2 py-5">
                        <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                        <span className="px-4 text-green-600">Compost Details</span>
                        <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                    </h2>
                )}
                <div className="grid grid-cols-2 gap-4 mb-10">
                    {data.compost_details &&
                        Object.entries(data.compost_details).map(([key, value]) => {
                            const formattedLabel = key
                                .replace(/_/g, " ")
                                .split(" ")
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(" ");
                            return (
                                <div key={key} className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">{formattedLabel}</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={value || "Not Available"}
                                        readOnly
                                    />
                                </div>
                            );
                        })}
                </div>

                {address?.address && (
                    <>
                        <h2 className="text-xl font-bold mb-4 text-green-600 flex items-center w-1/2 py-5">
                            <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                            <span className="px-4 text-green-600">Address</span>
                            <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                        </h2>
                        <div className="grid grid-cols-2 gap-4 mb-10">
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Label</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={address?.label ? address.label.replace(/\b\w/g, (char) => char.toUpperCase()) : "Not Available"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Address</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={address?.address ? address.address.replace(/\b\w/g, (char) => char.toUpperCase()) : "Not Available"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">City</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        address?.city === "N/A"
                                            ? "Not Available"
                                            : address?.city?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
                                    }
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">State</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        address?.state === "N/A"
                                            ? "Not Available"
                                            : address?.state?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
                                    }
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Pincode</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={address?.pincode === "N/A" ? "Not Available" : address?.pincode}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Coordinates</label>
                                {address?.coordinates ? (
                                    <a
                                        href={`https://www.google.com/maps?q=${address.coordinates.x},${address.coordinates.y}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-blue-100 px-5 py-3 text-blue-600 font-semibold text-center"
                                    >
                                        Open Location
                                    </a>
                                ) : (
                                    <div className="mt-1 block w-full rounded-2xl shadow-sm bg-red-100 px-5 py-3 text-red-600 font-semibold text-center">
                                        No Coordinates Available
                                    </div>
                                )}
                            </div>
                            <ImageField label="Address Proof" imageUrl={address?.addressProofUrl} createdAt={data.created_at} />
                        </div>
                    </>
                )}
            </>
        );
    } else {
        content = <p className="mt-6 text-center text-gray-600">No sequestration details available</p>;
    }

    return (
        <div className="users h-full w-full">
            <div className="all-users">
                <header className="flex items-center justify-between mb-8">
                    <div className="left">
                        <SearchBox searchValue={q} setSearchValue={setQ} handleSearch={() => { }} />
                    </div>
                    <div className="right">
                        <button
                            onClick={handleDownloadAll}
                            className="flex items-center gap-2 px-4 py-2 text-white bg-green-600 hover:bg-green-700 rounded-lg"
                        >
                            <FaDownload size={18} />
                            Download
                        </button>
                    </div>
                </header>
                <div className="w-full px-0">{content}</div>
            </div>
            {selectedImage && <ImageViewer imageUrl={selectedImage} onClose={() => setSelectedImage(null)} />}
        </div>
    );
};

export default SequestrationDetails;