import { HiAcademicCap } from "react-icons/hi";
import { Link } from "react-router-dom";

import DashboardCardMain from "../components/DashboardCards/DashboardCardMain";
import DashboardCardSub from "../components/DashboardCards/DashboardCardSub";
import DashboardGraph from "../components/DashboardGraph/DashboardGraph";
import InfoCard from "../components/Accordian-Cards/InfoCard";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import axiosInstance from "../axiosInstance";
import { Form, Formik } from "formik";
import Input from "../components/FormikComponents/Input";
import SubmitBtn from "../components/FormikComponents/SubmitBtn";
import { addToast } from "../redux/features/toastSlice";
import { ERROR, EXTREME_DATES, SUCCESS } from "../types/constants";
import { useDispatch } from "react-redux";


import * as Yup from "yup";
import { set } from "react-datepicker/dist/date_utils";
import Loader from "../components/Loader/Loader";


type UserDataTypes = {
  artisans: number;
  sellers: number;
  fpos: number;
  buyers: number;
}

type UserQuantitiesTypes = {
  biochar_quantity: number;
  sequester_quantity: number;
  sold_product_quantity: number;
  formulated_product_quantity: number;
}

const Dashboard = (
  { isBioChar = false }: { isBioChar?: boolean }
) => {
  const [role, setRole] = useState(localStorage.getItem('role'))

  const [loading, setLoading] = useState<boolean>(false);
  const [quantitiesData, setQuantitiesData] = useState<UserQuantitiesTypes | null>(null)

  const [start_date, setStart_date] = useState<Date | string | null>();
  const [end_date, setEnd_date] = useState<Date | string | null>();


  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const dateValidationSchema = Yup.object().shape({
    start_date: Yup.string().required("Start Date is required"),
    end_date: Yup.string().required("End Date is required")
  });

  const getUserCount = async () => {
    const { data } = await axiosInstance.get(
      "/admin/dashboard/users/count", {
        params: {
          start_date: start_date,
          end_date: end_date
        }
      }
    );
    return data.data;
  };

  const { data, error, isLoading } = useQuery<
    UserDataTypes | undefined,
    any,
    UserDataTypes,
    any
  >(["getUserCount", start_date, end_date], getUserCount);

  const replaceUnderscore = (str: string) => {
    return str.replace(/_/g, ' ');
  }

console.log(start_date, end_date, start_date || end_date ? false : true)

  const getQuantities = async () => {
    const url = role === 'admin' ? '/admin/dashboard/quantities' : '/fpo/dashboard/quantities'
              const { data } = await axiosInstance.post(url, {
                "biocharTimeRange": {
                  "start_date": start_date ?? EXTREME_DATES.start_date,
                  "end_date": end_date ?? EXTREME_DATES.end_date
                },
                "sequesterTimeRange": {
                  "start_date": start_date ?? EXTREME_DATES.start_date,
                  "end_date": end_date ?? EXTREME_DATES.end_date
                },
                "productSoldTimeRange": {
                  "start_date": start_date ?? EXTREME_DATES.start_date,
                  "end_date": end_date ?? EXTREME_DATES.end_date
                },
                "productFormulatedTimeRange": {
                  "start_date": start_date ?? EXTREME_DATES.start_date,
                  "end_date": end_date ?? EXTREME_DATES.end_date
                }
              })
    return data.data;
  };

  const { data : quantityData, error: quantityError, isLoading: isLoadingQuantity } = useQuery<
    UserQuantitiesTypes | undefined,
    any,
    UserQuantitiesTypes,
    any
  >(["getQuantities", start_date, end_date], getQuantities);


  console.log(isBioChar)
  return (
    <div className="dashboard px-4">
      <div className="top flex items-center mb-14 gap-4">
        {isBioChar ? null : (
          isLoading ? (<Loader/>) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mt-7 w-full lg:w-[60%]">
              {data && Object.entries(data).map(([key, value]) => {
          return (
            <InfoCard
              key={key}
              title={key.toUpperCase()}
              value={value}
              classes="bg-green-50"
            />
          )
              })}
            </div>
          )
        )}

        {isLoadingQuantity ? (<Loader/>) : (<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mt-7 w-full lg:w-[60%]">
          {quantityData && Object.entries(quantityData).map(([key, value]) => {
            return (
              <InfoCard
                key={key}
                title={replaceUnderscore(key.toUpperCase())}
                value={value +" KG"}
                classes="bg-green-50"
              />
            )
          })}
        </div>)}


        {/* <DashboardGraph /> */}
      </div>

      <div className="w-full">
        <Formik
          initialValues={{
            start_date: "",
            end_date: ""
          }}
          validationSchema={dateValidationSchema}
          onSubmit={async (values) => {
            try {
              setLoading(true);

              const url = role === 'admin' ? '/admin/dashboard/quantities' : '/fpo/dashboard/quantities'
              const { data } = await axiosInstance.post(url, {
                "biocharTimeRange": {
                  "start_date": values.start_date,
                  "end_date": values.end_date
                },
                "sequesterTimeRange": {
                  "start_date": values.start_date,
                  "end_date": values.end_date
                },
                "productSoldTimeRange": {
                  "start_date": values.start_date,
                  "end_date": values.end_date
                },
                "productFormulatedTimeRange": {
                  "start_date": values.start_date,
                  "end_date": values.end_date
                }
              })
  
              setQuantitiesData(data.data as UserQuantitiesTypes)

              dispatch( addToast({
                kind: SUCCESS,
                msg: "Data fetched successfully."
              }))
              setLoading(false);

            } catch (error) {
              setLoading(true);
              dispatch( addToast({
                kind: ERROR,
                msg: "Something went wrong. Please try again."
              }))
              setLoading(false);
            }
          }}

        >
          {props =>
            <Form className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-7 w-full items-center">
             
              <Input
                label="Start Date"
                type="date"
                name="start_date"
                placeholder="Start Date"
                id="start_date"
                onChange={(e) => {
                  props.setFieldValue('start_date', e.target.value);
                  setStart_date(e.target.value);
                }}
                
              />
              <Input
                label="End Date"
                type="date"
                name="end_date"
                placeholder="End Date"
                id="end_date"
                onChange={(e) => {
                  props.setFieldValue('end_date', e.target.value)
                  setEnd_date(e.target.value);
                }}
              />
           
            
              <SubmitBtn
                text="Refresh"
                isSubmitting={props.isSubmitting}
                containerClasses="h-full"
                isSubmittingClasses="lg:mt-6"
                classes="text-sm lg:mt-6 lg:p-4 lg:rounded-lg"
              />
              <button
                type="button"
                onClick={() => {
                  props.resetForm();
                  setStart_date(null);
                  setEnd_date(null);
                }}
                disabled={start_date || end_date ? false : true}
                className="btn-primary w-full disabled:opacity-100 cursor-default flex items-center justify-center text-sm lg:-mt-2 lg:p-4 lg:rounded-lg">
                Reset
              </button> 
              
            </Form>
          }

        </Formik>
      </div>

      {/* {loading ? (<Loader/>) :(<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mt-7 w-full lg:w-[60%]">
        {quantitiesData && Object.entries(quantitiesData).map(([key, value]) => {
          return (
            <InfoCard
              key={key}
              title={replaceUnderscore(key.toUpperCase())+" (KG) "}
              value={value}
            />
          )
        })}
      </div>)} */}

    </div>
  );
};

export default Dashboard;
