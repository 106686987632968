import { useState } from "react";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import { FaEye, FaPen } from "react-icons/fa";
import {
    ADD_MOISTURE_METER_MODAL,
    // EDIT_MOISTURE_METER_MODAL,
} from "../../types/constants";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MdAdd, MdLinkOff } from "react-icons/md";
import { useQuery, useQueryClient } from "react-query";

interface MoistureMeterObj {
    moisture_meter_id: string;
    supplier_name: string;
    manufacturer_model: string;
    product_unique_number: string;
    temperature_range_measured: string;
    iot_enabled: boolean;
    meter_image: string;
    created_at: string;
    updated_at: string;
}

interface UserProps {
    otherUrl?: string;
}

const MoistureMeter = ({ otherUrl }: UserProps) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { id } = useParams();
    const pt_id = id ? parseInt(id, 10) : null;

    const handleUnlink = async (moisture_meter_id: string) => {
        if (!pt_id) return;
        try {
            await axiosInstance.patch("/pyrolysis_technology/assets/link-unlink", {
                pt_id: pt_id,
                type: "unlink",
                moisture_meter_id: moisture_meter_id,
            });
            toast.success("Moisture Meter Unlinked Successfully");
            queryClient.invalidateQueries(["getAllMoistureMeters"]);
        } catch (error: any) {
            toast.error(error.response?.data?.error || "Failed to unlink Thermometer");
        }
    };

    const MOISTURE_METER_COLUMNS = [
        {
            Header: "Sr. No",
            accessor: "serialNumber",
            Cell: ({ row }: any) => row.index + 1,
        },
        {
            Header: "MoistureMeter ID",
            accessor: "moisture_meter_id",
            Cell: ({ value }: any) => value || "Not Available",
        },
        {
            Header: "Supplier Name",
            accessor: "supplier_name",
            Cell: ({ value }: any) => value || "Not Available",
        },
        {
            Header: "Manufacturer Model",
            accessor: "manufacturer_model",
            Cell: ({ value }: any) => value || "Not Available",
        },
        {
            Header: "Unique Number",
            accessor: "product_unique_number",
            Cell: ({ value }: any) => value || "Not Available",
        },
        {
            Header: "Temperature Range",
            accessor: "temperature_range_measured",
            Cell: ({ value }: any) => value || "Not Available",
        },
        {
            Header: "IoT Enabled",
            accessor: "iot_enabled",
            Cell: ({ value }: any) => (value ? "Yes" : "No"),
        },
        {
            Header: "MoistureMeter Image",
            accessor: "meter_image",
            Cell: ({ value }: { value: string }) =>
                value ? (
                    <a
                        href={value}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-green-600 text-white px-2 py-3 inline-flex items-center justify-center"
                        title="View Image"
                    >
                        <FaEye />
                    </a>
                ) : (
                    <span className="text-gray-500">Not Available</span>
                ),
        },
        {
            Header: "Created At",
            accessor: "created_at",
            Cell: ({ value }: any) =>
                value ? new Date(value).toLocaleString() : "Invalid Date",
        },
        // {
        //     Header: "Edit",
        //     accessor: "edit",
        //     Cell: ({ row }: any) => {
        //         return (
        //             <button
        //                 onClick={() => {
        //                     dispatch(
        //                         showModal({
        //                             modalType: EDIT_MOISTURE_METER_MODAL,
        //                             modalTitle: "Edit MoistureMeter",
        //                             modalProps: {
        //                                 moisture_meter_id: row?.original.moisture_meter_id,
        //                                 supplier_name: row?.original.supplier_name,
        //                                 manufacturer_model: row?.original.manufacturer_model,
        //                                 product_unique_number:
        //                                     row?.original.product_unique_number,
        //                                 temperature_range_measured:
        //                                     row?.original.temperature_range_measured,
        //                                 iot_enabled: row?.original.iot_enabled,
        //                                 meter_image: row?.original.meter_image,
        //                             },
        //                         })
        //                     );
        //                 }}
        //                 className="bg-green-600 text-white px-2 py-3"
        //             >
        //                 <FaPen />
        //             </button>
        //         );
        //     },
        // },
        {
            Header: "Unlink",
            accessor: "unlink",
            Cell: ({ row }: any) => (
                <button onClick={() => handleUnlink(row.original.moisture_meter_id)} className="text-red-600" disabled={!pt_id}>
                    <MdLinkOff size={24} />
                </button>
            ),
        },
    ];

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState(null);
    const [desc, setDesc] = useState(true);
    const [q, setQ] = useState("");

    const [selectedRows, setSelectedRows] = useState([]);

    const getAllMoistureMeters = async () => {
        const { data } = await axiosInstance.get(otherUrl ||
            `/pyrolysis_technology/moisture_meter/all`
        );
        return data?.data?.moisture_meters || [];
    };

    const { data, error, isLoading } = useQuery<MoistureMeterObj[]>(
        ["getAllMoistureMeters", pageIndex, cPageSize, q],
        getAllMoistureMeters
    );

    console.log(data);
    let component = null;

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {(error as Error).message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance
                excludeColumnSearch={[]}
                tableData={data || []}
                columnName={MOISTURE_METER_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1}
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        );
    }

    return (
        <div className="all-moisture-meters">
            <header className="flex items-center justify-between mb-8">
                <div className="left">
                    <SearchBox
                        searchValue={q}
                        setSearchValue={setQ}
                        handleSearch={() => { }}
                    />
                    {selectedRows.length > 0 && (
                        <span className="btn-primary flex items-center mt-5 w-48">
                            Selected Row: {selectedRows.length}
                        </span>
                    )}
                </div>
                <div className="right">
                    <button
                        className="btn-primary flex items-center"
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_MOISTURE_METER_MODAL,
                                    modalTitle: "Add MoistureMeter",
                                    modalProps: { otherUrl },
                                })
                            );
                        }}
                    >
                        <span className="mr-1">Add</span> <MdAdd size={17} />
                    </button>
                </div>
            </header>
            {component !== null && component}
        </div>
    );
};

export default MoistureMeter;
