import { Form, Formik } from "formik";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import logo from "../../assets/logo192.png";

interface FormValues {
    name: string;
}

const AddPyroTechBundle = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const initialValues: FormValues = {
        name: "",
    };

    const validationSchema = Yup.object({
        name: Yup.string().trim().required("Technology Name is Required"),
    });

    const handleSubmit = async (values: FormValues) => {
        setIsSubmitting(true);
        try {
            const obj = {
                name: values.name,
            };

            const result = await axiosInstance.post("/pyrolysis_technology/create", obj);
            if (result) {
                setIsSubmitting(false);
                queryClient.invalidateQueries(["getAllPyroTechDetails"]);
                dispatch(
                    addToast({
                        kind: SUCCESS,
                        msg: "Pyrolysis Technology Created Successfully!",
                    })
                );
                dispatch(hideModal());
            }
        } catch (error: any) {
            setIsSubmitting(false);

            if (error.response) {
                const response = error.response;
                const { msg } = response.data;

                switch (response.status) {
                    case 400:
                    case 500:
                        dispatch(
                            addToast({
                                kind: ERROR,
                                msg: msg,
                            })
                        );
                        break;
                    default:
                        dispatch(
                            addToast({
                                kind: ERROR,
                                msg: "Oops, something went wrong",
                            })
                        );
                        break;
                }
            } else {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: "Oops, something went wrong",
                    })
                );
            }
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
        >
            <Form className="px-6 py-4 mt-2"
                style={{ minWidth: "40vw" }}
            >

                <div className="flex justify-center items-center bg-white px-6 py-5">
                    <img src={logo} alt="logo" height={100} width={100} />
                </div>

                <div className="flex item-center gap-x-6">
                    <Input
                        label="Pyro Tech Bundle Name"
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Enter Pyro Tech Bundle Name" />
                </div>

                <div className="buttons flex items-center w-full justify-center my-4">
                    <SubmitBtn
                        text="Add Pyro Tech Bundle"
                        isSubmitting={isSubmitting}
                        containerClasses="h-full"
                        isSubmittingClasses="lg:mt-6"
                        classes="text-sm lg:p-4 lg:rounded-lg"
                    />
                </div>
            </Form>
        </Formik>
    );
};

export default AddPyroTechBundle;
