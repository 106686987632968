interface ViewArtisanModalProps {
    artisans: any[];
}

const ViewAssignedArtisan = (props: ViewArtisanModalProps) => {
    const { artisans: artisansList } = props;
    console.log("Artisans List",props);
    console.log(artisansList);

    return (
        <div
            className="px-6 py-4 mt-2"
            style={{
                minWidth: "360px",
                maxWidth: "760px",
            }}
            // style={{ minWidth: "40vw" }}
        >
            <div className="flex flex-row item-center gap-x-6">
                <div className="text-xl flex flex-col gap-4 max-h-96 overflow-x-scroll">
                    {artisansList && artisansList.length > 0 ? (
                        artisansList.map((artisan: any) => (
                            <div
                                key={artisan.id}
                                className="flex flex-col gap-2 border border-gray-200 p-4 rounded-md"
                            >
                                <div className="flex flex-row gap-2">
                                    <div className="font-semibold">Name:</div>
                                    <div>{artisan.name}</div>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <div className="font-semibold">Email:</div>
                                    <div>{artisan.artisan_email}</div>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <div className="font-semibold">
                                        Address:
                                    </div>
                                    <div>{artisan.address}</div>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <div className="font-semibold">
                                        Biomass Name:
                                    </div>
                                    <div>{artisan.biomass_name || "N/A"}</div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>
                            <p>No Artisans assigned</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ViewAssignedArtisan;
