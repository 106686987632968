import { useParams } from 'react-router-dom';
import Thermometer from '../Thermometer';

const PyroThermometer = () => {
    const { id } = useParams();
    return (
        <Thermometer otherUrl={`/pyrolysis_technology/thermometer/all?pt_id=${id}`} />
    )
}

export default PyroThermometer