import { useParams } from "react-router-dom";
import AllBatch from "../biomass-batch/AllBatch";

const FpoBioChar = () => {
    const { id } = useParams();
    return (
        <AllBatch otherUrl={`/biomass_batch/admin/all?batch_id=${id}`} />
    )
}

export default FpoBioChar