import { useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showModal } from "../../redux/features/modalSlice";
import { EDIT_SOIL_PIT_MODAL } from "../../types/constants";
import { FaPen, FaEye } from "react-icons/fa";

interface SoilPitDetailsObj {
    soil_pit_id: number;
    pit_name: string;
    pit_length: string;
    pit_width: string;
    pit_height: string;
    pit_photo: string;
    created_at: string;
    updated_at: string;
    created_by_fpo_id: number | null;
    is_archived: boolean;
    address_id: string;
    label: string;
    address: string;
    city: string;
    state: string;
    pincode: string;
    addressProofUrl: string;
    coordinates: {
        x: number;
        y: number;
    };
}

const SoilPitDetails = () => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const getAllSoilPits = async () => {
        try {
            const response = await axiosInstance.get(`/pyrolysis_technology/soil_pit/all?is_archived=false`);
            return response.data;
        } catch (error) {
            console.error("Error fetching soil pits:", error);
            throw error;
        }
    };

    const { data, error, isLoading } = useQuery<{ data: SoilPitDetailsObj[] }>(
        ["getAllSoilPits"],
        getAllSoilPits,
        {
            refetchOnWindowFocus: false,
            retry: 1,
        }
    );

    if (isLoading) return <p className="mt-6 text-center">Loading...</p>;
    if (error) return <p className="mt-6 text-center text-red-600">Error: {(error as Error).message}</p>;

    const filteredData = data?.data.filter(
        (item: SoilPitDetailsObj) => item.soil_pit_id === Number(id)
    );

    const pitData = filteredData?.[0];

    return (
        <div className="p-6">
            {pitData ? (
                <div className="space-y-10">
                    <div className="grid grid-cols-2 gap-6 mb-10">
                        {/* Pit Details Section */}
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Pit Name</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={
                                    pitData.pit_name !== "N/A"
                                        ? pitData.pit_name.replace(/\b\w/g, (char) => char.toUpperCase())
                                        : "Not Available"
                                }
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Pit Length (cm)</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={pitData.pit_length !== "N/A" ? pitData.pit_length : "Not Available"}
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Pit Width (cm)</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={pitData.pit_width !== "N/A" ? pitData.pit_width : "Not Available"}
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Pit Height (cm)</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={pitData.pit_height !== "N/A" ? pitData.pit_height : "Not Available"}
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Created By Fpo Id</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={pitData.created_by_fpo_id !== null ? pitData.created_by_fpo_id : "Not Available"}
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Pit Photo</label>
                            <div className="mt-1">
                                {pitData.pit_photo ? (
                                    <a
                                        href={pitData.pit_photo}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center w-full rounded-2xl shadow-sm bg-green-100 px-5 py-3 hover:bg-green-200 transition-colors font-bold"
                                    >
                                        <FaEye className="w-5 h-5 mr-2 text-green-600" />
                                        <span className="flex-1 text-center text-green-600">View Photo</span>
                                    </a>
                                ) : (
                                    <div className="w-full rounded-2xl shadow-sm bg-red-100 px-5 py-3 text-center">
                                        Not Available
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Timestamps */}
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Created At</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={pitData.created_at ? new Date(pitData.created_at).toLocaleDateString("en-GB") : "Not Available"}
                                    readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        pitData.created_at
                                            ? new Date(pitData.created_at).toLocaleTimeString("en-GB", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: true
                                            }).toUpperCase()
                                            : "Not Available"
                                    }
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Updated At</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={pitData.updated_at ? new Date(pitData.updated_at).toLocaleDateString("en-GB") : "Not Available"}
                                    readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        pitData.updated_at
                                            ? new Date(pitData.updated_at).toLocaleTimeString("en-GB", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: true
                                            }).toUpperCase()
                                            : "Not Available"
                                    }
                                    readOnly
                                />
                            </div>
                        </div>

                    </div>

                    {/* Address Section */}
                    <div>
                        <h2 className="text-xl font-bold mb-4 text-green-600 flex items-center w-full py-5">
                            <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                            <span className="px-4 text-green-600">Address Details</span>
                            <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                        </h2>

                        <div className="p-4 bg-gray-100 rounded-2xl shadow-sm space-y-4">
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-md font-semibold text-gray-700">Label</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            pitData.label !== "N/A"
                                                ? pitData.label.replace(/\b\w/g, (char) => char.toUpperCase())
                                                : "Not Available"
                                        }
                                        readOnly
                                    />
                                </div>

                                <div>
                                    <label className="block text-md font-semibold text-gray-700">Address</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            pitData.address !== "N/A"
                                                ? pitData.address.replace(/\b\w/g, (char) => char.toUpperCase())
                                                : "Not Available"
                                        }
                                        readOnly
                                    />
                                </div>

                                <div>
                                    <label className="block text-md font-semibold text-gray-700">City</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            pitData.city !== "N/A"
                                                ? pitData.city.replace(/\b\w/g, (char) => char.toUpperCase())
                                                : "Not Available"
                                        }
                                        readOnly
                                    />
                                </div>

                                <div>
                                    <label className="block text-md font-semibold text-gray-700">State</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            pitData.state !== "N/A"
                                                ? pitData.state.replace(/\b\w/g, (char) => char.toUpperCase())
                                                : "Not Available"
                                        }

                                        readOnly
                                    />
                                </div>

                                <div>
                                    <label className="block text-md font-semibold text-gray-700">Pincode</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            pitData.pincode !== "N/A"
                                                ? pitData.pincode.replace(/\b\w/g, (char) => char.toUpperCase())
                                                : "Not Available"
                                        }
                                        readOnly
                                    />
                                </div>

                                <div>
                                    <label className="block text-md font-semibold text-gray-700">Coordinates</label>
                                    {pitData.coordinates ? (
                                        <a
                                            href={`https://www.google.com/maps?q=${pitData.coordinates.x},${pitData.coordinates.y}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-blue-100 px-5 py-3 text-blue-600 font-semibold text-center"
                                        >
                                            Open Location
                                        </a>
                                    ) : (
                                        <div className="mt-1 block w-full rounded-2xl shadow-sm bg-red-100 px-5 py-3 text-red-600 font-semibold text-center">
                                            No Coordinates Available
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <label className="block text-md font-semibold text-gray-700">Address Proof</label>
                                    <div className="mt-1">
                                        {pitData.addressProofUrl && pitData.addressProofUrl !== "N/A" ? (
                                            <a
                                                href={pitData.addressProofUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center w-full rounded-2xl shadow-sm bg-green-100 px-5 py-3 hover:bg-green-200 transition-colors font-bold"
                                            >
                                                <FaEye className="w-5 h-5 mr-2 text-green-600" />
                                                <span className="flex-1 text-center text-green-600">View Proof</span>
                                            </a>
                                        ) : (
                                            <div className="w-full rounded-2xl shadow-sm bg-red-100 px-5 py-3 text-center text-red-600 font-bold">
                                                Not Available
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <p className="mt-6 text-center">No soil pit found with ID: {id}</p>
            )}
        </div>
    );
};

export default SoilPitDetails;