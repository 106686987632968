import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { Option } from "../../types";
import { ERROR } from "../../types/constants";
import logo from "../../assets/logo192.png";
import SubmitBtn from "../FormikComponents/SubmitBtn";

const AssignSiteModal = (props: any) => {

    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const [sites, setSites] = useState<Option[] | null>(null);

    const getAllSites = async () => {
        try {
            const { data } = await axiosInstance.get("/site/all?limit=10&offset=0");
            return data;
        } catch (error) {
            console.log(error);
        }
    };

    const { data, status } = useQuery({
        queryKey: ["getAllSites"],
        queryFn: getAllSites,
    });

    useEffect(() => {
        if (status === "success") {
            if (!data) return;
            const options = data?.data?.sites?.map((item: any) => ({
                label: `${item.site_name} (Site Id: ${item.site_id})`,
                value: item.site_id,
            }));
            setSites(options);
        } else if (status === "error") {
            dispatch(
                addToast({
                    kind: ERROR,
                    msg: "An error occurred while fetching sites",
                })
            );
        }
    }, [status, data, dispatch]);

    const initialValues = { site: "" };

    const validationSchema = Yup.object({
        site: Yup.string().required("Please select a site"),
    });

    const handleSubmit = async (
        values: { site: string },
        { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
    ) => {
        if (!values.site) {
            dispatch(
                addToast({
                    kind: ERROR,
                    msg: "Please select a site",
                })
            );
            setSubmitting(false);
            return;
        }
        try {
            const { data } = await axiosInstance.put(
                `/pyrolysis_technology/assign-site/${props.data.pt_id}/${values.site}`
            );
            if (data) {
                toast.success("Site Assigned Successfully");
                queryClient.invalidateQueries("getArtisans");
                dispatch(hideModal());
            }
        } catch (error: any) {
            if (error.response) {
                const response = error.response;
                const { msg } = response.data;
                switch (response.status) {
                    case 400:
                    case 500:
                        dispatch(
                            addToast({
                                kind: ERROR,
                                msg: msg,
                            })
                        );
                        break;
                    default:
                        dispatch(
                            addToast({
                                kind: ERROR,
                                msg: "Oops, something went wrong",
                            })
                        );
                        break;
                }
            } else if (error.request) {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: "Oops, something went wrong",
                    })
                );
            } else {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: `Error: ${error.message}`,
                    })
                );
            }
        }
        setSubmitting(false);
    };

    return (
        <div
            className="px-6 py-4 mt-2"
            // style={{
            //     minWidth: "360px",
            //     maxWidth: "760px",
            //     display: "flex",
            //     flexDirection: "column",
            //     gap: 30,
            // }}
            style={{ minWidth: "40vw" }}

        >

            <div className="flex justify-center items-center bg-white px-6 py-5">
                <img src={logo} alt="logo" height={100} width={100} />
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, values, isSubmitting }) => (
                    <Form className="flex flex-col">
                        <div className="flex flex-col gap-4 mb-10">
                            <p className="font-bold">Select Site:</p>
                            {sites ? (
                                <Select
                                    options={sites}
                                    onChange={(option) =>
                                        setFieldValue("site", option ? option.value : "")
                                    }
                                    value={sites.find((opt) => opt.value === values.site) || null}
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor: "green",
                                            outlineColor: "green",
                                            outline: "none",
                                        }),
                                    }}
                                    placeholder="Select a site"
                                />
                            ) : (
                                <span>No site to show</span>
                            )}
                        </div>

                        <div className="buttons flex items-center w-full justify-center my-4">
                            <SubmitBtn
                                text="Assign Site"
                                isSubmitting={isSubmitting}
                                containerClasses="h-full"
                                isSubmittingClasses="lg:mt-6"
                                classes={`text-sm lg:p-4 lg:rounded-lg ${!values.site
                                        ? "bg-red-500 opacity-50"
                                        : "bg-green-500 hover:bg-green-600"
                                    }`}
                            // disabled={!values.site || isSubmitting}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AssignSiteModal;
