import { NavLink, Outlet } from "react-router-dom";

const PyrolysisEquipmentLayout = () => {
    return (
        <div className="users h-full w-full">
            <nav className="users-nav mb-12 noselect">
                <ul className="flex gap-x-6 items-center">
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            end
                            to=""
                        >
                            Kontiki
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            // end
                            to="soil-pit"
                        >
                            Soil Pit

                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            end

                            to="semi-industrial"
                        >
                            Semi Industrial
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            // end
                            to="measuring-vessel"
                        >
                            Measuring Vessel
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            end

                            to="thermometer"
                        >
                            Thermometer
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            end

                            to="moisture-meter"
                        >
                            Moisture Meter
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            end
                            to="retention-bucket"
                        >
                            Retention Bucket
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            end
                            to="sampling-beaker"
                        >
                            Sampling Beaker
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            end
                            to="iot"
                        >
                            Iot
                        </NavLink>
                    </li>



                </ul>
            </nav>

            <div className="content">
                <Outlet />
            </div>
        </div>
    );
};

export default PyrolysisEquipmentLayout;