import { useState } from "react";
import { useQuery } from "react-query";
import { FaEye } from "react-icons/fa";
import axiosInstance from "../../axiosInstance";
import TableInstance from "../../components/Table/TableInstance";
import { useParams } from "react-router-dom";

interface ViewPreparationDetailsObj {
    artisan_name: string;
    biomass_name: string;
    biomass_quantity: number;
    moisture_percentage: number;
    biomass_photo: string;
    biomass_moistures: string;
    kiln_name: string;
    kiln_photo: string;
    updated_at: string;
}

interface ViewPreparationDetailsProps {
    otherUrl?: string;
}

const ViewPreparationDetails = ({ otherUrl }: ViewPreparationDetailsProps) => {
    const { preparation_id } = useParams();

    const ALL_USERS_COLUMNS = [
        {
            Header: "Sr. No",
            accessor: "serialNumber",
            Cell: ({ row }: any) => row.index + 1
        },
        {
            Header: "Artisan Name",
            accessor: "artisan_name",
            Cell: ({ value }: any) => value || 'Not Available'
        },
        {
            Header: "Biomass Name",
            accessor: "biomass_name",
            Cell: ({ value }: any) => value || 'Not Available'
        },
        {
            Header: "Biomass Quantity",
            accessor: "biomass_quantity",
            Cell: ({ value }: any) => value ? `${value} Kg` : 'Not Available'
        },
        {
            Header: "Moisture Percentage",
            accessor: "moisture_percentage",
            Cell: ({ value }: any) => value ? `${value}%` : 'Not Available'
        },
        {
            Header: "Biomass Moistures",
            accessor: "biomass_moistures",
            Cell: ({ value }: any) => value || 'Not Available'
        },
        {
            Header: "Kiln Name",
            accessor: "kiln_name",
            Cell: ({ value }: any) => value || 'Not Available'
        },
        {
            Header: "Biomass Photo",
            accessor: "biomass_photo",
            Cell: ({ value }: any) => value ? (
                <a href={value} className="text-green-400" target="_blank" rel="noreferrer">
                    <button className="bg-green-600 text-white px-2 py-3">
                        <FaEye />
                    </button>
                </a>
            ) : 'Not Available'
        },
        {
            Header: "Kiln Photo",
            accessor: "kiln_photo",
            Cell: ({ value }: any) => value ? (
                <a href={value} className="text-green-400" target="_blank" rel="noreferrer">
                    <button className="bg-green-600 text-white px-2 py-3">
                        <FaEye />
                    </button>
                </a>
            ) : 'Not Available'
        },
        {
            Header: "Updated At",
            accessor: "updated_at",
            Cell: ({ value }: any) => value ? new Date(value).toLocaleString() : 'Not Available'
        },
    ];

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [q, setQ] = useState("");
    const [selectedRows, setSelectedRows] = useState<any[]>([]);

    const getPreparationDetails = async () => {
        const { data } = await axiosInstance.get(`/biomass_batch/preparation/${preparation_id}`);
        return data?.data;
    };

    const { data, error, isLoading } = useQuery<ViewPreparationDetailsObj | undefined, Error>(
        ["getSinglePreparationDetails", preparation_id],
        getPreparationDetails
    );

    let component = null;

    if (error) {
        component = <p className="mt-6 ml-4 text-center">An error has occurred: {error.message}</p>;
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance
                excludeColumnSearch={[]}
                tableData={data ? [data] : []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={false}
                cSortBy={[]}
                cSetSortBy={() => { }}
                desc={false}
                setDesc={() => { }}
            />
        );
    }

    return (
        <div className="all-users">
            {component !== null && component}
        </div>
    );
};

export default ViewPreparationDetails;
