import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";

interface ProductObj {
  sp_id: string;
  product_name: string;
  material_id: string;
  material_qty: number;
  total_qty: number;
  created_at: string;
  updated_at: string;
}

type ViewProductFormulationModalProps = {
  sp_id: string;
  product_name: string;
  material_id: string;
  material_qty: number;
  total_qty: number;
  created_at: string;
  updated_at: string;
};

const ViewProductFormulationModal: React.FC<
  ViewProductFormulationModalProps
> = ({
  sp_id,
  product_name,
  material_id,
  material_qty,
  total_qty,
  created_at,
  updated_at,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: ProductObj = {
    sp_id: sp_id,
    product_name: product_name,
    material_id: material_id,
    material_qty: material_qty,
    total_qty: total_qty,
    created_at: created_at,
    updated_at: updated_at,
  };

  const validationSchema = Yup.object({
    sp_id: Yup.string().trim().required("SP ID is Required"),
    product_name: Yup.string().trim().required("Product Name is Required"),
    material_id: Yup.string().trim().required("Material ID is Required"),
    material_qty: Yup.number().required("Material Quantity is Required"),
    total_qty: Yup.number().required("Total Quantity is Required"),
    created_at: Yup.string().trim().required("Created Date is Required"),
    updated_at: Yup.string().trim().required("Updated Date is Required"),
  });

  const handleSubmit = useCallback(
    async (product: ProductObj) => {
      setIsSubmitting(true);
      try {
        const result = await axiosInstance.get(`/suncarbon_product/all`, {
          params: {
            sp_id: product.sp_id,
            product_name: product.product_name,
            material_id: product.material_id,
            material_qty: product.material_qty,
            total_qty: product.total_qty,
            created_at: product.created_at,
            updated_at: product.updated_at,
          },
          headers: { ContentType: "application/json" },
        });
        

        queryClient.invalidateQueries(["getProductFormulation"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Products Fetched Successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setIsSubmitting(false);
    },
    [queryClient, dispatch]
  );

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata",
    };
    return date.toLocaleString("en-IN", options);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, handleChange }) => (
        <Form
          className="px-6 py-4 mt-2"
          // style={{
          //   minWidth: "360px",
          //   maxWidth: "760px",
          // }}
          style={{ minWidth: "40vw" }}
        >
          <div className="mb-4">
            <label className="block text-gray-700 font-bold">SP ID</label>
            <div className="border px-3 py-2 rounded bg-gray-200">{sp_id}</div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-bold">
              Product Name
            </label>
            <div className="border px-3 py-2 rounded bg-gray-200">
              {product_name}
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-bold">Material ID</label>
            <div className="border px-3 py-2 rounded bg-gray-200">
              {material_id}
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-bold">
              Material Quantity
            </label>
            <div className="border px-3 py-2 rounded bg-gray-200">
              {material_qty}
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-bold">
              Total Quantity
            </label>
            <div className="border px-3 py-2 rounded bg-gray-200">
              {total_qty}
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-bold">Created At</label>
            <div className="border px-3 py-2 rounded bg-gray-200">
              {formatDate(created_at)}
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-bold">Updated At</label>
            <div className="border px-3 py-2 rounded bg-gray-200">
              {formatDate(updated_at)}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ViewProductFormulationModal;
