import { useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import { useParams } from "react-router-dom";

interface ProjectDetails {
    created_at: string;
    project_id: string;
    start_date: string;
    updated_at: string;
    project_area: any;
    project_name: string;
    project_location: string;
    project_contact_details: {
        person_name: string;
        person_phone: string;
    };
    biochar_capacity_per_anum: any;
}

interface FpoDetails {
    cin: string;
    pc_id: string;
    fpo_id: string;
    fpo_name: string;
    password: string;
    fpo_email: string;
    is_active: boolean;
    created_at: string;
    fpo_number: string;
    gst_number: string;
    updated_at: string;
    land_holding: number;
    project_type: string;
    enrolled_members: number;
}

interface ClusterDetailsObj {
    pc_id: string;
    name: string;
    project_id: string | null;
    created_at: string;
    updated_at: string;
    project?: ProjectDetails;
    fpo?: FpoDetails;
}

const ClusterDetails = () => {
    const { id } = useParams();

    const getClusterDetails = async () => {
        try {
            const { data } = await axiosInstance.get(`/project/cluster/${id}`);
            return data?.data;
        } catch (error) {
            console.error("Error fetching cluster details:", error);
            throw error;
        }
    };

    const { data, error, isLoading } = useQuery<ClusterDetailsObj>(
        ["getClusterDetails", id],
        getClusterDetails
    );

    if (isLoading) return <p className="mt-6 text-center">Loading...</p>;
    if (error) return <p className="mt-6 text-center text-red-600">Error: {(error as Error).message}</p>;

    return (
        <div className="p-6">
            {data ? (
                <div className="space-y-10">
                    <div>
                        <div className="grid grid-cols-2 gap-4 mb-10">
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Cluster ID</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data.pc_id || "N/A"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Project ID</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data.project_id || "N/A"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Name</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        data.name
                                            ? data.name
                                                .toLowerCase()
                                                .replace(/\b\w/g, (char) => char.toUpperCase())
                                            : "N/A"
                                    }
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Created At</label>
                                <div className="flex space-x-4">
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.created_at ? new Date(data.created_at).toLocaleDateString("en-GB") : "N/A"}
                                        readOnly
                                    />
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            data.created_at
                                                ? new Date(data.created_at).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: true }).toUpperCase()
                                                : "N/A"
                                        }
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Updated At</label>
                                <div className="flex space-x-4">
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.updated_at ? new Date(data.updated_at).toLocaleDateString("en-GB") : "N/A"}
                                        readOnly
                                    />
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            data.updated_at
                                                ? new Date(data.updated_at).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: true }).toUpperCase()
                                                : "N/A"
                                        }
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    {data.project && (
                        <div>
                            <h2 className="text-xl font-bold mb-4 text-green-600 flex items-center w-full py-5">
                                <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                                <span className="px-4 text-green-600">Project Details</span>
                                <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                            </h2>
                            <div className="grid grid-cols-2 gap-4 mb-10">
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Project ID</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.project.project_id || "N/A"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Project Name</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            data.project.project_name
                                                ? data.project.project_name
                                                    .toLowerCase()
                                                    .replace(/\b\w/g, (char) => char.toUpperCase())
                                                : "N/A"
                                        }
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Project Location</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.project.project_location || "N/A"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Project Area</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.project.project_area ?? "N/A"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Biochar Capacity Per Annum</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.project.biochar_capacity_per_anum ?? "N/A"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Contact Person Name</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            data.project.project_contact_details?.person_name
                                                ? data.project.project_contact_details.person_name
                                                    .toLowerCase()
                                                    .replace(/\b\w/g, (char) => char.toUpperCase())
                                                : "N/A"
                                        }
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Contact Person Phone</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.project.project_contact_details?.person_phone || "N/A"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Start Date</label>
                                    <div className="flex space-x-4">
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={data.project.start_date ? new Date(data.project.start_date).toLocaleDateString("en-GB") : "N/A"}
                                            readOnly
                                        />
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={
                                                data.project.start_date
                                                    ? new Date(data.project.start_date).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: true }).toUpperCase()
                                                    : "N/A"
                                            }
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Created At</label>
                                    <div className="flex space-x-4">
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={data.project.created_at ? new Date(data.project.created_at).toLocaleDateString("en-GB") : "N/A"}
                                            readOnly
                                        />
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={
                                                data.project.created_at
                                                    ? new Date(data.project.created_at).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: true }).toUpperCase()
                                                    : "N/A"
                                            }
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Updated At</label>
                                    <div className="flex space-x-4">
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={data.project.updated_at ? new Date(data.project.updated_at).toLocaleDateString("en-GB") : "N/A"}
                                            readOnly
                                        />
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={
                                                data.project.updated_at
                                                    ? new Date(data.project.updated_at).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: true }).toUpperCase()
                                                    : "N/A"
                                            }
                                            readOnly
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                    )}

                    {data.fpo && (
                        <div>
                            <h2 className="text-xl font-bold mb-4 text-green-600 flex items-center w-full py-5">
                                <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                                <span className="px-4 text-green-600">FPO Details</span>
                                <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                            </h2>
                            <div className="grid grid-cols-2 gap-4 mb-10">
                                {/* Basic Information */}
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">FPO ID</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.fpo.fpo_id || "N/A"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">FPO Name</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.fpo.fpo_name || "N/A"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">FPO Email</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.fpo.fpo_email || "N/A"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">FPO Number</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.fpo.fpo_number || "N/A"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">GST Number</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.fpo.gst_number || "N/A"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">CIN</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.fpo.cin || "N/A"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">PC ID</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.fpo.pc_id || "N/A"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Is Active</label>
                                    <input
                                        type="text"
                                        className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 outline-none border-none ${data.fpo.is_active ? "text-green-700 bg-green-100" : "text-red-700 bg-red-100"
                                            }`}
                                        value={data.fpo.is_active ? "True" : "False"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Land Holding</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.fpo.land_holding ?? "N/A"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Project Type</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            data.fpo.project_type
                                                ? data.fpo.project_type
                                                    .replace(/_/g, " ")
                                                    .replace(/\b\w/g, (char) => char.toUpperCase())
                                                : "N/A"
                                        }
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Enrolled Members</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.fpo.enrolled_members ?? "N/A"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Created At</label>
                                    <div className="flex space-x-4">
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={data.fpo.created_at ? new Date(data.fpo.created_at).toLocaleDateString("en-GB") : "N/A"}
                                            readOnly
                                        />
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={
                                                data.fpo.created_at
                                                    ? new Date(data.fpo.created_at).toLocaleTimeString("en-GB", {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                        hour12: true,
                                                    }).toUpperCase()
                                                    : "N/A"
                                            }
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Updated At</label>
                                    <div className="flex space-x-4">
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={data.fpo.updated_at ? new Date(data.fpo.updated_at).toLocaleDateString("en-GB") : "N/A"}
                                            readOnly
                                        />
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={
                                                data.fpo.updated_at
                                                    ? new Date(data.fpo.updated_at).toLocaleTimeString("en-GB", {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                        hour12: true,
                                                    }).toUpperCase()
                                                    : "N/A"
                                            }
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <p className="mt-6 text-center">No cluster data found</p>
            )}
        </div>
    );
};

export default ClusterDetails;