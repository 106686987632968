import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { NewFertilizerCombination } from "../../types";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import Select from "../FormikComponents/Select";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import logo from "../../assets/logo192.png";

const AddFertiliserCombinationModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [fertilisersList, setFertilisersList] = useState<
    { label: string; value: string }[] | null
  >(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: NewFertilizerCombination = {
    name: "",
    fertilizerId1: "",
    fertilizerId3: "",
    fertilizerId2: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Fertiliser Name is required"),
    fertilizerId1: Yup.string().trim().required("Fertiliser 1 is required"),
    fertilizerId2: Yup.string().trim().required("Fertiliser 2 is required"),
    fertilizerId3: Yup.string().trim().optional(),
  });

  const getFertilisers = async () => {
    const { data } = await axiosInstance.get(`/users/fertilizers/all`);
    const fertilisersData = data.data.map((_: any) => {
      return {
        label: _.name,
        value: _.id,
      };
    });
    setFertilisersList(fertilisersData);
    return data?.data;
  };

  const handleSubmit = useCallback((fertiliser: NewFertilizerCombination) => {
    setIsSubmitting(true);
    if (fertiliser.fertilizerId3 === "") {
      delete fertiliser.fertilizerId3;
    }

    axiosInstance
      .post(`/fertilizer/inorganic-fertilizer-combo`, fertiliser, {
        headers: {
          ContentType: "application/json",
        },
      })
      .then((response) => {
        const { msg } = response.data;

        setIsSubmitting(false);

        queryClient.invalidateQueries(["getFertilisersComb"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Fertiliser Combination Added Successfully!",
          })
        );

        dispatch(hideModal());
      })
      .catch((error: AxiosError) => {
        setIsSubmitting(false);

        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            // bad request or invalid format or unauthorized
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      });
  }, []);

  useEffect(() => {
    getFertilisers();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {(props) => (
        <Form
          className="px-6 py-4 mt-2"
          // style={{
          //   minWidth: "360px",
          //   maxWidth: "760px",
          // }}
          style={{ minWidth: "40vw" }}
        >

          <div className="flex justify-center items-center bg-white px-6 py-5">
            <img src={logo} alt="logo" height={100} width={100} />
          </div>

          <div className="flex flex-col item-center gap-x-6">
            <div>
              <Input
                label="Fertiliser Name"
                id="name"
                name="name"
                type="text"
                placeholder="Please use (-) between names"
              />
            </div>

            <div className="mb-5">
              {fertilisersList && (
                <Select
                  options={fertilisersList}
                  label="Fertilizer 1"
                  id="fertilizerId1"
                  name="fertilizerId1"
                />
              )}
            </div>

            <div className="mb-5">
              {fertilisersList && (
                <Select
                  options={fertilisersList}
                  label="Fertilizer 2"
                  id="fertilizerId2"
                  name="fertilizerId2"
                />
              )}
            </div>

            <div className="mb-5">
              {fertilisersList && (
                <Select
                  options={fertilisersList}
                  label="Fertilizer 3 (Optional)"
                  id="fertilizerId3"
                  name="fertilizerId3"
                />
              )}
            </div>

          </div>
          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text="Save"
              isSubmitting={isSubmitting}
              // classes="text-sm"
              containerClasses="h-full"
              isSubmittingClasses="lg:mt-6"
              classes="text-sm lg:p-4 lg:rounded-lg"
            />
          </div>

        </Form>
      )}
    </Formik>
  );
};

export default AddFertiliserCombinationModal;
