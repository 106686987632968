import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import logo from "../../assets/logo192.png";


interface ProductObj {
  name: string;
  actualPrice: string;
  discountedPrice: string;
  stock: string;
}

type EditProductModalProps = {
  productId: string;
  name: string;
  actualPrice: string;
  discountedPrice: string;
  stock: string;
  addressId: string;
};

const EditProductModal: React.FC<EditProductModalProps> = ({
  productId,
  name,
  actualPrice,
  discountedPrice,
  stock,
  addressId,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: ProductObj = {
    name: name,
    actualPrice: actualPrice,
    discountedPrice: discountedPrice,
    stock: stock,
  };

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Product Name is Required"),
    actualPrice: Yup.number().required("Actual Price is Required"),
    discountedPrice: Yup.number().required("Discounted Price is Required"),
    stock: Yup.number().required("Stock is Required"),
  });

  const handleSubmit = useCallback(async (product: ProductObj) => {
    setIsSubmitting(true);
    try {
      const result = await axiosInstance.patch(
        `/products/${productId}`,
        {
          name: product.name,
          actualPrice: product.actualPrice,
          discountedPrice: product.discountedPrice,
          addresses: [
            {
              addressId: addressId,
              stockInKG: product.stock,
            },
          ],
        },
        { headers: { ContentType: "application/json" } }
      );


      queryClient.invalidateQueries(["getProducts"]);
      dispatch(
        addToast({
          kind: SUCCESS,
          msg: "Product Updated Successfully!",
        })
      );

      dispatch(hideModal());
    } catch (error: any) {
      if (error.response) {
        const response = error.response;
        const { msg } = response.data;

        switch (response.status) {
          // bad request or invalid format or unauthorized
          case 400:
          case 500:
            dispatch(
              addToast({
                kind: ERROR,
                msg: msg,
              })
            );
            break;
          default:
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Oops, something went wrong",
              })
            );
            break;
        }
      } else if (error.request) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Oops, something went wrong",
          })
        );
      } else {
        dispatch(
          addToast({
            kind: ERROR,
            msg: `Error: ${error.message}`,
          })
        );
      }
    }
    setIsSubmitting(false);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form
        className="px-6 py-4 mt-2"
        // style={{
        //   minWidth: "360px",
        //   maxWidth: "760px",
        // }}
        style={{ minWidth: "40vw" }}
      >

        <div className="flex justify-center items-center bg-white px-6 py-5">
          <img src={logo} alt="logo" height={100} width={100} />
        </div>

        <div className="flex item-center gap-x-6">
          <Input label="Product Name" id="name" name="name" type="text" />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Actual Price"
            id="actualPrice"
            name="actualPrice"
            type="text"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Discounted Price"
            id="discountedPrice"
            name="discountedPrice"
            type="text"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input label="Stock" id="stock" name="stock" type="text" />
        </div>

        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Save"
            isSubmitting={isSubmitting}
            // classes="text-sm"
            containerClasses="h-full"
            isSubmittingClasses="lg:mt-6"
            classes="text-sm lg:p-4 lg:rounded-lg"
          />
        </div>

      </Form>
    </Formik>
  );
};

export default EditProductModal;
