import { useState } from "react";
import { MdAdd, MdArchive, MdLinkOff } from "react-icons/md";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
  ADD_MEASURING_VESSEL_MODAL,
  EDIT_MEASURING_VESSEL_MODAL,
} from "../../types/constants";
import { FaEye, FaPen } from "react-icons/fa";
import { useQuery, useQueryClient } from "react-query";
import useIsAdmin from "../../hooks/useIsAdmin";
import { toast } from "react-toastify";
import { NavLink, useParams } from "react-router-dom";

interface MeasuringVesselObj {
  measuring_vessel_id: string;
  vessel_name: string;
  manufacturer_name: string;
  vessel_type: string;
  volume: string;
  vessel_image: string;
  created_at: string;
  updated_at: string;
}

interface MeasuringVesselProps {
  otherUrl?: string;
}

const MeasuringVessel = ({ otherUrl }: MeasuringVesselProps) => {
  const queryClient = useQueryClient();
  const { isAdmin } = useIsAdmin();
  const dispatch = useDispatch();
  const { id } = useParams();
  const pt_id = id ? parseInt(id, 10) : null;

  const handleArchival = async (id: string) => {
    try {
      const response = await axiosInstance.patch(
        `/pyrolysis_technology/measuring_vessel/archive/${id}`,
        { is_archived: true }
      );
      console.log(response);
      toast.success("Measuring Vessel Archived Successfully");
      queryClient.invalidateQueries(["getAllMeasuringVessel"]);
    } catch (error: any) {
      console.error(error);
      toast.error(error.response?.data?.error || "Failed to archive Measuring Vessel");
    }
  };

  const handleUnlink = async (measuring_vessel_id: string) => {
    if (!pt_id) return;
    try {
      await axiosInstance.patch(
        "/pyrolysis_technology/assets/link-unlink",
        {
          pt_id: pt_id,
          type: "unlink",
          measuring_vessel_id: measuring_vessel_id,
        }
      );
      toast.success("Measuring Vessel Unlinked Successfully");
      queryClient.invalidateQueries(["getAllMeasuringVessel"]);
    } catch (error: any) {
      toast.error(error.response?.data?.error || "Failed to unlink Measuring Vessel");
    }
  };

  const ALL_USERS_COLUMNS = [
    {
      Header: "Sr. No",
      accessor: "serialNumber",
      Cell: ({ row }: any) => {
        return row.index + 1;
      },
    },
    {
      Header: "Vessel Id",
      accessor: "measuring_vessel_id",
      Cell: ({ row }: any) => {
        const measuring_vessel_id = row?.original.measuring_vessel_id;
        return (
          <NavLink
            to={`${row?.original.measuring_vessel_id}`}
            className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
            title={`View Measuring Vessel Details`}
          >
            {measuring_vessel_id}
          </NavLink>
        );
      },
    },
    {
      Header: "Vessel Name",
      accessor: "vessel_name",
      Cell: ({ row }: any) => {
        const vessel_name = row?.original.vessel_name;
        return vessel_name === " " ? <span>Not Available</span> : <span>{vessel_name}</span>;
      },
    },
    {
      Header: "Manufacturer Name",
      accessor: "manufacturer_name",
      Cell: ({ row }: any) => {
        const manufacturer_name = row?.original.manufacturer_name;
        return manufacturer_name === " " ? (
          <p className="">Not Available</p>
        ) : (
          <span>{manufacturer_name}</span>
        );
      },
    },
    {
      Header: "Vessel Type",
      accessor: "vessel_type",
      Cell: ({ row }: any) => {
        const vessel_type = row?.original.vessel_type;
        return vessel_type === " " ? <span>Not Available</span> : <span>{vessel_type}</span>;
      },
    },
    {
      Header: "Volume (L/ml/m³)",
      accessor: "volume",
      Cell: ({ row }: any) => {
        const volume = row?.original.volume;
        return volume === " " ? <span>0</span> : <span>{volume}</span>;
      },
    },
    {
      Header: "Created At",
      accessor: "created_at",
      Cell: ({ value }: any) => {
        return value ? new Date(value).toLocaleString() : "Invalid Date";
      },
    },
    // {
    //   Header: "Updated At",
    //   accessor: "updated_at",
    //   Cell: ({ value }: any) => {
    //     return new Date(value).toLocaleString();
    //   },
    // },
    {
      Header: "Vessel Img",
      accessor: "vessel_image",
      Cell: ({ value }: any) => {
        return (
          <a href={value} className="text-green-400" target="_blank" rel="noreferrer">
            <button className="bg-green-600 text-white px-2 py-3">
              <FaEye />
            </button>
          </a>
        );
      },
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: ({ value, row }: any) => {
        console.table(row.original);
        return (
          <button
            onClick={() => {
              dispatch(
                showModal({
                  modalType: EDIT_MEASURING_VESSEL_MODAL,
                  modalTitle: "Edit Measuring Vessel",
                  modalProps: {
                    measuringVesselId: row?.original.measuring_vessel_id,
                    vessel_name: row?.original.vessel_name,
                    manufacturer_name: row?.original.manufacturer_name,
                    vessel_type: row?.original.vessel_type,
                    volume: row?.original.volume,
                    vessel_image: row?.original.vessel_image,
                    addressId: row?.original.addressId,
                  },
                })
              );
            }}
            className="bg-green-600 text-white px-2 py-3"
          >
            <FaPen />
          </button>
        );
      },
    },
    {
      Header: "Archive",
      accessor: "archive",
      Cell: ({ row }: any) => {
        return (
          <button
            onClick={() => handleArchival(row?.original.measuring_vessel_id)}
            className="text-green-600"
          >
            <MdArchive size={36} />
          </button>
        );
      },
    },
    {
      Header: "Unlink",
      accessor: "unlink",
      Cell: ({ row }: any) => (
        <button
          onClick={() => handleUnlink(row?.original.measuring_vessel_id)}
          className="text-red-600"
          disabled={!pt_id}
        >
          <MdLinkOff size={24} />
        </button>
      ),
    },

  ];

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getAllMeasuringVessel = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(otherUrl ||
      `/pyrolysis_technology/measuring_vessel/all?is_archived=false`
    );
    const vessels = data?.data?.vessels || [];
    const filteredData = isAdmin
      ? vessels.filter((e: any) => e.created_by_fpo_id === null)
      : vessels;
    return filteredData;
  };

  const { data, error, isLoading } = useQuery<
    MeasuringVesselObj[] | undefined,
    any,
    MeasuringVesselObj[],
    any[]
  >(
    ["getAllMeasuringVessel", pageIndex, cPageSize, cSortBy, desc, q],
    getAllMeasuringVessel
  );

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        excludeColumnSearch={[]}
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1}
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => { }}
          />

          {selectedRows.length > 0 && (
            <span className="btn-primary flex items-center mt-5 w-48">
              Selected Row : {selectedRows.length}
            </span>
          )}
        </div>

        <div className="right">
          <button
            className="btn-primary flex items-center"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_MEASURING_VESSEL_MODAL,
                  modalTitle: "Add Measuring Vessel",
                  modalProps: { otherUrl },
                })
              );
            }}
          >
            <span className="mr-1">Add</span> <MdAdd size={17} />
          </button>
        </div>
      </header>

      {component !== null && component}
    </div>
  );
};

export default MeasuringVessel;
