import { Form, Formik } from "formik";
import React, { useState, useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import debounce from "lodash/debounce";
import logo from "../../assets/logo192.png";
import axiosInstance from "../../axiosInstance";
import useAssignProject from "../../hooks/useAssignProject";
import ErrorBox from "../FormikComponents/ErrorBox";
import SelectDropDownAsync from "../FormikComponents/SelectDropDownAsync";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import Loader from "../Loader/Loader";

interface AssignProjectObj {
    project_id: string;
}

interface AssignProjectModalProps {
    artisan_id: string;
    action: string;
}

interface ProjectType {
    project_artisan_id: number;
    project_id: string;
    artisan_id: string;
    project_name: string;
    project_contact_details: {
        person_phone: string;
        person_name: string;
    };
    project_location: string;

}

const AssignProjectModal: React.FC<AssignProjectModalProps> = (props) => {
    const { artisan_id, action } = props;

    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [commonError, setCommonError] = useState("");
    const queryClient = useQueryClient();
    const initialValues: AssignProjectObj = {
        project_id: "",
    };
    const { handleProjectAssign } = useAssignProject();

    const validationSchema = Yup.object().shape({
        project_id: Yup.string().trim().required("Project Id is required"),
    });

    const handleSubmit = async (values: AssignProjectObj) => {
        if (!values.project_id) {
            setCommonError("Project Id is required");
            return;
        }
        await handleProjectAssign({
            action: action,
            project_id: values.project_id,
            artisan_id: artisan_id,
        });
    };

    const getArtisanProjects = async () => {
        const { data } = await axiosInstance.get(`/project/projects/${artisan_id}`);
        return data.data.projects;
    };

    const { data, isLoading } = useQuery<ProjectType[] | undefined, any, ProjectType[], any>(
        ["getArtisanProjects", artisan_id],
        async () => await getArtisanProjects()
    );

    // Debounced function to load project options from the API
    const debouncedLoadOptions = useCallback(
        debounce(
            async (
                inputValue: string,
                callback: (options: { label: string; value: string }[]) => void
            ) => {
                try {
                    const response = await axiosInstance.get("/project/all");
                    let requiredData: { label: string; value: string }[] = [];
                    if (response?.data?.data?.length > 0) {
                        for (let i = 0; i < response.data.data.length; i++) {
                            const project = response.data.data[i];
                            if (
                                project?.project_name
                                    ?.toLowerCase()
                                    .replace(" ", "")
                                    .includes(inputValue.toLowerCase().replace(" ", ""))
                            ) {
                                requiredData.push({
                                    label: project.project_name,
                                    value: project.project_id,
                                });
                            }
                        }
                    }
                    callback(requiredData);
                } catch (error) {
                    callback([
                        {
                            label: "Error. Something went wrong!!",
                            value: "error",
                        },
                    ]);
                }
            },
            500
        ),
        []
    );

    const loadOptions = (inputValue: string) => {
        return new Promise<{ label: string; value: string }[]>((resolve) => {
            debouncedLoadOptions(inputValue, resolve);
        });
    };

    return (
        <div className="flex flex-col justify-center items-center">
            <img src={logo} alt="logo" height={80} width={80} />
            <div id="projects" className="max-h-72 px-10 py-6 mt-4 overflow-auto w-full">
                {isLoading ? (
                    <Loader />
                ) : data && data.length !== 0 ? (
                    data.map((project: ProjectType, index) => (
                        <div className="grid grid-cols-3 gap-10 mb-12" key={index}>
                            <div className="flex items-start">
                                <h3 className="text-base font-semibold mr-4">Project Name: </h3>
                                <p className="text-base font-medium text-green-500">
                                    {project?.project_name}
                                </p>
                            </div>
                            <div className="flex items-start">
                                <h3 className="text-base font-semibold mr-4">Person Name: </h3>
                                <p className="text-base font-medium text-green-500">
                                    {project?.project_contact_details?.person_name}
                                </p>
                            </div>
                            <div className="flex items-start">
                                <h3 className="text-base font-semibold mr-4">Person Phone: </h3>
                                <p className="text-base font-medium text-green-500">
                                    {project?.project_contact_details?.person_phone}
                                </p>
                            </div>
                            <div className="flex items-start">
                                <h3 className="text-base font-semibold mr-4">Project Location: </h3>
                                <p className="text-base font-medium text-green-500">
                                    {project?.project_location}
                                </p>
                            </div>
                            <hr className="col-span-3" />
                        </div>
                    ))
                ) : (
                    <div className="text-xl font-semibold">
                        No Project Assigned to this Artisan
                    </div>
                )}
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values }) => (
                    <Form
                        className="max-w-sm flex flex-col justify-center items-center bg-white px-6 py-5 shadow"
                        style={{ minWidth: "40vw" }}
                    // encType="multipart/form-data"
                    >

                        <div className="flex flex-col w-full">
                            <SelectDropDownAsync
                                label="Project List"
                                id="project_id"
                                name="project_id"
                                classes="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                loadOptions={loadOptions}
                            />
                            {/* {values?.project_id && (
                                <div className="flex gap-2">
                                    <p>Select Project Id : </p>
                                    <p className="font-bold">
                                        {values?.project_id}
                                    </p>
                                </div>
                            )} */}

                            <div className="flex gap-2">
                                <p>Selected Artisan ID: </p>
                                <p className="font-bold">
                                    {artisan_id}
                                </p>
                            </div>

                        </div>
                        {commonError && (
                            <div className="common-error mb-2 text-center">
                                <ErrorBox msg={commonError} />
                            </div>
                        )}
                        <div className="buttons flex flex-col items-center w-full justify-center my-4 mb-6">
                            <SubmitBtn
                                text="Assign Project"
                                isSubmitting={isSubmitting}
                                // classes="text-sm"
                                containerClasses="h-full"
                                isSubmittingClasses="lg:mt-6"
                                classes="text-sm lg:p-4 lg:rounded-lg"
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AssignProjectModal;
