import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
    ADD_SEMI_INDUSTRIAL_MODAL,
    // EDIT_SEMI_INDUSTRIAL_MODAL
} from "../../types/constants";
import { FaEye, FaPen } from "react-icons/fa";
import { NavLink } from "react-router-dom";

interface SemiIndustrialObj {
    pyro_eq_si_id: number;
    pyro_eq_name: string;
    pyro_eq_model_name: string;
    pyro_eq_manufacturer: string;
    pyro_eq_methane_emission: string;
    length: string;
    breadth: string;
    height: string;
    pyro_eq_image: string;
    created_at: string;
    updated_at: string;
    is_archived: boolean;
}

const SemiIndustrial = () => {
    const dispatch = useDispatch();

    const ALL_USERS_COLUMNS = [
        {
            Header: "Sr. No",
            accessor: "serialNumber",
            Cell: ({ row }: any) => row.index + 1,
        },
        {
            Header: "Equipment Name",
            accessor: "pyro_eq_name",
            Cell: ({ row }: any) => {
                const pyro_eq_name = row?.original.pyro_eq_name;
                return (
                    <NavLink
                        to={`${row?.original.pyro_eq_si_id}`}
                        className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
                        title={`View Semi Industrial Details`}
                    >
                        {pyro_eq_name}
                    </NavLink>
                );
            },
        },
        {
            Header: "Model Name",
            accessor: "pyro_eq_model_name",
            Cell: ({ value }: any) => value || "Not Available",
        },
        {
            Header: "Manufacturer",
            accessor: "pyro_eq_manufacturer",
            Cell: ({ value }: any) => value || "Not Available",
        },
        {
            Header: "Methane Emissions",
            accessor: "pyro_eq_methane_emission",
            Cell: ({ value }: any) => value || "Not Available",
        },
        {
            Header: "Length (cm)",
            accessor: "length",
            Cell: ({ value }: any) => value || "Not Available",
        },
        {
            Header: "Breadth (cm)",
            accessor: "breadth",
            Cell: ({ value }: any) => value || "Not Available",
        },
        {
            Header: "Height (cm)",
            accessor: "height",
            Cell: ({ value }: any) => value || "Not Available",
        },
        {
            Header: "Image",
            accessor: "pyro_eq_image",
            Cell: ({ value }: any) => {
                return value ? (
                    <a
                        href={value}
                        className="text-green-400"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <button className="bg-green-600 text-white px-2 py-3">
                            <FaEye />
                        </button>
                    </a>
                ) : (
                    <p className="text-red-500">No image</p>
                );
            },
        },
        {
            Header: "Created At",
            accessor: "created_at",
            Cell: ({ value }: any) =>
                value ? new Date(value).toLocaleString() : "Invalid Date",
        },
        // Edit column remains commented out
        // {
        //     Header: "Edit",
        //     accessor: "edit",
        //     Cell: ({ row }: any) => {
        //         return (
        //             <button
        //                 onClick={() => {
        //                     try {
        //                         dispatch(
        //                             showModal({
        //                                 modalType: EDIT_SEMI_INDUSTRIAL_MODAL,
        //                                 modalTitle: "Edit Semi Industrial",
        //                                 modalProps: {
        //                                     pyro_eq_name: row?.original.pyro_eq_name,
        //                                     pyro_eq_model_name: row?.original.pyro_eq_model_name,
        //                                     pyro_eq_manufacturer: row?.original.pyro_eq_manufacturer,
        //                                     pyro_eq_methane_emission: row?.original.pyro_eq_methane_emission,
        //                                     length: row?.original.length,
        //                                     breadth: row?.original.breadth,
        //                                     height: row?.original.height,
        //                                     pyro_eq_image: row?.original.pyro_eq_image,
        //                                 },
        //                             })
        //                         );
        //                     } catch (error) {
        //                         console.error("Error opening edit modal:", error);
        //                     }
        //                 }}
        //                 className="bg-green-600 text-white px-2 py-3"
        //             >
        //                 <FaPen />
        //             </button>
        //         );
        //     },
        // },
    ];

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState(null);
    const [desc, setDesc] = useState(true);
    const [q, setQ] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);

    const getAllClusterDetails = async () => {
        try {
            const { data } = await axiosInstance.get(`/pyrolysis_technology/semi_Industrial/all?limit=100`);
            return data?.data?.semi_industrials || [];
        } catch (error) {
            console.error("Error fetching cluster details:", error);
            throw new Error("Failed to load data. Please try again later.");
        }
    };

    const { data, error, isLoading } = useQuery<SemiIndustrialObj[]>(
        ["getAllClusterDetails", pageIndex, cPageSize, q],
        getAllClusterDetails
    );

    let component = null;

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center text-red-500">
                An error has occurred: {(error as Error).message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance
                excludeColumnSearch={[]}
                tableData={data || []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
            />
        );
    }

    return (
        <div className="all-users">
            <header className="flex items-center justify-between mb-8">
                <div className="left">
                    <SearchBox
                        searchValue={q}
                        setSearchValue={setQ}
                        handleSearch={() => { }}
                    />
                    {selectedRows.length > 0 && (
                        <span className="btn-primary flex items-center mt-5 w-48">
                            Selected Row : {selectedRows.length}
                        </span>
                    )}
                </div>
                <div className="right">
                    <button
                        className="btn-primary flex items-center"
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_SEMI_INDUSTRIAL_MODAL,
                                    modalTitle: "Add Semi Industrial Modal",
                                })
                            );
                        }}
                    >
                        <span className="mr-1">Add</span> <MdAdd size={17} />
                    </button>
                </div>
            </header>
            {component !== null && component}
        </div>
    );
};

export default SemiIndustrial;
