import { useFormikContext } from "formik";
import { useState } from "react";

interface Props {
  text: string;
  isSubmitting: boolean;
  classes?: string;
  isSubmittingClasses?: string;
  containerClasses?: string;
  checkDirty?: boolean;
}

const SubmitBtn = ({
  text,
  isSubmitting,
  classes,
  isSubmittingClasses,
  containerClasses,
  checkDirty = true,
}: Props) => {
  const { isValid, dirty, errors } = useFormikContext();

  let errorsArr = [];
  for (const [key, value] of Object.entries(errors)) {
    //@ts-ignore
    if (Array.isArray(value)) {
      //@ts-ignore
      value.forEach((item: any, index: number) => {
        errorsArr.push(`Row ${index + 1}: ${Object.values(item)[0]}`);
      });
    } else {
      errorsArr.push(value);
    }
  }

  return isSubmitting === true ? (
    <div className={`relative w-full ${containerClasses}`}>
      <button
        // className={`btn-primary  w-full disabled:opacity-100 cursor-default flex items-center justify-center ${isSubmittingClasses} `}
        className={`btn-primary w-full disabled:opacity-100 cursor-default flex items-center justify-center text-sm lg:mt-6 lg:p-4 lg:rounded-lg ${isSubmittingClasses} `}
        disabled
      >
        <svg
          className="animate-spin h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>

          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>

        </svg>

      </button>
    </div>
  ) : (
    <>
      <div
        className={`relative w-full ${containerClasses}`}
        data-tooltip-target="tooltip-default"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={`${errorsArr.length > 0 ? errorsArr.join("\n") : "No errors"}`}
      >
        <button
          type="submit"
          className={`btn-primary w-full disabled:opacity-40 disabled:cursor-not-allowed  focus:bg-sky-700 ${classes}`}
          disabled={!isValid || (checkDirty && !dirty)}
        >
          {text}
        </button>
      </div>
    </>
  );
};

export default SubmitBtn;
