import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { showModal } from "../../redux/features/modalSlice";
import {
    ADD_PYROLYSIS_KILN_MODAL,
    ADD_SEMI_INDUSTRIAL_MODAL,
    ADD_SOIL_PIT_MODAL,
    ASSIGN_SITE_MODAL,
} from "../../types/constants";
import { toast } from "react-toastify"; // Import toast

interface PyroTechBundleDetailsObj {
    pt_id: number;
    name: string;
    soil_pit_id: number | null;
    pyrolysis_kiln_id: number | null;
    site_id: number | null;
    created_at: string;
    updated_at: string;
    pyro_eq_si_id: number | null;
    id?: number;
}

const PyroTechBundleDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const ALL_USERS_COLUMNS = [
        {
            Header: "PyroTech ID",
            accessor: "pt_id",
        },
        {
            Header: "PyroTech Name",
            accessor: "name",
        },
        {
            Header: "Soil Pit",
            accessor: "soil_pit_id",
            Cell: ({ value, row }: { value: any; row: any }) => {
                if (value !== null) {
                    return (
                        <div className="flex items-center justify-center gap-2">
                            <div
                                className="bg-green-500 text-white p-2 rounded inline-block cursor-pointer"
                                onClick={() => {
                                    window.location.href = `/pyrolysis-equipment/soil-pit/${value}`;
                                }}
                            >
                                View - ( ID: {value} )
                            </div>
                            <button
                                onClick={async () => {
                                    try {
                                        await axiosInstance.patch(
                                            `/pyrolysis_technology/unlink-equipment/${id}/soil_pit`
                                        );
                                        queryClient.invalidateQueries(["getPyrolysisTechnologyById", id]);
                                        toast.success("Soil Pit unlinked successfully");
                                    } catch (error) {
                                        toast.error("Error unlinking Soil Pit");
                                        console.error("Error unlinking Soil Pit:", error);
                                    }
                                }}
                                className="bg-red-500 text-white p-2 rounded inline-block cursor-pointer"
                            >
                                Unlink Soil Pit
                            </button>
                        </div>
                    );
                }
                return (
                    <button
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_SOIL_PIT_MODAL,
                                    modalTitle: "Assign Soil Pit",
                                    modalProps: {
                                        pt_id: id,
                                    },
                                })
                            );
                        }}
                        className="bg-green-600 text-white px-2 py-3"
                        disabled={row.original.pyro_eq_si_id !== null || row.original.pyrolysis_kiln_id !== null}
                    >
                        Assign Soil Pit
                    </button>
                );
            },
        },
        {
            Header: "Kontiki",
            accessor: "pyrolysis_kiln_id",
            Cell: ({ value, row }: { value: any; row: any }) => {
                if (value !== null) {
                    return (
                        <div className="flex items-center justify-center gap-2">
                            <div
                                className="bg-green-500 text-white p-2 rounded inline-block cursor-pointer"
                                onClick={() => {
                                    window.location.href = `/pyrolysis-equipment/${value}`;
                                }}
                            >
                                View - ( ID: {value} )
                            </div>
                            <button
                                onClick={async () => {
                                    try {
                                        await axiosInstance.patch(
                                            `/pyrolysis_technology/unlink-equipment/${id}/pyrolysis_kiln`
                                        );
                                        queryClient.invalidateQueries(["getPyrolysisTechnologyById", id]);
                                        toast.success("Kontiki unlinked successfully");
                                    } catch (error) {
                                        toast.error("Error unlinking Kontiki");
                                        console.error("Error unlinking Kontiki:", error);
                                    }
                                }}
                                className="bg-red-500 text-white p-2 rounded inline-block cursor-pointer"
                            >
                                Unlink Kontiki
                            </button>
                        </div>
                    );
                }
                return (
                    <button
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_PYROLYSIS_KILN_MODAL,
                                    modalTitle: "Add & Assign Kontiki",
                                    modalProps: {
                                        data: row.original,
                                        pt_id: id,
                                    },
                                })
                            );
                        }}
                        className="bg-green-600 text-white px-2 py-3"
                        disabled={row.original.pyro_eq_si_id !== null || row.original.soil_pit_id !== null}
                    >
                        Assign Kontiki
                    </button>
                );
            },
        },
        {
            Header: "Semi Industrial",
            accessor: "pyro_eq_si_id",
            Cell: ({ value, row }: { value: any; row: any }) => {
                if (value !== null) {
                    return (
                        <div className="flex items-center justify-center gap-2">
                            <div
                                className="bg-green-500 text-white p-2 rounded inline-block cursor-pointer"
                                onClick={() => {
                                    window.location.href = `/pyrolysis-equipment/semi-industrial/${value}`;
                                }}
                            >
                                View - ( ID: {value} )
                            </div>
                            <button
                                onClick={async () => {
                                    try {
                                        await axiosInstance.patch(
                                            `/pyrolysis_technology/unlink-equipment/${id}/semi_industrial`
                                        );
                                        queryClient.invalidateQueries(["getPyrolysisTechnologyById", id]);
                                        toast.success("Semi Industrial unlinked successfully");
                                    } catch (error) {
                                        toast.error("Error unlinking Semi Industrial");
                                        console.error("Error unlinking Semi Industrial:", error);
                                    }
                                }}
                                className="bg-red-500 text-white p-2 rounded inline-block cursor-pointer"
                            >
                                Unlink Semi Industrial
                            </button>
                        </div>
                    );
                }
                return (
                    <button
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_SEMI_INDUSTRIAL_MODAL,
                                    modalTitle: "Add & Assign Semi Industrial Eq.",
                                    modalProps: {
                                        data: row.original,
                                        pt_id: id,
                                    },
                                })
                            );
                        }}
                        className="bg-green-600 text-white px-2 py-3"
                        disabled={row.original.pyrolysis_kiln_id !== null || row.original.soil_pit_id !== null}
                    >
                        Assign Semi Ind.
                    </button>
                );
            },
        },
        {
            Header: "Site ID",
            accessor: "site_id",
            Cell: ({ value, row }: any) => {
                return value !== null ? (
                    <div className="bg-green-500 text-white p-2 rounded inline-block">
                        Linked - ( ID# {value} )
                    </div>
                ) : (
                    <button
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ASSIGN_SITE_MODAL,
                                    modalTitle: "Assign Site",
                                    modalProps: {
                                        data: row.original,
                                    },
                                })
                            );
                        }}
                        className="bg-green-600 text-white px-2 py-3"
                    >
                        Assign Site
                    </button>
                );
            },
        },
        {
            Header: "Created At",
            accessor: "created_at",
            Cell: ({ value }: any) =>
                value ? new Date(value).toLocaleString() : "Invalid Date",
        },
        {
            Header: "Updated At",
            accessor: "updated_at",
            Cell: ({ value }: any) =>
                value ? new Date(value).toLocaleString() : "Invalid Date",
        },
    ];

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState(null);
    const [desc, setDesc] = useState(true);
    const [q, setQ] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);

    const getPyrolysisTechnologyById = async () => {
        try {
            const { data } = await axiosInstance.get(`/pyrolysis_technology/${id}`);
            return data?.data || null;
        } catch (error) {
            console.error("Error fetching user details:", error);
            throw error;
        }
    };

    const { data, error, isLoading } = useQuery<PyroTechBundleDetailsObj[]>(
        ["getPyrolysisTechnologyById", id, pageIndex, cPageSize, q],
        getPyrolysisTechnologyById
    );

    let component = null;

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {(error as Error).message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance
                excludeColumnSearch={[]}
                tableData={data || []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1}
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        );
    }

    return (
        <div className="users h-full w-full">
            <div className="all-users">
                <header className="flex items-center justify-between mb-8">
                    <div className="left">
                        <SearchBox
                            searchValue={q}
                            setSearchValue={setQ}
                            handleSearch={() => { }}
                        />
                        {selectedRows.length > 0 && (
                            <span className="btn-primary flex items-center mt-5 w-48">
                                Selected Row : {selectedRows.length}
                            </span>
                        )}
                    </div>
                </header>
                {component !== null && component}
            </div>

            <nav className="users-nav mb-12 mt-10 noselect">
                <ul className="flex gap-x-6 items-center">
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            end
                            to=""
                        >
                            Thermometer
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            end
                            to="moisture-meter"
                        >
                            Moisture Meter
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            end
                            to="retention-bucket"
                        >
                            Retention Bucket
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            end
                            to="sampling-beaker"
                        >
                            Sampling Beaker
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            end
                            to="measuring-vessel"
                        >
                            Measuring Vessel
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            end
                            to="iot"
                        >
                            Iot
                        </NavLink>
                    </li>
                </ul>
            </nav>

            <div className="content">
                <Outlet />
            </div>
        </div>
    );
};

export default PyroTechBundleDetails;
