import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { useQuery, QueryFunctionContext } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
    ADD_NEW_SITE_MODAL,
    EDIT_SITE_MODAL,
    ASSIGN_FPO_TO_SITE_MODAL
} from "../../types/constants";
import { FaEye, FaPen } from "react-icons/fa";
import { NavLink } from "react-router-dom";

interface SiteObj {
    site_id: string;
    site_name: string;
    site_area: number;
    site_pictures: {
        image1: string;
        image2: string;
        image3: string;
        image4: string;
    };
    is_archived: boolean;
    fpo_id: number | null;
    created_at: string;
    updated_at: string;
    id: string
}

const Site = () => {
    const dispatch = useDispatch();

    const ALL_SITES_COLUMNS = [
        {
            Header: "Sr. No",
            accessor: "serialNumber",
            Cell: ({ row }: { row: { index: number } }) =>
                row.index + 1,
        },
        {
            Header: "Site Name",
            accessor: "site_name",
            Cell: ({ row }: any) => {
                const site_name = row?.original.site_name;
                return (
                    <NavLink
                        to={`${row?.original.site_id}`}
                        className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
                        title={`View Site Details`}
                    >
                        {site_name}
                    </NavLink>
                );
            },
        },
        {
            Header: "Site Area (Acres)",
            accessor: "site_area",
            Cell: ({ value }: { value: string }) => value || "Not Available",
        },
        {
            Header: "Image 1",
            accessor: "site_pictures.image1",
            Cell: ({ value }: { value: string }) => (
                <a
                    href={value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-green-600 text-white px-2 py-3 inline-flex items-center justify-center"
                    title="View Image 1"
                >
                    <FaEye />
                </a>
            ),
        },
        {
            Header: "Image 2",
            accessor: "site_pictures.image2",
            Cell: ({ value }: { value: string }) => (
                <a
                    href={value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-green-600 text-white px-2 py-3 inline-flex items-center justify-center"
                    title="View Image 2"
                >
                    <FaEye />
                </a>
            ),
        },
        {
            Header: "Image 3",
            accessor: "site_pictures.image3",
            Cell: ({ value }: { value: string }) => (
                <a
                    href={value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-green-600 text-white px-2 py-3 inline-flex items-center justify-center"
                    title="View Image 3"
                >
                    <FaEye />
                </a>
            ),
        },
        {
            Header: "Image 4",
            accessor: "site_pictures.image4",
            Cell: ({ value }: { value: string }) => (
                <a
                    href={value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-green-600 text-white px-2 py-3 inline-flex items-center justify-center"
                    title="View Image 4"
                >
                    <FaEye />
                </a>
            ),
        },
        {
            Header: "Assign FPO / FPO ID",
            accessor: "fpo_id",
            Cell: ({ value, row }: any) => {
                return value != null ? (
                    value
                ) : (
                    <button
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ASSIGN_FPO_TO_SITE_MODAL,
                                    modalTitle: "Assign FPO To Site",
                                    modalProps: {
                                        id: row.original.site_id
                                    },
                                })
                            );
                        }}
                        className="bg-green-600 text-white px-2 py-3"
                    >
                        Assign FPO
                    </button>
                );
            },
        },
        {
            Header: "Created At",
            accessor: "created_at",
            Cell: ({ value }: { value: string }) =>
                value ? new Date(value).toLocaleString() : "Invalid Date",
        },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: ({ row }: { row: { original: SiteObj } }) => (
                <button
                    onClick={() =>
                        dispatch(
                            showModal({
                                modalType: EDIT_SITE_MODAL,
                                modalTitle: "Edit Site",
                                modalProps: {
                                    site_id: row.original.site_id,
                                    site_name: row.original.site_name,
                                    site_area: row.original.site_area,
                                    site_pictures: row.original.site_pictures,
                                    is_archived: row.original.is_archived,
                                    fpo_id: row.original.fpo_id,
                                },
                            })
                        )
                    }
                    className="bg-green-600 text-white px-2 py-3"
                >
                    <FaPen />
                </button>
            ),
        },
        {
            Header: "Archived",
            accessor: "is_archived",
            Cell: ({ value }: { value: boolean }) => (value ? "Yes" : "No"),
        },
    ];

    const [cPageSize, cSetPageSize] = useState<number>(10);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [cSortBy, cSetSortBy] = useState<string | null>(null);
    const [desc, setDesc] = useState<boolean>(true);
    const [q, setQ] = useState<string>("");
    const [selectedRows, setSelectedRows] = useState<SiteObj[]>([]);

    let component: JSX.Element | null = null;

    const getAllSitesDetails = async (context: QueryFunctionContext): Promise<{ sites: SiteObj[]; totalCount: number }> => {
        const queryKey = context.queryKey as [string, number, number];
        const [, pageIndex, cPageSize] = queryKey;
        const limit = cPageSize;
        const offset = pageIndex * cPageSize;
        const { data } = await axiosInstance.get(
            `/site/all?limit=${limit}&offset=${offset}`
        );
        return {
            sites: data.data.sites as SiteObj[],
            totalCount: data.data.count as number,
        };
    };

    const { data, error, isLoading } = useQuery<{ sites: SiteObj[]; totalCount: number }>(
        ["getAllSitesDetails", pageIndex, cPageSize],
        getAllSitesDetails
    );

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {error instanceof Error ? error.message : "Unknown error"}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance
                excludeColumnSearch={[]}
                tableData={data?.sites || []}
                columnName={ALL_SITES_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={
                    data?.totalCount ? Math.ceil(data.totalCount / cPageSize) : 0
                }
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        );
    }

    return (
        <div className="all-sites">
            <header className="flex items-center justify-between mb-8">
                <div className="left">
                    <SearchBox
                        searchValue={q}
                        setSearchValue={setQ}
                        handleSearch={() => { }}
                    />
                    {selectedRows.length > 0 && (
                        <span className="btn-primary flex items-center mt-5 w-48">
                            Selected Row: {selectedRows.length}
                        </span>
                    )}
                </div>
                <div className="right">
                    <button
                        className="btn-primary flex items-center"
                        onClick={() =>
                            dispatch(
                                showModal({
                                    modalType: ADD_NEW_SITE_MODAL,
                                    modalTitle: "Add Site",
                                    modalProps: {
                                        isUpdate: false,
                                    },
                                })
                            )
                        }
                    >
                        <span className="mr-1">Add</span> <MdAdd size={17} />
                    </button>
                </div>
            </header>
            {component}
        </div>
    );
};

export default Site;
