import { Form, Formik } from "formik";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { uploadImage } from "../../http/uploadImage";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import {
    AZURE_STORAGE_DIRECTORY,
    AZURE_STORAGE_SUBDIRECTORY,
    ERROR,
    SUCCESS
} from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import { GoogleMap, LoadScript, Marker, Polygon } from "@react-google-maps/api";
import { GOOLGLE_MAPS_API_KEY } from "../../config";
import logo from "../../assets/logo192.png";
import { STATES } from "../../../src/types/constants";
import Select from "../FormikComponents/Select";

interface GeoCoordinate {
    latitude: number;
    longitude: number;
}

interface FormValues {
    site_name: string;
    site_area: string;
    line_1: string;
    city: string;
    state: string;
    pincode: string;
}

const AddNewSiteModal = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [img1, setImg1] = useState<File | null>(null);
    const [img2, setImg2] = useState<File | null>(null);
    const [img3, setImg3] = useState<File | null>(null);
    const [img4, setImg4] = useState<File | null>(null);

    const [coordinates, setCoordinates] = useState<GeoCoordinate[]>([]);
    // New state for the unit (acres or hectares)
    const [areaUnit, setAreaUnit] = useState<string>("acres");

    const initialValues: FormValues = {
        site_name: "",
        site_area: "",
        line_1: "",
        city: "",
        state: "",
        pincode: "",
    };

    const validationSchema = Yup.object({
        site_name: Yup.string().trim().required("Site Name is required"),
        site_area: Yup.number()
            .transform((value, originalValue) => (originalValue === "" ? NaN : value))
            .typeError("Site Area must be a number")
            .required("Site Area is required"),
        line_1: Yup.string().trim().required("Address Line is required"),
        city: Yup.string().trim().required("City is required"),
        state: Yup.string().trim().required("State is required"),
        pincode: Yup.string().trim().required("Pincode is required"),
    });

    const handleSubmit = async (values: FormValues) => {
        if (!img1 || !img2 || !img3 || !img4) {
            dispatch(
                addToast({
                    kind: ERROR,
                    msg: "All images are required",
                })
            );
            return;
        }

        if (coordinates.length < 3) {
            dispatch(
                addToast({
                    kind: ERROR,
                    msg: "At least 3 geofence coordinates are required",
                })
            );
            return;
        }

        setIsSubmitting(true);
        try {
            const imgRes1 = await uploadImage(
                img1,
                AZURE_STORAGE_DIRECTORY.PUBLIC,
                AZURE_STORAGE_SUBDIRECTORY.PROFILE
            );
            const imgRes2 = await uploadImage(
                img2,
                AZURE_STORAGE_DIRECTORY.PUBLIC,
                AZURE_STORAGE_SUBDIRECTORY.PROFILE
            );
            const imgRes3 = await uploadImage(
                img3,
                AZURE_STORAGE_DIRECTORY.PUBLIC,
                AZURE_STORAGE_SUBDIRECTORY.PROFILE
            );
            const imgRes4 = await uploadImage(
                img4,
                AZURE_STORAGE_DIRECTORY.PUBLIC,
                AZURE_STORAGE_SUBDIRECTORY.PROFILE
            );

            if (
                !imgRes1.data.publicUrl ||
                !imgRes2.data.publicUrl ||
                !imgRes3.data.publicUrl ||
                !imgRes4.data.publicUrl
            ) {
                throw new Error("Image upload failed");
            }

            const payload = {
                site_name: values.site_name,
                site_area: parseFloat(values.site_area),
                site_pictures: {
                    image1: imgRes1.data.publicUrl,
                    image2: imgRes2.data.publicUrl,
                    image3: imgRes3.data.publicUrl,
                    image4: imgRes4.data.publicUrl,
                },
                address: {
                    line_1: values.line_1,
                    city: values.city,
                    state: values.state,
                    pincode: values.pincode,
                    geofence_coordinates: coordinates,
                },
            };

            const result = await axiosInstance.post("/admin/site/create", payload, {
                headers: { "Content-Type": "application/json" },
            });
            if (result) {
                queryClient.invalidateQueries(["getAllSites"]);
                dispatch(
                    addToast({
                        kind: SUCCESS,
                        msg: "Site Added Successfully!",
                    })
                );
                dispatch(hideModal());
            }
        } catch (error: any) {
            if (error.response) {
                const response = error.response;
                const { msg } = response.data;
                switch (response.status) {
                    case 400:
                    case 500:
                        dispatch(
                            addToast({
                                kind: ERROR,
                                msg: msg,
                            })
                        );
                        break;
                    default:
                        dispatch(
                            addToast({
                                kind: ERROR,
                                msg: "Oops, something went wrong",
                            })
                        );
                        break;
                }
            } else if (error.request) {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: "Oops, something went wrong",
                    })
                );
            } else {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: `Error: ${error.message}`,
                    })
                );
            }
        }
        setIsSubmitting(false);
    };

    const handleAddCoordinate = () => {
        setCoordinates([...coordinates, { latitude: 0, longitude: 0 }]);
    };

    const handleRemoveCoordinate = (index: number) => {
        const newCoordinates = [...coordinates];
        newCoordinates.splice(index, 1);
        setCoordinates(newCoordinates);
    };

    const handleCoordinateChange = (
        index: number,
        field: "latitude" | "longitude",
        value: number
    ) => {
        const newCoordinates = [...coordinates];
        newCoordinates[index][field] = value;
        setCoordinates(newCoordinates);
    };

    const handleMapClick = (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
            const newCoordinate = { latitude: event.latLng.lat(), longitude: event.latLng.lng() };
            setCoordinates((prev) => {
                if (!prev.some(coord => coord.latitude === newCoordinate.latitude && coord.longitude === newCoordinate.longitude)) {
                    return [...prev, newCoordinate];
                }
                return prev;
            });
        }
    };

    const resetGeofence = () => {
        setCoordinates([]);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
        >
            {({ values, setFieldValue }) => (
                <Form className="px-6 py-4 mt-2" style={{ minWidth: "40vw" }}>

                    <div className="flex justify-center items-center bg-white px-6 py-5">
                        <img src={logo} alt="logo" height={100} width={100} />
                    </div>

                    <div className="flex items-center gap-x-6">
                        <Input
                            label="Site Name"
                            id="site_name"
                            name="site_name"
                            type="text"
                            placeholder="Enter Site Name" />
                    </div>

                    {/* Updated "site_area" section: type changed from "text" to "number" */}
                    <div className="flex items-center gap-x-6">
                        <div className="flex-1">
                            <Input
                                label={`Site Area (in ${areaUnit})`}
                                id="site_area"
                                name="site_area"
                                type="number"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-bold text-gray-700 mb-1">Unit</label>
                            <select
                                value={areaUnit}
                                onChange={(e) => {
                                    const newUnit = e.target.value;
                                    const currentValue = parseFloat(values.site_area);
                                    if (!isNaN(currentValue)) {
                                        if (areaUnit === "acres" && newUnit === "hectares") {
                                            // Convert acres to hectares
                                            const converted = currentValue * 0.404686;
                                            setFieldValue("site_area", converted.toFixed(6));
                                        } else if (areaUnit === "hectares" && newUnit === "acres") {
                                            // Convert hectares to acres
                                            const converted = currentValue * 2.47105;
                                            setFieldValue("site_area", converted.toFixed(6));
                                        }
                                    }
                                    setAreaUnit(newUnit);
                                }}
                                className={`p-3 mb-6 border rounded-lg focus:outline-none ${areaUnit === ""
                                    ? "border-red-500"
                                    : "border-gray-300 focus:border-green-500"
                                    }`}
                            >
                                <option value="acres">Acres</option>
                                <option value="hectares">Hectares</option>
                            </select>
                        </div>
                    </div>

                    <div className="flex items-center gap-x-6">
                        <Input
                            label="Address Line"
                            id="line_1"
                            name="line_1"
                            type="text"
                            placeholder="Enter Address"
                        />
                    </div>

                    <div className="flex items-center gap-x-6">
                        <Input label="City" id="city" name="city" type="text" placeholder="Enter City Name" />
                        <div className="w-full mb-5">
                            <Select
                                options={STATES}
                                label="State"
                                id="state"
                                name="state"
                                placeholder="Select state"
                            />
                        </div>
                    </div>

                    <div className="flex items-center gap-x-6">
                        <Input label="Pincode" id="pincode" name="pincode" type="text" />
                    </div>

                    <h3 className="font-semibold text-lg">Geofence Area</h3>
                    {/* @ts-ignore */}
                    <LoadScript googleMapsApiKey={GOOLGLE_MAPS_API_KEY as string}>
                        {/* @ts-ignore */}
                        <GoogleMap
                            center={{ lat: 20, lng: 78 }}
                            zoom={5}
                            mapContainerStyle={{ height: "300px", width: "100%" }}
                            onClick={handleMapClick}
                            options={{
                                zoomControl: true,
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: true,
                                gestureHandling: "greedy",
                            }}
                        >
                            {coordinates.map((c, index) => (
                                // @ts-ignore
                                <Marker key={index} position={{ lat: c.latitude, lng: c.longitude }} />
                            ))}

                            {coordinates.length >= 3 &&
                                // @ts-ignore
                                <Polygon paths={coordinates} options={{ fillColor: "#00FF00", fillOpacity: 0.2, strokeColor: "#00FF00", strokeWeight: 2 }} />}
                        </GoogleMap>
                    </LoadScript>

                    <button type="button" onClick={resetGeofence} className="mt-2 bg-red-500 text-white py-2 px-4 rounded mb-5">Reset Geofence</button>

                    <div className="flex item-center gap-x-6">
                        <Input
                            label="Upload Image 1 File (Image or PDF)"
                            id="image1"
                            name="image1"
                            type="file"
                            accept=".jpg,.png,.jpeg,.webp,.pdf"
                            onChange={(e) => {
                                if (e.target.files) {
                                    setImg1(e.target.files[0]);
                                }
                            }}
                        />
                    </div>

                    <div className="flex item-center gap-x-6">
                        <Input
                            label="Upload Image 2 File (Image or PDF)"
                            id="image2"
                            name="image2"
                            type="file"
                            accept=".jpg,.png,.jpeg,.webp,.pdf"
                            onChange={(e) => {
                                if (e.target.files) {
                                    setImg2(e.target.files[0]);
                                }
                            }}
                        />
                    </div>

                    <div className="flex item-center gap-x-6">
                        <Input
                            label="Upload Image 3 File (Image or PDF)"
                            id="image3"
                            name="image3"
                            type="file"
                            accept=".jpg,.png,.jpeg,.webp,.pdf"
                            onChange={(e) => {
                                if (e.target.files) {
                                    setImg3(e.target.files[0]);
                                }
                            }}
                        />
                    </div>

                    <div className="flex item-center gap-x-6">
                        <Input
                            label="Upload Image 4 File (Image or PDF)"
                            id="image4"
                            name="image4"
                            type="file"
                            accept=".jpg,.png,.jpeg,.webp,.pdf"
                            onChange={(e) => {
                                if (e.target.files) {
                                    setImg4(e.target.files[0]);
                                }
                            }}
                        />
                    </div>

                    <div className="buttons flex items-center w-full justify-center my-4">
                        <SubmitBtn
                            text="Add Site"
                            isSubmitting={isSubmitting}
                            containerClasses="h-full"
                            isSubmittingClasses="lg:mt-6"
                            classes="text-sm lg:p-4 lg:rounded-lg"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default AddNewSiteModal;
