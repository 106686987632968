import { useParams } from 'react-router-dom';
import Sequestration from '../sequestration/sequestration';

const FpoSequestration = () => {
    // const { biomass_batch_id, sequestration_id } = useParams();
    const { id } = useParams();
    return (
        <Sequestration otherUrl={`biomass_batch/sequestration/all?sequestration_id=${id}`} />
        // <Sequestration otherUrl={`biomass_batch/sequestration/${biomass_batch_id}/${sequestration_id}`} />
    )
}

export default FpoSequestration