import { Form, Formik } from "formik";
import { useState } from "react";
import { useQueryClient, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import isAxiosError from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import {
    ERROR,
    SUCCESS,
    AZURE_STORAGE_DIRECTORY,
    AZURE_STORAGE_SUBDIRECTORY
} from "../../types/constants";
import { uploadImage } from "../../http/uploadImage";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import Select from "../FormikComponents/Select";
import { STATES } from "../../types/constants";
import CenterMarker from "../MapsComponents/CenterMarker";
import logo from "../../assets/logo192.png";

interface FileWithPreview extends File {
    preview?: string;
}

interface FormValues {
    pyrolysis_equipment_name: string;
    pyrolysis_technology_model_name: string;
    pyrolysis_technology_manufacturer: string;
    equipment_methane_emissions: number;
    length: number;
    breadth: number;
    height: number;
    address: {
        line_1: string;
        city: string;
        state: string;
        pincode: string;
        lat?: string;
        lng?: string;
    };
    pyro_eq_image: string;
    pt_id: string;
}

interface PyrolysisTechnology {
    pt_id: number;
    name: string;
}

interface PyrolysisTechnologyResponse {
    msg: string;
    data: {
        pyro_techs: PyrolysisTechnology[];
        count: number;
    };
}

const AddSemiIndustrialModal = ({ pt_id }: { pt_id?: string; }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [files, setFiles] = useState<FileWithPreview[]>([]);

    const [lengthUnit, setLengthUnit] = useState<string>("cm");
    const [breadthUnit, setBreadthUnit] = useState<string>("cm");
    const [heightUnit, setHeightUnit] = useState<string>("cm");

    const { data: pyrolysisTechnologies, isLoading } = useQuery<PyrolysisTechnologyResponse>(
        ["getAllPyrolysisTechnologies"],
        async () => {
            const response = await axiosInstance.get("/pyrolysis_technology/all");
            return response.data;
        }
    );

    const initialValues: FormValues = {
        pyrolysis_equipment_name: "",
        pyrolysis_technology_model_name: "",
        pyrolysis_technology_manufacturer: "",
        equipment_methane_emissions: 0,
        length: 0,
        breadth: 0,
        height: 0,
        address: {
            line_1: "",
            city: "",
            state: "",
            pincode: "",
            lat: "",
            lng: ""
        },
        pyro_eq_image: "",
        pt_id: pt_id || ""
    };

    const validationSchema = Yup.object({
        pyrolysis_equipment_name: Yup.string().trim().required("Equipment Name is Required"),
        pyrolysis_technology_model_name: Yup.string().trim().required("Technology Model Name is Required"),
        pyrolysis_technology_manufacturer: Yup.string().trim().required("Manufacturer Name is Required"),
        equipment_methane_emissions: Yup.number().required("Methane Emissions is Required"),
        length: Yup.number().required("Length is Required"),
        breadth: Yup.number().required("Breadth is Required"),
        height: Yup.number().required("Height is Required"),
        address: Yup.object({
            line_1: Yup.string().trim().required("Address Line 1 is Required"),
            city: Yup.string().trim().required("City is Required"),
            state: Yup.string().trim().required("State is Required"),
            pincode: Yup.string().trim().required("Pincode is Required"),
            lat: Yup.string().nullable(),
            lng: Yup.string().nullable()
        }),
        pyro_eq_image: Yup.mixed(),
        pt_id: Yup.string().trim().required("Pyrolysis Technology ID is Required")
    });

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const selectedFiles = Array.from(e.target.files);
            const totalFiles = files.length + selectedFiles.length;

            if (totalFiles > 11) {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: "You can upload a maximum of 11 images",
                    })
                );
                return;
            }

            const newFiles = selectedFiles.map(file => {
                if (file.type.startsWith('image/')) {
                    const fileWithPreview = file as FileWithPreview;
                    fileWithPreview.preview = URL.createObjectURL(file);
                    return fileWithPreview;
                }
                return file;
            });

            setFiles(prev => [...prev, ...newFiles]);
        }
    };

    const removeFile = (index: number) => {
        const updatedFiles = [...files];
        if (updatedFiles[index].preview) {
            URL.revokeObjectURL(updatedFiles[index].preview!);
        }
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
    };

    const handleSubmit = async (values: FormValues) => {
        setIsSubmitting(true);

        if (files.length === 0) {
            dispatch(
                addToast({
                    kind: ERROR,
                    msg: "At least one image is required"
                })
            );
            setIsSubmitting(false);
            return;
        }

        try {
            const uploadPromises = files.map(file =>
                uploadImage(file, AZURE_STORAGE_DIRECTORY.MEASURING_VESSEL, AZURE_STORAGE_SUBDIRECTORY.PROFILE)
            );
            const uploadResults = await Promise.all(uploadPromises);
            const imageUrls = uploadResults.map(result => result.data.publicUrl).filter(Boolean);

            const pyro_eq_images: { [key: string]: string } = {};
            imageUrls.forEach((url, index) => {
                pyro_eq_images[`image${index + 1}`] = url;
            });

            const payload = {
                pyro_eq_name: values.pyrolysis_equipment_name,
                pyro_eq_model_name: values.pyrolysis_technology_model_name,
                pyro_eq_manufacturer: values.pyrolysis_technology_manufacturer,
                pyro_eq_methane_emission: values.equipment_methane_emissions,
                length: values.length,
                breadth: values.breadth,
                height: values.height,
                address: {
                    line_1: values.address.line_1,
                    city: values.address.city,
                    state: values.address.state,
                    pincode: values.address.pincode,
                    ...(values.address.lat !== "" && { lat: values.address.lat }),
                    ...(values.address.lng !== "" && { lng: values.address.lng })
                },
                pyro_eq_images,
                pt_id: Number(values.pt_id)
            };
            const result = await axiosInstance.post("/pyrolysis_technology/semi_Industrial/create", payload);
            if (result) {
                setIsSubmitting(false);
                queryClient.invalidateQueries(["getAllSemiIndustrialDetails"]);
                queryClient.invalidateQueries(["getPyrolysisTechnologyById"]);
                dispatch(
                    addToast({
                        kind: SUCCESS,
                        msg: "Semi-Industrial Equipment Created Successfully!"
                    })
                );
                dispatch(hideModal());
            }
        } catch (error: any) {
            setIsSubmitting(false);

            if (await isAxiosError(error) && error.response) {
                const { msg } = error.response.data;

                switch (error.response.status) {
                    case 400:
                    case 500:
                        dispatch(
                            addToast({
                                kind: ERROR,
                                msg: msg || "An error occurred."
                            })
                        );
                        break;
                    default:
                        dispatch(
                            addToast({
                                kind: ERROR,
                                msg: "Oops, something went wrong"
                            })
                        );
                        break;
                }
            } else {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: "Oops, something went wrong"
                    })
                );
            }
        }
    };

    const ptOptions = (pyrolysisTechnologies?.data?.pyro_techs || []).map((tech) => ({
        value: tech.pt_id.toString(),
        label: `${tech.name} (Pyro Tech Id: ${tech.pt_id})`,
    }));

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
        >
            {({ values, setFieldValue }) => (
                <Form className="px-6 py-4 mt-2" style={{ minWidth: "40vw" }}>
                    <div className="flex justify-center items-center bg-white px-6 py-5">
                        <img src={logo} alt="logo" height={100} width={100} />
                    </div>

                    <div className="flex flex-col gap-y-4">
                        <div className="flex flex-col w-full">
                            <Input
                                label="Equipment Name"
                                id="pyrolysis_equipment_name"
                                name="pyrolysis_equipment_name"
                                type="text"
                                classes="w-full"
                                placeholder="Enter Equipment Name"
                            />

                            <Input
                                label="Technology Model Name"
                                id="pyrolysis_technology_model_name"
                                name="pyrolysis_technology_model_name"
                                type="text"
                                classes="w-full"
                                placeholder="Enter technology model Name"
                            />

                            <Input
                                label="Manufacturer"
                                id="pyrolysis_technology_manufacturer"
                                name="pyrolysis_technology_manufacturer"
                                type="text"
                                classes="w-full"
                                placeholder="Enter Manufacture Name"
                            />

                            <Input
                                label="Methane Emissions"
                                id="equipment_methane_emissions"
                                name="equipment_methane_emissions"
                                type="number"
                                classes="w-full"
                            />
                        </div>

                        <div className="gap-y-4">
                            <div className="flex items-center gap-x-3 w-full">
                                <div className="flex-1">
                                    <Input
                                        label={`Length (in ${lengthUnit})`}
                                        id="length"
                                        name="length"
                                        type="number"
                                        classes="w-full"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-bold mb-1">Length's Unit</label>
                                    <select
                                        value={lengthUnit}
                                        onChange={(e) => {
                                            const newUnit = e.target.value;
                                            const currentValue = parseFloat(values.length.toString());
                                            const conversionToCm: { [key: string]: number } = {
                                                mm: 0.1,
                                                cm: 1,
                                                meter: 100,
                                            };
                                            if (!isNaN(currentValue)) {
                                                const valueInCm = currentValue * conversionToCm[lengthUnit];
                                                const converted = valueInCm / conversionToCm[newUnit];
                                                setFieldValue("length", Number(converted.toFixed(6)));
                                            }
                                            setLengthUnit(newUnit);
                                        }}
                                        className={`p-3 mb-6 border rounded-lg focus:outline-none ${lengthUnit === "" ? "border-red-500" : "border focus:border-green-500"}`}
                                    >
                                        <option value="mm">mm</option>
                                        <option value="cm">cm</option>
                                        <option value="meter">meter</option>
                                    </select>
                                </div>
                            </div>

                            <div className="flex items-center gap-x-3 w-full">
                                <div className="flex-1">
                                    <Input
                                        label={`Breadth (in ${breadthUnit})`}
                                        id="breadth"
                                        name="breadth"
                                        type="number"
                                        classes="w-full"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-bold mb-1">Breadth's Unit</label>
                                    <select
                                        value={breadthUnit}
                                        onChange={(e) => {
                                            const newUnit = e.target.value;
                                            const currentValue = parseFloat(values.breadth.toString());
                                            const conversionToCm: { [key: string]: number } = {
                                                mm: 0.1,
                                                cm: 1,
                                                meter: 100,
                                            };
                                            if (!isNaN(currentValue)) {
                                                const valueInCm = currentValue * conversionToCm[breadthUnit];
                                                const converted = valueInCm / conversionToCm[newUnit];
                                                setFieldValue("breadth", Number(converted.toFixed(6)));
                                            }
                                            setBreadthUnit(newUnit);
                                        }}
                                        className={`p-3 mb-6 border rounded-lg focus:outline-none ${breadthUnit === "" ? "border-red-500" : "border focus:border-green-500"}`}
                                    >
                                        <option value="mm">mm</option>
                                        <option value="cm">cm</option>
                                        <option value="meter">meter</option>
                                    </select>
                                </div>
                            </div>

                            <div className="flex items-center gap-x-3 w-full">
                                <div className="flex-1">
                                    <Input
                                        label={`Height (in ${heightUnit})`}
                                        id="height"
                                        name="height"
                                        type="number"
                                        classes="w-full"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-bold mb-1">Height's Unit</label>
                                    <select
                                        value={heightUnit}
                                        onChange={(e) => {
                                            const newUnit = e.target.value;
                                            const currentValue = parseFloat(values.height.toString());
                                            const conversionToCm: { [key: string]: number } = {
                                                mm: 0.1,
                                                cm: 1,
                                                meter: 100,
                                            };
                                            if (!isNaN(currentValue)) {
                                                const valueInCm = currentValue * conversionToCm[heightUnit];
                                                const converted = valueInCm / conversionToCm[newUnit];
                                                setFieldValue("height", Number(converted.toFixed(6)));
                                            }
                                            setHeightUnit(newUnit);
                                        }}
                                        className={`p-3 mb-6 border rounded-lg focus:outline-none ${heightUnit === "" ? "border-red-500" : "border focus:border-green-500"}`}
                                    >
                                        <option value="mm">mm</option>
                                        <option value="cm">cm</option>
                                        <option value="meter">meter</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col">
                            <Input
                                label="Address Line 1"
                                id="address.line_1"
                                name="address.line_1"
                                type="text"
                                classes="w-full"
                                placeholder="Enter Address Name"
                            />

                            <div className="flex flex-row gap-4">
                                <Input
                                    label="City"
                                    id="address.city"
                                    name="address.city"
                                    type="text"
                                    classes="w-full"
                                    placeholder="Enter City Name"
                                />
                                <div className="w-full">
                                    <Select
                                        options={STATES}
                                        label="State"
                                        id="address.state"
                                        name="address.state"
                                    />
                                </div>
                                <Input
                                    label="Pincode"
                                    id="address.pincode"
                                    name="address.pincode"
                                    type="text"
                                    classes="w-full"
                                />
                            </div>

                            <div className="flex flex-row gap-4">
                                <Input
                                    label="Latitude"
                                    id="address.lat"
                                    name="address.lat"
                                    type="text"
                                    classes="w-full"
                                    readOnly
                                />
                                <Input
                                    label="Longitude"
                                    id="address.lng"
                                    name="address.lng"
                                    type="text"
                                    classes="w-full"
                                    readOnly
                                />
                            </div>

                            <div className="w-full h-96 rounded-xl">
                                <CenterMarker latName="address.lat" lngName="address.lng" />
                            </div>
                        </div>



                        <div className="flex flex-col gap-y-4">
                            <Select
                                label="Pyrolysis Technology ID"
                                id="pt_id"
                                name="pt_id"
                                options={ptOptions}
                                isLoading={isLoading}
                                placeholder="Select Pyrolysis Technology ID"
                                classes="w-full"
                                disabled={pt_id ? true : false}
                            />
                        </div>
                    </div>

                    <div className="flex item-center gap-x-6 mb-5 mt-2">
                        <div className="w-full">
                            <label htmlFor="pyro_eq_image" className="block mb-1 text-gray-700 font-bold text-sm">
                                Upload Equipment Photos (Image or PDF)
                            </label>
                            <input
                                id="pyro_eq_image"
                                name="pyro_eq_image"
                                type="file"
                                accept=".jpg,.png,.jpeg,.webp, .pdf"
                                multiple
                                onChange={handleFileChange}
                                className="w-full p-2 border border-gray-300 rounded-lg"
                            />
                            <p className="text-xs text-gray-500 mt-1">*You can also upload multiple images</p>
                        </div>
                    </div>

                    {files.length > 0 && (
                        <div className="mt-4 mb-6">
                            <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
                                {files.map((file, index) => (
                                    <div key={index} className="relative border rounded-lg p-2">
                                        {file.preview ? (
                                            <img
                                                src={file.preview}
                                                alt={`Preview ${index}`}
                                                className="h-20 w-full object-cover rounded-lg mb-1"
                                            />
                                        ) : (
                                            <div className="h-20 w-full flex items-center justify-center bg-gray-100 rounded-lg mb-1">
                                                <span className="text-xs text-gray-500">File</span>
                                            </div>
                                        )}
                                        <div className="flex justify-between items-center">
                                            <span className="text-xs truncate max-w-[80%]">{`image${index + 1}: ${file.name}`}</span>
                                            <button
                                                type="button"
                                                onClick={() => removeFile(index)}
                                                className="text-red-500 hover:text-red-700 text-xs"
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    <div className="buttons flex items-center w-full justify-center my-6">
                        <SubmitBtn
                            text="Add Equipment"
                            isSubmitting={isSubmitting}
                            containerClasses="h-full"
                            isSubmittingClasses="lg:mt-6"
                            classes="text-sm lg:p-4 lg:rounded-lg"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default AddSemiIndustrialModal;