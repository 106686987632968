import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";

interface FPOObj {
  fpo_name: string;
  fpo_number: number;
  fpo_email: string;
  cin: string;
  gst_number: string;
}

type ViewFPOModalProps = {
  fpo_name: string;
  fpo_number: number;
  fpo_email: string;
  cin: string;
  gst_number: string;
  fpo_id: string;
  addressId: string;
};

const ViewFPOModal: React.FC<ViewFPOModalProps> = ({
  fpo_name,
  fpo_number,
  fpo_email,
  cin,
  gst_number,
  fpo_id,
  addressId,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: FPOObj = {
    fpo_name: fpo_name,
    fpo_number: fpo_number,
    fpo_email: fpo_email,
    cin: cin,
    gst_number: gst_number,
  };

  const validationSchema = Yup.object({
    fpo_name: Yup.string().trim().required("Name is Required"),
    fpo_number: Yup.number()
      .required("Number is Required")
      .min(1000000000, "Number must be at least 10 digits")
      .max(9999999999, "Number must be at most 10 digits"),
    fpo_email: Yup.string().trim()
      .email("Invalid email")
      .required("Email is Required"),
    cin: Yup.string().trim().required("CIN is Required"),
    gst_number: Yup.string().trim().required("GST Number is Required"),
  });

  const handleSubmit = useCallback(
    async (fpo: FPOObj) => {
      setIsSubmitting(true);
      try {
        const result = await axiosInstance.patch(
          `/fpo/${fpo_id}`,
          {
            fpo_name: fpo.fpo_name,
            fpo_number: fpo.fpo_number,
            fpo_email: fpo.fpo_email,
            cin: fpo.cin,
            gst_number: fpo.gst_number,
            addresses: [
              {
                addressId: addressId,
              },
            ],
          },
          { headers: { ContentType: "application/json" } }
        );
        

        queryClient.invalidateQueries(["getProducts"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "FPO Updated Successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setIsSubmitting(false);
    },
    [fpo_id, queryClient, addressId, dispatch]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, handleChange }) => (
        <Form
          className="px-6 py-4 mt-2"
          // style={{
          //   minWidth: "360px",
          //   maxWidth: "760px",
          // }}
          style={{ minWidth: "40vw" }}
        >
          <div className="mb-4">
            <label className="block text-gray-700 font-bold	">Name</label>
            <div className="border px-3 py-2 rounded bg-gray-200">
              {fpo_name}
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-bold	">Contact</label>
            <div className="border px-3 py-2 rounded bg-gray-200">
              {fpo_number}
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-bold	">Email</label>
            <div className="border px-3 py-2 rounded bg-gray-200">
              {fpo_email}
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-bold	">CIN</label>
            <div className="border px-3 py-2 rounded bg-gray-200">{cin}</div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-bold	">GST Number</label>
            <div className="border px-3 py-2 rounded bg-gray-200">
              {gst_number}
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-bold	">FPO ID</label>
            <div className="border px-3 py-2 rounded bg-gray-200">{fpo_id}</div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ViewFPOModal;
