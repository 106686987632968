import { Form, Formik } from "formik";
import { useCallback, useState, useEffect } from "react";
import { useQueryClient, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import Select from "../FormikComponents/Select";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import logo from "../../assets/logo192.png";

interface EditClusterModalObj {
    name: string;
    project_id: string;
}

type EditClusterModalProps = {
    pc_id: string;
    name: string;
    project_id: string;
};

interface Project {
    project_id: string;
    project_name: string;
}

const EditClusterModal: React.FC<EditClusterModalProps> = ({
    pc_id,
    name,
    project_id,
}) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [projects, setProjects] = useState<{ value: string; label: string }[]>([]);

    const { data: projectsData, isLoading: projectsLoading, error: projectsError } = useQuery(
        ["getAllProjects"],
        async () => {
            const response = await axiosInstance.get("/project/all");
            return response.data;
        }
    );

    useEffect(() => {
        if (projectsData && projectsData.data) {
            const projectOptions = projectsData.data.map((project: Project) => ({
                value: project.project_id,
                label: `${project.project_name} (Project Id: ${project.project_id})`,
            }));
            setProjects(projectOptions);
        }
    }, [projectsData]);

    useEffect(() => {
        if (projectsError) {
            dispatch(
                addToast({
                    kind: ERROR,
                    msg: "Failed to load projects. Please try again.",
                })
            );
        }
    }, [projectsError, dispatch]);

    const initialValues: EditClusterModalObj = {
        name,
        project_id,
    };

    const validationSchema = Yup.object({
        name: Yup.string().trim().required("Cluster Name is required"),
        project_id: Yup.string().trim().required("Project ID is required"),
    });

    const handleSubmit = useCallback(
        async (values: EditClusterModalObj) => {
            setIsSubmitting(true);
            try {
                const payload = {
                    name: values.name,
                    project_id: values.project_id,
                };

                await axiosInstance.patch(
                    `/project/cluster/update/${pc_id}`,
                    payload,
                    { headers: { "Content-Type": "application/json" } }
                );

                queryClient.invalidateQueries(["getAllClusters"]);
                dispatch(
                    addToast({
                        kind: SUCCESS,
                        msg: "Cluster Updated Successfully!",
                    })
                );

                dispatch(hideModal());
            } catch (error: any) {
                if (error.response) {
                    const response = error.response;
                    const { msg } = response.data;

                    dispatch(
                        addToast({
                            kind: ERROR,
                            msg: msg || "Oops, something went wrong",
                        })
                    );
                } else {
                    dispatch(
                        addToast({
                            kind: ERROR,
                            msg: `Error: ${error.message}`,
                        })
                    );
                }
            }
            setIsSubmitting(false);
        },
        [dispatch, pc_id, queryClient]
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {() => (
                <Form className="px-6 py-4 mt-2" style={{ minWidth: "40vw" }}>
                    <div className="flex justify-center items-center bg-white px-6 py-5">
                        <img src={logo} alt="logo" height={100} width={100} />
                    </div>

                    <div className="flex item-center gap-x-6">
                        <Input
                            label="Cluster Name"
                            id="name"
                            name="name"
                            type="text"
                        />
                    </div>

                    <div className="flex item-center gap-x-6">
                        {projectsLoading ? (
                            <div className="w-full">
                                <label className="block text-sm font-medium text-gray-700">Project</label>
                                <div className="mt-1 py-2">Loading projects...</div>
                            </div>
                        ) : (
                            <Select
                                options={projects}
                                label="Select Project"
                                id="project_id"
                                name="project_id"
                                placeholder="Select a project"
                                classes="w-full"
                            />
                        )}
                    </div>

                    <div className="buttons flex items-center w-full justify-center my-4 mt-10">
                        <SubmitBtn
                            text="Save"
                            isSubmitting={isSubmitting}
                            containerClasses="h-full"
                            isSubmittingClasses="lg:mt-6"
                            classes="text-sm lg:p-4 lg:rounded-lg"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default EditClusterModal;
