import { useParams } from 'react-router-dom';
import MoistureMeter from '../MoistureMeter/MoistureMeter';

const PyroMoistureMeter = () => {
    const { id } = useParams();
    return (
        <MoistureMeter otherUrl={`/pyrolysis_technology/moisture_meter/all?pt_id=${id}`} />
    )
}

export default PyroMoistureMeter