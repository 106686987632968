import { Form, Formik } from "formik";
import { useState } from "react";
import { useQueryClient, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { uploadImage } from "../../http/uploadImage";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import {
    AZURE_STORAGE_DIRECTORY,
    AZURE_STORAGE_SUBDIRECTORY,
    ERROR,
    SUCCESS,
} from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import Select from "../FormikComponents/Select";
import logo from "../../assets/logo192.png";

interface FileWithPreview extends File {
    preview?: string;
}

interface FormValues {
    supplier_name: string;
    manufacturer_model: string;
    product_unique_number: string;
    temperature_range_measured: string;
    iot_enabled: boolean;
    pt_id: string;
}

interface PyrolysisTechnology {
    pt_id: number;
    name: string;
}

interface PyrolysisTechnologyResponse {
    msg: string;
    data: {
        pyro_techs: PyrolysisTechnology[];
        count: number;
    };
}

const AddMoistureMeterModal = ({ otherUrl }: { otherUrl?: string }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [files, setFiles] = useState<FileWithPreview[]>([]);

    const { data: pyrolysisTechnologies, isLoading } = useQuery<PyrolysisTechnologyResponse>(
        ["getAllPyrolysisTechnologies"],
        async () => {
            const response = await axiosInstance.get("/pyrolysis_technology/all");
            return response.data;
        }
    );

    const initialValues: FormValues = {
        supplier_name: "",
        manufacturer_model: "",
        product_unique_number: "",
        temperature_range_measured: "",
        iot_enabled: false,
        pt_id: otherUrl?.split("=")[1] || "",
    };

    const validationSchema = Yup.object({
        supplier_name: Yup.string().trim().required("Supplier Name is Required"),
        manufacturer_model: Yup.string().trim().required("Manufacturer Model is Required"),
        product_unique_number: Yup.string().trim().required("Product Unique Number is Required"),
        temperature_range_measured: Yup.string().trim().required("Temperature Range is Required"),
        pt_id: Yup.string().trim().required("PT ID is Required"),
    });

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const selectedFiles = Array.from(e.target.files);
            const totalFiles = files.length + selectedFiles.length;

            if (totalFiles > 11) {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: "You can upload a maximum of 11 images",
                    })
                );
                return;
            }

            const newFiles = selectedFiles.map((file) => {
                if (file.type.startsWith("image/")) {
                    const fileWithPreview = file as FileWithPreview;
                    fileWithPreview.preview = URL.createObjectURL(file);
                    return fileWithPreview;
                }
                return file;
            });

            setFiles((prev) => [...prev, ...newFiles]);
        }
    };

    const removeFile = (index: number) => {
        const updatedFiles = [...files];
        if (updatedFiles[index].preview) {
            URL.revokeObjectURL(updatedFiles[index].preview!);
        }
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
    };

    const handleSubmit = async (values: FormValues) => {
        if (files.length === 0) {
            dispatch(
                addToast({
                    kind: ERROR,
                    msg: "At least one file is required",
                })
            );
            return;
        }

        setIsSubmitting(true);
        try {
            const uploadPromises = files.map((file) =>
                uploadImage(file, AZURE_STORAGE_DIRECTORY.PUBLIC, AZURE_STORAGE_SUBDIRECTORY.PROFILE)
            );
            const uploadResults = await Promise.all(uploadPromises);
            const imageUrls = uploadResults.map((result) => result.data.publicUrl).filter(Boolean);

            const meter_images: { [key: string]: string } = {};
            imageUrls.forEach((url, index) => {
                meter_images[`image${index + 1}`] = url;
            });

            const obj = {
                supplier_name: values.supplier_name,
                manufacturer_model: values.manufacturer_model,
                product_unique_number: values.product_unique_number,
                temperature_range_measured: values.temperature_range_measured,
                iot_enabled: values.iot_enabled,
                meter_images, // Changed from meter_image
                pt_id: Number(values.pt_id),
            };

            const result = await axiosInstance.post("/pyrolysis_technology/moisture_meter/create", obj);
            if (result) {
                setIsSubmitting(false);
                queryClient.invalidateQueries(["getAllMoistureMeters"]);
                dispatch(
                    addToast({
                        kind: SUCCESS,
                        msg: "Moisture Meter Added Successfully!",
                    })
                );
                dispatch(hideModal());
            }
        } catch (error: any) {
            setIsSubmitting(false);
            if (error.response) {
                const response = error.response;
                if (
                    response.data.error &&
                    (response.data.error.includes("duplicate key value violates unique constraint") ||
                        response.data.error.includes("moisture_meter_product_unique_number_unique"))
                ) {
                    dispatch(
                        addToast({
                            kind: ERROR,
                            msg: "Product Unique Number already exists.",
                        })
                    );
                } else {
                    const { msg } = response.data;
                    switch (response.status) {
                        case 400:
                        case 500:
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: msg,
                                })
                            );
                            break;
                        default:
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: "Oops, something went wrong",
                                })
                            );
                            break;
                    }
                }
            } else if (error.request) {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: "Oops, something went wrong",
                    })
                );
            } else {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: `Error: ${error.message}`,
                    })
                );
            }
        }
        setIsSubmitting(false);
    };

    const ptOptions = (pyrolysisTechnologies?.data?.pyro_techs || []).map((tech) => ({
        value: tech.pt_id.toString(),
        label: `${tech.name} (Pyro Tech Id: ${tech.pt_id})`,
    }));

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
        >
            <Form className="px-6 py-4 mt-2" style={{ minWidth: "40vw" }}>
                <div className="flex justify-center items-center bg-white px-6 py-5">
                    <img src={logo} alt="logo" height={100} width={100} />
                </div>

                <div className="flex items-center gap-x-6">
                    <Input
                        label="Supplier Name"
                        id="supplier_name"
                        name="supplier_name"
                        type="text"
                        placeholder="Enter Supplier Name"
                    />
                </div>

                <div className="flex items-center gap-x-6">
                    <Input
                        label="Manufacturer Model"
                        id="manufacturer_model"
                        name="manufacturer_model"
                        type="text"
                        placeholder="Enter Manufacturer Model"
                    />
                </div>

                <div className="flex items-center gap-x-6">
                    <Input
                        label="Product Unique Number"
                        id="product_unique_number"
                        name="product_unique_number"
                        type="text"
                        placeholder="Enter Product Unique Number"
                    />
                </div>

                <div className="flex items-center gap-x-6">
                    <Input
                        label="Temperature Range Measured"
                        id="temperature_range_measured"
                        name="temperature_range_measured"
                        type="text"
                        placeholder="Enter Temp Range Measured"
                    />
                </div>

                <div className="flex items-center gap-x-6 ">
                    <Select
                        label="Pyrolysis Technology Id"
                        id="pt_id"
                        name="pt_id"
                        options={ptOptions}
                        isLoading={isLoading}
                        disabled={otherUrl ? true : false}
                        placeholder="Select Pyrolysis Technology Id"
                        classes="w-full"
                    />
                </div>

                <div className="flex items-center my-5">
                    <input
                        type="checkbox"
                        id="iot_enabled"
                        name="iot_enabled"
                        className="mr-2 w-5 h-5 text-green-500 focus:ring-green-500"
                    />
                    <label htmlFor="iot_enabled" className="font-bold text-gray-700">
                        IoT Enabled
                    </label>
                </div>

                <div className="flex items-center gap-x-6">
                    <div className="w-full">
                        <Input
                            label="Upload Moisture Meter Files (Image or PDF)"
                            id="moisture_meter_file"
                            name="moisture_meter_file"
                            type="file"
                            accept=".jpg,.png,.jpeg,.webp,.pdf"
                            multiple
                            onChange={handleFileChange}
                        />
                        <p className="text-xs text-gray-500 mt-1">*You can upload multiple files</p>
                    </div>
                </div>

                {files.length > 0 && (
                    <div className="mt-4 mb-6">
                        <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
                            {files.map((file, index) => (
                                <div key={index} className="relative border rounded-lg p-2">
                                    {file.preview ? (
                                        <img
                                            src={file.preview}
                                            alt={`Preview ${index}`}
                                            className="h-20 w-full object-cover rounded-lg mb-1"
                                        />
                                    ) : (
                                        <div className="h-20 w-full flex items-center justify-center bg-gray-100 rounded-lg mb-1">
                                            <span className="text-xs text-gray-500">File</span>
                                        </div>
                                    )}
                                    <div className="flex justify-between items-center">
                                        <span className="text-xs truncate max-w-[80%]">{`file${index + 1}: ${file.name}`}</span>
                                        <button
                                            type="button"
                                            onClick={() => removeFile(index)}
                                            className="text-red-500 hover:text-red-700 text-xs"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div className="buttons flex items-center w-full justify-center my-4">
                    <SubmitBtn
                        text="Add Moisture Meter"
                        isSubmitting={isSubmitting}
                        containerClasses="h-full"
                        isSubmittingClasses="lg:mt-6"
                        classes="text-sm lg:p-4 lg:rounded-lg"
                    />
                </div>
            </Form>
        </Formik>
    );
};

export default AddMoistureMeterModal;