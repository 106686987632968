import { useState } from "react";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import { FaEye } from "react-icons/fa";
import {
    ADD_RETENTION_BUCKET_MODAL,
    // EDIT_RETENTION_BUCKET_MODAL,
} from "../../types/constants";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MdAdd, MdLinkOff } from "react-icons/md";
import { useQuery, useQueryClient } from "react-query";
interface RetentionBucketObj {
    retention_bucket_id: string;
    volume: string;
    date_of_start: string;
    date_of_closing: string;
    bucket_image: string;
    created_at: string;
    updated_at: string;
}

const RetentionBucket = ({ otherUrl }: { otherUrl?: string }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { id } = useParams();
    const pt_id = id ? parseInt(id, 10) : null;

    const handleUnlink = async (retention_bucket_id: string) => {
        if (!pt_id) return;
        try {
            await axiosInstance.patch("/pyrolysis_technology/assets/link-unlink", {
                pt_id: pt_id,
                type: "unlink",
                retention_bucket_id: retention_bucket_id,
            });
            toast.success("Retention Bucket Unlinked Successfully");
            queryClient.invalidateQueries(["getAllRetentionBuckets"]);
        } catch (error: any) {
            toast.error(error.response?.data?.error || "Failed to unlink Retention Bucket");
        }
    };

    const RETENTION_BUCKET_COLUMNS = [
        {
            Header: "Sr. No",
            accessor: "serialNumber",
            Cell: ({ row }: any) => row.index + 1,
        },
        {
            Header: "Retention Bucket ID",
            accessor: "retention_bucket_id",
            Cell: ({ value }: any) => value || "Not Available",
        },
        {
            Header: "Volume (Litre)",
            accessor: "volume",
            Cell: ({ value }: any) => value || "Not Available",
        },
        {
            Header: "Date of Start",
            accessor: "date_of_start",
            Cell: ({ value }: any) => {
                if (!value) return "Invalid Date";
                const date = new Date(value);
                return date.toLocaleDateString("en-GB");
            },
        },
        {
            Header: "Date of Closing",
            accessor: "date_of_closing",
            Cell: ({ value }: any) => {
                if (!value) return "Invalid Date";
                const date = new Date(value);
                return date.toLocaleDateString("en-GB");
            },
        },
        {
            Header: "Bucket Image",
            accessor: "bucket_image",
            Cell: ({ value }: { value: string }) =>
                value ? (
                    <a
                        href={value}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-green-600 text-white px-2 py-3 inline-flex items-center justify-center"
                        title="View Image"
                    >
                        <FaEye />
                    </a>
                ) : (
                    <span className="text-gray-500">Not Available</span>
                ),
        },
        {
            Header: "Created At",
            accessor: "created_at",
            Cell: ({ value }: any) =>
                value ? new Date(value).toLocaleString() : "Invalid Date",
        },
        // {
        //     Header: "Edit",
        //     accessor: "edit",
        //     Cell: ({ row }: any) => (
        //         <button
        //             onClick={() => {
        //                 dispatch(
        //                     showModal({
        //                         modalType: EDIT_RETENTION_BUCKET_MODAL,
        //                         modalTitle: "Edit RetentionBucket",
        //                         modalProps: {
        //                             retention_bucket_id: row?.original.retention_bucket_id,
        //                             volume: row?.original.volume,
        //                             date_of_start: row?.original.date_of_start,
        //                             date_of_closing: row?.original.date_of_closing,
        //                             bucket_image: row?.original.bucket_image,
        //                         },
        //                     })
        //                 );
        //             }}
        //             className="bg-green-600 text-white px-2 py-3"
        //         >
        //             <FaPen />
        //         </button>
        //     ),
        // },
        {
            Header: "Unlink",
            accessor: "unlink",
            Cell: ({ row }: any) => (
                <button onClick={() => handleUnlink(row.original.retention_bucket_id)} className="text-red-600" disabled={!pt_id}>
                    <MdLinkOff size={24} />
                </button>
            ),
        },
    ];

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState(null);
    const [desc, setDesc] = useState(true);
    const [q, setQ] = useState("");

    const [selectedRows, setSelectedRows] = useState([]);

    const getAllRetentionBuckets = async () => {
        const { data } = await axiosInstance.get(
            otherUrl || `/pyrolysis_technology/retention_bucket/all`
        );
        return data?.data?.retention_buckets || [];
    };

    const { data, error, isLoading } = useQuery<RetentionBucketObj[]>(
        ["getAllRetentionBuckets", pageIndex, cPageSize, q],
        getAllRetentionBuckets
    );

    let component = null;

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {(error as Error).message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance
                excludeColumnSearch={[]}
                tableData={data || []}
                columnName={RETENTION_BUCKET_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1}
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        );
    }

    return (
        <div className="all-retention-buckets">
            <header className="flex items-center justify-between mb-8">
                <div className="left">
                    <SearchBox
                        searchValue={q}
                        setSearchValue={setQ}
                        handleSearch={() => { }}
                    />
                    {selectedRows.length > 0 && (
                        <span className="btn-primary flex items-center mt-5 w-48">
                            Selected Row: {selectedRows.length}
                        </span>
                    )}
                </div>
                <div className="right">
                    <button
                        className="btn-primary flex items-center"
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_RETENTION_BUCKET_MODAL,
                                    modalTitle: "Add Retention Bucket",
                                    modalProps: { otherUrl },
                                })
                            );
                        }}
                    >
                        <span className="mr-1">Add</span> <MdAdd size={17} />
                    </button>
                </div>
            </header>
            {component !== null && component}
        </div>
    );
};

export default RetentionBucket;
