import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { FaPen, FaEye } from "react-icons/fa";
// import { NavLink } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
  ADD_NEW_PROJECT_MODAL,
  VIEW_ARTISAN_OR_PROJECT_MODAL,
} from "../../types/constants";
import { NavLink } from "react-router-dom";

interface UserObj {
  id: string;
  name: string;
  createdAt: string;
}

const Projects = () => {
  const ALL_USERS_COLUMNS = [
    {
      Header: "Sr. No",
      accessor: "serialNumber",
      Cell: ({ row }: any) => {
        return row.index + 1;
      },
    },
    {
      Header: "Project Id ",
      accessor: "project_id",
      Cell: ({ row }: any) => {
        const project_id = row?.original?.project_id || "Not Available";
        return (
          <NavLink
            to={`${row?.original?.project_id || "#"}`}
            className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
            title={`View Project Details`}
          >
            {project_id}
          </NavLink>
        );
      },
    },
    {
      Header: "Project Name",
      accessor: "project_name",
      Cell: ({ row }: any) => {
        const project_name = row?.original?.project_name || "Not Available";
        return (
          <NavLink
            to={`${row?.original?.project_id || "#"}`}
            className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
            title={`View Project Details`}
          >
            {project_name}
          </NavLink>
        );
      },
    },
    // {
    //   Header: "Person Name",
    //   accessor: "project_contact_details.person_name",
    // Cell: ({ row }: any) => {
    //   const person_name = row?.original?.project_contact_details?.person_name || "Not Available";
    //   return (
    //     <NavLink
    //       to={`${row?.original?.project_id || "#"}`}
    //       className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
    //       title={`View Project Details`}
    //     >
    //       {person_name}
    //     </NavLink>
    //   );
    // },
    // },
    // {
    //   Header: "Person Contact",
    //   accessor: "project_contact_details.person_phone",
    //   Cell: ({ row }: any) => row?.original?.Project?.person_phone || "Not Available",
    // },

    {
      Header: "Project State",
      accessor: "project_location",
      Cell: ({ row }: any) => row?.original?.project_location || "Not Available",
    },
    {
      Header: "Project Total Approx. Area (Acres) ",
      accessor: "project_area",
      Cell: ({ row }: any) => row?.original?.biochar_capacity_per_anum || "Not Available",
    },
    {
      Header: "Biochar Capacity Per Anum (Tonnes)",
      accessor: "biochar_capacity_per_anum",
      Cell: ({ row }: any) => row?.original?.biochar_capacity_per_anum || "Not Available",
    },
    // {
    //   Header: "Project Start Date ",
    //   accessor: "start_date",
    //   Cell: ({ value }: any) => (value ? new Date(value).getUTCDate() : "Invalid Date"),
    // },
    {
      Header: "Project Start Date",
      accessor: "start_date",
      Cell: ({ value }: any) => {
        if (!value) return "Invalid Date";
        const date = new Date(value);
        return date.toLocaleDateString("en-GB"); // en-GB format gives dd/mm/yyyy
      },
    },
    // {
    //   Header: "Project Type",
    //   accessor: "project_type",
    //   Cell: ({ value }: any) => {
    //     return value === "soil_pit" ? "Soil Pit" : "Pyrolysis Kiln"
    //   },
    // },
    // {
    //   Header: "Created At",
    //   accessor: "created_at",
    //   Cell: ({ value }: any) => (value ? new Date(value).toLocaleString() : "Invalid Date"),
    // },
    // {
    //   Header: "Artisans list",
    //   accessor: "project_id",
    //   Cell: ({ value }: any) => {
    //     return (
    //       <button
    //         className="bg-green-600 text-white px-2 py-3"
    //         onClick={() => {
    //           dispatch(
    //             showModal({
    //               modalType: VIEW_ARTISAN_OR_PROJECT_MODAL,
    //               modalTitle: "View Artisans",
    //               modalProps: {
    //                 isArtisanList: true,
    //                 id: value,
    //               },
    //             })
    //           );
    //         }}
    //       >
    //         <FaEye />
    //       </button>
    //     );
    //   },
    // },
    {
      Header: "Update",
      accessor: "updated_at",
      Cell: ({ row }: any) => {
        return (
          <button
            className="bg-green-600 text-white px-2 py-3"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_NEW_PROJECT_MODAL,
                  modalTitle: "Add Project",
                  modalProps: {
                    isUpdate: true,
                    data: row.original,
                  },
                })
              );
            }}
          >
            <FaPen />
          </button>
        );
      },
    },

  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getAllProjectsDetails = async () => {
    const { data } = await axiosInstance.get(`/project/all`);

    return data?.data;
  };

  const { data, error, isLoading } = useQuery<UserObj[]>(
    ["getAllProjectsDetails", pageIndex, cPageSize, cSortBy, desc, q],
    getAllProjectsDetails
  );

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {(error as Error).message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance excludeColumnSearch={[]}
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">

        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => { }}
          />

          {selectedRows.length > 0 && (
            <span className="btn-primary flex items-center mt-5 w-48">
              Selected Row : {selectedRows.length}
            </span>
          )}

        </div>

        <div className="right">
          <button
            className="btn-primary flex items-center"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_NEW_PROJECT_MODAL,
                  modalTitle: "Add Project",
                })
              );
            }}
          >
            <span className="mr-1">Add</span> <MdAdd size={17} />
          </button>
        </div>

      </header>

      {component !== null && component}
    </div>
  );
};

export default Projects;
