import { useParams } from 'react-router-dom';
import SamplingBeaker from '../samplingBeaker/samplingBeaker';

const PyroSamplingBeaker = () => {
    const { id } = useParams();
    return (
        <SamplingBeaker otherUrl={`/pyrolysis_technology/sampling_beaker/all?pt_id=${id}`} />
    )
}

export default PyroSamplingBeaker