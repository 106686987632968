import { useState } from "react";
import { useQuery } from "react-query";
import { FaEye } from "react-icons/fa";
import { useParams } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import { FiX } from "react-icons/fi";

interface SellerDetails {
    artisans: any;
    id: string;
    name: string;
    gstin: string;
    fcoLicenseNumber: string | null;
    msmeLicenseNumber: string | null;
    companyCategory: string;
    verified: boolean;
    kycValid: boolean;
    createdAt: string;
    updatedAt: string;
    companyName: string;
    productCategories: string[];
    type: string;
    internal_fpo_user: boolean;
    fpo_id: string | null;
    biomass_suppliers: BiomassSupplier[];
    notes: Note[];
}

interface BiomassSupplier {
    biomass_supplier_id: string;
    owner_name: string;
    company_address: string;
    company_email: string;
    company_mobile: string;
    owner_kyc_doc_url: string;
    onsite_person_name: string;
    onsite_person_mobile: string;
    site_image_url: string;
    biomass_type: string;
    createdAt: string;
    updatedAt: string;
    status: string;
    seller_id: string;
}

interface Note {
    note_id: number;
    comment: string;
    biomass_type: string;
    vehicle_type: string;
    vehicle_number: string;
    biomass_quantity: string;
    biomass_price: number;
    user_id: string | null;
    admin_id: string | null;
    created_at: string;
    updated_at: string;
    fpo_id: string | null;
    artisan_id: string | null;
    seller_id: string;
}

const AllUsersDetails = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSite, setIsSite] = useState(false);
    const { id } = useParams();

    const getSeller = async () => {
        const { data } = await axiosInstance.get(`/admin/seller/details/${id}`);
        return data.data;
    };

    const { data: seller, error, isLoading } = useQuery<SellerDetails>(
        ["getSeller", id],
        getSeller
    );

    const formatDateTime = (dateString?: string) => {
        if (!dateString) return { date: "Not Available", time: "Not Available" };
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString("en-GB");
        const formattedTime = date.toLocaleTimeString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        }).toUpperCase();
        return { date: formattedDate, time: formattedTime };
    };

    if (isLoading) return <p className="mt-6 text-center">Loading...</p>;
    if (error)
        return (
            <p className="mt-6 text-center text-red-600">
                Error: {(error as Error).message}
            </p>
        );

    return (
        <div className="p-6">
            {seller ? (
                <div className="space-y-10">
                    <div>
                        <div className="grid grid-cols-2 gap-4 mb-10">
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Seller ID</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={seller.id || "Not Available"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Name</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={(seller.name || "Not Available").replace(/\b\w/g, (char) => char.toUpperCase())}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Type</label>
                                <input
                                    type="text"
                                    className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 outline-none border-none
                                    ${seller.type === "verified" ? "text-green-600 bg-green-100" : "text-red-700 bg-red-100"}`}
                                    value={seller.type ? seller.type.charAt(0).toUpperCase() + seller.type.slice(1).toLowerCase() : "Not Available"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Verified</label>
                                <input
                                    type="text"
                                    className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 outline-none border-none ${seller.verified
                                        ? "text-green-700 bg-green-100"
                                        : "text-red-700 bg-red-100"
                                        }`}
                                    value={seller.verified ? "True" : "False"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Company Name</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={(seller.companyName || "Not Available").replace(/\b\w/g, (char) => char.toUpperCase())}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Company Category</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={(seller.companyCategory || "Not Available").replace(/\b\w/g, (char) => char.toUpperCase())}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">GSTIN</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={seller.gstin || "Not Available"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">FCO License Number</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={seller.fcoLicenseNumber || "Not Available"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">MSME License Number</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={seller.msmeLicenseNumber || "Not Available"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Product Categories</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        seller.productCategories
                                            ?.map((cat) => cat.replace(/\b\w/g, (char) => char.toUpperCase()))
                                            .join(", ") || "Not Available"
                                    }
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Internal FPO User</label>
                                <input
                                    type="text"
                                    className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 outline-none border-none ${seller.internal_fpo_user
                                        ? "text-green-700 bg-green-100"
                                        : "text-red-700 bg-red-100"
                                        }`}
                                    value={seller.internal_fpo_user ? "Yes" : "No"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">FPO ID</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={seller.fpo_id || "Not Available"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">KYC Valid</label>
                                <input
                                    type="text"
                                    className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 outline-none border-none ${seller.kycValid
                                        ? "text-green-700 bg-green-100"
                                        : "text-red-700 bg-red-100"
                                        }`}
                                    value={seller.kycValid ? "True" : "False"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Created At</label>
                                <div className="flex space-x-4">
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={formatDateTime(seller.createdAt).date}
                                        readOnly
                                    />
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={formatDateTime(seller.createdAt).time}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Updated At</label>
                                <div className="flex space-x-4">
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={formatDateTime(seller.updatedAt).date}
                                        readOnly
                                    />
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={formatDateTime(seller.updatedAt).time}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                    {seller.biomass_suppliers?.length > 0 && (
                        <div>
                            <h2 className="text-xl font-bold mb-4 text-green-600 flex items-center w-full py-5">
                                <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                                <span className="px-4 text-green-600">Biomass Suppliers</span>
                                <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                            </h2>
                            {seller.biomass_suppliers.map((supplier, index) => (
                                <div key={index} className="mb-10">
                                    <div className="grid grid-cols-2 gap-4">
                                        {/* <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Biomass Supplier ID</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={supplier.biomass_supplier_id || "Not Available"}
                                                readOnly
                                            />
                                        </div> */}
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Owner Name</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={(supplier.owner_name || "Not Available").replace(/\b\w/g, (char) => char.toUpperCase())}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Company Address</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={(supplier.company_address ? supplier.company_address.replace(/\b\w/g, (char) => char.toUpperCase()) : "Not Available")}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Company Email</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={supplier.company_email || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Company Mobile</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={supplier.company_mobile || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Owner KYC Doc URL</label>
                                            <div className="relative mt-1">
                                                <div className="absolute inset-y-0 left-3 flex items-center">
                                                    <button
                                                        className="text-green-600 hover:text-green-800"
                                                        onClick={() => supplier.owner_kyc_doc_url && setIsOpen(true)}
                                                    >
                                                        <FaEye size={20} />
                                                    </button>
                                                </div>
                                                <div
                                                    className="block w-full rounded-2xl shadow-sm bg-green-100 text-green-600 px-12 py-3 outline-none border-none text-center cursor-pointer"
                                                    onClick={() => supplier.owner_kyc_doc_url && setIsOpen(true)}
                                                >
                                                    <span className="font-bold text-lg hover:text-green-800">
                                                        {supplier.owner_kyc_doc_url ? "Open Image" : "Not Available"}
                                                    </span>
                                                    <span className="text-sm text-green-600 font-bold">
                                                        {supplier.createdAt
                                                            ? ` (${new Date(supplier.createdAt).toLocaleDateString("en-GB")}, ${new Date(supplier.createdAt).toLocaleTimeString("en-GB", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                                second: "2-digit",
                                                                hour12: true,
                                                            }).toUpperCase()})`
                                                            : " (Not Available, Not Available)"}
                                                    </span>
                                                </div>
                                                {/* <div className="absolute inset-y-0 right-3 flex items-center">
                                                    <a
                                                        href={supplier.owner_kyc_doc_url || "#"}
                                                        download="product_image.jpg"
                                                        className="text-green-600 hover:text-green-800"
                                                        onClick={(e) => {
                                                            if (!supplier.owner_kyc_doc_url) {
                                                                e.preventDefault();
                                                                alert("No image available to download.");
                                                            }
                                                        }}
                                                    >
                                                        <FiDownload size={20} />
                                                    </a>
                                                </div> */}
                                            </div>
                                            {isOpen && (
                                                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
                                                    <div className="relative bg-white rounded-xl shadow-lg max-w-xl w-full">
                                                        <button
                                                            className="absolute top-2 right-2 bg-green-600 text-white p-2 rounded-full hover:bg-green-800"
                                                            onClick={() => setIsOpen(false)}
                                                        >
                                                            <FiX size={20} />
                                                        </button>
                                                        <img
                                                            src={supplier.owner_kyc_doc_url || "default-image.jpg"}
                                                            alt="Product"
                                                            className="w-full h-auto rounded-lg"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Onsite Person Name</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={(supplier.onsite_person_name ? supplier.onsite_person_name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : "Not Available")}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Onsite Person Mobile</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={supplier.onsite_person_mobile || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Site Image URL</label>
                                            <div className="relative mt-1">
                                                <div className="absolute inset-y-0 left-3 flex items-center">
                                                    <button
                                                        className="text-green-600 hover:text-green-800"
                                                        onClick={() => supplier.site_image_url && setIsSite(true)}
                                                    >
                                                        <FaEye size={20} />
                                                    </button>
                                                </div>
                                                <div
                                                    className="block w-full rounded-2xl shadow-sm bg-green-100 text-green-600 px-12 py-3 outline-none border-none text-center cursor-pointer"
                                                    onClick={() => supplier.site_image_url && setIsSite(true)}
                                                >
                                                    <span className="font-bold text-lg hover:text-green-800">
                                                        {supplier.site_image_url ? "Open Image" : "Not Available"}
                                                    </span>
                                                    <span className="text-sm text-green-600 font-bold">
                                                        {supplier.createdAt
                                                            ? ` (${new Date(supplier.createdAt).toLocaleDateString("en-GB")}, ${new Date(supplier.createdAt).toLocaleTimeString("en-GB", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                                second: "2-digit",
                                                                hour12: true,
                                                            }).toUpperCase()})`
                                                            : " Not Available, Not Available)"}
                                                    </span>
                                                </div>
                                                {/* <div className="absolute inset-y-0 right-3 flex items-center">
                                                    <a
                                                        href={supplier.site_image_url || "#"}
                                                        download="product_image.jpg"
                                                        className="text-green-600 hover:text-green-800"
                                                        onClick={(e) => {
                                                            if (!supplier.site_image_url) {
                                                                e.preventDefault();
                                                                alert("No image available to download.");
                                                            }
                                                        }}
                                                    >
                                                        <FiDownload size={20} />
                                                    </a>
                                                </div> */}
                                            </div>
                                            {isSite && (
                                                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
                                                    <div className="relative bg-white rounded-xl shadow-lg max-w-xl w-full">
                                                        <button
                                                            className="absolute top-2 right-2 bg-green-600 text-white p-2 rounded-full hover:bg-green-800"
                                                            onClick={() => setIsSite(false)}
                                                        >
                                                            <FiX size={20} />
                                                        </button>
                                                        <img
                                                            src={supplier.site_image_url || "default-image.jpg"}
                                                            alt="Product"
                                                            className="w-full h-auto rounded-lg"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Biomass Type</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={(supplier.biomass_type ? supplier.biomass_type.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : "Not Available")}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Status</label>
                                            <input
                                                type="text"
                                                className={
                                                    `mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 outline-none border-none 
                                                ${supplier.status === 'approved' ? 'bg-green-100 text-green-700' : ''} 
                                                ${supplier.status === 'pending' ? 'bg-red-100 text-red-700' : ''}`
                                                }
                                                value={
                                                    supplier.status
                                                        ? supplier.status.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
                                                        : "Not Available"
                                                }
                                                readOnly
                                            />
                                        </div>
                                        {/* <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Created At</label>
                                            <div className="flex space-x-4">
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={formatDateTime(supplier.createdAt).date}
                                                    readOnly
                                                />
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={formatDateTime(supplier.createdAt).time}
                                                    readOnly
                                                />
                                            </div>
                                        </div> */}
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Updated At</label>
                                            <div className="flex space-x-4">
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={formatDateTime(supplier.updatedAt).date}
                                                    readOnly
                                                />
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={formatDateTime(supplier.updatedAt).time}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}


                    {seller.notes?.length > 0 && (
                        <div>
                            <h2 className="text-xl font-bold mb-4 text-green-600 flex items-center w-full py-5">
                                <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                                <span className="px-4 text-green-600">Notes</span>
                                <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                            </h2>
                            {seller.notes.map((note, index) => (
                                <div key={index} className="mb-10">
                                    <div className="grid grid-cols-2 gap-4">
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Artisan ID</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={note.artisan_id || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Biomass Price</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={note.biomass_price ?? "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Biomass Quantity</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={note.biomass_quantity ?? "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Biomass Type</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={(note.biomass_type || "Not Available").replace(/\b\w/g, (char) => char.toUpperCase())}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Comment</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={note.comment || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Created At</label>
                                            <div className="flex space-x-4">
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={formatDateTime(note.created_at).date}
                                                    readOnly
                                                />
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={formatDateTime(note.created_at).time}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">FPO ID</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={note.fpo_id || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Note ID</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={note.note_id || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Updated At</label>
                                            <div className="flex space-x-4">
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={formatDateTime(note.updated_at).date}
                                                    readOnly
                                                />
                                                <input
                                                    type="text"
                                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                    value={formatDateTime(note.updated_at).time}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Vehicle Number</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={note.vehicle_number || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                        <div className="pr-10">
                                            <label className="block text-md font-semibold text-gray-700">Vehicle Type</label>
                                            <input
                                                type="text"
                                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                                value={note.vehicle_type || "Not Available"}
                                                readOnly
                                            />
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ) : (
                <p className="mt-6 text-center">No seller data found</p>
            )}
        </div>
    );
};

export default AllUsersDetails;