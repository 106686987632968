import { useState } from "react";
import { NavLink } from "react-router-dom";
import { MdUnarchive } from "react-icons/md";
import { useQuery, useQueryClient } from "react-query";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { toast } from "react-toastify";

interface ArchivedCategoriesObj {
  id: string;
  category: string;
  is_biomass: boolean;
}

const ArchivedCategories = () => {

  const queryClient = useQueryClient();

  const handleUnarchive = async (id: string) => {
    try {
      const response = await axiosInstance.patch(
        `/admin/productcategories/archive/${id}`,
        { is_archived: false }
      );
      console.log(response);
      toast.success("Category Unarchived Successfully");
      queryClient.invalidateQueries(["getArchivedCategories"]);
    } catch (error: any) {
      console.error(error);
      toast.error(error.response?.data?.error || "Failed to unarchive Category");
    }
  };

  const ALL_USERS_COLUMNS = [
    {
      Header: "Sr. No",
      accessor: "serialNumber",
      Cell: ({ row }: any) => {
        return row.index + 1;
      },
    },
    // {
    //   Header: "Category ID",
    //   accessor: "id",
    //   Cell: ({ row }: any) => {
    //     const id = row?.original.id;
    //     return (
    //       <NavLink
    //         to={`${row?.original.id}`}
    //         className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
    //         title={`View Categories Details`}
    //       >
    //         {id}
    //       </NavLink>
    //     );
    //   },
    // },
    {
      Header: "Title",
      accessor: "category",
      Cell: ({ row }: any) => {
        const category = row?.original.category || "Not Available";
        return category.trim() === "" ? (
          <p>Not Available</p>
        ) : (
          <span>{category}</span>
        );
      },
    },

    {
      Header: "Is Biomass",
      accessor: "is_biomass",
      Cell: ({ value }: any) => {
        return value ? "Yes" : "No";
      },
    },
    // {
    //   Header: "Edit",
    //   accessor: "edit",
    //   Cell: ({ row }: any) => {
    //     console.table(row.original);
    //     return (
    //       <button
    //         onClick={() => {
    //           dispatch(
    //             showModal({
    //               modalType: EDIT_ALL_CATEGORIES_MODAL,
    //               modalTitle: "Edit Category",
    //               modalProps: {
    //                 categoryId: row?.original?.id,
    //                 category: row?.original?.category,
    //                 addressId: row?.original?.addressId,
    //               },
    //             })
    //           );
    //         }}
    //         className="bg-green-600 text-white px-2 py-3"
    //       >
    //         <FaPen />
    //       </button>
    //     );
    //   },
    // },
    {
      Header: "Unarchive",
      accessor: "unarchive",
      Cell: ({ row }: any) => {
        return (
          <button
            onClick={() => handleUnarchive(row?.original.id)}
            className="text-green-600"
          >
            <MdUnarchive size={36} />
          </button>
        );
      },
    },
  ];

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getArchivedCategories = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(
      `/admin/productcategories/all?is_archived=true`
    );

    return data?.data;
  };

  const { data, error, isLoading } = useQuery<
    ArchivedCategoriesObj[] | undefined,
    any,
    ArchivedCategoriesObj[],
    any[]
  >(["getArchivedCategories", pageIndex, cPageSize, cSortBy, desc, q], getArchivedCategories);

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance excludeColumnSearch={[]}
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1}
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => { }}
          />

          {selectedRows.length > 0 && (
            <span className="btn-primary flex items-center mt-5 w-48">
              Selected Row : {selectedRows.length}
            </span>
          )}

        </div>

        {/* <div className="right">
          <button
            className="btn-primary flex items-center"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_CATEGORY_MODAL,
                  modalTitle: "Add a Product Category",
                })
              );
            }}
          >
            <span className="mr-1">Add</span> <MdAdd size={17} />
          </button>
        </div> */}
      </header>

      {component !== null && component}
    </div>
  );
};

export default ArchivedCategories;
