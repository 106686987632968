import { useState } from "react";
import { useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import { FaEye } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { useParams } from "react-router-dom";

interface SellerDetails {
    length: number;
    name: string;
    gstin: string;
    fcoLicenseNumber: string | null;
    msmeLicenseNumber: string | null;
    companyCategory: string;
    verified: boolean;
    kycValid: boolean;
    createdAt: string;
    updatedAt: string;
    userId: string;
    companyName: string;
    productCategories: string[];
    type: string;
    internal_fpo_user: boolean;
    fpo_id: string | null;
    id: string;
}

interface BuyerDetails {
    name: string;
    user_id: string;
    email: string;
    phone: string;
    order_id: string;
    payment_status: string;
    total_amount: number;
    quantity: number;
    order_date: string;
    order_created_at: string;
    user_created_at: string;
}

interface ProductDetails {
    id: string;
    name: string;
    product_category_id: string;
    packaging_type: string;
    actual_price: number;
    discounted_price: number;
    unit: string;
    image_url: string;
    product_description: string;
    verified: boolean;
    created_at: string;
    updated_at: string;
    seller_id: string;
    package_size_grams: number;
    is_active: boolean;
    seller_details: SellerDetails;
    buyer_details: BuyerDetails[];
}

const AllProductsDetails = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { id } = useParams();

    const getProducts = async () => {
        try {
            const response = await axiosInstance.get(`admin/product/details/${id}`);
            return response.data.data;
        } catch (error) {
            console.error("Error fetching product details:", error);
            throw error;
        }
    };

    const { data, error, isLoading } = useQuery<ProductDetails>(
        ["productDetails", id],
        getProducts
    );

    if (isLoading) return <p className="mt-6 text-center">Loading...</p>;
    if (error) return <p className="mt-6 text-center text-red-600">Error: {(error as Error).message}</p>;

    return (
        <div className="p-6">
            {data ? (
                <div className="space-y-10">
                    <div>
                        <div className="grid grid-cols-2 gap-4 mb-10">
                            {/* <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">ID</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data.id || "N/A"}
                                    readOnly
                                />
                            </div> */}
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Name</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data.name || "N/A"}
                                    readOnly
                                />
                            </div>
                            {/* <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Product Category ID</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data.product_category_id || "N/A"}
                                    readOnly
                                />
                            </div> */}
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Product Description</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        data.product_description
                                            ? data.product_description.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase())
                                            : "N/A"
                                    }
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Actual Price</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data.actual_price ?? "N/A"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Discounted Price</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data.discounted_price ?? "N/A"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Packaging Type</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        data.packaging_type
                                            ? data.packaging_type.charAt(0).toUpperCase() + data.packaging_type.slice(1).toLowerCase()
                                            : "N/A"
                                    }
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Unit</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data.unit || "N/A"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Package Size (grams)</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data.package_size_grams ?? "N/A"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md  text-gray-700">Verified</label>
                                <input
                                    type="text"
                                    className={`mt-1 block font-bold w-full rounded-2xl shadow-sm px-5 py-3 outline-none border-none ${data.verified ? "text-green-700 bg-green-100" : "text-red-700 bg-red-100"
                                        }`}
                                    value={data.verified ? "True" : "False"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md text-gray-700">Is Active</label>
                                <input
                                    type="text"
                                    className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 outline-none border-none ${data.is_active ? "text-green-700 bg-green-100" : "text-red-700 bg-red-100"
                                        }`}
                                    value={data.is_active ? "True" : "False"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Seller ID</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data.seller_id || "N/A"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Product Image</label>
                                <div className="relative mt-1">
                                    <div className="absolute inset-y-0 left-3 flex items-center">
                                        <button
                                            className="text-green-600 hover:text-green-800"
                                            onClick={() => data?.image_url && setIsOpen(true)}
                                        >
                                            <FaEye size={20} />
                                        </button>
                                    </div>
                                    <div
                                        className="block w-full rounded-2xl shadow-sm bg-green-100 text-green-600 px-12 py-3 outline-none border-none text-center cursor-pointer"
                                        onClick={() => data?.image_url && setIsOpen(true)}
                                    >
                                        <span className="font-bold text-lg hover:text-green-800">
                                            {data?.image_url ? "Open Image" : "Not Available"}
                                        </span>
                                        <span className="text-sm text-green-600 font-bold">
                                            {data?.created_at
                                                ? ` (${new Date(data.created_at).toLocaleDateString("en-GB")}, ${new Date(data.created_at).toLocaleTimeString("en-GB", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    second: "2-digit",
                                                    hour12: true,
                                                }).toUpperCase()})`
                                                : " (N/A, N/A)"}
                                        </span>
                                    </div>
                                    {/* <div className="absolute inset-y-0 right-3 flex items-center">
                                        <a
                                            href={data?.image_url || "#"}
                                            download="product_image.jpg"
                                            className="text-green-600 hover:text-green-800"
                                            onClick={(e) => {
                                                if (!data?.image_url) {
                                                    e.preventDefault();
                                                    alert("No image available to download.");
                                                }
                                            }}
                                        >
                                            <FiDownload size={20} />
                                        </a>
                                    </div> */}
                                </div>
                                {isOpen && (
                                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
                                        <div className="relative bg-white rounded-xl shadow-lg max-w-xl w-full">
                                            <button
                                                className="absolute top-2 right-2 bg-green-600 text-white p-2 rounded-full hover:bg-green-800"
                                                onClick={() => setIsOpen(false)}
                                            >
                                                <FiX size={20} />
                                            </button>
                                            <img
                                                src={data?.image_url || "default-image.jpg"}
                                                alt="Product"
                                                className="w-full h-auto rounded-lg"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Created At</label>
                                <div className="flex space-x-4">
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.created_at ? new Date(data.created_at).toLocaleDateString("en-GB") : "N/A"}
                                        readOnly
                                    />
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            data.created_at
                                                ? new Date(data.created_at).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: true }).toUpperCase()
                                                : "N/A"
                                        }
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Updated At</label>
                                <div className="flex space-x-4">
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.updated_at ? new Date(data.updated_at).toLocaleDateString("en-GB") : "N/A"}
                                        readOnly
                                    />
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            data.updated_at
                                                ? new Date(data.updated_at).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: true }).toUpperCase()
                                                : "N/A"
                                        }
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Seller Details Section */}
                    <div>
                        <h2 className="text-xl font-bold mb-4 text-green-600 flex items-center w-full py-5">
                            <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                            <span className="px-4 text-green-600">Seller Details</span>
                            <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                        </h2>
                        <div className="grid grid-cols-2 gap-4 mb-10">
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Seller ID</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data.seller_details.id || "N/A"}
                                    readOnly
                                />
                            </div>
                            {/* <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">User ID</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data.seller_details.userId || "N/A"}
                                    readOnly
                                />
                            </div> */}
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Name</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data.seller_details.name || "N/A"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Company Name</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        data.seller_details.companyName
                                            ? data.seller_details.companyName.charAt(0).toUpperCase() + data.seller_details.companyName.slice(1)
                                            : "N/A"
                                    }
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Company Category</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        data.seller_details.companyCategory
                                            ? data.seller_details.companyCategory.charAt(0).toUpperCase() + data.seller_details.companyCategory.slice(1).toLowerCase()
                                            : "N/A"
                                    }
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md text-gray-700">Type</label>
                                <input
                                    type="text"
                                    className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 outline-none border-none ${data.seller_details.verified ? "text-green-700 bg-green-100" : "text-red-700 bg-red-100"
                                        }`}
                                    value={
                                        data.seller_details.type
                                            ? data.seller_details.type.charAt(0).toUpperCase() + data.seller_details.type.slice(1).toLowerCase()
                                            : "N/A"
                                    }
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">GSTIN</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data.seller_details.gstin || "N/A"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">FCO License Number</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data.seller_details.fcoLicenseNumber || "Not Available"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">MSME License Number</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data.seller_details.msmeLicenseNumber || "Not Available"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Product Categories</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        data.seller_details.productCategories?.map((category) => category.charAt(0).toUpperCase() + category.slice(1).toLowerCase()).join(", ") ||
                                        "N/A"
                                    }
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-normal text-gray-700">Verified</label>
                                <input
                                    type="text"
                                    className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 font-normal outline-none border-none ${data.seller_details.verified ? "text-green-700 bg-green-100" : "text-red-700 bg-red-100"
                                        }`}
                                    value={data.seller_details.verified ? "True" : "False"}
                                    readOnly
                                />
                            </div>

                            <div className="pr-10">
                                <label className="block text-md  text-gray-700">KYC Valid</label>
                                <input
                                    type="text"
                                    className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 outline-none border-none ${data.seller_details.kycValid ? "text-green-700 bg-green-100" : "text-red-700 bg-red-100"
                                        }`}
                                    value={data.seller_details.kycValid ? "True" : "False"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Internal FPO User</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data.seller_details.internal_fpo_user ? "Yes" : "No"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">FPO ID</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data.seller_details.fpo_id || "Not Available"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Created At</label>
                                <div className="flex space-x-4">
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.seller_details.createdAt ? new Date(data.seller_details.createdAt).toLocaleDateString("en-GB") : "N/A"}
                                        readOnly
                                    />
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            data.seller_details.createdAt
                                                ? new Date(data.seller_details.createdAt).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: true }).toUpperCase()
                                                : "N/A"
                                        }
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Updated At</label>
                                <div className="flex space-x-4">
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.seller_details.updatedAt ? new Date(data.seller_details.updatedAt).toLocaleDateString("en-GB") : "N/A"}
                                        readOnly
                                    />
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            data.seller_details.updatedAt
                                                ? new Date(data.seller_details.updatedAt).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: true }).toUpperCase()
                                                : "N/A"
                                        }
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Buyer Details Section */}
                    {data.buyer_details?.length > 0 && (
                        <div>
                            <h2 className="text-xl font-bold mb-4 text-green-600 flex items-center w-full py-5">
                                <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                                <span className="px-4 text-green-600">Buyer Details</span>
                                <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                            </h2>
                            <div className="grid grid-cols-2 gap-4 mb-10">
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Buyer Name</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.buyer_details[0].name || "N/A"}
                                        readOnly
                                    />
                                </div>
                                {/* <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">User ID</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.buyer_details[0].user_id || "N/A"}
                                        readOnly
                                    />
                                </div> */}
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Email</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.buyer_details[0].email || "N/A"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Phone</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.buyer_details[0].phone || "N/A"}
                                        readOnly
                                    />
                                </div>
                                {/* <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Order ID</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.buyer_details[0].order_id || "N/A"}
                                        readOnly
                                    />
                                </div> */}
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Payment Status</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.buyer_details[0].payment_status || "N/A"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Total Amount</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.buyer_details[0].total_amount ?? "N/A"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Quantity</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data.buyer_details[0].quantity ?? "N/A"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Order Date</label>
                                    <div className="flex space-x-4">
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={data.buyer_details[0].order_date ? new Date(data.buyer_details[0].order_date).toLocaleDateString("en-GB") : "N/A"}
                                            readOnly
                                        />
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={
                                                data.buyer_details[0].order_date
                                                    ? new Date(data.buyer_details[0].order_date).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: true }).toUpperCase()
                                                    : "N/A"
                                            }
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Order Created At</label>
                                    <div className="flex space-x-4">
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={
                                                data.buyer_details[0].order_created_at
                                                    ? new Date(data.buyer_details[0].order_created_at).toLocaleDateString("en-GB")
                                                    : "N/A"
                                            }
                                            readOnly
                                        />
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={
                                                data.buyer_details[0].order_created_at
                                                    ? new Date(data.buyer_details[0].order_created_at).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: true }).toUpperCase()
                                                    : "N/A"
                                            }
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">User Created At</label>
                                    <div className="flex space-x-4">
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={
                                                data.buyer_details[0].user_created_at ? new Date(data.buyer_details[0].user_created_at).toLocaleDateString("en-GB") : "N/A"
                                            }
                                            readOnly
                                        />
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={
                                                data.buyer_details[0].user_created_at
                                                    ? new Date(data.buyer_details[0].user_created_at).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: true }).toUpperCase()
                                                    : "N/A"
                                            }
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <p className="mt-6 text-center">No product data found</p>
            )}
        </div>
    );
};

export default AllProductsDetails;