import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
    ADD_CLUSTER_MODAL,
    ASSIGN_FPO_TO_CLUSTER_MODAL,
    EDIT_CLUSTER_MODAL,
} from "../../types/constants";
import { FaPen } from "react-icons/fa";
import { NavLink } from "react-router-dom";

interface ClusterObj {
    pc_id: string;
    name: string;
    project_id: string | null;
    created_at: string;
    updated_at: string;
}

const Cluster = () => {
    const ALL_USERS_COLUMNS = [
        {
            Header: "Sr. No",
            accessor: "serialNumber",
            Cell: ({ row }: any) => row.index + 1,
        },
        {
            Header: "Cluster ID",
            accessor: "pc_id",
            Cell: ({ row }: any) => {
                const pc_id = row?.original.pc_id;
                return (
                    <NavLink
                        to={`${row?.original.pc_id}`}
                        className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
                        title={`View Cluster Details`}
                    >
                        {pc_id}
                    </NavLink>
                );
            },
        },
        {
            Header: "Cluster Name",
            accessor: "name",
            Cell: ({ row }: any) => {
                const name = row?.original.name;
                return (
                    <NavLink
                        to={`${row?.original.pc_id}`}
                        className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
                        title={`View Cluster Details`}
                    >
                        {name}
                    </NavLink>
                );
            },
        },
        {
            Header: "Project ID",
            accessor: "project_id",
            Cell: ({ value }: any) => value || "Not Available",
        },
        {
            Header: "Assign FPO / FPO ID",
            accessor: "fpo_id",
            Cell: ({ value, row }: any) => {
                return value != null ? (
                    value
                ) : (
                    <button
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ASSIGN_FPO_TO_CLUSTER_MODAL,
                                    modalTitle: "Assign FPO To Cluster",
                                    modalProps: {
                                        id: row.original.pc_id
                                    },
                                })
                            );
                        }}
                        className="bg-green-600 text-white px-2 py-3"
                    >
                        Assign FPO
                    </button>
                );
            },
        },
        {
            Header: "Created At",
            accessor: "created_at",
            Cell: ({ value }: any) => (value ? new Date(value).toLocaleString() : "Invalid Date"),
        },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: ({ row }: any) => {
                return (
                    <button
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: EDIT_CLUSTER_MODAL,
                                    modalTitle: "Edit Cluster",
                                    modalProps: {
                                        pc_id: row?.original.pc_id,
                                        name: row?.original.name,
                                        project_id: row?.original.project_id,
                                    },
                                })
                            );
                        }}
                        className="bg-green-600 text-white px-2 py-3"
                    >
                        <FaPen />
                    </button>
                );
            },
        },
    ];

    const dispatch = useDispatch();

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState(null);
    const [desc, setDesc] = useState(true);
    const [q, setQ] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);

    const getAllClusterDetails = async () => {
        const { data } = await axiosInstance.get(`/project/cluster/all`);
        return data?.data?.clusters || [];
    };

    const { data, error, isLoading } = useQuery<ClusterObj[]>(
        ["getAllClusterDetails", pageIndex, cPageSize, q],
        getAllClusterDetails
    );

    let component = null;

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {(error as Error).message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance
                excludeColumnSearch={[]}
                tableData={data || []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1}
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        );
    }

    return (
        <div className="all-users">
            <header className="flex items-center justify-between mb-8">
                <div className="left">
                    <SearchBox
                        searchValue={q}
                        setSearchValue={setQ}
                        handleSearch={() => { }}
                    />
                    {selectedRows.length > 0 && (
                        <span className="btn-primary flex items-center mt-5 w-48">
                            Selected Row : {selectedRows.length}
                        </span>
                    )}
                </div>
                <div className="right">
                    <button
                        className="btn-primary flex items-center"
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_CLUSTER_MODAL,
                                    modalTitle: "Add Cluster",
                                })
                            );
                        }}
                    >
                        <span className="mr-1">Add</span> <MdAdd size={17} />
                    </button>
                </div>
            </header>
            {component !== null && component}
        </div>
    );
};

export default Cluster;
