import { useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import { useParams } from "react-router-dom";
import { FaEye } from "react-icons/fa";

interface MeasuringVesselDetailObj {
    measuring_vessel_id: string;
    vessel_name: string;
    manufacturer_name: string;
    vessel_type: string;
    volume: string;
    vessel_image: string;
    created_by_fpo_id: string | null;
    created_at: string;
    updated_at: string;
}

const MeasuringVesselDetail = () => {
    const { id } = useParams<{ id: string }>();

    const getMeasuringVesselDetail = async () => {
        try {
            const response = await axiosInstance.get(`/pyrolysis_technology/measuring_vessel/${id}`);
            return response.data;
        } catch (error) {
            console.error("Error fetching measuring vessel details:", error);
            throw error;
        }
    };

    const { data, error, isLoading } = useQuery<{ data: MeasuringVesselDetailObj }>(
        ["getMeasuringVesselDetail", id],
        getMeasuringVesselDetail,
        {
            refetchOnWindowFocus: false,
            retry: 1,
        }
    );

    if (isLoading) return <p className="mt-6 text-center">Loading...</p>;
    if (error) return <p className="mt-6 text-center text-red-600">Error: {(error as Error).message}</p>;

    const vesselData = data?.data;

    return (
        <div className="p-6">
            {vesselData ? (
                <div className="space-y-10">
                    <div className="grid grid-cols-2 gap-6 mb-10">
                        {/* Vessel Details */}
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Vessel ID</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={vesselData.measuring_vessel_id || "N/A"}
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Vessel Name</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={
                                    vesselData.vessel_name && vesselData.vessel_name !== " "
                                        ? vesselData.vessel_name.replace(/\b\w/g, (char) => char.toUpperCase())
                                        : "N/A"
                                }
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Manufacturer Name</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={
                                    vesselData.manufacturer_name && vesselData.manufacturer_name !== " "
                                        ? vesselData.manufacturer_name.replace(/\b\w/g, (char) => char.toUpperCase())
                                        : "N/A"
                                }

                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Vessel Type</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={
                                    vesselData.vessel_type && vesselData.vessel_type !== " "
                                        ? vesselData.vessel_type.replace(/\b\w/g, (char) => char.toUpperCase())
                                        : "N/A"
                                }

                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Volume (L/ml/m³)</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={vesselData.volume && vesselData.volume !== " " ? vesselData.volume : "0"}
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Created By FPO ID</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={vesselData.created_by_fpo_id || "Not Available"}
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-md font-semibold text-gray-700">Vessel Image</label>
                            <div className="mt-1">
                                {vesselData.vessel_image ? (
                                    <a
                                        href={vesselData.vessel_image}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center w-full rounded-2xl shadow-sm bg-green-100 px-5 py-3 hover:bg-green-200 transition-colors font-bold"
                                    >
                                        <FaEye className="w-5 h-5 mr-2 text-green-600" />
                                        <span className="flex-1 text-center text-green-600">View Image</span>
                                    </a>
                                ) : (
                                    <div className="w-full rounded-2xl shadow-sm bg-red-100 px-5 py-3 text-center">
                                        Not Available
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Timestamps */}
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Created At</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={vesselData.created_at ? new Date(vesselData.created_at).toLocaleDateString("en-GB") : "N/A"}
                                    readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        vesselData.created_at
                                            ? new Date(vesselData.created_at).toLocaleTimeString("en-GB", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: true
                                            }).toUpperCase()
                                            : "N/A"
                                    }
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Updated At</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={vesselData.updated_at ? new Date(vesselData.updated_at).toLocaleDateString("en-GB") : "N/A"}
                                    readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        vesselData.updated_at
                                            ? new Date(vesselData.updated_at).toLocaleTimeString("en-GB", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: true
                                            }).toUpperCase()
                                            : "N/A"
                                    }
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <p className="mt-6 text-center">No measuring vessel found with ID: {id}</p>
            )}
        </div>
    );
};

export default MeasuringVesselDetail;