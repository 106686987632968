import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { useParams } from "react-router-dom";

interface Cluster {
    pc_id: string;
    name: string;
    project_id: string;
    created_at: string;
    updated_at: string;
}

interface ProjectDetailsObj {
    project_id: string;
    project_name: string;
    project_contact_details: {
        person_phone: string;
        person_name: string;
    };
    project_location: string;
    created_at: string;
    updated_at: string;
    project_area: string | null;
    biochar_capacity_per_anum: string | null;
    clusters: Cluster[];
}

const ProjectDetails = () => {
    const { id } = useParams();

    const getCategories = async () => {
        try {
            const { data } = await axiosInstance.get(`/project/${id}`);
            return [data?.data];
        } catch (error) {
            console.error("Error fetching project details:", error);
            throw error;
        }
    };

    const { data, error, isLoading } = useQuery<ProjectDetailsObj[], any>(["getCategories", id], getCategories);

    if (isLoading) return <p className="mt-6 text-center">Loading...</p>;
    if (error) return <p className="mt-6 text-center text-red-600">Error: {error.message}</p>;

    return (
        <div className="all-users p-6">
            {data && data[0] ? (
                <div className="space-y-10">
                    {/* Project Details Section */}
                    <div>
                        <div className="grid grid-cols-2 gap-4 mb-10">
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Project ID</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data[0].project_id || "Not Available"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Project Name</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        (data[0].project_name || "Not Available")
                                            .replace(/\b\w/g, (char) => char.toUpperCase())
                                    }
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Contact Person</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data[0].project_contact_details?.person_name || "Not Available"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Phone Number</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={data[0].project_contact_details?.person_phone || "Not Available"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Location</label>
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={
                                        (data[0].project_location || "Not Available")
                                            .replace(/\b\w/g, (char) => char.toUpperCase())
                                    }
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Project Area</label>
                                <input
                                    type="text"
                                    className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 outline-none border-none 
                                    ${data[0].project_area ? "bg-gray-200 text-black" : "bg-red-100 text-red-600"}`}
                                    value={data[0].project_area || "Not Available"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Biochar Capacity / Anum</label>
                                <input
                                    type="text"
                                    className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 outline-none border-none 
                                    ${data[0].biochar_capacity_per_anum ? "bg-gray-200 text-black" : "bg-red-100 text-red-600"}`}
                                    value={data[0].biochar_capacity_per_anum || "Not Available"}
                                    readOnly
                                />
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Created At</label>
                                <div className="flex space-x-4">
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data[0].created_at ? new Date(data[0].created_at).toLocaleDateString("en-GB") : "Not Available"}
                                        readOnly
                                    />
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            data[0].created_at
                                                ? new Date(data[0].created_at).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: true }).toUpperCase()
                                                : "Not Available"
                                        }
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Updated At</label>
                                <div className="flex space-x-4">
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data[0].updated_at ? new Date(data[0].updated_at).toLocaleDateString("en-GB") : "Not Available"}
                                        readOnly
                                    />
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            data[0].updated_at
                                                ? new Date(data[0].updated_at).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: true }).toUpperCase()
                                                : "Not Available"
                                        }
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {data[0].clusters && data[0].clusters.length > 0 && data[0].clusters[0] !== null && (
                        <div>
                            <h2 className="text-xl font-bold mb-4 text-green-600 flex items-center w-full py-5">
                                <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                                <span className="px-4 text-green-600">Cluster Details</span>
                                <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                            </h2>
                            <div className="grid grid-cols-2 gap-4 mb-10">
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Cluster ID</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data[0].clusters[0].pc_id || "Not Available"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Project ID</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={data[0].clusters[0].project_id || "Not Available"}
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Cluster Name</label>
                                    <input
                                        type="text"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value={
                                            (data[0].clusters[0].name || "Not Available")
                                                .replace(/\b\w/g, (char) => char.toUpperCase())
                                        }
                                        readOnly
                                    />
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Cluster Created At</label>
                                    <div className="flex space-x-4">
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={
                                                data[0].clusters[0].created_at
                                                    ? new Date(data[0].clusters[0].created_at).toLocaleDateString("en-GB")
                                                    : "Not Available"
                                            }
                                            readOnly
                                        />
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={
                                                data[0].clusters[0].created_at
                                                    ? new Date(data[0].clusters[0].created_at)
                                                        .toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: true })
                                                        .toUpperCase()
                                                    : "Not Available"
                                            }
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="pr-10">
                                    <label className="block text-md font-semibold text-gray-700">Cluster Updated At</label>
                                    <div className="flex space-x-4">
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={
                                                data[0].clusters[0].updated_at
                                                    ? new Date(data[0].clusters[0].updated_at).toLocaleDateString("en-GB")
                                                    : "Not Available"
                                            }
                                            readOnly
                                        />
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                            value={
                                                data[0].clusters[0].updated_at
                                                    ? new Date(data[0].clusters[0].updated_at)
                                                        .toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: true })
                                                        .toUpperCase()
                                                    : "Not Available"
                                            }
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <p className="mt-6 text-center">No project data found</p>
            )}
        </div>
    );
};

export default ProjectDetails;