import { useState } from "react";
import { useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import { FaEye } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { FiDownload, FiX } from "react-icons/fi";
import { FaDownload } from "react-icons/fa6";
import JSZip from "jszip";
import { saveAs } from "file-saver";

interface AllBatchDetailsObj {
    artisan: {
        createdAt: string;
        artisan_id: string;
        name: string;
        address: string;
        company_email: string;
        company_mobile: string;
        site_image_url: string;
        artisan_kyc_doc_url: string;
        seller_id: string;
        company_registration_id: string;
    };
    preparation_id: any;
    pyrolysis_id: any;
    biochar_id: any;
    status: string;
    biomass_batch_id: string;
    name: string;
    companyName: string;
    createdAt: string;
    updatedAt?: string;
    biomass_preparation?: any;
    pyrolysis_detail?: any;
    biochar_detail?: any;
    sequestration?: any;
    artisan_id?: any;
    sequestration_id?: any;
}

const AllBatchDetails = () => {
    const { id } = useParams();
    const [q, setQ] = useState("");
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const getSingleBiomassBatch = async () => {
        try {
            const { data } = await axiosInstance.get(`/biomass_batch/admin/${id}`);
            return Array.isArray(data?.data) ? data?.data : [data?.data];
        } catch (error) {
            console.error("Error fetching batch details:", error);
            throw error;
        }
    };

    const { data, error, isLoading } = useQuery<AllBatchDetailsObj[], any>(
        ["getSingleBiomassBatch", q],
        getSingleBiomassBatch
    );

    const formatDate = (dateString: string | undefined) => {
        if (!dateString) return { date: "N/A", time: "N/A" };
        const date = new Date(dateString);
        return {
            date: date.toLocaleDateString("en-GB"),
            time: date.toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true
            }).toUpperCase(),
        };
    };

    const handleDownload = async (url: string, label: string) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const extension = blob.type.split('/')[1] || 'jpg';
            const filename = `${label.toLowerCase().replace(/ /g, '_')}.${extension}`;
            const urlObject = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = urlObject;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(urlObject);
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };

    const handleDownloadAll = async () => {
        // Check if data exists and is a non-empty array
        if (!data || data.length === 0) return;

        const zip = new JSZip();

        // Collect all image fields from all batches into a single array
        const allImages = data.flatMap(batch => [
            { label: "Biomass Photo", url: batch.biomass_preparation?.biomass_photo, batchId: batch.biomass_batch_id },
            { label: "Moisture Meter Photo", url: batch.biomass_preparation?.moisture_meter_photo, batchId: batch.biomass_batch_id },
            { label: "Initial Stage Image", url: batch.pyrolysis_detail?.initial_stage_image, batchId: batch.biomass_batch_id },
            { label: "Ongoing Stage Image", url: batch.pyrolysis_detail?.ongoing_stage_image, batchId: batch.biomass_batch_id },
            { label: "Final Stage Image", url: batch.pyrolysis_detail?.final_stage_image, batchId: batch.biomass_batch_id },
            { label: "Biochar Image", url: batch.biochar_detail?.biochar_image, batchId: batch.biomass_batch_id },
            { label: "Sampling Image", url: batch.biochar_detail?.sampling_image, batchId: batch.biomass_batch_id },
            { label: "Retention Bucket Image", url: batch.biochar_detail?.retention_bucket_img, batchId: batch.biomass_batch_id },
            { label: "Mixing Image", url: batch.sequestration?.mixing_image, batchId: batch.biomass_batch_id },
            { label: "Final Image", url: batch.sequestration?.final_image, batchId: batch.biomass_batch_id },
            { label: "Site Image", url: batch.artisan?.site_image_url, batchId: batch.biomass_batch_id },
            { label: "Artisan KYC Document", url: batch.artisan?.artisan_kyc_doc_url, batchId: batch.biomass_batch_id },
        ]).filter(image =>
            image.url &&
            image.url !== "Not Available" &&
            typeof image.url === "string"
        );

        // Check if there are any images to download
        if (allImages.length === 0) {
            alert("No images available to download.");
            return;
        }

        // Download all images concurrently and add them to the zip
        await Promise.all(
            allImages.map(async (image) => {
                try {
                    const response = await fetch(image.url);
                    if (!response.ok) throw new Error(`Failed to fetch ${image.url}`);
                    const blob = await response.blob();
                    const contentType = response.headers.get("content-type");
                    let extension = "jpg"; // Default extension
                    if (contentType) {
                        if (contentType.includes("jpeg")) extension = "jpg";
                        else if (contentType.includes("png")) extension = "png";
                        else if (contentType.includes("gif")) extension = "gif";
                        // Add more content types as needed
                    }
                    const filename = `batch_${image.batchId}/${image.label.replace(/ /g, "_")}.${extension}`;
                    zip.file(filename, blob);
                } catch (error) {
                    console.error(`Error downloading ${image.label} for batch ${image.batchId}:`, error);
                }
            })
        );

        // Generate and save the zip file
        zip.generateAsync({ type: "blob" }).then((content:any) => {
            saveAs(content, "all_batches_images.zip");
        });
    };

    const ImageViewer = ({ imageUrl, onClose }: { imageUrl: string; onClose: () => void }) => (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <div className="relative bg-white rounded-xl shadow-lg max-w-xl w-full">
                <button
                    className="absolute top-2 right-2 bg-green-600 text-white p-2 rounded-full hover:bg-green-800"
                    onClick={onClose}
                >
                    <FiX size={20} />
                </button>
                <img src={imageUrl} alt="View" className="w-full h-auto rounded-lg" />
            </div>
        </div>
    );

    const ImageField = ({ label, imageUrl, createdAt }: { label: string; imageUrl: string | undefined; createdAt: string | undefined }) => (
        <div className="pr-10">
            <label className="block text-md font-semibold text-gray-700">
                {label}
            </label>
            <div className="relative mt-1">
                {imageUrl && (
                    <div className="absolute inset-y-0 left-3 flex items-center">
                        <button
                            className="text-green-600 hover:text-green-800"
                            onClick={() => setSelectedImage(imageUrl)}
                        >
                            <FaEye size={20} />
                        </button>
                    </div>
                )}
                <div
                    className={`block w-full rounded-2xl shadow-sm px-12 py-3 outline-none border-none text-center cursor-pointer ${imageUrl ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'}`}
                    onClick={() => imageUrl && setSelectedImage(imageUrl)}
                >
                    <span className="font-bold text-lg hover:text-green-800">
                        {imageUrl ? "Open Image" : "Not Available"}
                    </span>
                    {imageUrl && (
                        <span className="text-sm text-green-600 font-bold">
                            {createdAt ? ` (${formatDate(createdAt).date}, ${formatDate(createdAt).time})` : " (N/A, N/A)"}
                        </span>
                    )}
                </div>
                {imageUrl && (
                    <div className="absolute inset-y-0 right-3 flex items-center">
                        <button
                            onClick={() => handleDownload(imageUrl, label)}
                            className="text-green-600 hover:text-green-800"
                        >
                            <FiDownload size={20} />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );

    const BatchCard = ({ batch }: { batch: AllBatchDetailsObj }) => (
        <div>
            <div className="grid grid-cols-2 gap-4 mb-10">
                <div className="pr-10">
                    <label className="block text-md font-semibold text-gray-700">Biomass Batch ID</label>
                    <input
                        type="text"
                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                        value={batch.biomass_batch_id || "Not Available"} readOnly
                    />
                </div>
                <div className="pr-10">
                    <label className="block text-md font-semibold text-gray-700">Status</label>
                    <input
                        type="text"
                        className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 outline-none border-none 
                        ${batch?.status === 'submitted' ? 'bg-blue-500 text-white' : ''}
                        ${batch?.status === 'draft' ? 'bg-yellow-500 text-white' : ''}
                        ${batch?.status === 'sequestered' ? 'bg-yellow-700 text-white' : ''}
                        ${batch?.status === 'accepted' ? 'bg-green-500 text-white' : ''}
                        ${batch?.status === 'cancelled' ? 'bg-red-500 text-white' : ''}
                        ${batch?.status === 'deleted' ? 'bg-gray-500 text-white' : ''}
                        ${batch?.status === 'completed' ? 'bg-green-500 text-white' : ''}`}
                        value={batch?.status ? batch.status.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : "Not Available"}
                        readOnly
                    />
                </div>
                <div className="pr-10">
                    <label className="block text-md font-semibold text-gray-700">Preparation ID</label>
                    <input
                        type="text"
                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                        value={batch.preparation_id || "Not Available"} readOnly
                    />
                </div>
                <div className="pr-10">
                    <label className="block text-md font-semibold text-gray-700">Pyrolysis ID</label>
                    <input
                        type="text"
                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                        value={batch.pyrolysis_id || "Not Available"} readOnly
                    />
                </div>
                <div className="pr-10">
                    <label className="block text-md font-semibold text-gray-700">Biochar ID</label>
                    <input
                        type="text"
                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                        value={batch.biochar_id || "Not Available"} readOnly />
                </div>
                <div className="pr-10">
                    <label className="block text-md font-semibold text-gray-700">Sequestration ID</label>
                    <input
                        type="text"
                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                        value={batch.sequestration_id || "Not Available"} readOnly
                    />
                </div>
                <div className="pr-10">
                    <label className="block text-md font-semibold text-gray-700">Artisan ID</label>
                    <input
                        type="text"
                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                        value={batch.artisan_id || "Not Available"} readOnly
                    />
                </div>
                <div className="pr-10">
                    <label className="block text-md font-semibold text-gray-700">Pyrolysis Details</label>
                    <input
                        type="text"
                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                        value={batch.pyrolysis_detail?.pyrolysis_id || "Not Available"} readOnly
                    />
                </div>
                <div className="pr-10">
                    <label className="block text-md font-semibold text-gray-700">Biochar Details</label>
                    <input
                        type="text"
                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                        value={batch.biochar_detail?.biochar_weight || "Not Available"} readOnly
                    />
                </div>
                <div className="pr-10">
                    <label className="block text-md font-semibold text-gray-700">Created At</label>
                    <div className="flex space-x-4">
                        <input
                            type="text"
                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                            value={formatDate(batch.createdAt).date} readOnly
                        />
                        <input
                            type="text"
                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                            value={formatDate(batch.createdAt).time} readOnly
                        />
                    </div>
                </div>
                <div className="pr-10">
                    <label className="block text-md font-semibold text-gray-700">Updated At</label>
                    <div className="flex space-x-4">
                        <input
                            type="text"
                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                            value={formatDate(batch.updatedAt).date} readOnly
                        />
                        <input
                            type="text"
                            className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                            value={formatDate(batch.updatedAt).time} readOnly
                        />
                    </div>
                </div>
            </div>

            {/* Biomass Preparation Details */}
            {batch.biomass_preparation && (
                <>
                    <h2 className="text-xl font-bold mb-4 text-green-600 flex items-center w-1/2 py-5">
                        <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                        <span className="px-4 text-green-600">Biomass Preparation Details</span>
                        <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                    </h2>
                    <div className="grid grid-cols-2 gap-4 mb-10">
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Preparation ID</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.biomass_preparation?.preparation_id || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Artisan ID</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.biomass_preparation?.artisan_id || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Artisan Name</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.biomass_preparation?.artisan_name || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Artisan DOB</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.biomass_preparation?.artisan_dob).date} readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.biomass_preparation?.artisan_dob).time} readOnly
                                />
                            </div>
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Soil Pit ID</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.biomass_preparation?.soil_pit_id || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Pyrolysis Kiln ID</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.biomass_preparation?.pyrolysis_kiln_id || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Biomass Quantity</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.biomass_preparation?.biomass_quantity || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Biomass Composition ID</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.biomass_preparation?.biomass_composition_id || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Biomass Moistures</label>
                            <div className="grid grid-flow-col gap-2 auto-cols-fr">
                                {batch.biomass_preparation?.biomass_moistures?.length ? (
                                    batch.biomass_preparation.biomass_moistures.map((moisture: string | number, index: number) => (
                                        <input
                                            key={index}
                                            type="text"
                                            className="w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none text-center"
                                            value={moisture}
                                            readOnly
                                        />
                                    ))
                                ) : (
                                    <input
                                        type="text"
                                        className="w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        value="Not Available" readOnly
                                    />
                                )}
                            </div>
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Avg Moisture Percentage</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.biomass_preparation?.avg_moisture_percentage || "Not Available"} readOnly
                            />
                        </div>
                        <ImageField label="Biomass Photo" imageUrl={batch.biomass_preparation?.biomass_photo} createdAt={batch.biomass_preparation?.createdAt} />
                        <ImageField label="Moisture Meter Photo" imageUrl={batch.biomass_preparation?.moisture_meter_photo} createdAt={batch.biomass_preparation?.createdAt} />
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Created At</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.biomass_preparation?.createdAt).date} readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.biomass_preparation?.createdAt).time} readOnly
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}

            {/* Pyrolysis Details */}
            {batch.pyrolysis_detail && (
                <>
                    <h2 className="text-xl font-bold mb-4 text-green-600 flex items-center w-1/2 py-5">
                        <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                        <span className="px-4 text-green-600">Pyrolysis Details</span>
                        <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                    </h2>
                    <div className="grid grid-cols-2 gap-4 mb-10">
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Pyrolysis ID</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.pyrolysis_detail?.pyrolysis_id || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Start Time</label>
                            <div className="flex space-x-4">
                                <input type="text" className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none" value={formatDate(batch.pyrolysis_detail?.start_time).date} readOnly />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.pyrolysis_detail?.start_time).time} readOnly
                                />
                            </div>
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">End Time</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.pyrolysis_detail?.end_time).date} readOnly />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.pyrolysis_detail?.end_time).time} readOnly
                                />
                            </div>
                        </div>
                        <ImageField
                            label="Initial Stage Image"
                            imageUrl={batch.pyrolysis_detail?.initial_stage_image}
                            createdAt={batch.pyrolysis_detail?.start_time}
                        />
                        <ImageField
                            label="Ongoing Stage Image"
                            imageUrl={batch.pyrolysis_detail?.ongoing_stage_image}
                            createdAt={batch.pyrolysis_detail?.start_time}
                        />
                        <ImageField
                            label="Final Stage Image"
                            imageUrl={batch.pyrolysis_detail?.final_stage_image}
                            createdAt={batch.pyrolysis_detail?.end_time}
                        />
                    </div>
                </>
            )}

            {/* Biochar Details */}
            {batch.biochar_detail && (
                <>
                    <h2 className="text-xl font-bold mb-4 text-green-600 flex items-center w-1/2 py-5">
                        <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                        <span className="px-4 text-green-600">BioChar Details</span>
                        <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                    </h2>
                    <div className="grid grid-cols-2 gap-4 mb-10">
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Biochar Detail ID</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.biochar_detail?.biochar_id || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Measuring Vessel ID</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.biochar_detail?.measuring_vessel_id || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Vessel Qty</label>
                            <input
                                type="text" className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.biochar_detail?.vessel_qty || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Biochar Weight</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.biochar_detail?.biochar_weight || "Not Available"} readOnly
                            />
                        </div>
                        <ImageField
                            label="Biochar Image"
                            imageUrl={batch.biochar_detail?.biochar_image}
                            createdAt={batch.biochar_detail?.new_sampling_date}
                        />
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">New Sampling Date</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.biochar_detail?.new_sampling_date).date} readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.biochar_detail?.new_sampling_date).time} readOnly
                                />
                            </div>
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Created At</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.biochar_detail?.created_at).date} readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.biochar_detail?.created_at).time} readOnly
                                />
                            </div>
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Updated At</label>
                            <div className="flex space-x-4">
                                <input type="text" className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none" value={formatDate(batch.biochar_detail?.updated_at).date} readOnly />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.biochar_detail?.updated_at).time} readOnly
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}

            {/* Sampling and Retention Details */}
            {batch.biochar_detail && (
                <>
                    <h2 className="text-xl font-bold mb-4 text-green-600 flex items-center w-1/2 py-5">
                        <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                        <span className="px-4 text-green-600">Sampling and Retention Details</span>
                        <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                    </h2>
                    <div className="grid grid-cols-2 gap-4 mb-10">
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Sampling Date</label>
                            <div className="flex space-x-4">
                                <input type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.biochar_detail?.sampling_date).date} readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.biochar_detail?.sampling_date).time} readOnly
                                />
                            </div>
                        </div>
                        <ImageField
                            label="Sampling Image"
                            imageUrl={batch.biochar_detail?.sampling_image}
                            createdAt={batch.biochar_detail?.sampling_date}
                        />
                        <ImageField
                            label="Retention Bucket Image"
                            imageUrl={batch.biochar_detail?.retention_bucket_img}
                            createdAt={batch.biochar_detail?.sampling_date}
                        />
                    </div>
                </>
            )}

            {/* Sequestration Details */}
            {batch.sequestration && (
                <>
                    <h2 className="text-xl font-bold mb-4 text-green-600 flex items-center w-1/2 py-5">
                        <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                        <span className="px-4 text-green-600">Sequestration Details</span>
                        <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                    </h2>
                    <div className="grid grid-cols-2 gap-4 mb-10">
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Sequestration ID</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.sequestration?.sequestration_id || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">SP ID</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.sequestration?.sp_id || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Status</label>
                            <input
                                type="text"
                                className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 outline-none border-none 
                                    ${batch.sequestration?.status === 'draft' ? 'bg-yellow-500 text-white' : ''}
                                    ${batch.sequestration?.status === 'sequestered' ? 'bg-yellow-700 text-white' : ''}
                                    ${batch.sequestration?.status === 'accepted' ? 'bg-green-500 text-white' : ''}
                                    ${batch.sequestration?.status === 'cancelled' ? 'bg-red-500 text-white' : ''}
                                    ${batch.sequestration?.status === 'deleted' ? 'bg-gray-500 text-white' : ''}
                                    ${batch.sequestration?.status === 'submitted' ? 'bg-blue-500 text-white' : ''}
                                    ${batch.sequestration?.status === 'completed' ? 'bg-green-500 text-white' : ''}`}
                                value={batch.sequestration?.status ? batch.sequestration.status.split(' ').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : "Not Available"}
                                readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Biomass Batch ID</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.sequestration?.biomass_batch_id || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Batch Date</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.sequestration?.batch_date).date} readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.sequestration?.batch_date).time} readOnly
                                />
                            </div>
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Incorporation Time</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.sequestration?.incorporation_time).date} readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.sequestration?.incorporation_time).time} readOnly
                                />
                            </div>
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Biochar Weight</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.sequestration?.biochar_weight || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Compost Details</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.sequestration?.compost_details || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Sequestration Main Type</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.sequestration?.sequestration_type?.main_type ? batch.sequestration.sequestration_type.main_type.replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase()) : "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Sequestration Subtype</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.sequestration?.sequestration_type?.subtype || "Not Available"} readOnly
                            />
                        </div>
                        <ImageField
                            label="Mixing Image"
                            imageUrl={batch.sequestration?.mixing_image}
                            createdAt={batch.sequestration?.created_at}
                        />
                        <ImageField
                            label="Final Image"
                            imageUrl={batch.sequestration?.final_image}
                            createdAt={batch.sequestration?.created_at} />
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Created At</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.sequestration?.created_at).date} readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.sequestration?.created_at).time} readOnly
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}

            {/* Artisan Details */}
            {batch.artisan && (
                <>
                    <h2 className="text-xl font-bold mb-4 text-green-600 flex items-center w-1/2 py-5">
                        <span className="flex-1 h-0.5 bg-gradient-to-l from-green-600 to-transparent"></span>
                        <span className="px-4 text-green-600">Artisan Details</span>
                        <span className="flex-1 h-0.5 bg-gradient-to-r from-green-600 to-transparent"></span>
                    </h2>
                    <div className="grid grid-cols-2 gap-4 mb-10">
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Artisan ID</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.artisan?.artisan_id || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Artisan Name</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.artisan?.name ? batch.artisan.name.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ") : "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Address</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.artisan?.address ? batch.artisan.address.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ") : "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Company Email</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.artisan?.company_email || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Company Mobile</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.artisan?.company_mobile || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Company Registration ID</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.artisan?.company_registration_id || "Not Available"} readOnly
                            />
                        </div>
                        <ImageField
                            label="Site Image"
                            imageUrl={batch.artisan?.site_image_url}
                            createdAt={batch.artisan?.createdAt}
                        />
                        <ImageField
                            label="Artisan KYC Document"
                            imageUrl={batch.artisan?.artisan_kyc_doc_url}
                            createdAt={batch.artisan?.createdAt}
                        />
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Seller ID</label>
                            <input
                                type="text"
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                value={batch.artisan?.seller_id || "Not Available"} readOnly
                            />
                        </div>
                        <div className="pr-10">
                            <label className="block text-md font-semibold text-gray-700">Created At</label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.artisan?.createdAt).date} readOnly
                                />
                                <input
                                    type="text"
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    value={formatDate(batch.artisan?.createdAt).time} readOnly
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );

    let content = null;
    if (error) {
        content = <p className="mt-6 text-center">An error has occurred: {error.message}</p>;
    } else if (isLoading) {
        content = <p className="mt-6 text-center">Loading...</p>;
    } else if (data && data.length > 0) {
        content = data.map((batch, index) => <BatchCard key={batch.biomass_batch_id || index} batch={batch} />);
    } else {
        content = <p className="mt-6 text-center">No batch details available</p>;
    }

    return (
        <div className="users h-full w-full">
            <div className="all-users">
                <header className="flex items-center justify-between mb-8">
                    <div className="left">
                        <SearchBox
                            searchValue={q}
                            setSearchValue={setQ}
                            handleSearch={() => { }}
                        />
                    </div>
                    <div className="right">
                        <button
                            onClick={handleDownloadAll}
                            className="flex items-center gap-2 px-4 py-2 text-white bg-green-600 hover:bg-green-700 rounded-lg"
                        >
                            <FaDownload size={18} />
                            Download
                        </button>
                    </div>
                </header>
                <div className="w-full px-0">
                    {content}
                </div>
            </div>
            {selectedImage && <ImageViewer
                imageUrl={selectedImage}
                onClose={() => setSelectedImage(null)}
            />}
        </div>
    );
};

export default AllBatchDetails;